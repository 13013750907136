/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import wiegantFormat from '../../utils/api/wiegantFormat';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { WiegandFormatReduxInterface } from '../../interface/wiegandFormat.interface';

export const fetchWiegandFormatList = createAsyncThunk(
  'wiegandFormat/fetchWiegandFormatList',
  async (data: any, { dispatch }) => {
    const response = await wiegantFormat
      .getWiegandFormat(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.list.map((val: any) => ({
              ...val,
              ...(val?.initFlag ? { delete: false } : {}), // Add delete: false to the first 9 items
              autoMode: val?.isDefaultFmt ? 'Yes' : 'No',
              parityCheckFormat: val?.parityFmt,
              cardCheckFormat: val?.cardFmt,
            })),
            size: response1.data.data.size,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: WiegandFormatReduxInterface = {
  wiegandFormats: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const wiegandSlice: any = createSlice({
  name: 'wiegandFormat',
  initialState,
  reducers: {
    addWiegandFormat: (state, action: any) => {
      if (state.wiegandFormats.find((val: any) => val.id === action.payload.id)) {
        state.wiegandFormats = state.wiegandFormats.map((val: any) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
            output.autoMode =
              action.payload?.isDefaultFmt || action.payload?.isDefaultFmt === 'Yes' ? 'Yes' : 'No';
            output.parityCheckFormat = action.payload?.parityFmt;
            output.cardCheckFormat = action.payload?.cardFmt;
          }
          return output;
        });
      } else {
        state.wiegandFormats = [
          {
            ...action.payload,
            autoMode: action.payload?.isDefaultFmt ? 'Yes' : 'No',
            parityCheckFormat: action.payload?.parityFmt,
            cardCheckFormat: action.payload?.cardFmt,
          },
          ...state.wiegandFormats,
        ];
        state.totalDataCount += 1;
        if (state.wiegandFormats.length > 10) {
          state.wiegandFormats.pop();
        }
      }
    },
    deleteWiegandFormat: (state, action: any) => {
      state.wiegandFormats = state.wiegandFormats.filter((val: any) => val.code !== action.payload);
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWiegandFormatList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWiegandFormatList.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          state.wiegandFormats = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchWiegandFormatList.rejected, (state) => {
        state.status = 'success';
      });
  },
});

export const { setSearchFilter, deleteWiegandFormat, addWiegandFormat } = wiegandSlice.actions;

export default wiegandSlice.reducer;
