/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import accessRightsByDoor from '../../utils/api/AccControlReports/accessRightsByDoor';

export const fetchDoorList = createAsyncThunk(
  'acc/fetchDoorList',
  async (data: any, { dispatch }) => {
    const response = await accessRightsByDoor
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((item: any) => ({
              ...item,
              id: item.id,
            })),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

export const fetchPersonList = createAsyncThunk(
  'acc/fetchPersonList',
  async (data: any, { dispatch }) => {
    const response = await accessRightsByDoor
      .personList(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data,
            size: response1.data.data.length,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReduxInterface {
  doorList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
  personList: any;
  doorStatus: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  doorTotalDataCount: number;
  doorCurrentPageNo: number;
  doorSearchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  doorList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  personList: [],
  doorStatus: 'success',
  doorTotalDataCount: 0,
  doorCurrentPageNo: 0,
  doorSearchFilter: {},
};

export const accessRightByDoorSlice: any = createSlice({
  name: 'accessByDoor',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    setDoorSearchFilter: (state, action: any) => {
      state.doorSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDoorList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchDoorList.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          state.doorList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchDoorList.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchPersonList.pending, (state = initialState) => {
        state.doorStatus = 'loading';
      })
      .addCase(fetchPersonList.fulfilled, (state, action: any) => {
        if (action.payload.success) {
          state.doorTotalDataCount = action.payload.data.size;
          state.doorCurrentPageNo = action.payload.data.pageNo;

          if (state.currentPageNo === 1) {
            state.personList = action.payload.data.list;
          } else {
            state.personList = [...state.personList, ...action.payload.data.list];
          }
        }
        state.doorStatus = 'success';
      })
      .addCase(fetchPersonList.rejected, (state: any) => {
        state.doorStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setDoorSearchFilter } = accessRightByDoorSlice.actions;

export default accessRightByDoorSlice.reducer;
