/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import interlock from '../../utils/api/AccessRule/interlock';
import { setNotificationError } from './NotificationSlices';

export const fetchInterlock = createAsyncThunk(
  'interlock/fetchInterlock',
  async (data: any, { dispatch }) => {
    const apiResponse = await interlock
      .getListPage(data)
      .then((response) => {
        return {
          ...response.data,
          data: {
            list: response.data.data.data,
            size: response.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return apiResponse;
  },
);

const initialState: any = {
  interlock: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const interlockSlice: any = createSlice({
  name: 'interlock',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    deleteInterlock: (state, action: any) => {
      state.interlock = state.interlock.filter((val: any) => val.code !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchCardList actions
    builder
      .addCase(fetchInterlock.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInterlock.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.interlock = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchInterlock.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteInterlock } = interlockSlice.actions;

export default interlockSlice.reducer;
