import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field'; // New import for radio buttons
import { EditReaderProps } from '../../../interface/accessReader.interface';
import reader from '../../../utils/api/Devices/reader';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { editReader } from '../../../redux/slices/ReaderSlice';

function EditReader({ initialData, closeModal }: EditReaderProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [editData, setEditData] = useState(initialData);
  const [checkExistName, setCheckExistName] = useState(true);
  const [disable, setDisable] = useState(true);

  // Common onChange function to handle input changes
  const onChange = (name: string, value: any) => {
    console.log(value);
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onBlur = async (name: string, value: any) => {
    if (name === 'name' && value !== '' && value !== initialData?.name) {
      const response = await reader.isExist({ name: value });
      setCheckExistName(response?.data);
    }
  };

  const saveChanges = () => {
    const req = {
      name: editData?.name,
      id: editData?.id,
    };
    reader
      .saveReader(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(editReader(editData));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    if (editData.name && checkExistName) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [editData]);
  return (
    <div className="edit-reader">
      <form className="form">
        <div className="input-row">
          <InputField
            id="doorName"
            value={editData.doorName}
            label={t('doorName')}
            type="text"
            name="doorName"
            onChangeFunction={(name, value) => onChange(name, value)}
            disabled
            required
          />
          <InputField
            id="name"
            value={editData.name}
            label={t('name')}
            type="text"
            name="name"
            onChangeFunction={(name, value) => onChange(name, value)}
            required
            onBlurFunction={onBlur}
            errorStatus={!checkExistName}
            message={t('nameIsAlreadyExist')}
          />
        </div>
        <div className="input-row">
          <InputField
            id="number"
            value={editData.number}
            label={t('number')}
            type="number"
            name="number"
            onChangeFunction={(name, value) => onChange(name, value)}
            required
            disabled
          />
          <RadioButtonField
            label={t('in/Out')}
            name="inOut"
            alignment="row"
            required
            data={[
              {
                label: t('in'),
                id: 'in',
                value: 'in',
                disabled: 'disabled',
              },
              {
                label: t('out'),
                id: 'out',
                value: 'out',
                disabled: 'disabled',
              },
            ]}
            value={editData.inOut}
            onChangeFunction={(name, value) => onChange(name, value)}
          />
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={saveChanges}
            title={t('ok')}
            className="btn-primary"
            disabled={disable}
          />
        </div>
      </form>
    </div>
  );
}

export default EditReader;
