import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/antiPassback/listByPage`, {}, { ...data });
  },

  selectDevicelist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/antiPassback/selectDevicelist`, data, {});
  },

  validGlobalApb: (id: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/antiPassback/validGlobalApb/${id}`, {}),

  getRule: (data: string) =>
    get(`${process.env.REACT_APP_API_URL}Acc/api/antiPassback/getRule/${data}`, {}),

  saveAntiPassback: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/antiPassback/save`, data, {});
  },

  deleteAntiPassback: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/antiPassback/delete?ids=${id}`, {});
  },
};
