import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import accessByPerson from '../../../utils/api/AccessRule/accessByPerson';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

interface SynchronizeLevelinterface {
  closeModal: () => void;
  ids: string[];
}
function SynchronizeLevel({ closeModal, ids }: SynchronizeLevelinterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const submit = () => {
    accessByPerson
      .syncPersonLevel(ids)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personSynchronizeSuccessfully'),
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  return (
    <div className="camera-modal">
      <div className="camera-p">
        <p>{t('areYouWantToPerformTheSynchronizeLevelOperation?')}</p>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => closeModal()}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary btn-bold" />
      </div>
    </div>
  );
}

export default SynchronizeLevel;
