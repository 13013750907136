/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import globalInterlockGroup from '../../utils/api/AdvancedFunctions/globalInterlockGroup';

interface Level {
  id: string;
}
export const fetchGlobalInterlockGroup = createAsyncThunk(
  'globalInterlockGroup/fetchGlobalInterlockGroup',
  async (data: any, { dispatch }) => {
    const apiResponse = await globalInterlockGroup
      .listByPage(data)
      .then((response) => {
        return {
          ...response.data,
          data: {
            list: response.data.data.data,
          },
          size: response.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return apiResponse;
  },
);
export const fetchInterlockDoorList = createAsyncThunk(
  'fetchInterlockDoorList',
  async (data: any, { dispatch }) => {
    try {
      const response = await globalInterlockGroup.getPersonDoorList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.data,
        },
        size: response.data.data.total,
        ...data,
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
export const fetchPersonList = createAsyncThunk(
  'fetchPersonList',
  async (data: any, { dispatch }) => {
    try {
      const response = await globalInterlockGroup.getDoorlist(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

interface GlobalInterface {
  globalInterlockList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
  persons: any;
  doorId: string;
  groupStatus: 'loading' | 'success' | 'failed';
  doorTotalDataCount: number;
  personSearchFilter: { [key: string]: any };
  levels: Level[];
  levelStatus: string;
  levelTotalDataCount: number;
  levelCurrentPageNo: number;
  levelSearchFilter: Record<string, any>;
}

const initialState: GlobalInterface = {
  globalInterlockList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  persons: [],
  doorId: '',
  groupStatus: 'success',
  doorTotalDataCount: 0,
  personSearchFilter: {},
  levels: [],
  levelStatus: 'success',
  levelTotalDataCount: 0,
  levelCurrentPageNo: 0,
  levelSearchFilter: {},
};

export const globalInterlockGroupSlice: any = createSlice({
  name: 'globalInterlockGroup',
  initialState,
  reducers: {
    deleteInterlockGroup: (state, action: any) => {
      state.globalInterlockList = state.globalInterlockList.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    deleteLevels: (state, action: any) => {
      state.persons = state.persons.filter((val: any) => val.id !== action.payload);
      state.doorTotalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    setLevelSearchFilter: (state, action: any) => {
      state.personSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchGlobalInterlockGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGlobalInterlockGroup.fulfilled, (state, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.size;
          state.globalInterlockList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchGlobalInterlockGroup.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchPersonList.pending, (state) => {
        state.groupStatus = 'loading';
      })
      .addCase(fetchPersonList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.doorTotalDataCount = action.payload.data.size;
          state.levelCurrentPageNo = action.payload.data.pageNo;

          state.persons = action.payload.data.list;
        }
        state.groupStatus = 'success';
      })
      .addCase(fetchPersonList.rejected, (state) => {
        state.groupStatus = 'failed';
      });
    builder
      .addCase(fetchInterlockDoorList.pending, (state) => {
        state.levelStatus = 'loading';
      })
      .addCase(fetchInterlockDoorList.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.levelTotalDataCount = action.payload.size;

          state.levels = action.payload.data.list as Level[];
        }
        state.levelStatus = 'success';
      })
      .addCase(fetchInterlockDoorList.rejected, (state) => {
        state.levelStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearchFilter,
  deleteInterlockGroup,
  setLevelSearchFilter,
  resetLevels,
  deleteLevels,
} = globalInterlockGroupSlice.actions;

export default globalInterlockGroupSlice.reducer;
