import { get, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accOccupancy/list`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accOccupancy/save`, data, {});
  },
  isExistName: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/accOccupancy/isExist`, {}, data),
  delete: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accOccupancy/delete`, {}, data);
  },
  getZoneList: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/zone/getZoneList?zoneExpOutside=1`, {});
  },
};
