import React, { Fragment } from 'react';

// Use assets svg icons
import { RightArrowIcon } from '../../assets/svgicons/svgicon';

function TopHeader({ title, broadCram }: { title: string; broadCram: any[] }) {
  return (
    <div className="top-header">
      <div className="header-title">{title}</div>
      <div className="header-broad-cram">
        {broadCram.map((val, index) => {
          return (
            <Fragment key={val.title}>
              <div className="title">{val.title}</div>
              {index !== broadCram.length - 1 && (
                <div className="icon">
                  <RightArrowIcon />
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default TopHeader;
