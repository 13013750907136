import { get, post } from '../base/index';

export default {
  getAllDoor: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/getAllDoor`, {});
  },

  getAllAuxIn: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/getAllAuxIn`, {});
  },

  getAllAuxOut: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/getAllAuxOut`, {});
  },

  openDoor: (data: any = {}, interval: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/openDoor?ids=${data}&openInterval=${interval}`,
      {},
    ),

  closeDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/closeDoor?ids=${data}`, {}),

  cancelAlarm: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/cancelAlarm?ids=${data}`, {}),

  lockDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/lockDoor?ids=${data}`, {}),

  unLockDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/unLockDoor?ids=${data}`, {}),

  normalOpenDoor: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/normalOpenDoor?ids=${data}`, {}),

  enableNormalOpenDoor: (data: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/enableNormalOpenDoor?ids=${data}`,
      {},
    ),

  disableNormalOpenDoor: (data: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/disableNormalOpenDoor?ids=${data}`,
      {},
    ),

  openAuxOut: (data: any = {}, interval: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/openAuxOut?ids=${data}&openInterval=${interval}`,
      {},
    ),

  closeAuxOut: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/closeAuxOut?ids=${data}`, {}),

  auxOutNormalOpen: (data: any = {}) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/realTimeMonitor/auxOutNormalOpen?ids=${data}`,
      {},
    ),
};
