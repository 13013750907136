import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputDateTime from '../../../shared-components/input-time-date/input-time-date';
import { LoadingProgressCount, sortValuesByStatus } from '../../../utils/helpers';
import deviceApi from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import { DevicePopupInterface } from '../../../interface/device.interface';

function GetTransaction({ closeModal, selectedValues, selectedId }: DevicePopupInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [responseMsg, setResponseMsg] = useState('');
  const [value, setValue] = useState({
    transactionMode: '1',
    validTime: [],
    startTime: dayjs().startOf('day'),
    endTime: dayjs().endOf('day'),
  });
  const options = [
    {
      label: t('getNewTransactions'),
      id: '1',
      value: '1',
    },
    {
      label: t('getAllTransactions'),
      id: '0',
      value: '0',
    },
  ];
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const getOnlineDevice = !selectedDevices.map((data: any) => data?.status).includes('online');
  const submit = () => {
    const request = {
      devId: selectedId?.[0],
      records: value?.transactionMode,
      setValidDate: value?.validTime?.[0] === 'yes' ? 'true' : 'false',
      startTime: value.startTime.format('YYYY-MM-DD HH:mm:ss'),
      endTime: value.endTime.format('YYYY-MM-DD HH:mm:ss'),
    };
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    deviceApi
      .uploadTransaction(request)
      .then(() => {
        setResponseMsg('true');
        setTimeout(() => {
          stopProgress();
          setLoading('0');
        }, 3000);
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: values,
    }));
  };
  const changeDateTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };
  const changeDateEndTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };
  // Handle when the user clicks the OK button
  const handleAcceptStartTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      startTime: dayjs(data),
    }));
  };
  const handleAcceptEndTime = (data: any) => {
    setValue((prev: any) => ({
      ...prev,
      endTime: dayjs(data),
    }));
  };
  const getPersonnelInfo = () => {
    const request = {
      id: selectedId,
      type: 'uploadTransaction',
    };
    deviceApi
      .getUploadTransactionInfo(request)
      .then((res) => {
        console.log(res.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    getPersonnelInfo();
  }, []);
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <div className="select-device-wrapper">
                    <RadioButtonField
                      label=""
                      name="status"
                      alignment="column"
                      data={[
                        {
                          label: `${device.deviceName}: `,
                          id: 'on',
                          value: 'on',
                        },
                      ]}
                      value="on"
                      onChangeFunction={() => {}}
                    />
                    <span className="active-name">{device.alias}</span>
                  </div>
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.alias)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.alias)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <RadioButtonField
            label=""
            name="transactionMode"
            alignment="row-wrap"
            data={options}
            value={value?.transactionMode}
            onChangeFunction={onChangeValue}
          />
          <CheckBoxInputField
            name="validTime"
            data={[
              {
                label: t('setValidTime'),
                id: 'yes',
                value: 'yes',
              },
            ]}
            value={value?.validTime}
            onChangeFunction={onChangeValue}
          />
          {value?.validTime?.[0] === 'yes' && (
            <div className="time-input">
              <InputDateTime
                label="start time"
                value={value?.startTime}
                onChangeFunction={changeDateTime}
                okFunction={handleAcceptStartTime}
              />
              <InputDateTime
                label="end time"
                value={value?.endTime}
                onChangeFunction={changeDateEndTime}
                okFunction={handleAcceptEndTime}
              />
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={getOnlineDevice || loading === '1'}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && responseMsg && (
                <div className="success-wrapper">
                  <p>Data Processing, Please Wait.....</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Sending commands completed
                  </p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Get Event Log successfully.
                  </p>
                  <p>No data are returned from the device.</p>
                  <p>Completed...</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GetTransaction;
