import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import DatePickerField from '../../../shared-components/date-picker-field/date-picker-field';

// Use interface
import { EditDismissedPersonnelInterFace } from '../../../interface/dismissed-personnel.interface';

// Use asset json file
import dismissalTypes from '../../../assets/json/dismissal-type.json';

// Use api calls
import PersonApi from '../../../utils/api/person';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { changeDateFormat } from '../../../utils/helpers';
import { editDismissedPerson } from '../../../redux/slices/DismissalPersonSlices';

function EditDismissedPersonnel({ closeModal, data }: EditDismissedPersonnelInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectData = dismissalTypes.map((val) => ({
    ...val,
    label: t(val.label),
  }));

  const [disableButton, setDisableButton] = useState(true);
  const [submitButton, setSubmitButton] = useState(false);
  const [dismissedPersonnelValue, setDismissedPersonnelValue] = useState(
    data || {
      leaveDate: '',
      leaveType: '',
      leaveReason: '',
      pin: '',
    },
  );
  const onChangeValue = (name: string, value: any) => {
    setDismissedPersonnelValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    setDisableButton(true);
    const request = {
      leaveDate: changeDateFormat(dismissedPersonnelValue.leaveDate),
      leaveReason: dismissedPersonnelValue.leaveReason,
      leaveType: dismissedPersonnelValue.leaveType,
      pins: dismissedPersonnelValue.pin,
    };
    PersonApi.editDismissedPersons(request)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('dismissalPersonnelEditSuccessfully'),
              status: 200,
            }),
          );
          dispatch(editDismissedPerson(response.data.data));
          setSubmitButton(true);
          setDisableButton(false);
          closeModal();
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    let i = 0;
    if (
      dismissedPersonnelValue.leaveDate === '' ||
      dismissedPersonnelValue.leaveType === '' ||
      dismissedPersonnelValue.leaveReason === ''
    ) {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [dismissedPersonnelValue]);
  return (
    <div className="add-department">
      <div className="add-department-form">
        <div className="row row-2-1">
          <div className="col">
            <DatePickerField
              name="leaveDate"
              label={t('dismissalDate')}
              value={dismissedPersonnelValue.leaveDate}
              required
              errorStatus={dismissedPersonnelValue.leaveDate === '' && submitButton}
              message={
                dismissedPersonnelValue.leaveDate === '' ? 'Department Number is required' : ''
              }
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <SelectBoxField
              name="leaveType"
              label={t('dismissalType')}
              id="leaveType"
              data={selectData}
              value={dismissedPersonnelValue.leaveType}
              className=""
              required
              errorStatus={dismissedPersonnelValue.leaveType === '' && submitButton}
              message={
                dismissedPersonnelValue.leaveType === '' ? 'Parent Dismissal Type is required' : ''
              }
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col full-field">
            <InputTextAreaField
              name="leaveReason"
              label={t('dismissalReason')}
              id="leaveReason"
              value={dismissedPersonnelValue.leaveReason}
              required
              innerIconStatus={false}
              errorStatus={dismissedPersonnelValue.leaveReason === '' && submitButton}
              message={
                dismissedPersonnelValue.leaveReason === '' ? 'Department Name is required' : ''
              }
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default EditDismissedPersonnel;
