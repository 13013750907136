import { get, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/list`, {}, { ...data });
  },
  getAccReaderList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/getAccReaderList`, {}, data);
  },
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/save`, data, {});
  },
  zoneTree: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/zone/tree`, {});
  },
  deleteReader: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/delete`, {}, data);
  },
  addReader: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/addReaders`, {}, data);
  },
  accSelectReader: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accSelectReader/list`, data, {});
  },
  checkDevIssueVerify: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accReaderZone/checkDevIssueVerify`,
      {},
      data,
    );
  },
};
