import { post } from '../base';

export default {
  getVerifyModeGroupList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRulePersonGroup/list`,
      {},
      { ...data },
    );
  },
  getPersonList: (data: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRulePersonGroup/personList`,
      data,
      {},
    );
  },
  addPerson: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRulePersonGroup/addPerson`,
      {},
      data,
    );
  },
  delPerson: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRulePersonGroup/delPerson`,
      {},
      data,
    );
  },
};
