import React, { useRef } from 'react';

// Use interface
import { ModalBoxInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { CloseIcon } from '../../assets/svgicons/svgicon';

function ModalBox({ status, closeModal, title, content }: ModalBoxInterFace) {
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    if (ref.current === event.target) {
      closeModal();
    }
  };
  return status ? (
    <div className="modal" onClick={handleClickOutside} role="presentation" ref={ref}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-title">{title}</div>
          <span className="close" onClick={closeModal} role="presentation">
            <CloseIcon color="#000" />
          </span>
        </div>
        <div className="modal-body">{content}</div>
      </div>
    </div>
  ) : (
    <> </>
  );
}

export default ModalBox;
