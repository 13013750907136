interface ValidationErrors {
  username?: string;
  password?: string;
  form?: string;
}

export type { ValidationErrors };

const emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordValidator = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*()_+[\]{};':"\\|,.<>/?~-]{8,}$/;
const phoneNumberValidator = /^\+?[1-9]\d{1,14}$/;
const numberValidator = /^[-+]?\d+$/;

type ValidationResult = boolean;

interface LoginFormData {
  username: string;
  password: string;
}

// Email Validation
export const emailValidation = (value: string): ValidationResult => emailValidator.test(value);

// REGEX for name allowing any language characters
const createNameRegex = (minLength: number, maxLength: number) => {
  const regexPattern = `^[\\p{L}\\d][\\p{L}\\p{N}._ @-]{${minLength - 1},${maxLength - 1}}$`;
  return new RegExp(regexPattern, 'u');
};

// REGEX for description allowing any language characters
const createDescriptionRegex = (maxLength = 500) => {
  const regexPattern = `^[\\p{L}\\p{N} .,!?;:'"()\\[\\]{}_/-]{0,${maxLength}}$`;
  return new RegExp(regexPattern, 'u');
};

// Name Validation
export const nameValidation = (minLength: number, maxLength: number, name: string = '') => {
  const NAME_REGEX = createNameRegex(minLength, maxLength);
  return NAME_REGEX.test(name);
};

// Description Validation
export const descriptionValidation = (maxLength: number, description: string = '') => {
  const DESCRIPTION_REGEX = createDescriptionRegex(maxLength);
  return DESCRIPTION_REGEX.test(description);
};

// Integer Validation
export const integerValidation = (min: number, max: number, number = 0) => {
  const value = Number(number);
  return value <= max && value >= min && numberValidator.test(String(value));
};

// Password Validation
export const passwordValidation = (value: string): ValidationResult =>
  passwordValidator.test(value);

// Phone number Validation
export const phoneNumberValidation = (value: string): ValidationResult =>
  phoneNumberValidator.test(value);

// Function to validate login form data
export const validateLoginData = (formData: LoginFormData): ValidationErrors => {
  const { username, password } = formData;
  const errors: ValidationErrors = {};

  if (!emailValidation(username)) {
    errors.username = 'Invalid mail ID';
  }

  if (!passwordValidation(password)) {
    errors.password =
      'Password must be at least 8 characters long and contain both letters and numbers';
  }

  return errors;
};

// ip address validation
export const isValidIpAddress = (ipAddress: string): boolean => {
  // Regular expression to validate IP address format (xxx.xxx.xxx.xxx)
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
  return ipRegex.test(ipAddress);
};
export const isValidMultipleEmail = (email: string): boolean => {
  const validEmail =
    /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})([,;][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/;
  return validEmail.test(email);
};

export const isIdNotInEnd = (id: string): boolean => {
  // check parent id have last value _
  const testIdRegex = /^((?!_).)*$/;
  return testIdRegex.test(id);
};

const base64Regex = /data:image\/[a-zA-Z]+;base64,([a-zA-Z0-9+/=]+)/;
export const isExtractBase64 = (img: string): boolean => {
  /**
   * @regex - use a regular expression to find and extract the base64 part.
   */
  return base64Regex.test(img);
};

export const matchExtractUrl = (val: string) => val.match(base64Regex);
