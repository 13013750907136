import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../input-field/input-field';
import SearchIcon, { RightArrowIcon } from '../../assets/svgicons/svgicon';
import Table from '../table/table';
import { SwapTableInterface } from '../../interface/common.interface';

function SwapTable({
  searchValue = '',
  changeSearch,
  tableColumns,
  tableData, // Available data
  selectedTableData = [], // Initially selected data
  onTableSwap,
  customStyle = {},
}: SwapTableInterface) {
  const { t } = useTranslation();

  // Add state for filtered available data only
  const [filteredAvailableData, setFilteredAvailableData] = useState(tableData);

  // State to manage selected items in both tables
  const [selectRow, setSelectRow] = useState<string[]>([]); // Track selected items in "available" table
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]); // Track selected items in "selected" table

  // Handle moving items from available to selected
  const handleSelectTable = () => {
    const newSelected = tableData.filter((item: any) => selectRow.includes(item.id));
    const remainingData = tableData.filter((item: any) => !selectRow.includes(item.id));
    onTableSwap(remainingData, [...selectedTableData, ...newSelected]); // Call the parent function to update the tables
    setSelectRow([]); // Clear selection
  };

  // Handle moving items from selected back to available
  const handleSelectSelectedTable = () => {
    const unselected = selectedTableData.filter((item: any) => !selectedTableRow.includes(item.id));
    const movedBackToAvailable = selectedTableData.filter((item: any) =>
      selectedTableRow.includes(item.id),
    );
    onTableSwap([...tableData, ...movedBackToAvailable], unselected); // Call the parent function to update the tables
    setSelectedTableRow([]); // Clear selection
  };
  const onChangeSearch = (name: string, value: any) => {
    changeSearch(name, value);
  };

  // Update filtered data when search value or table data changes
  useEffect(() => {
    if (!searchValue) {
      setFilteredAvailableData(tableData);
      return;
    }

    const filtered = tableData.filter((item: any) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
    setFilteredAvailableData(filtered);
  }, [searchValue, tableData]);
  return (
    <div className="swap-table" style={customStyle}>
      <div className="search-input">
        <InputField
          name="name"
          label={t('search')}
          id="name"
          type="text"
          value={searchValue}
          innerIconStatus
          InnerIcon={SearchIcon}
          onChangeFunction={onChangeSearch}
        />
      </div>
      <div className="table-container">
        <div className="table-wrapper">
          <h4 className="title">{t('alternative')}</h4>
          <Table
            classes="bordered"
            header={tableColumns}
            value={filteredAvailableData}
            checkBoxStatus
            selectedValues={selectRow}
            onSelect={(selected) => setSelectRow(selected)}
          />
        </div>
        <div className="icons-wrapper">
          <div
            className={`icon ${selectRow.length === 0 ? 'disabled' : ''}`}
            role="none"
            onClick={handleSelectTable}>
            <RightArrowIcon />
          </div>
          <div
            className={`icon backward ${selectedTableRow.length === 0 ? 'disabled' : ''}`}
            role="none"
            onClick={handleSelectSelectedTable}>
            <RightArrowIcon />
          </div>
        </div>
        <div className="table-wrapper">
          <h4 className="title">
            {t('selected')} ({selectedTableData.length})
          </h4>
          <Table
            classes="bordered"
            header={tableColumns}
            value={selectedTableData}
            checkBoxStatus
            selectedValues={selectedTableRow}
            onSelect={(selected) => setSelectedTableRow(selected)}
          />
        </div>
      </div>
    </div>
  );
}

export default SwapTable;
