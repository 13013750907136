// Use interface
import { ButtonInterFace } from '../../interface/common.interface';
// import DotLoader from '../dot-loader/DotLoader';
import SpinnerLoader from '../spinnerLoader/spinner-loader';

function Button({ title, onClickFunction, disabled, className, icon, loading }: ButtonInterFace) {
  return (
    <div className="button-field">
      <button
        type="button"
        className={`btn ${className}`}
        onClick={onClickFunction}
        disabled={disabled}>
        {title} {icon}
        {loading && <SpinnerLoader />}
      </button>
    </div>
  );
}

export default Button;
