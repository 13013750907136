import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  fetchDoorVerify,
  fetchTimeSegList,
  fetchVerificationMode,
} from '../../../redux/slices/VerificationModeSlices';
import verificationMode from '../../../utils/api/AccessRule/verificationMode';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { checkAnyVerification, timeZoneDecode, verifySubmitData } from '../../../utils/helpers';
import {
  DayIntervalTimes,
  StateProps,
  TimeStateProps,
} from '../../../interface/VerificationMode.interface';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'holiday1',
  'holiday2',
  'holiday3',
];

const intervals = ['interval1', 'interval2', 'interval3'];

const initialTimeValues = () => {
  const initialState: DayIntervalTimes = {};
  days.forEach((day) => {
    initialState[day] = {};
    intervals.forEach((interval) => {
      initialState[day][interval] = {
        startTime: { hour: '00', minute: '00' },
        endTime: { hour: '00', minute: '00' },
        doorVerification: '',
        personnelVerification: '',
      };
    });
  });
  return initialState;
};
const initialValues = {
  name: '',
  copySettings: [],
  timeSegId: '',
  newVerifyMode: '0',
};

interface ModalType {
  closeModal: () => void;
  update?: any;
  searchFilter?: {
    pageIndex: 1;
    pageSize: 10;
  };
}
function AddVerificationMode({ closeModal, update, searchFilter }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { timeSegmentList, doorVerifyList } = useAppSelector(
    (state: any) => state.verificationMode,
  );
  const verificationOptions = [
    {
      label: t('oldVerificationMode'),
      id: '0',
      value: '0',
    },
    {
      label: t('newVerificationMode'),
      id: '1',
      value: '1',
    },
  ];
  const [timeValues, setTimeValues] = useState<DayIntervalTimes>(initialTimeValues);
  const [values, setValues] = useState<StateProps>({
    name: update.name || '',
    copySettings: [],
    timeSegId: update.timeSegId || '',
    newVerifyMode: update.newVerifyMode || '0',
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'title',
    content: <>hi</>,
  });
  const [disabled, setDisabled] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const [timeZoneList, setTimeZoneList] = useState<any>([]);

  const getTimeSegments = async (val: string) => {
    try {
      const response = await verificationMode.getTimeSegById({ id: val });
      if (response?.data.data) {
        const result = timeZoneDecode(response?.data?.data);
        setTimeValues(result);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  const onChangeValue = (
    day: string,
    interval: string,
    field: 'startTime' | 'endTime' | string,
    time: TimeStateProps | string | number,
  ) => {
    setTimeValues((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        [interval]: {
          ...prev[day][interval],
          [field]: time,
        },
      },
    }));
  };
  const onBlur = async (name: string, value: any) => {
    if (name === 'name' && value !== '') {
      const responseData = await verificationMode.isExistName({ name: value });
      setCheckExistName(responseData?.data);
    }
  };
  const handleChange = (name: string, value: any) => {
    if (name === 'verificationMode' && value === '2') {
      setModalOpen(true);
      setModalView({
        title: '',
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('thereIsNoDeviceThatSupportsTheNewVerificationMethod')}
            buttonLabel={t('ok')}
          />
        ),
      });
      return;
    }
    if (name === 'timeSegId') {
      getTimeSegments(value);
    }
    setValues((prev: StateProps) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'copySettings') {
      const mondayValues = Object.fromEntries(
        Object.entries(timeValues.monday).map(([key, door]: any) => [
          key,
          {
            doorVerification: door.doorVerification,
            personnelVerification: door.personnelVerification,
          },
        ]),
      );

      if (Object.keys(mondayValues)) {
        if (value.length > 0) {
          const updatedTimeValues = { ...timeValues };
          // Replicate only doorVerification to Tuesday through Friday
          days.slice(1, 5).forEach((day) => {
            updatedTimeValues[day] = Object.fromEntries(
              Object.entries(updatedTimeValues[day]).map(([key, entry]: any) => [
                key,
                {
                  ...entry, // Keep existing startTime and endTime
                  doorVerification: mondayValues[key]?.doorVerification, // Use Monday's doorVerification
                  personnelVerification: mondayValues[key]?.personnelVerification, // Use Monday's doorVerification
                },
              ]),
            );
          });
          setTimeValues(updatedTimeValues);
        } else {
          const updatedTimeValues = { ...timeValues };
          days.slice(1, 5).forEach((day) => {
            Object.keys(updatedTimeValues[day]).forEach((interval) => {
              updatedTimeValues[day][interval] = {
                startTime: { hour: '00', minute: '00' },
                endTime: { hour: '00', minute: '00' },
                personnelVerification: '',
                doorVerification: '',
              };
            });
          });
          setTimeValues(updatedTimeValues);
        }
      }
    }
  };

  const submit = async (params?: string) => {
    const verifyIsVerification = checkAnyVerification(timeValues);
    if (!verifyIsVerification) {
      setModalOpen(true);
      setModalView({
        title: '',
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('Please select at least one verification mode!')}
            buttonLabel={t('ok')}
          />
        ),
      });
      return;
    }
    setDisabled(true);
    const result = () => verifySubmitData(timeValues);
    try {
      const request = {
        name: values?.name,
        timeSegId: values?.timeSegId,
        ...result,
      };

      const response = await verificationMode.save(request);
      if (response) {
        const message =
          Object.keys(update)?.length > 0
            ? t('VerificationModeUpdatedSuccessfully')
            : t('VerificationModeAddedSuccessfully');
        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
        setDisabled(false);
        dispatch(
          fetchVerificationMode({
            pageNo: searchFilter?.pageIndex || 1,
            pageSize: searchFilter?.pageSize || 10,
          }),
        );
      }
      if (params) {
        closeModal();
      } else {
        setTimeValues(initialTimeValues);
        setValues(initialValues);
      }
    } catch (error: any) {
      setDisabled(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchTimeSegList(update?.timeSegId));
    dispatch(fetchDoorVerify());
    if (Object.keys(update)?.length > 0) {
      const list = [
        {
          label: update.timeSegName,
          id: update.timeSegId,
        },
      ];
      setTimeZoneList(list);
    } else {
      setTimeZoneList(timeSegmentList);
    }
  }, [update]);
  useEffect(() => {
    if (Object.keys(update)?.length > 0) {
      setTimeValues(update);
    }
  }, [update]);

  useEffect(() => {
    if (values.name && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values, checkExistName]);

  return (
    <div className="add-verification-mode">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row-wrap">
        <div className="form-row">
          <InputField
            id="name"
            name="name"
            label={t('ruleName')}
            type="text"
            value={values.name}
            onBlurFunction={onBlur}
            errorStatus={values.name !== '' && !checkExistName}
            message={t('nameIsAlreadyExist')}
            onChangeFunction={handleChange}
            required
          />
          <SelectBoxField
            name="timeSegId"
            label={t('timeZones')}
            id="timeSegId"
            value={values.timeSegId}
            required
            onChangeFunction={(name, val) => handleChange(name, val.id)}
            data={timeZoneList}
          />
        </div>
        <div className="form-row-1">
          <RadioButtonField
            label={t('verificationMode')}
            name="newVerifyMode"
            alignment="row-wrap"
            data={verificationOptions}
            value={values?.newVerifyMode}
            onChangeFunction={handleChange}
          />
          <CheckBoxInputField
            name="copySettings"
            data={[
              {
                label: t('copyMondaySettingstoOthersWeekdays'),
                id: 'yes',
                value: 'yes',
              },
            ]}
            value={values?.copySettings}
            onChangeFunction={handleChange}
            alignment="column"
          />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="date-time-header" colSpan={2} rowSpan={2}>
              <span>{t('dateTime')}</span>
            </th>
            {intervals.map((interval) => (
              <th key={interval} colSpan={4}>
                {t(interval)}
              </th>
            ))}
          </tr>
          <tr>
            {intervals.map((interval) => (
              <React.Fragment key={interval}>
                <th>{t('startTime')}</th>
                <th>{t('endTime')}</th>
                <th>{t('doorVerification')}</th>
                <th>{t('personnelVerification')}</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {days.map((day) => (
            <tr key={day}>
              <td colSpan={2}>{t(day)}</td>
              {intervals.map((interval) => {
                const timeState: any = timeValues?.[day]?.[interval];
                return (
                  <React.Fragment key={interval}>
                    <td>
                      <span>
                        {timeState?.startTime?.hour}: {timeState?.startTime?.minute}
                      </span>
                    </td>
                    <td>
                      <span>
                        {timeState?.endTime?.hour}: {timeState?.endTime?.minute}
                      </span>
                    </td>
                    <td>
                      <div className="table-inputs">
                        <SelectBoxField
                          name="doorVerification"
                          label={t('doorVerification')}
                          id="doorVerification"
                          value={timeValues?.[day]?.[interval]?.doorVerification}
                          onChangeFunction={(name, value) =>
                            onChangeValue(day, interval, name, value.id)
                          }
                          data={doorVerifyList}
                          className=""
                        />
                      </div>
                    </td>
                    <td>
                      <div className="table-inputs">
                        <SelectBoxField
                          name="personnelVerification"
                          label={t('personnelVerification')}
                          id="personnelVerification"
                          value={timeValues?.[day]?.[interval]?.personnelVerification}
                          onChangeFunction={(name, value) =>
                            onChangeValue(day, interval, name, value.id)
                          }
                          data={doorVerifyList}
                          className=""
                        />
                      </div>
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t(
          'RS485 reader can only follow the door verification mode, does not support the personnel verification mode.',
        )}
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => submit('save&New')}
          title={t('save&New')}
          className="btn-primary"
          disabled={disabled}
        />
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disabled}
        />
      </div>
    </div>
  );
}

AddVerificationMode.defaultProps = {
  update: {},
  searchFilter: {
    pageIndex: '',
    pageSize: '',
  },
};

export default AddVerificationMode;
