import React, { useState, useEffect } from 'react';

// i18next for handling language translation
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// For handling cookies
import Cookies from 'universal-cookie';

// React Router hooks for navigation and linking
import { useNavigate, Link } from 'react-router-dom';

// API calls for login
import LoginApis from '../../utils/api/auth';

// Shared input field component
import InputField from '../../shared-components/input-field/input-field';

// Shared button component
import Button from '../../shared-components/button/button';
import CheckBoxInputField from '../../shared-components/checkbox-input-field/checkbox-input-field';
import DropDown from '../../shared-components/drop-down/drop-down';

// Login page image asset
import loginImage from '../../assets/images/login-image.png';

// Fingerprint icon asset for alternative login
import fingerprintIcon from '../../assets/images/fingerprint-icon.png';

// SVG icons for view, hide password, and language flags
import { viewIcon, hideIcon, ArabicFlagIcon, EnglishFlagIcon } from '../../assets/svgicons/svgicon';

// Interface for dropdown menu items
import { DropDownMenuInterFace } from '../../interface/common.interface';

// Interfaces for form data and checkbox options
import { FormData, CheckBoxInterFace } from '../../interface/login.interface';

// Redux hook for dispatching actions
import { useAppDispatch } from '../../redux/store';

// Redux slice action for setting notification errors
import { setNotificationError } from '../../redux/slices/NotificationSlices';

function Login() {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLanguageCode = cookies.get('i18next');

  const [formData, setFormData] = useState<FormData>({
    username: '',
    password: '',
    agreement: [],
  });
  const [errors, setErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [activeLogin, setActiveLogin] = useState<'user' | 'person'>('user');
  const [disableButton, setDisableButton] = useState(true);

  const checkBoxOptions: CheckBoxInterFace[] = [
    {
      id: 'agreement',
      value: 'agreement',
      label: t('iHaveReadAndAgreeToTheProductUseAgreement'),
    },
  ];

  const validateFormData = (): void => {
    if (!formData.username || !formData.password || formData.agreement.length === 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  useEffect(() => {
    validateFormData();
  }, [formData, errors]);

  const handleInputChange = (name: string, value: any): void => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setDisableButton(true);
    try {
      const login: any = await LoginApis.login({
        userName: formData.username,
        password: formData.password,
      });

      if (login.data.code === 200) {
        dispatch(
          setNotificationError({
            error: login.data.message,
            status: login.data.code,
          }),
        );
        setErrors(false);
        setErrorMessage('');
        cookies.set('token', login.data.token.accessToken, { path: '/' });
        cookies.set('username', login.data.data.username, { path: '/' });
        navigate('/admin/personnel/personnel/person');
      } else if (login.data.code === 400) {
        setErrors(true);
        setErrorMessage(login.data.message);
      }
    } catch (error: any) {
      setErrors(true);
      setErrorMessage(error.message);
    }
  };

  const togglePasswordVisibility = (): void => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleLoginClick = (loginType: 'user' | 'person') => {
    setActiveLogin(loginType);
  };

  const actions = [
    {
      icon: <EnglishFlagIcon />,
      label: 'English',
      key: 'en',
    },
    {
      icon: <ArabicFlagIcon />,
      label: 'عربي',
      key: 'ar',
    },
  ];

  const getSelectLanguage = (val: DropDownMenuInterFace) => {
    i18next.changeLanguage(val.key);
    if (val.key === 'ar') {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
    window.location.reload();
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <div className="logo">
          <span className="logo-aic">AIC</span>
          <span className="logo-tec">TEC</span>
          <div className="subtitle">SECURITY SYSTEM</div>
        </div>
        <div className="links">
          <Link to="/download">{t('downloadCertificate')}</Link>
          <Link to="/about">{t('about')}</Link>
          <DropDown
            name={cookies.get('i18next') === 'en' ? <EnglishFlagIcon /> : <ArabicFlagIcon />}
            action={actions}
            clickFunction={(val: DropDownMenuInterFace) => getSelectLanguage(val)}
          />
        </div>
      </div>
      <div className="login-body">
        <div className="login-image">
          <img src={loginImage} alt={t('Login')} />
        </div>
        <div className={`login-form ${currentLanguageCode === 'ar' ? 'ar' : ''}`}>
          <h2>{t('welcomeBack!PleaseEnterYourDetails')}</h2>
          <div className="login-links">
            <div
              className={`login-link ${activeLogin === 'user' ? 'active' : ''}`}
              onClick={() => handleLoginClick('user')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleLoginClick('user');
                }
              }}>
              {t('userLogin')}
            </div>
            <div
              className={`login-link ${activeLogin === 'person' ? 'active' : ''}`}
              onClick={() => handleLoginClick('person')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleLoginClick('person');
                }
              }}>
              {t('person-SelfLogin')}
            </div>
          </div>
          <form>
            <InputField
              name="username"
              label={t('username')}
              id="username"
              type="text"
              value={formData.username}
              onChangeFunction={handleInputChange}
              innerIconStatus={false}
              InnerIcon={null}
              innerIconFunction={() => {
                console.log('asfd');
              }}
              className="username-input"
              required
              message={t('invalidMailID')}
              disabled={false}
            />
            <InputField
              name="password"
              label={t('password')}
              id="password"
              type={isPasswordVisible ? 'text' : 'password'}
              value={formData.password}
              onChangeFunction={handleInputChange}
              innerIconStatus
              InnerIcon={isPasswordVisible ? viewIcon : hideIcon}
              innerIconFunction={togglePasswordVisibility}
              className="password-input"
              required
              message={t('Invalid Password')}
              disabled={false}
            />
            {errors && <div className="error-message">{errorMessage}</div>}
            <Link to="/forgot-password" className="forgot-password">
              {t('forgotPassword?')}
            </Link>
            <CheckBoxInputField
              name="agreement"
              data={checkBoxOptions}
              value={formData.agreement}
              label=""
              alignment="horizontal"
              position="left"
              required={false}
              onChangeFunction={handleInputChange}
            />
            <Button
              title={t('login')}
              className="btn-primary full-size"
              onClickFunction={handleSubmit}
              disabled={disableButton}
            />
            {activeLogin === 'user' && <div className="or-divider">{t('or')}</div>}
            {activeLogin === 'user' && (
              <div className="fingerprint-login">
                <img src={fingerprintIcon} alt={t('Fingerprint')} />
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
