import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RadioButtonField from '../../../../shared-components/radio-button-field/radio-button-field';
import SwapTable from '../../../../shared-components/swapTables/swapTable';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import glboalInterlockGroupApi from '../../../../utils/api/AdvancedFunctions/globalInterlockGroup';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

import {
  PersonProps,
  ApiParams,
  ModalType,
} from '../../../../interface/globalInterlockGroup.interface';

function AddDoors({ closeModal, groupId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = [
    {
      label: t('query'),
      id: 'query',
      value: 'query',
    },
    {
      label: t('department'),
      id: 'department',
      value: 'department',
    },
  ];

  const [type, setType] = useState('query');
  const [persons, setPersons] = useState<PersonProps[]>(); // Available persons
  const [selectedPerson, setSelectedPerson] = useState<PersonProps[]>([]); // Selected persons
  const [deptName, setDeptName] = useState('');
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const changeSearch = (event: any) => {
    setDeptName(event.target.value);
  };

  const fetchLevel = (params: ApiParams) => {
    glboalInterlockGroupApi
      .getDoorlist(params)
      .then((res) => {
        setPersons(res.data.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: PersonProps[], newSelectedData: PersonProps[]) => {
    setPersons(newAvailableData); // Update available data
    setSelectedPerson(newSelectedData); // Update selected data
  };
  const submit = () => {
    const doorId = selectedPerson.map((item: PersonProps) => item.id).join(',');
    const req = {
      groupId,
      doorIds: doorId,
    };
    glboalInterlockGroupApi
      .addDoors(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('levelAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchLevel({
      groupId,
      type: 'noSelected',
    });
  }, [groupId]);
  return (
    <div className="access-rule-add-personnel flex-row column">
      <RadioButtonField
        label=""
        name="recurring"
        alignment="row-wrap"
        data={options}
        value={type}
        onChangeFunction={(name, value: any) => setType(value)}
      />
      {type === 'department' && (
        <div className="select-input">
          <SelectBoxField
            id="deptName"
            label={t('departmentName')}
            value={deptName}
            name="deptName"
            data={[
              { label: 'Management', id: 'Management' },
              { label: 'Tower Management', id: 'Tower Management' },
            ]}
            onChangeFunction={(name: any, value: any) => setDeptName(value)}
          />
          <p className="text-danger">Note: Importing all the personnel of selected departments</p>
        </div>
      )}
      {type === 'query' && (
        <SwapTable
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={persons}
          selectedTableData={selectedPerson}
          onTableSwap={onTableSwap}
        />
      )}
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={selectedPerson.length === 0}
      />
    </div>
  );
}

export default AddDoors;
