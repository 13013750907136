import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';

// Use interface
import { PermissionInterface } from '../../../interface/permissions.interface';

// Use assets svg icons
import { AddIcon, DeleteIcon } from '../../../assets/svgicons/svgicon';

function MoreCards({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();

  const [value, setValue] = useState(values);

  const onChange = (name: string, changeValue: any, index: number) =>
    setValue((prev: any) => {
      const totalPlate = prev[name];
      totalPlate[index] = changeValue;
      return {
        ...prev,
        [name]: totalPlate,
      };
    });

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <div className="permissions-form">
        <div className="row-form items-2">
          {value.moreCards.map((val, index) => (
            <div className="item item-row">
              <InputField
                name="moreCards"
                label={t('secondaryCard')}
                id={`moreCards_${index}`}
                type="text"
                value={val}
                required
                onChangeFunction={(name, changeValue) => onChange(name, changeValue, index)}
              />
              {index === 1 && (
                <div
                  className="function-icon"
                  onClick={() => {
                    setValue((prev: any) => {
                      return {
                        ...prev,
                        moreCards: [...prev.moreCards, ''],
                      };
                    });
                  }}
                  role="presentation">
                  <AddIcon color="green" />
                </div>
              )}
              {index > 1 && (
                <div
                  className="function-icon"
                  onClick={() => {
                    setValue((prev: any) => {
                      return {
                        ...prev,
                        moreCards: prev.moreCards.filter(
                          (_val: string, ind: number) => index !== ind,
                        ),
                      };
                    });
                  }}
                  role="presentation">
                  <DeleteIcon color="red" />
                </div>
              )}
              {index === 0 && <div className="function-icon"> </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MoreCards;
