import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../../shared-components/swapTables/swapTable';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import musterPoint from '../../../../utils/api/AdvancedFunctions/musterPoint';
import {
  AddDeptModalType,
  ApiParams,
  DeptProps,
} from '../../../../interface/musterPoint.interface';
import { fetchMusterPoint } from '../../../../redux/slices/MusterPointSlices';

function AddDepartment({ closeModal, id }: AddDeptModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.musterPoint);

  const [depts, setDepts] = useState<DeptProps[]>([]); // Available depts
  const [selectedDepts, setSelectedDepts] = useState<DeptProps[]>([]); // Selected depts
  const [deptName, setDeptName] = useState('');
  const [disable, setDisable] = useState(false);

  const tableColumns = [
    { label: t('departmentNumber'), id: 'code' },
    { label: t('departmentName'), id: 'deptName' },
    { label: t('parentDepartment'), id: 'parentDeptName' },
  ];

  const changeSearch = (event: any) => {
    setDeptName(event.target.value);
  };
  const fetchData = (params: ApiParams) => {
    musterPoint
      .selectDeptList(params)
      .then((res) => {
        setDepts(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: DeptProps[], newSelectedData: DeptProps[]) => {
    setDepts(newAvailableData); // Update available data
    setSelectedDepts(newSelectedData); // Update selected data
  };
  const submit = () => {
    setDisable(true);
    const selectedIds = selectedDepts.map((item: DeptProps) => item.id).join(',');
    const req = {
      musterPointId: id,
      deptIds: selectedIds,
    };
    musterPoint
      .addDept(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('departmentAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          setDisable(false);
          dispatch(fetchMusterPoint({ pageNo: 1, pageSize: 10, ...searchFilter.filter }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
          setDisable(false);
        }
      })
      .catch((error) => {
        setDisable(false);
        dispatch(setNotificationError(error.response.data));
      });
  };

  useEffect(() => {
    fetchData({
      musterPointId: id,
      type: 'noSelected',
    });
  }, [id]);

  useEffect(() => {
    if (selectedDepts.length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedDepts]);

  return (
    <div className="access-rule-add-personnel flex-row column">
      <SwapTable
        searchValue={deptName}
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={depts}
        selectedTableData={selectedDepts}
        onTableSwap={onTableSwap}
      />
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disable}
      />
    </div>
  );
}

export default AddDepartment;
