// Use common modules
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import AddIoBoard from './components/add-ioboard';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import { AddIcon } from '../../assets/svgicons/svgicon';
import { DepartmentParamsProps } from '../../interface/accessByDepartment.interface';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { fetchIoBoardList, setSearchFilter } from '../../redux/slices/AccIOBoardSlices';
import IoBoard from '../../utils/api/Devices/IoBoard';

function IOBoard() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { ioBoards, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.ioBoard,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'title',
    content: <>hi</>,
  });
  const [selectedId, setSelectedId] = useState<string[]>([]);

  const fetchIoBoard = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchIoBoardList({ ...params }));
    }
  };

  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'alias',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.alias}
        </div>
      ),
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'deviceAlias',
      header: t('ownedDevice'),
    },
    {
      accessorKey: 'extBoardNo',
      header: t('number'),
    },
    {
      accessorKey: 'extBoardType',
      header: t('i/o Board'),
      Cell: ({ row }: any) => {
        let result;
        if (row.original.extBoardType === 104) {
          result = <span>EX0808</span>;
        } else if (row.original.extBoardType === 103) {
          result = <span>AUX458</span>;
        } else if (row.original.extBoardType === 101) {
          result = <span>DM10</span>;
        }
        return result;
      },
    },
    {
      accessorKey: 'commAddress',
      header: t('rs485Address'),
    },
    {
      accessorKey: 'devProtocolType',
      header: t('protocolType'),
    },
  ];

  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      name: editData?.alias,
      deviceId: editData?.devId,
      deviceName: editData?.deviceAlias,
      protocolType: String(editData.devProtocolType),
      ioBoardType: String(editData?.extBoardType),
      rs485Address: editData?.commAddress,
    };
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: <AddIoBoard closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };
  const deleteRecord = (id: string) => {
    IoBoard.delete(id)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('IoBoardDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchIoBoard({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteIoBoard'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchIoBoard({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('i/OBoard'),
          },
        ]}
      />
      <CardBox
        title={t('listOfi/OBoard')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('getPersonnelInformation'),
                content: <AddIoBoard closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedId}
          getSelectedData={(val: any): any => setSelectedId(val)}
          checkBoxActive
          enablePagination
          isFetching={status === 'loading'}
          values={ioBoards}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default IOBoard;
