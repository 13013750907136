import React from 'react';

// Use common modules
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// Use interface
import { DatePickerFieldInterFace } from '../../interface/common.interface';

function DatePickerField({
  name,
  label,
  value,
  disabled,
  required,
  message,
  errorStatus,
  onChangeFunction,
  disableFuture,
}: DatePickerFieldInterFace) {
  const onKeyup = (changeValue: any): void => {
    onChangeFunction(name, dayjs(changeValue).format('YYYY-MM-DD'));
  };
  return (
    <div className="form-item">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label={
              <>
                {label}
                {required && <span style={{ color: 'red' }}> *</span>}
              </>
            }
            onChange={onKeyup}
            value={value ? dayjs(value) : undefined}
            disabled={disabled}
            sx={{
              width: '100%',
              position: 'unset',
              '& .MuiFormLabel-root': {
                left: '2px',
                marginTop: '-6px',
                letterSpacing: '1.1px',
                background: 'white',
                padding: '0px 4px 0px 0px',
              },
              '& .muiltr-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                top: '8px',
              },
              '& .MuiInputBase-root': {
                borderRadius: '8px',
                input: {
                  padding: '12px 10px',
                  fontSize: '14px',
                  letterSpacing: '1.2px',
                  fontFamily: 'Urbanist',
                },
              },
            }}
            disableFuture={disableFuture}
          />
        </DemoContainer>
      </LocalizationProvider>
      {errorStatus && <small className="input-error-message">{message}</small>}
    </div>
  );
}

export default DatePickerField;
