import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import alarmMonitoring from '../../../utils/api/Devices/alarmMonitoring';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { ModalType } from '../../../interface/alarmMonitoring.interface';

function AlarmProcessingHistory({ closeModal, data }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<any>({});
  const onChangeValue = (name: string, value: any) => {
    setState((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    alarmMonitoring
      .showHistory(data?.[0]?.id)
      .then((res: any) => {
        setState({
          eventDescription: res?.data?.data?.eventName || '',
          deviceName: res?.data?.data?.devAlias || '',
          eventPoint: res?.data?.data?.eventPointName || '',
        });
      })
      .catch((error: any) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  }, []);
  return (
    <div className="acknowledge" style={{ width: '620px' }}>
      <div className="flex-row column">
        <div className="input-row">
          <InputField
            name="eventDescription"
            label={t('eventDescription')}
            id="eventDescription"
            type="text"
            value={state?.eventDescription}
            onChangeFunction={onChangeValue}
            disabled
          />
          <InputField
            name="deviceName"
            label={t('deviceName')}
            id="deviceName"
            type="text"
            value={state?.deviceName}
            onChangeFunction={onChangeValue}
            disabled
          />
          <InputField
            name="eventPoint"
            label={t('eventPoint')}
            id="eventPoint"
            type="text"
            value={state?.eventPoint}
            onChangeFunction={onChangeValue}
            disabled
          />
        </div>
        <div className="row-1">
          <h2>{t('processingRecords')}</h2>
          <div className="box-1" />
        </div>
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('cancel')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default AlarmProcessingHistory;
