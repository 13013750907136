import React, { useEffect, useState } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import DraggableImage from './draggable-Image';
import DropContainer from './drop-container';
import { DragDropImagesProps, ImageTypeInterface } from '../../../../interface/map.interface';

function DragDropImages({
  imgProperties,
  draggableImages = [],
  handleClickEvent,
  handleDragAndDrop,
}: DragDropImagesProps) {
  const [images, setImages] = useState<ImageTypeInterface[]>(draggableImages);
  const [dragStart, setDragStart] = useState(false);

  const moveImage = (id: number, newPosition: { x: number; y: number }) => {
    setImages((prevImages) =>
      prevImages.map((image) => (image.id === id ? { ...image, position: newPosition } : image)),
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, delta }: any = event;

    if (delta) {
      const image = images.find((img) => img.id === active.id);
      if (image) {
        const newPosition = {
          x: image.position.x + delta.x,
          y: image.position.y + delta.y,
        };

        moveImage(active.id, newPosition);

        // Send updated positions of all images to the parent
        const updatedImages = images.map((img) =>
          img.id === active.id ? { ...img, position: newPosition } : img,
        );
        handleDragAndDrop(
          updatedImages.map((img) => ({
            id: img.id,
            width: 40,
            x: Math.ceil(img.position.x),
            y: Math.ceil(img.position.y),
          })),
        );

        // Update the state with the new image positions
        setImages(updatedImages);
      }
    }
    setDragStart(false);
  };

  useEffect(() => {
    if (draggableImages) {
      setImages(draggableImages);
    }
  }, [draggableImages]);

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={() => setDragStart(true)}>
      <DropContainer imgProperties={imgProperties}>
        {images.map((image: any) => (
          <DraggableImage
            key={image.id}
            id={image.id}
            src={image.src}
            position={image.position}
            data={image.data}
            handleClickEvent={handleClickEvent}
            dragStart={dragStart}
          />
        ))}
      </DropContainer>
    </DndContext>
  );
}

export default DragDropImages;
