import { post } from './base/index';

export default {
  getLibrary: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/library/getByPage`, {}, { ...data });
  },
  getAllLibrary: () => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/library/getAll`, {});
  },
  addLibrary: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/library/add`, { ...data });
  },
  deleteLibrary: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/library/delete/${id}`, {});
  },
};
