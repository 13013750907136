import React, { useEffect, useState } from 'react';
// Use translation
import { useTranslation } from 'react-i18next';

// Use common modules
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use own component pages
import PersonalDetails from './personal-details';
import ProfessionalDetails from './professional-details';
import Permissions from './permissions';

// Use shared components
import TopHeader from '../../../shared-components/top-header/top-header';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import WidgetForm from '../../../shared-components/widget-forms/widget-forms';
import Button from '../../../shared-components/button/button';

// Use api components
import personAddApi from '../../../utils/api/person';

// Use validation functions
// import { emailValidation, phoneNumberValidation } from '../../../shared-components/form/validation';

// Use interface
import { PersonValue } from '../../../interface/personnel.interface';

// Use redux functions
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchPersonsList, removeLevel } from '../../../redux/slices/PersonSlices';
import { fetchDepartmentList } from '../../../redux/slices/DepartmentSlices';
import { fetchAllPositionList } from '../../../redux/slices/PositionSlices';
import { emailValidation } from '../../../shared-components/form/validation';

function AddPerson() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams(); // Get the ID from the URL
  const allDepartments = useAppSelector((state: any) => state.departments.departments);
  const allPositions = useAppSelector((state: any) => state.positions.allPositions); // Get all positions
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView] = useState({
    title: t('addNewPerson'),
    content: <>hi</>,
  });
  const [loading, setLoading] = useState(false);
  const departMentResult = allDepartments.map((item: any) => ({
    label: item.name,
    id: item.departmentId,
  }));

  const [personValue, setPersonValue] = useState({
    id: '',
    levelName: '',
    timeZone: '',
    levelSettings: [],
    name: '',
    email: '',
    birthday: '',
    personIdPhoto: '',
    personPhoto: null,
    lastName: '',
    whatsappMobileNo: '',
    gender: '',
    mobilePhone: '',
    pin: '',
    hireDate: '',
    certNumber: '',
    deptCode: '',
    cardNo: '',
    superUser: '',
    certType: '',
    position: '',
    deviceVerification: '',
    biometrics: '',
    deviceOperationRule: '',
    type: [],
    attendanceMode: '',
    verificationRole: '',
    deviceOperationRole: '',
    personnelType: '',
    general: false,
    lockerNo: '',
    plateRegisterNo: [
      { licensePlate: '', parkingNo: '' },
      { licensePlate: '', parkingNo: '' },
    ],
    moreCards: ['', ''],
    booking: '',
    civilIdNo: '',
    maritalStatus: '',
    usufructo: '',
    passage: '',
    faceKioskArea: { areaName: [], hallWayArea: [] },
    attendanceArea: { areaName: [], hallWayArea: [] },
  });
  const onChangeFunction = (values: Partial<PersonValue>) => {
    setPersonValue((prev: any) => ({
      ...prev,
      ...values,
    }));
  };
  console.log(personValue);
  const submit = () => {
    setLoading(true);
    const request = {
      item: {
        birthday: personValue?.birthday,
        cardNo: personValue?.cardNo,
        name: personValue?.name,
        lastName: personValue?.lastName,
        mobilePhone: personValue?.mobilePhone,
        gender: personValue?.gender,
        pin: personValue?.pin,
        hireDate: personValue?.hireDate,
        whatsappMobileNo: personValue?.whatsappMobileNo,
        positionId: personValue?.position,
        deptCode: personValue?.deptCode,
        deptId: personValue?.deptCode,
        cropPhotoDel: false,
        cropPhotoBase64: personValue?.personIdPhoto?.replace(/^data:image\/[a-z]+;base64,/, ''),
      },
      certificate: {
        certType: personValue?.certType,
        certNumber: personValue?.certNumber,
      },
      extParams: {
        mail: personValue?.email,
        'acc.personLevelIds': personValue?.levelSettings.toString(),
        moduleAuth: 'acc',
        personIdPhoto: personValue?.personIdPhoto?.replace(/^data:image\/[a-z]+;base64,/, ''),
      },
      attributeExt: {},
    };

    personAddApi
      .addPerson(request)
      .then((response) => {
        if (response.data.success) {
          // Handle successful response
          const message = id ? t('personEditedSuccessfully') : t('personAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200, // Success status code
            }),
          );
          setLoading(false);
          dispatch(fetchPersonsList({ pageNo: 1, pageSize: 10 }));
          navigate('/admin/personnel/personnel/person');
        } else {
          // Handle specific error response
          setLoading(false);
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500, // Error status code
            }),
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const back = () => {
    navigate('/admin/personnel/personnel/person');
  };
  useEffect(() => {
    dispatch(fetchDepartmentList({ pageNo: 1 })); // Fetch department list on component mount with empty object or required params
    dispatch(fetchAllPositionList({}));
  }, [dispatch]);
  useEffect(() => {
    return () => {
      dispatch(removeLevel());
    };
  }, [dispatch]);
  return (
    <div className="person">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('addPerson')}
        broadCram={[{ title: t('personnel') }, { title: t('person') }, { title: t('addPerson') }]}
      />
      <WidgetForm
        rightContent={
          <Button onClickFunction={back} className="btn-default btn-sm" title={t('back')} />
        }
        backFunction={() => navigate('/admin/personnel/personnel/person')}
        tabs={[
          {
            title: t('personalDetails'),
            key: 'personalDetails',
            value: 1,
            content: <PersonalDetails onChangeFunction={onChangeFunction} values={personValue} />,
          },
          {
            title: t('professionalDetails'),
            key: 'professionalDetails',
            value: 2,
            content: (
              <ProfessionalDetails
                onChangeFunction={onChangeFunction}
                values={personValue}
                allDepartments={departMentResult} // Pass the departments data
                allPositions={allPositions}
              />
            ),
          },
          {
            title: t('permissions'),
            key: 'permissions',
            value: 3,
            content: <Permissions onChangeFunction={onChangeFunction} values={personValue} />,
          },
        ]}
        loading={loading}
        submitFunction={submit}
        data={personValue}
        firstStepValidation={personValue.email ? [emailValidation(personValue.email)] : []}
        secondStepValidation={[personValue.pin, personValue.deptCode]}
        thirdStepValidation={[personValue.levelSettings.length !== 0]}
      />
    </div>
  );
}

export default AddPerson;
