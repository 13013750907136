import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import CardBox from '../card/card';
import Button from '../button/button';

// Use interface
import { WidgetFormsInterface } from '../../interface/common.interface';

function WidgetForm({
  tabs,
  submitFunction,
  backFunction,
  rightContent,
  firstStepValidation,
  secondStepValidation,
  thirdStepValidation,
  data,
  loading,
}: WidgetFormsInterface) {
  const { t } = useTranslation();
  const [widgetTab, setWidgetTab] = useState(tabs[0]);
  const [buttonDisable, setButtonDisable] = useState(true);

  useEffect(() => {
    if (widgetTab.value === 1) {
      setButtonDisable(false);
      firstStepValidation.forEach((val) => {
        if (!val) {
          setButtonDisable(true);
        }
      });
    }
    if (widgetTab.value === 2) {
      setButtonDisable(false);
      secondStepValidation.forEach((val) => {
        if (!val) {
          setButtonDisable(true);
        }
      });
    }
    if (widgetTab.value === 3) {
      setButtonDisable(false);
      thirdStepValidation.forEach((val: any) => {
        if (!val) {
          setButtonDisable(true);
        }
      });
    }
  }, [data]);
  return (
    <div className="widget-form">
      <CardBox title="" rightSide={rightContent}>
        <div className="widget-tabs">
          <div className="tabs-list">
            {tabs.map((tab, index) => (
              <div className={`tab-items ${widgetTab.value >= tab.value && 'active'}`}>
                <div className={`number line${index + 1} ${widgetTab.value > tab.value && 'done'}`}>
                  {index + 1}
                </div>
                <div className="label">
                  <div className="title">Step {index + 1}</div>
                  <div className="sub-title">{tab.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </CardBox>
      <CardBox title={widgetTab.title}>
        <div className="page-content">{widgetTab.content}</div>
        <div className="parameters-buttons">
          <div className="form-buttons-right">
            <Button
              onClickFunction={backFunction}
              title={t('back')}
              className="btn-default btn-bold"
            />
            <Button
              onClickFunction={() => {
                const tab = tabs[widgetTab.value - 2];
                setWidgetTab(tab);
              }}
              title={t('prev')}
              disabled={widgetTab.value === 1}
              className="btn-default"
            />
            {widgetTab.value === 3 ? (
              <Button
                onClickFunction={submitFunction}
                title={loading ? t('loading') : t('Done')}
                className={`btn-primary ${loading ? 'loading' : ''} `}
                loading={loading}
                disabled={buttonDisable}
              />
            ) : (
              <Button
                onClickFunction={() => {
                  const tab = tabs[widgetTab.value];
                  setWidgetTab(tab);
                }}
                disabled={buttonDisable}
                title={t('next')}
                className="btn-primary"
              />
            )}
          </div>
        </div>
      </CardBox>
    </div>
  );
}

export default WidgetForm;
