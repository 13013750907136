import React, { useState } from 'react';
// Use Translation
import { useTranslation } from 'react-i18next';

import Button from '../button/button';

// Use interface
import { CardRightButtonInterface } from '../../interface/common.interface';

// Use assets svg icons
import { CloseSmallIcon, RightArrowSmallIcon } from '../../assets/svgicons/svgicon';

function CardRightButtonGroup({
  actions = [],
  buttonIcon,
  buttonLabel = '',
  buttonClass = '',
  buttonStatus,
  buttonFunction = () => {},
}: CardRightButtonInterface) {
  const [viewButtons, setViewButtons] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="card-right-button-group">
      {actions.length > 0 && (
        <div className="button-group">
          {!viewButtons && (
            <div className="view-button" onClick={() => setViewButtons(true)} role="presentation">
              <span>{t('more')}</span> <RightArrowSmallIcon />
            </div>
          )}
          {viewButtons && (
            <div className="hide-button">
              <div className="button-list">
                <div
                  className="button-item"
                  onClick={() => setViewButtons(false)}
                  role="presentation">
                  {t('close')} <CloseSmallIcon color="#f00" />
                </div>
                {actions.map((val) => (
                  <div key={val.title} className="button-item">
                    {val.dropdown ? (
                      <div
                        className="dropdown-wrapper"
                        onClick={val.clickFunction}
                        role="presentation">
                        {val.dropdown} {/* Dropdown is rendered here */}
                      </div>
                    ) : (
                      <div onClick={val.clickFunction} role="presentation">
                        {val.title} {/* Title is rendered here */}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {buttonStatus && (
        <Button
          onClickFunction={buttonFunction}
          title={buttonLabel}
          icon={buttonIcon}
          className={buttonClass}
        />
      )}
    </div>
  );
}

export default CardRightButtonGroup;
