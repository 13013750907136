/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import musterPointReport from '../../utils/api/AdvancedFunctions/musterPointReport';

export const fetchMusterPointReport = createAsyncThunk(
  'acc/fetchMusterPointReport',
  async (data: any, { dispatch }) => {
    const response = await musterPointReport
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data,
            size: response1.data.data.length,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReduxInterface {
  pointList: any[];
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  pointList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const musterPointReportSlice: any = createSlice({
  name: 'MusterPointReport',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMusterPointReport.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchMusterPointReport.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.currentPageNo = action.payload.data.pageNo;
          state.totalDataCount = action.payload.data.size;

          if (state.currentPageNo === 1) {
            state.pointList = action.payload.data.list;
          } else {
            state.pointList = [...state.pointList, ...action.payload.data.list];
          }
        }
        state.status = 'success';
      })
      .addCase(fetchMusterPointReport.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// // Action creators are generated for each case reducer function
export const { setSearchFilter, editReader, deleteReader } = musterPointReportSlice.actions;

export default musterPointReportSlice.reducer;
