import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use shared component
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
// import MultipleImageUpload from '../../../shared-components/multiple-file-upload/multiple-file-upload';
import FileUpload from '../../../shared-components/file-upload/file-upload';
// Import the API module
import PersonalApis from '../../../utils/api/person';

// Import the notification action
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchPersonsList } from '../../../redux/slices/PersonSlices';

function ImportDoorsAccessLevel({
  closeModal,
  submitFunction,
}: {
  closeModal: () => void;
  submitFunction: (val: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectData = [
    {
      label: t('excel'),
      id: 'excel',
    },
  ];
  const [value, setValue] = useState({
    fileType: '',
    fileList: [],
    updateExistingData: '',
    filePath: null,
    modifyPath: [],
  });
  const [disableButton, setDisableButton] = useState(true);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  const removeImage = () => {
    setValue((prev: any) => ({
      ...prev,
      filePath: null,
    }));
  };
  const handleSubmit = () => {
    const formData = new FormData();

    // Assuming fileList is an array of files
    if (value.fileList.length > 0) {
      formData.append('upload', value.fileList[0]); // Append the first file, adjust if multiple files are required
    }
    formData.append('updateExistData', value.updateExistingData === 'yes' ? '1' : '0');

    PersonalApis.importPerson(formData)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personImportsuccessfully'),
              status: 200, // Success status code
            }),
          );
          dispatch(fetchPersonsList({ pageNo: 1 }));
          closeModal();
          setDisableButton(false);
          submitFunction(formData); // Pass the request object or another relevant value
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 0,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    // Enable the button if all fields are filled
    if (value.fileType && value.fileList.length > 0 && value.updateExistingData) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [value]);
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="rows row-1">
          <div className="col filetype">
            <SelectBoxField
              name="fileType"
              label={t('fileFormat')}
              id="fileType"
              value={value.fileType}
              required
              onChangeFunction={(name, changeValue) => onChange(name, changeValue.id)}
              data={selectData}
              className=""
            />
          </div>
          <FileUpload
            label={t('uploadFile')}
            name="filePath"
            value={value.filePath}
            removeFunction={removeImage}
            onChangeFunction={onChange}
            required
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={handleSubmit}
              title={t('import')}
              disabled={disableButton}
              className="btn-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportDoorsAccessLevel;
