import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import alarmMonitoring from '../../../utils/api/Devices/alarmMonitoring';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { ModalType } from '../../../interface/alarmMonitoring.interface';

function Acknowledge({ closeModal, data, id }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(1);
  const [acknowledgement, setAcknowledgement] = useState('');
  const submit = () => {
    const req = {
      status,
      id: id?.[0],
      acknowledgement,
    };
    alarmMonitoring
      .editAcknowledgeStatus(req)
      .then((response) => {
        if (response.data.success) {
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  return (
    <div className="acknowledge">
      <div className="flex-row column">
        <div className="row-1">
          <h2>{t('eventDetails')}</h2>
          <div className="box">
            <span>
              {data?.[0]?.eventPointName}
              {`<${data?.[0]?.device}>`}
            </span>
          </div>
        </div>
        <RadioButtonField
          label=""
          name="onlineStatus"
          alignment=""
          data={[
            {
              label: t('processing'),
              id: 1,
              value: 1,
            },
            {
              label: t('confirmed'),
              id: 2,
              value: 2,
            },
          ]}
          value={status}
          onChangeFunction={(name: string, value: any) => setStatus(value)}
        />
        <div className="row-1">
          <h2>{t('processingRecords')}</h2>
          <InputTextAreaField
            id="acknowledgement"
            value={acknowledgement} // Provide a fallback value
            label=""
            name="acknowledgement"
            onChangeFunction={(name, value) => setAcknowledgement(value)}
          />
        </div>
        <div className="form-buttons-right">
          <Button onClickFunction={submit} title={t('ok')} className="btn-default" />
          <Button onClickFunction={closeModal} title={t('cancel')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default Acknowledge;
