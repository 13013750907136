import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field'; // For dropdown
import { EditAuxiliaryProps } from '../../../interface/accessAuxiliaryInput.interface';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import auxiliaryInputApi from '../../../utils/api/Devices/auxiliaryInput';

function EditAuxiliary({ initialData, closeModal, saveChanges }: EditAuxiliaryProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(initialData);
  const [dropdownData, setDropdownData] = useState([]);

  // Handles changes to the form fields
  const onChangeValue = (name: string, value: any) => {
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // Fetch dropdown data from API
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await auxiliaryInputApi.getTimeSegList();
        const formattedData = response.data.data.map((item: any) => ({
          label: item.text,
          id: item.value,
        }));

        setDropdownData(formattedData);
      } catch (error: any) {
        dispatch(setNotificationError(error.response?.data || t('errorFetchingData')));
      }
    };

    fetchDropdownData();
  }, [dispatch, t]);
  return (
    <div className="edit-reader-modal">
      <form className="edit-reader-form">
        <div className="input-grid">
          <div className="input-form">
            <div className="input-item">
              <InputField
                id="deviceName"
                value={editData.deviceName}
                label={t('deviceName')}
                type="text"
                onChangeFunction={onChangeValue}
                name="deviceName"
                readonly
              />
            </div>
            <div className="input-item">
              <InputField
                id="number"
                value={editData.number}
                label={t('number')}
                type="number"
                name="number"
                onChangeFunction={onChangeValue}
                readonly
              />
            </div>
            <div className="input-item">
              <InputField
                id="printedName"
                value={editData.printedName}
                label={t('printedName')}
                type="text"
                onChangeFunction={onChangeValue}
                name="printedName"
                readonly
              />
            </div>
          </div>
          <div className="input-select">
            <div className="input-item">
              <SelectBoxField
                name="activeTimeZone"
                label={t('activeTimeZone')}
                id="activeTimeZone"
                value={editData.activeTimeZone}
                data={dropdownData}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                className=""
              />
            </div>
            <div className="input-item">
              <InputField
                id="name"
                value={editData.name}
                label={t('name')}
                type="text"
                name="name"
                onChangeFunction={(name, value) => onChangeValue(name, value)}
              />
            </div>
            <div className="input-item">
              <InputTextAreaField
                id="remarks"
                value={editData.remarks || ''} // Provide a fallback value
                label={t('remarks')}
                name="remarks"
                onChangeFunction={(name, value) => onChangeValue(name, value)}
              />
            </div>
          </div>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              saveChanges(editData);
              closeModal();
            }}
            title={t('ok')}
            className="btn-primary btn-bold"
          />
        </div>
      </form>
    </div>
  );
}

export default EditAuxiliary;
