/* eslint-disable */
import React, { useState } from 'react';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeNodeInterface } from '../../interface/map.interface';
import { isIdNotInEnd } from '../form/validation';
// import { DownArrowIcon } from '../../assets/svgicons/svgicon';

type TreeNodeId = string;
const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
}));

// function ExpandIcon() {
//   return <DownArrowIcon />;
// }

// function CollapseIcon() {
//   return <DownArrowIcon />;
// }

// function EndIcon() {
//   return <DownArrowIcon />;
// }

// Recursive function to render tree items
const renderTreeItems = (nodes: any) => {
  return (
    <CustomTreeItem itemId={nodes.id} label={nodes.text}>
      {nodes.item &&
        nodes.item.length > 0 &&
        nodes.item.map((child: any) => renderTreeItems(child))}
    </CustomTreeItem>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: 'Urbanist', // Customize the font family globally
  },
});

export default function TreeViewDropdown({ listItems, onSelect, value, onSelectValue }: any) {
  const [selectedNode, setSelectedNode] = useState<TreeNodeId>(value || null);
  function findItemWithFilter(
    listItemsValues: TreeNodeInterface,
    itemId: string,
  ): TreeNodeInterface | null {
    const findInArray = (items: TreeNodeInterface[]): TreeNodeInterface | null => {
      // Use `find` to locate the item at the current level
      const foundItem = items.find((item) => item.id === itemId);
      if (foundItem) {
        return foundItem;
      }

      // Use `some` to iterate over children recursively
      let result: TreeNodeInterface | null = null;
      items.some((item) => {
        result = findInArray(item.item);
        return result !== null; // Stop recursion when an item is found
      });

      return result;
    };

    // Start with the root item's children
    return listItemsValues.id === itemId ? listItemsValues : findInArray(listItemsValues.item);
  }
  const handleNodeSelect = (event: React.MouseEvent, itemId: string): void => {
    setSelectedNode(itemId);
    console.log(selectedNode);
    onSelect(itemId);
    if (isIdNotInEnd(itemId)) {
      const matchedItem = findItemWithFilter(listItems, itemId);
      onSelectValue(matchedItem);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SimpleTreeView
        onItemClick={handleNodeSelect}
        aria-label="customized"
        defaultExpandedItems={[value]}
        sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 300 }}>
        {renderTreeItems(listItems)}
      </SimpleTreeView>
    </ThemeProvider>
  );
}
