import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';

// Use interface
import { PermissionInterface } from '../../../interface/permissions.interface';

function LockerSetting({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();

  const [value, setValue] = useState(values);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <div className="permissions-form">
        <div className="row-form">
          <div className="item">
            <div className="text-label">{t('lockerNumber')}</div>
          </div>
          <div className="item">
            <InputTextAreaField
              name="lockerNo"
              label=""
              id="lockerNo"
              value={value.lockerNo}
              onChangeFunction={onChange}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LockerSetting;
