import { post } from '../base';

export default {
  getReaders: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/reader/getListByPage`, {}, { ...data });
  },

  isExist: (data: any) => post(`${process.env.REACT_APP_API_URL}Acc/api/reader/isExist/`, {}, data),

  isExistVidChannel: () =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/reader/isExistVid`, {}, {}),

  saveReader: (data: any) => post(`${process.env.REACT_APP_API_URL}Acc/api/reader/save`, data, {}),

  selectReaderList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/reader/selectReaderList`, data, {});
  },
};
