import { post, fetch } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/list`, {}, { ...data });
  },
  getDoorList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/doorList`, {}, data);
  },
  addPersonToLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/addPerson`, {}, data);
  },
  getLevelPerson: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/getLevelPerson`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  deleteLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/delete`, {}, data);
  },
  addLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/add`, data);
  },
  openingDoor: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/openDoor/{ids}/${data.openInterval}?ids=${data?.ids}`,
      {},
    );
  },
  closingDoor: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/closeDoor/{ids}/${data?.ids}`,
      {},
    );
  },
  cancelAlarmDoor: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/cancelAlarmDoor/?ids=${data?.ids}`,
      {},
    );
  },
  remoteNormalOpenDoor: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/normalOpenDoor/?ids=${data?.ids}`,
      {},
    );
  },
  deleteDoor: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/delDoor`, {}, data);
  },
  activateLockDown: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/lockDoor/?ids=${data?.ids}`, {});
  },
  deActivateLockDown: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/unLockDoor/?ids=${data?.ids}`,
      {},
    );
  },
  enableIntradayPassage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/enableNormalOpenDoor/?ids=${data?.ids}`,
      {},
    );
  },
  disableIntradayPassage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/disableNormalOpenDoor/?ids=${data?.ids}`,
      {},
    );
  },
  getDoorSelectLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/selectDoorlist`, data, {});
  },
  addDoor: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/addDoor`, {}, data);
  },
  exportLevel: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/accLevel/exportLevel`, 'POST', data);
  },
  exportLevelDoor: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/exportLevelDoor?${data.levelIds}`,
      'POST',
    );
  },
};
