// import React from 'react';

// Use common modules
import { Navigate } from 'react-router-dom'; // Temp
import Cookies from 'universal-cookie';

function AuthGuard({ Component }: { Component: any }) {
  const cookies = new Cookies();
  return cookies.get('token') && cookies.get('username') ? Component : <Navigate to="/login" />; // <Navigate to="/login" />
}

export default AuthGuard;
