/* eslint-disable react/require-default-props */
import React from 'react';
import { Cell, Label, LabelProps, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartInterface } from '../../../interface/alarmMonitoring.interface';

interface CustomLabelProps {
  viewBox?: LabelProps['viewBox'];
  totalValue: string | number;
}

function CustomLabel({ viewBox, totalValue }: CustomLabelProps) {
  if (!viewBox || !('cx' in viewBox) || !('cy' in viewBox)) return null;

  const { cx, cy } = viewBox || { cx: 0, cy: 0 }; // Directly use cx and cy from the viewBox

  return (
    <text
      x={cx}
      y={cy}
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontSize: '20px',
        fontWeight: '500',
        fill: '#000000',
      }}>
      <tspan x={cx} dy="-15">
        Total
      </tspan>
      <tspan x={cx} dy="30" fontSize={30} fontWeight={600}>
        {totalValue}
      </tspan>
    </text>
  );
}

function DataAnalysisChart({ data }: ChartInterface) {
  const totalValue = data.reduce((a: any, b: any) => a + b.value, 0);
  // later time start this module, fix the circle to the total text
  return (
    <div>
      <ResponsiveContainer width={250} height={200}>
        <PieChart width={250} height={200}>
          <Tooltip />
          <Pie
            data={data}
            cx="50%" // Centered within the container
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value">
            {data.map((entry: any) => (
              <Cell key={`cell-${entry.value}`} fill={entry.color} />
            ))}
            <Label
              position="center"
              content={(props) => <CustomLabel {...props} totalValue={totalValue} />}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className="legends">
        {data?.map((legend: any) => (
          <div className="each-legends" key={legend?.value}>
            <div className="badge" style={{ backgroundColor: legend?.color }} />
            <span className="text">
              {legend.name} ({legend?.value})
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DataAnalysisChart;
