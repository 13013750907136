import { fetch, get, post } from '../base/index';

export default {
  getChartData: (data: string = '') => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/musterPointReport/getData/${data}`, {});
  },
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/musterPointReport/listByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  clearData: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/musterPointReport/clearData`, {});
  },
  saveSignInManually: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/musterPointReport/signInManually`,
      {},
      data,
    );
  },
  selectVisitorList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/musterPointReport/selectVisitorList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/musterPointReport/export`, 'POST', data);
  },
};
