import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { DownArrowIcon } from '../../assets/svgicons/svgicon';
import { ListDropdownInterface } from '../../interface/common.interface';

function ListDropdown({ label, data, changeFunction, disabled = true }: ListDropdownInterface) {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const currentLanguageCode = Cookies.get('i18next') || 'en';
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const handleClick = () => {
    if (disabled) {
      setShow(!show);
    }
  };
  const clickSelected = (labelValue: string, index: number) => {
    setSelected(index);
    changeFunction(labelValue);
    setShow(false);
  };
  const handleClickOutside = (event: MouseEvent) => {
    // Check if the click is outside the dropdown element
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShow(false); // Close the dropdown when clicking outside
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="select-box" ref={ref}>
      <div style={{ display: 'flex', gap: '5px' }} onClick={handleClick} role="presentation">
        {label}
        <div>
          <DownArrowIcon />
        </div>
      </div>
      <div
        className={`dropdown ${show ? 'dropdown-visible' : 'dropdown-hidden'} ${
          currentLanguageCode === 'en' ? '' : 'dropdown-arabic'
        }`}>
        {data?.map((item: any, index: number) => (
          <div
            className={`options ${selected === index ? 'selected' : ''}`}
            onClick={() => clickSelected(item?.value, index)}
            role="none">
            {item.icon && <item.icon color={selected === index ? '#FFFFFF' : '#696C6F'} />}
            {`${t(`${item.label}`)}`}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListDropdown;
