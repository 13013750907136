import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
// import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Button from '../../../../../shared-components/button/button';
import InputDateTime from '../../../../../shared-components/input-time-date/input-time-date';
import SelectBoxField from '../../../../../shared-components/selectbox-field/selectbox-field';

// interface StateProps {
//   backupFtp: string[];
// }

interface ModalType {
  closeModal: () => void;
}

function BackupSchedule({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    startTime: '',
    dbBackupRemote: [],
    dbBackupIntervalTime: '',
  });
  const handleChange = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const changeDateTime = (dateValue: any, types: string) => {
    setValues((prev: any) => ({
      ...prev,
      [types]: dayjs(dateValue),
    }));
  };

  const handleAcceptDate = (dateValue: any, types: string) => {
    setValues((prev: any) => ({
      ...prev,
      [types]: dayjs(dateValue),
    }));
  };
  return (
    <div className="backup-schedule flex-row column">
      <div className="input-row">
        <InputDateTime
          label={t('from')}
          value={values.startTime}
          onChangeFunction={(val) => changeDateTime(val, 'startTime')}
          okFunction={(val) => handleAcceptDate(val, 'startTime')}
          monthFormat={false}
        />
        <SelectBoxField
          name="dbBackupIntervalTime"
          label={t('byDay')}
          id="dbBackupIntervalTime"
          value={values.dbBackupIntervalTime}
          onChangeFunction={(name, value) => handleChange(name, value.id)}
          data={[
            { label: t('1'), id: '1' },
            { label: t('3'), id: '3' },
            { label: t('7'), id: '7' },
            { label: t('30'), id: '30' },
          ]}
          className=""
        />
      </div>
      <div className="messages">
        <p className="">The last backup time: 2024-08-23 18:00:06</p>
        <p>The next backup time:2024-08-30 18:00:00, left 1 day 22 hour 30 minute 46 second.</p>
      </div>
      <CheckBoxInputField
        name="dbBackupRemote"
        data={[
          {
            label: t('backUpToFTPServerAtTheSameTime'),
            id: '1',
            value: '1',
          },
        ]}
        value={values?.dbBackupRemote}
        onChangeFunction={handleChange}
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        <span className="error-msg">{t('warningClickCheckBoxManualFAQ')}</span>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={closeModal}
          title={t('done')}
          className="btn-primary"
          // disabled={disabled}
        />
      </div>
    </div>
  );
}

export default BackupSchedule;
