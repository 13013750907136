import React from 'react';
import { SwitchButtonInterface } from '../../interface/common.interface';

function SwitchButton({
  name,
  // label,
  id,
  type,
  value,
  disabled,
  onChangeFunction = () => {},
}: SwitchButtonInterface) {
  const onKeyup = (e: any): void => {
    onChangeFunction(e.target.name, e.target.checked);
  };
  return (
    <div className="switch-button">
      <label className="switch" htmlFor={`toggle-checkbox-${id}`}>
        <input
          name={name}
          type={type}
          id={`toggle-checkbox-${id}`}
          checked={value}
          onChange={onKeyup}
          aria-label={`Switch ${id}`}
          disabled={disabled}
        />
        <span className="slider round" />
      </label>
    </div>
  );
}

export default SwitchButton;
