import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// Use shared components
import InputField from '../../../../shared-components/input-field/input-field';
import Button from '../../../../shared-components/button/button';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../../shared-components/radio-button-field/radio-button-field';

// Use interface
import { ExportDataInterFace } from '../../../../interface/common.interface';

//  Import the helper function
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../../../utils/helpers'; // Path based on your project structure
// Use assets svg icons
import { EyeCloseIcon, EyeOpenIcon } from '../../../../assets/svgicons/svgicon';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices'; // Import the notification action
import allTransaction from '../../../../utils/api/AccControlReports/allTransaction';
import InputDateTime from '../../../../shared-components/input-time-date/input-time-date';
import { fetchDeviceList } from '../../../../redux/slices/DeviceSlice';

function ExportPhoto({ closeModal }: ExportDataInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { devices } = useAppSelector((state: any) => state.device);
  const [disableButton, setDisableButton] = useState(true);
  const [submitButton] = useState(false);
  const [type, setType] = useState('password');
  const [exportValue, setExportValue] = useState({
    reportType: '',
    exportType: 'all',
    startDateAndTime: '',
    endDateAndTime: '',
    fileEncryption: 'no',
    password: '',
    deviceSns: '',
  });

  const onChangeValue = (name: string, value: any) => {
    setExportValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeDateTime = (dateValue: any, types: string) => {
    setExportValue((prev: any) => ({
      ...prev,
      [types]: dayjs(dateValue),
    }));
  };

  const handleAcceptDate = (dateValue: any, types: string) => {
    setExportValue((prev: any) => ({
      ...prev,
      [types]: dayjs(dateValue),
    }));
  };

  // API Integration
  const submit = () => {
    setDisableButton(true);

    // Create a new FormData object
    const formData = new FormData();

    // Append the attrs array to the FormData
    formData.append('isEncrypt', exportValue.fileEncryption === 'yes' ? '1' : '0');
    formData.append('reportType', exportValue.reportType.toUpperCase());

    // Add logic for recordStart, recordCount, and exportType
    if (exportValue.exportType === 'selected') {
      formData.append('exportType', '3');
      formData.append('recordStart', exportValue.startDateAndTime || '1');
      formData.append('recordCount', exportValue.endDateAndTime || '1');
    } else {
      formData.append('exportType', '1');
    }

    // Call the API using formData
    allTransaction
      .exportPhoto(formData)
      .then((response) => response.blob()) // Set responseType to 'blob'
      .then((data) => {
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(data, `Transaction_${currentDateTime}.${exportValue.reportType}`);
        setDisableButton(false);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setDisableButton(false);
      });
  };

  useEffect(() => {
    let i = 0;
    if (exportValue.reportType === '' || exportValue.exportType === '') {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [exportValue]);

  useEffect(() => {
    dispatch(fetchDeviceList({ pageNo: 1 }));
  }, []);

  return (
    <div className="export-photo flex-row column">
      <div className="flex-row">
        <div className="col">
          <SelectBoxField
            name="reportType"
            label={t('fileType')}
            id="reportType"
            value={exportValue.reportType}
            required
            errorStatus={exportValue.reportType === '' && submitButton}
            message={exportValue.reportType === '' ? 'File Type is required' : ''}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[{ label: t('ZIP'), id: 'ZIP' }]}
            className=""
          />
        </div>
        <div className="col">
          <InputDateTime
            label={t('startDateAndTime')}
            value={exportValue.startDateAndTime}
            onChangeFunction={(val) => changeDateTime(val, 'startDateAndTime')}
            okFunction={(val) => handleAcceptDate(val, 'startDateAndTime')}
            monthFormat={false}
          />
        </div>
        <div className="col">
          <InputDateTime
            label={t('endDateAndTime')}
            value={exportValue.endDateAndTime}
            onChangeFunction={(val) => changeDateTime(val, 'endDateAndTime')}
            okFunction={(val) => handleAcceptDate(val, 'endDateAndTime')}
            disableFuture
            monthFormat={false}
          />
        </div>
      </div>
      <div className="flex-row">
        <div className="col-2">
          <SelectBoxField
            name="deviceSns"
            label={t('deviceName')}
            id="deviceSns"
            value={exportValue.deviceSns}
            required
            errorStatus={exportValue.deviceSns === '' && submitButton}
            message={exportValue.deviceSns === '' ? 'File Type is required' : ''}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={devices.map((item: any) => ({
              label: item.deviceName,
              id: item.id,
            }))}
            className=""
          />
        </div>
      </div>
      <div className="flex-row">
        <div className="col-1">
          <RadioButtonField
            label={t('fileEncryption')}
            name="fileEncryption"
            alignment="column"
            required
            data={[
              { label: t('yes'), id: 'yes', value: 'yes' },
              { label: t('no'), id: 'no', value: 'no' },
            ]}
            value={exportValue.fileEncryption}
            onChangeFunction={onChangeValue}
          />
        </div>
        {exportValue.fileEncryption === 'yes' && (
          <div className="col-1">
            <InputField
              name="password"
              label={t('password')}
              id="password"
              type={type}
              value={exportValue.password}
              innerIconStatus
              InnerIcon={type === 'password' ? EyeCloseIcon : EyeOpenIcon}
              innerIconFunction={() => setType(type === 'password' ? 'text' : 'password')}
              onChangeFunction={onChangeValue}
            />
          </div>
        )}
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('back')} className="btn-default btn-bold" />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ExportPhoto;
