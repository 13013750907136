/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import allTransaction from '../../utils/api/AccControlReports/allTransaction';

export const fetchTransactionList = createAsyncThunk(
  'acc/fetchTransactionList',
  async (data: any, { dispatch }) => {
    const response = await allTransaction
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReaderReduxInterface {
  transactions: any[];
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  transactions: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const allTransactionsSlice: any = createSlice({
  name: 'allTransaction',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchTransactionList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactionList.fulfilled, (state, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.size;
          state.currentPageNo = action.payload.pageNo;

          state.transactions = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchTransactionList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setPagination } = allTransactionsSlice.actions;

export default allTransactionsSlice.reducer;
