import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../shared-components/button/button';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import musterPointApi from '../../../../utils/api/AdvancedFunctions/musterPoint';
import SelectPerson from './SelectPerson';
import musterPointReport from '../../../../utils/api/AdvancedFunctions/musterPointReport';
import SelectVisitor from './SelectVisitor';

const initialValues = {
  musterPoint: '',
  persPersonType: '0',
  personId: '',
  personName: '',
  visitorId: '',
  visitorName: '',
};
interface StateProps {
  musterPoint: string;
  persPersonType: string;
  personId: string;
  personName: string;
  visitorId: string;
  visitorName: string;
}

interface MusterProps {
  text: string;
  value: string;
  css: string | null;
  selected: boolean;
  img: string | null;
}
interface ModalTypeProps {
  closeModal: () => void;
}
function SignInManually({ closeModal }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [values, setValues] = useState<StateProps>(initialValues);
  const [disable, setDisable] = useState(false);
  const [musterPointList, setMusterPointList] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const fetchData = () => {
    musterPointApi
      .getMusterPointList()
      .then((res) => {
        const result = res?.data?.data?.map((item: MusterProps) => ({
          label: item.text,
          id: item.value,
        }));
        setMusterPointList(result);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const onChangeValue = async (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setDisable(true);
      const req: any = {
        musterPointId: values?.musterPoint,
        persPersonType: values?.persPersonType,
      };
      if (values?.persPersonType === '0') {
        req.personId = values?.personId;
      }
      const response1 = await musterPointReport.saveSignInManually(req);
      if (response1.data.success) {
        dispatch(
          setNotificationError({
            error: response1.data.msg,
            status: 200,
          }),
        );
        setDisable(false);
        closeModal();
        // dispatch(fetchOccupancyControl({ pageNo: 1 }));
      } else {
        dispatch(
          setNotificationError({
            error: response1.data.message,
            status: response1.data.code,
          }),
        );
        setDisable(false);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const getSelectedPerson = (val: any, type: string) => {
    if (type === 'person') {
      setValues((prev) => ({
        ...prev,
        personName: val.persName,
        personId: val.id,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        visitorName: val.persName,
        visitorId: val.id,
      }));
    }
  };

  const handleClickPerson = () => {
    setModalView({
      title: t('selectPerson'),
      content: (
        <SelectPerson
          getSelected={(selected: any[]) => getSelectedPerson(selected, 'person')}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };

  const handleClickVisitor = () => {
    setModalView({
      title: t('selectVisitor'),
      content: (
        <SelectVisitor
          getSelected={(selected: any[]) => getSelectedPerson(selected, 'visitor')}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };

  useEffect(() => {
    if (values?.musterPoint && values?.persPersonType) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="signin-manually">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <div className="flex-row">
          <SelectBoxField
            id="musterPoint"
            name="musterPoint"
            label={t('musterPoint')}
            value={values.musterPoint}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={musterPointList}
            required
          />
          <SelectBoxField
            id="persPersonType"
            name="persPersonType"
            label={t('personnelType')}
            value={values.persPersonType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              {
                label: 'Personnel',
                id: '0',
              },
              {
                label: 'Visitor',
                id: '1',
              },
            ]}
            required
          />
          {values.persPersonType === '0' && (
            <InputField
              id="personName"
              name="personName"
              label={t('personnelId')}
              type="text"
              value={values.personName}
              onClick={handleClickPerson}
              required
            />
          )}
          {values.persPersonType === '1' && (
            <InputField
              id="visitorName"
              name="visitorName"
              label={t('visitorCode')}
              type="text"
              value={values.visitorName}
              onClick={handleClickVisitor}
              required
            />
          )}
        </div>
      </div>

      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default SignInManually;
