import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataAnalysisChart from './components/data-analysis-chart';
import Button from '../../shared-components/button/button';
import { MuteIcon, PauseIcon } from '../../assets/svgicons/svgicon';
import RecordsTable from './components/records-table';
import alarmMonitoring from '../../utils/api/Devices/alarmMonitoring';
import { useAppDispatch } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import alertSound from '../../assets/images/alert.wav';

function AlarmMonitoring() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [buttonStatus, setButtonStatus] = useState('');
  const [chartData, setChartData] = useState([]);
  const [alarmEvents, setAlarmEvents] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const buttonGroup = [
    {
      label: t('mute'),
      icon: <MuteIcon />,
      key: 'mute',
    },
    {
      label: t('suspend'),
      icon: <PauseIcon />,
      key: 'suspend',
    },
  ];
  const changeButton = (key: any) => {
    // If the clicked button is already selected, don't unselect it
    setButtonStatus((prev: any) => (prev === key ? '' : key));
    if (key === 'mute') {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        } else {
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      }
    }
  };
  const getChartData = () => {
    alarmMonitoring
      .getDataByEventNum()
      .then((res) => {
        if (res?.data?.success) {
          const chartValues = res?.data?.data?.analysis?.chartValue?.map((item: any) => {
            let finalColor;
            const setColors = (col: string) => {
              if (col === 'Weak') {
                finalColor = '#10427C';
              } else if (col === 'Medium') {
                finalColor = '#03D733';
              } else if (col === 'Strong') {
                finalColor = '#3D93F5';
              } else {
                finalColor = '#F41120';
              }
              return finalColor;
            };
            const color = setColors(item?.name);
            return {
              ...item,
              color,
            };
          });
          setChartData(chartValues);
          const setEventData = res?.data?.data?.analysis?.top5Value?.map((item: any) => ({
            label: item?.[0],
            value: item?.[1],
          }));
          setAlarmEvents(setEventData);
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    getChartData();
  }, []);
  return (
    <div className="alarm-monitoring">
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('alarmMonitoring'),
          },
        ]}
      />
      <div className="grid-container">
        <div className="flex-box">
          <div className="chart-box">
            <CardBox title={t('dataAnalysis')}>
              <div className="chart-box-wrapper">
                <DataAnalysisChart data={chartData} />
              </div>
            </CardBox>
          </div>
          <div className="alarm-events">
            <CardBox title={t('top5AlarmEvents')}>
              <div className="alarm-events-container">
                {alarmEvents?.map((item: any) => (
                  <div className="rows" key={item.label}>
                    <span className="label">{item.label}</span>
                    <h2 className="value">{item.value}</h2>
                  </div>
                ))}
              </div>
            </CardBox>
          </div>
          <div className="action-buttons">
            <CardBox>
              <div className="button-group">
                {buttonGroup?.map((btn: any) => (
                  <div key={btn?.key}>
                    <Button
                      onClickFunction={() => changeButton(btn?.key)}
                      title={btn?.label}
                      icon={btn?.icon}
                      className={
                        buttonStatus === btn?.key
                          ? 'btn btn-primary row-reverse'
                          : 'btn btn-outline-primary row-reverse'
                      }
                    />
                  </div>
                ))}
              </div>
            </CardBox>
          </div>
        </div>
        <div className="record-table">
          <CardBox title={t('todayRecords')}>
            <RecordsTable />
          </CardBox>
        </div>
      </div>
      <audio ref={audioRef} loop>
        <source src={alertSound} type="audio/wav" />
        <track kind="captions" srcLang="en" label="English" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default AlarmMonitoring;
