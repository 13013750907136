/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import map from '../../utils/api/Devices/map';

const transformData = (node: any) => {
  // Base case: return if the node is null or undefined
  if (!node) return null;

  // Extract required fields
  const { id, text, item, parent } = node;

  // Recursively transform child items if they exist
  const transformedItems = item?.map(transformData) || [];

  // Return the transformed node
  return { id, text, parent, item: transformedItems };
};

export const fetchMapArea = createAsyncThunk('map/fetchMapArea', async (_, { dispatch }) => {
  const apiResponse = await map
    .getMapTree()
    .then((response) => {
      return transformData(response?.data?.item?.[0]);
    })
    .catch((error) => {
      dispatch(setNotificationError(error.response.data));
      return error;
    });
  return apiResponse;
});

interface MapInterface {
  mapArea: any;
  status: 'loading' | 'success' | 'failed';
}

const initialState: MapInterface = {
  mapArea: [],
  status: 'success',
};

export const mapSlice: any = createSlice({
  name: 'timeZone',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMapArea.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchMapArea.fulfilled, (state: any, action: any) => {
        // Add mapArea to the state array
        state.mapArea = action?.payload;
        state.status = 'success';
      })
      .addCase(fetchMapArea.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

export const { setSearchFilter, deleteTimezone } = mapSlice.actions;

export default mapSlice.reducer;
