import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import { AddDoorModalType } from '../../../interface/accessLevel.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import accessLevel from '../../../utils/api/AccessRule/accessLevel';
import { fetchAccessLevels } from '../../../redux/slices/AccessLevelSlices';

function AddDoor({ closeModal, levelId }: AddDoorModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.accessLevel);
  const [doors, setDoors] = useState<any[]>([]); // Available doors
  const [selectedDoors, setSelectedDoors] = useState<any[]>([]); // Selected doors
  const [doorName, setDoorName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const fetchDoor = (params: any) => {
    accessLevel
      .getDoorSelectLevel(params)
      .then((res) => {
        setDoors(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  // Move selected doors from available to selected
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: any[], newSelectedData: any[]) => {
    setDoors(newAvailableData); // Update available data
    setSelectedDoors(newSelectedData); // Update selected data
  };

  const changeSearch = (event: any) => {
    setDoorName(event.target.value);
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const selectedIds = selectedDoors.map((item) => item.id).join(',');
      const request = {
        doorIds: selectedIds,
        levelId,
      };
      const response = await accessLevel.addDoor(request);
      if (response?.data?.success) {
        dispatch(
          setNotificationError({
            error: t('doorAddedSuccessfully'),
            status: 200,
          }),
        );
        setIsLoading(false);
        closeModal();
        dispatch(fetchAccessLevels({ pageNo: 1, pageSize: 10, ...searchFilter.filter }));
      }
    } catch (error: any) {
      setIsLoading(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    fetchDoor({
      levelId,
      type: 'noSelected',
    });
  }, [levelId]);

  return (
    <div className="level-add-door">
      <SwapTable
        searchValue={doorName}
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={doors}
        selectedTableData={selectedDoors}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={isLoading ? t('loading') : t('done')}
          className={`btn-primary ${isLoading ? 'loading' : ''} `}
          loading={isLoading}
          disabled={selectedDoors.length === 0}
        />
      </div>
    </div>
  );
}

export default AddDoor;
