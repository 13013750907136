import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import Button from '../../../../shared-components/button/button';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { AddMusterPointProps } from '../../../../interface/musterPoint.interface';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import Label from '../../../../shared-components/label/Label';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import AddSignPoint from './addSignPoint';
import { AttentionIcon, CircleRemoveIcon } from '../../../../assets/svgicons/svgicon';
import musterPoint from '../../../../utils/api/AdvancedFunctions/musterPoint';
import { fetchMusterPoint } from '../../../../redux/slices/MusterPointSlices';

function AddMusterPoint({ closeModal, update }: AddMusterPointProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.musterPoint);
  const [values, setValues] = useState({
    name: update?.name || '',
    remark: update?.remark || '',
    statisticsVisitors: update?.statisticsVisitors || [],
    messageNotification: update?.messageNotification || [],
    sendReport: update?.sendReport || [],
    sendEmail: update?.sendEmail || [],
    sendSMS: update?.sendSMS || [],
    sendWhatsapp: update?.sendWhatsapp || [],
    signPointIds: update?.signPointIds || [],
  });
  const [disable, setDisable] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const onChangeValue = async (name: string, value: any) => {
    setValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onBlurName = async (name: string, value: any) => {
    if (name === 'name' && value !== '' && value !== update?.name) {
      const responseData = await musterPoint.isExistName({ name: value });
      setCheckExistName(responseData?.data);
    }
  };

  const getSelectedReader = (val: any) => {
    const result = val.map((item: any) => ({
      label: item.name,
      id: item.id,
    }));
    setValues((prev: any) => ({
      ...prev,
      signPointIds: result,
    }));
  };

  const removeSignPointId = (index: number) => {
    const result = values?.signPointIds?.filter((_: any, i: number) => i !== index);
    setValues((prev: any) => ({
      ...prev,
      signPointIds: result,
    }));
  };

  const handleAddSignPointId = () => {
    setModalView({
      title: t('Add Sign Point'),
      content: (
        <AddSignPoint
          selectedReader={values?.signPointIds?.map((item: any) => item.id)}
          getSelected={(selected: any[]) => getSelectedReader(selected)}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };

  const submit = async () => {
    setDisable(true);
    try {
      const request = {
        name: values.name,
        remark: values.remark,
        statisticsVisitors: values?.statisticsVisitors?.length > 0,
        messageNotification: values?.messageNotification?.length > 0,
        sendReport: values?.sendReport?.length > 0,
        sendEmail: values?.sendEmail?.length > 0 ? '1' : '0',
        sendSMS: values?.sendSMS?.length > 0 ? '1' : '0',
        sendWhatsapp: values?.sendWhatsapp?.length > 0 ? '1' : '0',
        signPointIds: values?.signPointIds.map((item: any) => item.id).toString(),
      };
      const response1 = await musterPoint.save(request);
      if (response1.data.success) {
        dispatch(
          setNotificationError({
            error: response1.data.msg,
            status: 200,
          }),
        );
        setDisable(false);
        closeModal();
        dispatch(fetchMusterPoint({ pageNo: 1, pageSize: 10, ...searchFilter.filter }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const fetchEditData = (params: string) => {
    musterPoint
      .edit({ id: params })
      .then((res) => {
        const signPointData = res.data.data.signPointList.map((item: any) => ({
          label: item.signPointName,
          id: item.signPointId,
        }));
        setValues((prev) => ({
          ...prev,
          signPointIds: signPointData,
          statisticsVisitors: res.data.data.item?.statisticsVisitors ? ['0'] : [],
          messageNotification: res.data.data.item?.messageNotification ? ['0'] : [],
          sendReport: res.data.data.item?.sendReport ? ['0'] : [],
          sendEmail: res.data.data.item?.sendEmail === '1' ? ['0'] : [],
          sendSMS: res.data.data.item?.sendSMS === '1' ? ['0'] : [],
          sendWhatsapp: res.data.data.item?.sendWhatsapp === '1' ? ['0'] : [],
        }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (update) {
      fetchEditData(update?.id);
    }
  }, [update]);

  useEffect(() => {
    if (values?.name && values?.signPointIds.length > 0 && checkExistName) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values, checkExistName]);

  return (
    <div className="add-musterPoint">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <div className="grid-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={values.name}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlurName}
            errorStatus={values.name !== '' && !checkExistName}
            message={t('objectIsAlreadyExist')}
            required
          />
          <InputField
            type="text"
            id="remark"
            name="remark"
            label={t('remark')}
            value={values.remark}
            onChangeFunction={onChangeValue}
          />
          <div className="check-box-wrapper">
            <CheckBoxInputField
              name="statisticsVisitors"
              data={[
                {
                  label: t('statisticsVisitors'),
                  id: '0',
                  value: '0',
                },
              ]}
              value={values?.statisticsVisitors}
              onChangeFunction={onChangeValue}
              alignment="row"
            />
            <CheckBoxInputField
              name="messageNotification"
              data={[
                {
                  label: t('messageNotificationActivation'),
                  id: '0',
                  value: '0',
                },
              ]}
              value={values?.messageNotification}
              onChangeFunction={onChangeValue}
              alignment="row"
            />
          </div>
        </div>
        {values?.messageNotification?.length > 0 && (
          <div className="notify-row">
            <Label title={t('notificationType')} />
            <div className="flex-row">
              <CheckBoxInputField
                name="sendEmail"
                data={[
                  {
                    label: t('email'),
                    id: '0',
                    value: '0',
                  },
                ]}
                value={values?.sendEmail}
                onChangeFunction={onChangeValue}
                alignment="row"
              />
              <CheckBoxInputField
                name="sendSMS"
                data={[
                  {
                    label: t('sms'),
                    id: '0',
                    value: '0',
                  },
                ]}
                value={values?.sendSMS}
                onChangeFunction={onChangeValue}
                alignment="row"
              />
              <CheckBoxInputField
                name="sendWhatsapp"
                data={[
                  {
                    label: t('whatsapp'),
                    id: '0',
                    value: '0',
                  },
                ]}
                value={values?.sendWhatsapp}
                onChangeFunction={onChangeValue}
                alignment="row"
              />
            </div>
          </div>
        )}
        <div className="schedule-row">
          <Label title={t('scheduledPushReport')} />
          <CheckBoxInputField
            name="sendReport"
            data={[
              {
                label: t('scheduledPushReport'),
                id: '0',
                value: '0',
              },
            ]}
            value={values?.sendReport}
            onChangeFunction={onChangeValue}
            alignment="row"
          />
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('signPoint')} required />
            <span className="add-link" role="presentation" onClick={handleAddSignPointId}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            {values?.signPointIds?.map((sign: any, i: number) => (
              <div className="d-flex">
                <div
                  onClick={() => removeSignPointId(i)}
                  className="remove-icon"
                  role="presentation">
                  <CircleRemoveIcon />
                </div>
                <span>{sign.label}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="text-danger flex-row center">
          <AttentionIcon />
          {t('notifyMethodConfiguredSuccessWarning')}
        </div>
      </div>

      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddMusterPoint;
