import Cookies from 'universal-cookie';
import { RS485AddressCodeFigureType } from '../interface/accessDevice.interface';

export const getOicStorageKey = () => {
  const cookies = new Cookies();
  const authSettings = cookies.get('token');
  if (authSettings) {
    return authSettings;
  }
  return null;
};

export const getOicInfo = () => {
  const key = getOicStorageKey();
  if (key) {
    return key;
  }
  return null;
};

export const decodeJWTToken = (token: string) => {
  const payload = JSON.parse(atob(token.split('.')[1]));
  return payload;
};

export const getToken = () => {
  const oic = getOicInfo();
  if (oic) {
    return {
      Authorization: `Bearer ${oic}`,
      // 'content-type': 'multipart/form-data',
    };
  }
  return {};
};

export const getFirstCapsLetter = (name: string = '') => {
  return name.toUpperCase().split('')[0];
};

export const changeDateTimeFormat = (value: any) => {
  return value
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).format(new Date(value))
    : '';
};

export const changeDateFormat = (value: any) => {
  const date = new Date(value);
  const dateMDY = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  return dateMDY;
};
export const getCurrentDateTimeWithTimezone = () => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'Asia/Kolkata', // Specify your timezone
  };

  const formattedDate = new Date().toLocaleString('en-GB', options).match(/\d+/g);

  if (formattedDate && formattedDate.length === 6) {
    const [day, month, year, hour, minute, second] = formattedDate;
    return `${year}${month}${day}${hour}${minute}${second}`;
  }

  return '00000000000000';
};

export const fileDownload = (data: any, name: string) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name); // Set file name
  document.body.appendChild(link);
  link.click();

  link.remove();
  URL.revokeObjectURL(url);
  // Clean up the Object URL to release memory
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
};
export const getOperateTypeAction = (value: number) => {
  let result = '';
  switch (value) {
    case 1:
      result = 'Issue Card';
      break;
    case 7:
      result = 'Reactivate Lost Card';
      break;
    case 6:
      result = 'Reported Lost Card';
      break;
    case 10:
      result = 'Write management card';
      break;
    case 11:
      result = 'Write Card';
      break;
    case 12:
      result = 'Card Returned';
      break;
    case 13:
      result = 'Card Change';
      break;
    default:
      result = 'Unknown Action';
  }
  return result;
};

// Calculate RS485 Address based on switch states
export const getRS485Value = (switchStates: Record<string, boolean>): number => {
  // Each switch corresponds to a unique value, defined as below:
  const switchValues: any = {
    switch1: 1, // 2^0 = 1
    switch2: 2, // 2^1 = 2
    switch3: 4, // 2^2 = 4
    switch4: 8, // 2^3 = 8
    switch5: 16, // 2^4 = 16
    switch6: 32, // 2^5 = 32
    switch7: 64, // 2^6 = 64
    switch8: 128, // 2^7 = 128
  };

  // Calculate the RS485 address by summing the values of active switches
  return Object.entries(switchStates).reduce((total, [key, isOn]) => {
    return isOn ? total + switchValues[key] : total;
  }, 0);
};

export const getSwitchStatesFromRS485 = (value: any): RS485AddressCodeFigureType => {
  // Create a default state for 8 switches
  const switchStates: RS485AddressCodeFigureType = {
    switch1: false,
    switch2: false,
    switch3: false,
    switch4: false,
    switch5: false,
    switch6: false,
    switch7: false,
    switch8: false,
  };

  // Check the binary representation of the value
  const binaryString = value.toString(2).padStart(8, '0'); // Get binary and pad to 8 digits

  // Update switch states based on binary representation (from right to left)
  Object.keys(switchStates).forEach((key, index) => {
    const isSwitchOn = binaryString[binaryString.length - 1 - index] === '1'; // Rightmost bit represents switch1
    switchStates[key as keyof RS485AddressCodeFigureType] = isSwitchOn;
  });

  return switchStates;
};

// Helper to sort devices based on their online/offline/disabled status
export const sortValuesByStatus = (a: any, b: any): number => {
  if (a.status === 'online' && b.status !== 'online') return -1;
  if (a.status !== 'online' && b.status === 'online') return 1;
  return 0; // Maintain order if both have the same status
};

export function getCurrentMilliseconds() {
  const currentTimeInMillis = Date.now();
  return currentTimeInMillis;
}

export const LoadingProgressCount = (
  initialProgress: any,
  incrementAmount: any,
  intervalDuration: any,
  onUpdate: any,
  onComplete: any,
) => {
  let count = initialProgress; // Start from initial progress

  const interval = setInterval(() => {
    const newProgress = count < 100 ? count + incrementAmount : 100; // Calculate new progress
    onUpdate(newProgress); // Update progress using callback
    count = newProgress; // Update the count

    if (count >= 100) {
      clearInterval(interval); // Stop once count reaches 100
      onComplete(); // Call the completion callback
    }
  }, intervalDuration); // Set interval duration

  // Return a function to clear the interval if needed
  return () => clearInterval(interval);
};

export const verifyModeFormat = (verify: string): string | undefined => {
  const verifyData: any = {
    acc_verify_mode_onlyface: 'Face',
    acc_verify_mode_facefp: 'Face+Impressão Digital',
    acc_verify_mode_facepwd: 'Face+Senha',
    acc_verify_mode_facecard: 'Face+Cartão',
    acc_verify_mode_facefpcard: 'Face+Impressão Digital+Cartão',
    acc_verify_mode_facefppwd: 'Face+Impressão Digital+Senha',
    acc_verify_mode_fv: 'Veias do Dedo',
    acc_verify_mode_fvpwd: 'Veias do Dedo+Senha',
    acc_verify_mode_fvcard: 'Veias do Dedo+Cartão',
    acc_verify_mode_fvpwdcard: 'Veias do Dedo+Senha+Cartão',
    acc_verify_mode_pv: 'Veias da Palma',
    acc_verify_mode_pvcard: 'Veias da Palma+Cartão',
    acc_verify_mode_pvface: 'Veias da Palma+Face',
    acc_verify_mode_pvfp: 'Veias da Palma+Impressão Digital',
    acc_verify_mode_pvfacefp: 'Veias da Palma+Face+Impressão Digital',
  };

  return verifyData[verify];
};

export const commonVerifyMode = (verify: string): string | undefined => {
  const verifyData: any = {
    entiy: 'Verification Mode',
    cardOrFpOrPwd: 'Automatic Identification',
    onlyFp: 'Only Fingerprint',
    onlyPin: 'Only Pin',
    onlyPwd: 'Only Password',
    onlyCard: 'Only Card',
    pwdOrFp: 'Fingerprint/Password',
    cardOrFp: 'Card/Fingerprint',
    cardOrPwd: 'Card/Password',
    pinAndFp: 'Pin+Fingerprint',
    fpAndPwd: 'Fingerprint+Password',
    cardAndFp: 'Card+Fingerprint',
    cardAndPwd: 'Card+Password',
    cardAndFpAndPwd: 'Card+Password+Fingerprint',
    pinAndFpAndPwd: 'Pin+Password+Fingerprint',
    pinAndFpOrCardAndFp: 'Pin+Fingerprint/Card+Fingerprint',
    adminVerify: 'The administrator authentication',
    adminOrGeneralUser: 'The administrator/user authentication',
    doubleVerify: 'The two-step verification',
    other: 'Other',
    undefined: 'Verify Mode Undefined',
    entiyReverse: 'Verification Mode Reverse Selection',
    common_verifyMode_other: 'Other',
    common_verifyMode_entiy: 'Verification Mode',
    common_verifyMode_cardOrFpOrPwd: 'Automatic Identification',
    common_verifyMode_onlyFp: 'Only Fingerprint',
    common_verifyMode_onlyPin: 'Only Pin',
    common_verifyMode_onlyPwd: 'Only Password',
    common_verifyMode_onlyCard: 'Only Card',
    common_verifyMode_pwdOrFp: 'Fingerprint/Password',
    common_verifyMode_cardOrFp: 'Card/Fingerprint',
    common_verifyMode_cardOrPwd: 'Card/Password',
    common_verifyMode_pinAndFp: 'Pin+Fingerprint',
    common_verifyMode_fpAndPwd: 'Fingerprint+Password',
    common_verifyMode_cardAndFp: 'Card+Fingerprint',
    common_verifyMode_cardAndPwd: 'Card+Password',
    common_verifyMode_cardAndFpAndPwd: 'Card+Password+Fingerprint',
    common_verifyMode_pinAndFpAndPwd: 'Pin+Password+Fingerprint',
    common_verifyMode_pinAndFpOrCardAndFp: 'Pin+Fingerprint/Card+Fingerprint',
    common_verifyMode_adminVerify: 'The administrator authentication',
    common_verifyMode_adminOrGeneralUser: 'The administrator/user authentication',
    common_verifyMode_doubleVerify: 'The two-step verification',
    common_verifyMode_undefined: 'Verify Mode Undefined',
    common_verifyMode_entiyReverse: 'Verification Mode Reverse Selection',
    common_newVerify_mode_face: 'Face',
    common_newVerify_mode_pv: 'Palm',
    common_newVerify_mode_fv: 'Finger Vein',
    common_newVerify_mode_fp: 'Fingerprint',
    common_newVerify_mode_voice: 'Voice Print',
    common_newVerify_mode_iris: 'Iris',
    common_newVerify_mode_retina: 'Retina',
    common_newVerify_mode_pw: 'Password',
    common_newVerify_mode_pin: 'Work ID',
    common_newVerify_mode_rf: 'Card',
    common_newVerify_mode_idcard: 'ID Card',
    common_newVerify_mode_logic: 'Logic bit',
    common_newVerify_mode_logicOr: 'Or',
    common_newVerify_mode_logicAnd: 'And',
    common_newVerify_mode_manualConfig: 'Manual Configuration',
  };

  return verifyData[verify];
};
export const timeZoneDecode = (data: any) => {
  const result: any = {}; // The transformed structure.

  Object.entries(data).forEach(([key, value]: any) => {
    // Extract day, type (Start/End), and interval number from the key.
    const match = key.match(/([a-zA-Z]+(?:Type\d+)?)(Start|End)(\d+)/);
    if (match) {
      /**
       * Extracts the day, type (Start/End), and interval from the match.
       * The match contains the full key like "mondayStart1" and is sliced into day, type, and interval.
       *
       * @param match - The result of the key.match() which splits the key into day, type, and interval.
       * @returns {Array} - An array where:
       *   - day: The day (e.g., "monday", "holiday1")
       *   - type: "Start" or "End"
       *   - interval: The interval number (e.g., 1, 2, 3)
       */
      const [day, type, interval] = match.slice(1);
      // If the day contains "Type" part, remove it.
      const cleanedDay = day.replace('Type', '');
      /**
       * Ensures that the cleaned day exists in the result object.
       * If the day does not exist in the result, initialize it as an empty object.
       *
       * @param cleanedDay - The cleaned-up day name (e.g., "monday", "holiday1").
       * @returns {void}
       */
      if (!result[cleanedDay]) result[cleanedDay] = {};

      // Ensure the interval exists for the day.
      const intervalKey = `interval${interval}`;
      if (!result[cleanedDay][intervalKey]) {
        result[cleanedDay][intervalKey] = { startTime: {}, endTime: {} };
      }

      /**
       * Validates that the value (time) is in the correct format (HH:mm).
       * If valid, splits the time into hour and minute and assigns it to the appropriate time (start or end) in the result.
       *
       * @param value - The time value (e.g., "10:00", "23:59") for either start or end time.
       * @returns {void}
       */
      if (value && value.includes(':')) {
        const [hour, minute] = value.split(':');
        result[cleanedDay][intervalKey][`${type.toLowerCase()}Time`] = { hour, minute };
      }
    }
  });
  return result;
};

export const checkAnyVerification = (data: any) => {
  return Object.values(data).some((day: any) =>
    Object.values(day).some(
      (interval: any) => interval.doorVerification || interval.personnelVerification,
    ),
  );
};

export const transformVerifyData = (input: any) => {
  const output: any = {};
  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'holidayType1',
    'holidayType2',
    'holidayType3',
  ];

  const intervals = 3; // Can be dynamic or configurable

  const createInterval = (day: string, i: number): any => ({
    startTime: { hour: '00', minute: '00' },
    endTime: { hour: '00', minute: '00' },
    doorVerification:
      input[`${day}Time${i}VSDoor`] !== undefined ? input[`${day}Time${i}VSDoor`] : 255,
    personnelVerification:
      input[`${day}Time${i}VSPerson`] !== undefined ? input[`${day}Time${i}VSPerson`] : 255,
  });

  // Loop through each day and generate intervals
  days.forEach((day) => {
    let dayName = day;

    // Map the holidayType* names to holiday*
    if (dayName.includes('holidayType')) {
      dayName = dayName.replace('holidayType', 'holiday');
    }

    output[dayName] = {}; // Initialize the day object

    for (let i = 1; i <= intervals; i += 1) {
      output[dayName][`interval${i}`] = createInterval(day, i);
    }
  });

  return output;
};

export const verifySubmitData = (input: any) => {
  const result: any = {};
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  Object.entries(input).forEach(([day, inter]: any) => {
    const isHoliday = day.includes('holiday');
    const isWeekday = weekDays.includes(day); // Correctly check if it's a weekday

    Object.entries(inter).forEach(([, value]: any, index) => {
      const intervalNumber = index + 1; // Interval 1, 2, 3

      if (isWeekday && !isHoliday) {
        // Logic for Weekdays
        const doorKey = `${day}Time${intervalNumber}VSDoor`;
        const personnelKey = `${day}Time${intervalNumber}VSPerson`;

        // Assign values to the weekday keys (default to 255 if empty or undefined)
        result[doorKey] =
          value.doorVerification === '' || value.doorVerification === undefined
            ? 255
            : value.doorVerification;
        result[personnelKey] =
          value.personnelVerification === '' || value.personnelVerification === undefined
            ? 255
            : value.personnelVerification;
      }

      if (isHoliday) {
        // Logic for Holidays
        const holidayType = day.replace('holiday', 'holidayType');
        const holidayKeyPrefix = `${holidayType}Time${intervalNumber}`;

        result[`${holidayKeyPrefix}VSDoor`] =
          value.doorVerification === '' || value.doorVerification === undefined
            ? 255
            : value.doorVerification;

        result[`${holidayKeyPrefix}VSPerson`] =
          value.personnelVerification === '' || value.personnelVerification === undefined
            ? 255
            : value.personnelVerification;
      }
    });
  });
};

export const treeDataFunction = (node: any) => {
  // Base case: return if the node is null or undefined
  if (!node) return null;

  // Extract required fields
  const { id, text, item, parent } = node;

  // Recursively transform child items if they exist
  const transformedItems = item?.map(treeDataFunction) || [];

  // Return the transformed node
  return { id, text, parent, item: transformedItems };
};

export const personVerifyModes: any = {
  '0_0_0_0_0_0_0_1_0_0': 'Card',
  '0_0_0_0_1_0_0_0_0_0': 'Face',
  '0_0_0_0_0_0_1_0_0_0': 'Pin',
  '0_0_0_0_0_0_0_0_0_0': 'None',
  '0_0_0_0_1_0_0_1_0_0': 'Face+Card',
  '0_0_0_0_1_0_0_0_1_0': 'Face+Pin',
  '0_0_0_0_1_0_0_0_0_1': 'Face+Password',
  '0_0_0_0_0_0_1_0_0_1': 'Pin+Password',
  '0_0_0_0_0_0_0_1_0_1': 'Card+Password',
  '0_0_0_0_0_0_1_0_1_0': 'Pin+Card',
  '0_0_0_0_0_0_1_0_1_1': 'Pin+Card+Password',
  '0_0_0_0_0_0_1_1_0_0': 'Pin+Card+Face',
  '0_0_0_0_0_0_1_1_0_1': 'Pin+Card+Face+Password',
};
