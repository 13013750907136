import { get, post } from '../base';

export default {
  saveMap: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/map/save`, data, {}, true);
  },
  isExistName: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/map/isExist/${data}`, {}),
  getMapTree: () => get(`${process.env.REACT_APP_API_URL}Acc/api/map/getMapTree`, {}),
  getMap: (id: string) => get(`${process.env.REACT_APP_API_URL}Acc/api/map/getMap/${id}`, {}),
  mapSelectDoor: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/mapSelectDoor/list`,
      {},
      {
        ...data,
        pageNo: 1,
        pageSize: 50,
      },
    );
  },
  deleteMap: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/map/delete`, {}, data);
  },
  saveMapPosition: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/map/saveMapPos`, {}, data);
  },
  addDoor: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/map/addDoor`, {}, data);
  },
};
