import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/zonePerson/listByPage`, {}, { ...data });
  },
  deleteAreaHead: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/zonePerson/delPerson`, {}, data);
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/zonePerson/export`, 'POST', data);
  },
};
