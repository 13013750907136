/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import parametersApi from '../../utils/api/parameters';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { ParameterReduxInterface } from '../../interface/parameter.interface';

// Use get table custom attribute list
export const fetchParametersList = createAsyncThunk(
  'parameter/fetchParametersList',
  async (data: any, { dispatch }) => {
    const response = await parametersApi
      .getParameters()
      .then((response1) => {
        const permissions: string[] = [];
        const permision = [
          'cardNo',
          'birthday',
          'carPlate',
          'certNumber',
          'email',
          'gender',
          'lastName',
          'pin',
          'name',
          'mobilePhone',
          'bioPhoto',
          'headPortrait',
        ];

        permision.forEach((val) => {
          if (response1.data.data[`pers.${val}.encryptProp`] === 'true') {
            permissions.push(val);
          }
        });
        return {
          ...response1.data,
          data: {
            ...response1.data.data,
            permissions,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: ParameterReduxInterface = {
  parameters: {},
  status: 'success',
};

export const parameterSlices: any = createSlice({
  name: 'parameters',
  initialState,
  reducers: {
    updateParameters: (state, action: any) => {
      state.parameters = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchParametersList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchParametersList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          const permissions: string[] = [];
          const permission = [
            'cardNo',
            'birthday',
            'carPlate',
            'certNumber',
            'email',
            'gender',
            'lastName',
            'pin',
            'name',
            'mobilePhone',
            'bioPhoto',
            'headPortrait',
          ];

          permission.forEach((val) => {
            if (action.payload.data[`pers.${val}.encryptProp`] === 'true') {
              permissions.push(val);
            }
          });
          state.parameters = action.payload.data;
          state.parameters.permissions = permissions;
        }
        state.status = 'success';
      })
      .addCase(fetchParametersList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateParameters } = parameterSlices.actions;

export default parameterSlices.reducer;
