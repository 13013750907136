import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import { LoadingProgressCount, sortValuesByStatus } from '../../../utils/helpers';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

interface ModalType {
  closeModal: () => void;
  selectedValues: any;
  selectedId: string[];
}
function SynchronizeTime({ closeModal, selectedValues, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [responseMsg, setResponseMsg] = useState('');
  const submit = () => {
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    device
      .synchronizeTime(selectedId.join('&'))
      .then((response) => {
        setResponseMsg(response.data.success);
        if (response.data.success) {
          setTimeout(() => {
            stopProgress();
            setLoading('0');
          }, 3500);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled status
   */

  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const getOnlineDevice = selectedDevices.map((data: any) => data?.status).includes('online');
  return (
    <div className="device-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (item: any) =>
                item?.status === 'online' && (
                  <h4 className="device-name">
                    {item.deviceName}: <span className="message">{item.ipAddress}</span>
                  </h4>
                ),
            )}
            {selectedDevices.filter((item: any) => item?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((item: any) => item?.status === 'disabled').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('synchronize')}
                  className={`btn-primary ${loading === '1' ? 'loading' : ''} `}
                  disabled={!getOnlineDevice}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && responseMsg && (
                <div className="success-wrapper">
                  <p>Data Processing, Please Wait.....</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.deviceName}: </span>
                    ))}
                    Sending commands completed...
                  </p>
                  <p>Synchronize Time...</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.deviceName}: </span>
                    ))}
                    Time Synchronization successful...
                  </p>
                  <p>Completed</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SynchronizeTime;
