/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import daylightSavingTime from '../../utils/api/Devices/daylightSavingTime';
import { setNotificationError } from './NotificationSlices';

export const fetchDaylightSavingTime = createAsyncThunk(
  'daylightSavingTime/fetchDaylightSavingTime',
  async (data: any, { dispatch }) => {
    const apiResponse = await daylightSavingTime
      .getListPage(data)
      .then((response) => {
        console.log(response);
        return {
          ...response.data,
          data: {
            list: response.data.data.data.map((val: any) => ({
              ...val,
              id: val?.id,
            })),
            size: response.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return apiResponse;
  },
);
export const fetchTimeZone = createAsyncThunk(
  'all/fetchTimeZone',
  async (data: any, { dispatch }) => {
    const response = await daylightSavingTime
      .timeZoneList(data)
      .then((response1) => {
        const formattedResponse = response1.data.data.map((item: any) => ({
          label: item.text,
          id: item.value,
        }));
        return formattedResponse;
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);
const initialState = {
  daylightSavingTime: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  timeZone: [],
  timeZoneStatus: 'success',
};

export const daylightSavingTimeOutputSlice: any = createSlice({
  name: 'daylightSavingTime',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDaylightSavingTime.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchDaylightSavingTime.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.daylightSavingTime = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
        }
        state.status = 'success';
      })
      .addCase(fetchDaylightSavingTime.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchTimeZone.pending, (state = initialState) => {
        state.timeZoneStatus = 'loading';
      })
      .addCase(fetchTimeZone.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        state.timeZone = action.payload;
        state.timeZoneStatus = 'success';
      })
      .addCase(fetchTimeZone.rejected, (state: any) => {
        state.timeZoneStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = daylightSavingTimeOutputSlice.actions;

export default daylightSavingTimeOutputSlice.reducer;
