/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import { AccessByDepartmentInterface } from '../../interface/accessByDepartment.interface';
import accessLevel from '../../utils/api/AccessRule/accessLevel';

// Thunk for fetching the fetchAccessLevels list
export const fetchAccessLevels = createAsyncThunk(
  'fetchAccessLevels',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessLevel.getListPage(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.data.map((item: any) => ({
            ...item,
            ...(item?.initFlag ? { delete: false } : {}),
          })),
          size: response.data.data.total,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    }
  },
);
export const fetchLevelDoor = createAsyncThunk(
  'fetchLevelDoor',
  async (data: any, { dispatch }) => {
    try {
      const response = await accessLevel.getDoorList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);

interface LevelProps {
  equals: boolean;
  id: string;
  levelName: string;
  timeSegName: string;
}
// Initial state for the slice
interface PersonState {
  levels: AccessByDepartmentInterface[]; // List of issued cards
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
  levelId: string;
  doors: LevelProps[];
  doorStatus: string;
  doorTotalDataCount: number;
  doorCurrentPageNo: number;
  doorSearchFilter: any;
}

const initialState: PersonState = {
  levels: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  levelId: '',
  doors: [],
  doorStatus: 'success',
  doorTotalDataCount: 0,
  doorCurrentPageNo: 0,
  doorSearchFilter: {},
};

// Create the slice
export const accessLevelSlice = createSlice({
  name: 'accessByPerson',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
    deleteLevels: (state, action: PayloadAction<any>) => {
      state.levels = state.levels.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    setDoorSearchFilter: (state, action: PayloadAction<any>) => {
      state.doorSearchFilter = action.payload;
    },
    deleteLevelDoor: (state, action: PayloadAction<any>) => {
      state.doors = state.doors.filter((val: any) => val.id !== action.payload);
      state.doorTotalDataCount -= 1;
    },
    resetLevels: (state) => {
      state.doors = [];
    },
  },
  extraReducers: (builder) => {
    // Handle fetchAccessLevels actions
    builder
      .addCase(fetchAccessLevels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAccessLevels.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;
          state.levels = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchAccessLevels.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchLevelDoor.pending, (state) => {
        state.doorStatus = 'loading';
      })
      .addCase(fetchLevelDoor.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.doorTotalDataCount = action.payload.data.size;
          state.doorCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          // if (state.doorCurrentPageNo === 1) {
          state.doors = action.payload.data.list;
          // } else {
          //   state.doors = [...state.doors, ...action.payload.data.list];
          // }
        }
        state.doorStatus = 'success';
      })
      .addCase(fetchLevelDoor.rejected, (state) => {
        state.doorStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setDoorSearchFilter, resetLevels, deleteLevels, deleteLevelDoor } =
  accessLevelSlice.actions;

export default accessLevelSlice.reducer;
