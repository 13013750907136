import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputTextAreaField from '../../../../shared-components/input-text-area-field/input-text-area-field';
import { AttentionIcon } from '../../../../assets/svgicons/svgicon';
import { isValidMultipleEmail } from '../../../../shared-components/form/validation';

function GlobalEmailLinkage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  return (
    <div className="flex-row column">
      <InputTextAreaField
        id="email"
        value={email}
        label={t('emailAddress')}
        name="email"
        onChangeFunction={(name, value) => setEmail(value)}
        errorStatus={email !== '' && !isValidMultipleEmail(email)}
        message="Please enter a valid mail address"
        placeHolder="Ex: 123@eee.com; 456@nnn.com"
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t('multipleEmailMessage')}
      </div>
    </div>
  );
}

export default GlobalEmailLinkage;
