import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared-components/button/button';
import SwapTable from '../../../../shared-components/swapTables/swapTable';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../../redux/store';
import musterPoint from '../../../../utils/api/AdvancedFunctions/musterPoint';

interface ModalType {
  closeModal: () => void;
  getSelected: (val: any) => void;
}

function AddSignPoint({ closeModal, getSelected }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [readers, setReaders] = useState<any[]>([]); // Available readers
  const [selectedReaders, setSelectedReaders] = useState<any[]>([]); // Selected readers
  const [name, setName] = useState('');

  const tableColumns = [{ label: t('name'), id: 'name' }];

  const fetchReader = (params: any) => {
    musterPoint
      .selectReaderList(params)
      .then((res) => {
        setReaders(res.data.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  // Move selected readers from available to selected
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: any[], newSelectedData: any[]) => {
    setReaders(newAvailableData); // Update available data
    setSelectedReaders(newSelectedData); // Update selected data
  };

  const changeSearch = (event: any) => {
    setName(event.target.value);
  };

  const submit = () => {
    if (selectedReaders?.length > 0) {
      getSelected(selectedReaders);
      closeModal();
    }
  };

  useEffect(() => {
    fetchReader({
      // levelId,
      type: 'noSelected',
    });
  }, []);

  return (
    <div className="add-sign-point">
      <SwapTable
        searchValue={name}
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={readers}
        selectedTableData={selectedReaders}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedReaders.length === 0}
        />
      </div>
    </div>
  );
}

export default AddSignPoint;
