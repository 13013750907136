import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

type ParametersProps = {
  maxHeight: string;
};

interface TabChildProps {
  value: ParametersProps;
  onChangeValue: (data: ParametersProps) => void;
}
function MonitoringMailbox({ value, onChangeValue }: TabChildProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(value);
  const handleChange = (name: string, data: any) => {
    const updatedValues = {
      ...values,
      [name]: data,
    };
    setValues(updatedValues);
    onChangeValue(updatedValues);
  };
  return (
    <div className="parameters-inputs">
      <InputField
        name="maxHeight"
        label={t('realTimeMonitoringPage')}
        id="maxHeight"
        type="number"
        value={values?.maxHeight}
        validation
        message={t('px(80-500)')}
        onChangeFunction={(name, data) => handleChange(name, data)}
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        <span className="error-msg">
          {t('refreshTheReal-timeMonitoringPageAfterSettingHeight')}
        </span>
      </div>
    </div>
  );
}

export default MonitoringMailbox;
