import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Label from '../../../shared-components/label/Label';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

type ParametersProps = {
  autoExport: string;
  exportMode: string;
  exportByMonth: string;
  monthExportMode: string;
  hour?: string;
  minute?: string;
  specificDate?: string;
  recipientsMail?: string;
};

interface TabChildProps {
  value: ParametersProps;
  onChangeValue: (data: ParametersProps) => void;
}

function AutoExport({ value, onChangeValue }: TabChildProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(value);
  const autoExportOptions = [
    {
      label: t('none'),
      id: 'none',
      value: 'none',
    },
    {
      label: t('byDay'),
      id: 'byDay',
      value: 'byDay',
    },
    {
      label: t('byMonth'),
      id: 'byMonth',
      value: 'byMonth',
    },
  ];
  const options = [
    {
      label: t('dailyTransactions'),
      id: '0',
      value: '0',
    },
    {
      label: t('allDataExportUpTo30000PiecesOfData'),
      id: '1',
      value: '1',
    },
  ];

  const hours = Array.from({ length: 24 }, (_, i) => `${i}`);
  const hourOptions = hours.map((hour: string) => ({
    label: hour,
    id: hour,
    value: hour,
  }));
  const minutes = Array.from({ length: 60 }, (_, i) => `${i}`);
  const minuteOptions = minutes.map((hour: string) => ({
    label: hour,
    id: hour,
    value: hour,
  }));
  const dates = Array.from({ length: 28 }, (_, i) => `${i + 1}`);
  const datesOptions = dates.map((hour: string) => ({
    label: hour,
    id: hour,
    value: hour,
  }));
  const handleChange = (name: string, data: any) => {
    const updatedValues = {
      ...values,
      [name]: data,
    };
    setValues(updatedValues);
    onChangeValue(updatedValues);
  };
  return (
    <div className="auto-exports">
      <div className="form-box-row">
        <Label title="Transactions Auto-Export :" />
        <SelectBoxField
          id="autoExport"
          label={t('autoExpertFrequency')}
          value={values?.autoExport}
          name="autoExport"
          data={autoExportOptions}
          onChangeFunction={(name, changeValue) => handleChange(name, changeValue.id)}
        />
        {(values?.autoExport === 'byDay' || values?.autoExport === 'byMonth') && (
          <>
            {values?.autoExport === 'byDay' && (
              <div className="flex-row time-wrapper">
                <SelectBoxField
                  id="hour"
                  label={t('hh')}
                  value={values?.hour}
                  name="hour"
                  data={hourOptions}
                  onChangeFunction={(name, changeValue) => handleChange(name, changeValue.id)}
                />
                <SelectBoxField
                  id="minute"
                  label={t('mm')}
                  value={values?.minute}
                  name="minute"
                  data={minuteOptions}
                  onChangeFunction={(name, changeValue) => handleChange(name, changeValue.id)}
                />
              </div>
            )}
            {values?.autoExport === 'byMonth' && (
              <div className="by-month">
                <RadioButtonField
                  label=""
                  name="exportByMonth"
                  alignment="row-wrap"
                  data={[
                    {
                      label: t('firstDayOfTheMonth'),
                      id: '0',
                      value: '0',
                    },
                    {
                      label: t('specificDate'),
                      id: '1',
                      value: '1',
                    },
                  ]}
                  value={values?.exportByMonth}
                  onChangeFunction={(name, data) => handleChange(name, data)}
                />
                <div className="input-box">
                  <SelectBoxField
                    id="specificDate"
                    label=""
                    value={values?.specificDate}
                    name="specificDate"
                    data={datesOptions}
                    onChangeFunction={(name, changeValue) => handleChange(name, changeValue.id)}
                  />
                </div>
              </div>
            )}
            <div className="export-mode">
              {values?.autoExport === 'byDay' && (
                <RadioButtonField
                  label={t('exportMode')}
                  name="exportMode"
                  alignment="row-wrap"
                  data={options}
                  value={values?.exportMode}
                  onChangeFunction={(name, data) => handleChange(name, data)}
                />
              )}
              {values?.autoExport === 'byMonth' && (
                <RadioButtonField
                  label={t('exportMode')}
                  name="monthExportMode"
                  alignment="row-wrap"
                  data={options}
                  value={values?.monthExportMode}
                  onChangeFunction={(name, data) => handleChange(name, data)}
                />
              )}
            </div>
            <div className="mail-box">
              <InputTextAreaField
                id="recipientsMail"
                value={values?.recipientsMail || ''}
                label={t('RecipientMailbox')}
                name="recipientsMail"
                placeHolder="access2@saxco.com.my"
                onChangeFunction={handleChange}
              />
              <div className="text-danger flex-row center">
                <AttentionIcon />
                <span className="error-msg">{t('multipleEmailMessage')}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AutoExport;
