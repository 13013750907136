import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import Button from '../../../shared-components/button/button';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import verificationMode from '../../../utils/api/AccessRule/verificationMode';
import { fetchVerificationMode } from '../../../redux/slices/VerificationModeSlices';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { VerifyDoorProps } from '../../../interface/VerificationMode.interface';

interface AddToDefaultLevelProps {
  closeModal: () => void;
  verifyModeRuleId: string;
}
function AddPersonnel({ closeModal, verifyModeRuleId }: AddToDefaultLevelProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.verificationMode);
  const [doors, setDoors] = useState<VerifyDoorProps[]>([]); // Available doors
  const [selectedDoors, setSelectedDoors] = useState<VerifyDoorProps[]>([]); // Selected doors
  const [search, setSearch] = useState('');
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];
  const fetchData = (params: any) => {
    verificationMode
      .getDoorList(params)
      .then((res) => {
        setDoors(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: VerifyDoorProps[], newSelectedData: VerifyDoorProps[]) => {
    setDoors(newAvailableData); // Update available data
    setSelectedDoors(newSelectedData); // Update selected data
  };
  const submit = () => {
    const req = {
      verifyModeRuleId,
      doorIds: selectedDoors.map((item: any) => item.id).join(','),
    };
    verificationMode
      .addDoor(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('doorAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchVerificationMode({ pageNo: 1, pageSize: 10, ...searchFilter.filter }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    fetchData({
      verifyModeRuleId,
      type: 'noSelected',
    });
  }, [verifyModeRuleId]);
  return (
    <div className="flex-row column">
      <SwapTable
        customStyle={{ width: '70vw' }}
        searchValue={search}
        changeSearch={(name, val) => setSearch(val)}
        tableColumns={tableColumns}
        tableData={doors}
        selectedTableData={selectedDoors}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('done')} className="btn-primary" />
      </div>
    </div>
  );
}

export default AddPersonnel;
