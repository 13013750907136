import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import GlobalAddPartition from './globalAddPartition';
import Label from '../../../../shared-components/label/Label';

function GlobalIntrusion() {
  const { t } = useTranslation();
  const armTypeOptions = [
    {
      label: t('masterInstantArm'),
      id: 'masterInstantArm',
    },
    {
      label: t('masterDelayArm'),
      id: 'masterDelayArm',
    },
    {
      label: t('perimeterInstantArm'),
      id: 'perimeterInstantArm',
    },
    {
      label: t('perimeterDelayArm'),
      id: 'perimeterDelayArm',
    },
    {
      label: t('forceMasterDelayArm'),
      id: 'forceMasterDelayArm',
    },
    {
      label: t('forceMasterInstantArm'),
      id: 'forceMasterInstantArm',
    },
  ];
  const [value, setValue] = useState({
    actionType: '',
    armType: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
  };
  const addPartition = () => {
    setModalView({
      title: t('addPartition'),
      content: <GlobalAddPartition closeModal={() => setModalOpen(false)} />,
    });
    setModalOpen(true);
  };
  return (
    <>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="intrusion">
        <div className="grid-row">
          <SelectBoxField
            id="actionType"
            name="actionType"
            label={t('actionType')}
            value={value.actionType}
            onChangeFunction={(name, changeValue) => onChangeValue(name, changeValue.id)}
            data={[
              {
                label: 'Arm',
                id: 'arm',
              },
              {
                label: 'Disarm',
                id: 'Disarm',
              },
            ]}
          />
          <SelectBoxField
            id="actionType"
            name="actionType"
            label={t('actionType')}
            value={value.actionType}
            onChangeFunction={(name, changeValue) => onChangeValue(name, changeValue.id)}
            data={armTypeOptions}
          />
        </div>
        <div className="partition">
          <div className="header">
            <Label title={t('linkageTriggerConditions')} />
            <span className="add-link" role="presentation" onClick={addPartition}>
              {t('add')}
            </span>
          </div>
          <div className="box" />
        </div>
      </div>
    </>
  );
}

export default GlobalIntrusion;
