import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/transaction/list`, {}, { ...data });
  },
  clearData: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/transaction/clearData`, {});
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/transaction/export`, 'POST', data);
  },
  exportPhoto: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/transaction/exportPhoto`, 'POST', data);
  },
  getDecryptPhotoBase64: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/transaction/getDecryptPhotoBase64`,
      {},
      data,
    );
  },
  getTransactionsTodayLast: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/transaction/getTransactionsTodayLast`, data, {}),
};
