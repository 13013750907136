import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import Button from '../../../shared-components/button/button';
import { fetchAllPositionList } from '../../../redux/slices/PositionSlices';
import PersonalApis from '../../../utils/api/person'; // Import the API module
import { setNotificationError } from '../../../redux/slices/NotificationSlices'; // Import the notification action

function ChangePosition({
  closeModal,
  selectedPerson,
  submitFunction,
}: {
  closeModal: () => void;
  selectedPerson: any[];
  submitFunction: (val: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { allPositions } = useAppSelector((state: any) => state.positions);
  const [value, setValue] = useState({
    selectedPerson: [], // Initialize as an array for multiple selections
    newPosition: '',
    transferReason: '',
  });
  const [disableButton, setDisableButton] = useState(false); // Add state to manage button disable status

  const onChange = (name: string, changeValue: any) =>
    setValue((prev) => ({
      ...prev,
      [name]: changeValue,
    }));

  useEffect(() => {
    if (allPositions.length === 0) {
      dispatch(fetchAllPositionList({}));
    }
  }, [dispatch, allPositions.length]);
  useEffect(() => {
    if (value.newPosition && value.transferReason) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);

  // Add the submit function here
  const submit = () => {
    setDisableButton(true);

    // Prepare the request payload
    const request = {
      pins: selectedPerson.join(','),
      reason: value.transferReason, // Assign the transferReason to reason
      code: value.newPosition, // Assign the newPosition to code
    };

    // Call the API to change the position
    PersonalApis.changePosition(request) // Updated API method
      .then((response) => {
        if (response.data.code === 0) {
          setDisableButton(false);
          submitFunction(request); // Pass the request object or another relevant value
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    if (value.newPosition !== '' && value.newPosition !== '') {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="item span-2">
          <SelectBoxField
            name="newPosition"
            label={t('newPosition')}
            id="newPosition"
            value={value.newPosition}
            required
            onChangeFunction={(name, val) => {
              onChange(name, val.id);
            }}
            data={allPositions} // Use allPositions from Redux
          />
        </div>
        <div className="item span-2">
          <InputTextAreaField
            name="transferReason"
            label={t('transferReason')}
            id="transferReason"
            value={value.transferReason}
            onChangeFunction={onChange}
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={submit} // Use the updated submit function
              title={t('ok')}
              className="btn-primary btn-bold"
              disabled={disableButton} // Disable button while submitting
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePosition;
