import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/sysParam/list`,
      {},
      { ...data, pageSize: 10 },
    );
  },
};
