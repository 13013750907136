import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import multiPersonGroup from '../../../utils/api/AccessRule/multiPersonGroup';
import verificationModeGroup from '../../../utils/api/AccessRule/verificationModeGroup';
import { fetchVerifyModeGroup } from '../../../redux/slices/VerifyModeGroupSlices';
// import TreeViewDropdown from '../../../shared-components/tree-view/TreeViewDropdown';

interface AddPersonnelProps {
  closeModal: () => void;
  personIds: string;
}
interface AddPersonnelDevice {
  id: string;
  personnelId: string;
  firstName: string;
  lastName: string;
  department: string;
}
function AddPersonnel({ closeModal, personIds }: AddPersonnelProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const options = [
    {
      label: t('query'),
      id: 'query',
      value: 'query',
    },
    {
      label: t('department'),
      id: 'department',
      value: 'department',
    },
  ];
  const [type, setType] = useState('query');
  const [persons, setPersons] = useState<AddPersonnelDevice[]>([]); // Available persons
  const [selectedPerson, setSelectedPerson] = useState<AddPersonnelDevice[]>([]); // Selected persons
  const [deptName, setDeptName] = useState('');
  const [disable, setDisable] = useState(false);
  const tableColumns = [
    { label: t('personnelId'), id: 'personPin' },
    { label: t('firstName'), id: 'personName' },
    { label: t('lastName'), id: 'personLastName' },
    { label: t('department'), id: 'deptName' },
  ];

  const fetchData = (params: any) => {
    multiPersonGroup
      .getPersonlist(params)
      .then((res) => {
        setPersons(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  const changeSearch = () => {
    console.log('search');
  };
  // Function to handle table swapping
  const onTableSwap = (
    newAvailableData: AddPersonnelDevice[],
    newSelectedData: AddPersonnelDevice[],
  ) => {
    setPersons(newAvailableData); // Update available data
    setSelectedPerson(newSelectedData); // Update selected data
  };
  const submit = async () => {
    try {
      const req = {
        verifyModeRulePersonGroupId: personIds,
        personIds: selectedPerson.map((item: any) => item.id).join(','),
      };
      const response = await verificationModeGroup.addPerson(req);
      if (response.data.success) {
        const message = t('personAddedSuccessfully');
        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
        setDisable(false);
        closeModal();
        dispatch(fetchVerifyModeGroup({ pageNo: 1 }));
      } else {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: response.data.message,
            status: response.data.code,
          }),
        );
      }
    } catch (error: any) {
      setDisable(false);
      dispatch(setNotificationError(error.response.data));
    }
  };
  useEffect(() => {
    fetchData({
      personIds,
      type: 'noSelected',
      accVerifyModeRuleId: '-1',
    });
  }, [personIds]);
  useEffect(() => {
    if (selectedPerson?.length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedPerson]);

  return (
    <div className="access-rule-add-personnel flex-row column">
      <RadioButtonField
        label=""
        name="recurring"
        alignment="row-wrap"
        data={options}
        value={type}
        onChangeFunction={(name, value: any) => setType(value)}
      />
      {type === 'department' && (
        <div className="select-input">
          <SelectBoxField
            id="deptName"
            label={t('departmentName')}
            value={deptName}
            name="deptName"
            data={[
              { label: 'Management', id: 'Management' },
              { label: 'Tower Management', id: 'Tower Management' },
            ]}
            onChangeFunction={(name: any, value: any) => setDeptName(value)}
          />
          {/* <TreeViewDropdown
              listItems={[]}
              onSelect={() => console.log()}
              onSelectValue={() => console.log()}
              value=""
            /> */}
        </div>
      )}
      {type === 'query' && (
        <SwapTable
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={persons}
          selectedTableData={selectedPerson}
          onTableSwap={onTableSwap}
        />
      )}
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disable}
      />
    </div>
  );
}

export default AddPersonnel;
