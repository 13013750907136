import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBoxActiveIcon, CheckBoxIcon, NodataIcon } from '../../assets/svgicons/svgicon';
import { NormalTableComponentInterFace } from '../../interface/common.interface';

function BadgeComponent({ label, style }: any) {
  const customStyle = {
    ...style,
    width: 'max-content',
    borderRadius: '3px',
    padding: '3px',
  };
  return <div style={customStyle}>{label}</div>;
}

function Table({
  header,
  value = [],
  returnKey = 'id',
  checkBoxStatus = false,
  radioStatus = false,
  selectedValues = [],
  onSelectData = () => {},
  onSelect = () => {}, // Add onSelect prop to handle selected rows
  getSelectedFullData = () => {}, // get the full row data
  classes,
  hideHeader = true,
}: NormalTableComponentInterFace) {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<any[]>(selectedValues);

  const handleRowSelection = (row: any = null, selectAll: boolean = false) => {
    if (selectAll) {
      // Toggle "Select All" functionality
      if (selectedRows.length === value.length) {
        // If all are already selected, unselect all
        setSelectedRows([]);
        getSelectedFullData([]);
      } else {
        // Otherwise, select all
        const allRowIds = value.map((rows) => rows[returnKey]);
        setSelectedRows(allRowIds);
        getSelectedFullData(value);
      }
    } else if (row) {
      // Handle single row selection
      const isSelected = selectedRows.includes(row[returnKey]);
      const updatedSelection = isSelected
        ? selectedRows.filter((selectedRow) => selectedRow !== row[returnKey])
        : [...selectedRows, row[returnKey]];

      setSelectedRows(updatedSelection);
      onSelectData(updatedSelection); // Update the parent component with selected values
      getSelectedFullData(row);
      if (onSelect) {
        onSelect(updatedSelection); // Pass the selected rows to the onSelect prop
      }
    }
  };
  const handleRadioChange = (row: any) => {
    const updatedSelection = row[returnKey];
    setSelectedRows(updatedSelection);
    onSelectData(updatedSelection); // Update the parent component with selected value
    getSelectedFullData(row);
    if (onSelect) {
      onSelect(updatedSelection); // Pass the selected row to the onSelect prop
    }
  };
  // after start the api integration change the styles in reusable
  // also check how to hover show the full wrapped text
  const getRowStyles = (priority: any) => {
    let backgroundColor = 'transparent';
    let color = 'inherit';
    if (priority === 'danger') {
      backgroundColor = '#f411201a';
      color = '#f41120';
    } else if (priority === 'medium') {
      backgroundColor = '#e0ffe0';
      color = '#03d733';
    } else if (priority === 'weak') {
      backgroundColor = '#FF950033';
      color = '#FF9500';
    } else if (priority === 'strong') {
      backgroundColor = '#3D93F51A';
      color = '#3D93F5';
    }
    return { backgroundColor, color };
  };
  useEffect(() => {
    if (selectedValues) {
      setSelectedRows(selectedValues);
    }
  }, []);
  return (
    <div className={`tbl-container ${classes || ''}`}>
      <table className="table">
        <thead>
          <tr>
            {checkBoxStatus ? (
              <td style={{ width: '25px' }}>
                <div
                  className="checkbox-icon"
                  onClick={() => handleRowSelection(null, true)}
                  role="presentation">
                  {selectedRows?.length === value?.length && value?.length > 0 ? (
                    <CheckBoxActiveIcon />
                  ) : (
                    <CheckBoxIcon />
                  )}
                </div>
              </td>
            ) : (
              hideHeader && <td style={{ width: '25px' }} />
            )}
            {header.map((head) => (
              <td key={head.id} title={head.label}>
                {head.label}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {value?.length !== 0 ? (
            value?.map((dataValue) => (
              <tr key={dataValue[returnKey]}>
                {checkBoxStatus && (
                  <td>
                    <div
                      className="checkbox-icon"
                      onClick={() => handleRowSelection(dataValue)}
                      role="presentation">
                      {selectedRows.includes(dataValue[returnKey]) ? (
                        <CheckBoxActiveIcon />
                      ) : (
                        <CheckBoxIcon />
                      )}
                    </div>
                  </td>
                )}
                {radioStatus && (
                  <td>
                    <input
                      type="radio"
                      name={`optradio_${returnKey}`}
                      checked={selectedRows === dataValue[returnKey]}
                      onChange={() => handleRadioChange(dataValue)}
                    />
                  </td>
                )}
                {header?.map((head) => (
                  <td key={head.id} title={dataValue[head.id]}>
                    {head.id === 'priority' ? (
                      <BadgeComponent
                        label={dataValue[head.id]}
                        style={getRowStyles(dataValue[head.id]?.toLowerCase())}
                      />
                    ) : (
                      <span style={dataValue?.textColor && dataValue?.textColor}>
                        {dataValue[head.id]}
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <td
              colSpan={header.length + (checkBoxStatus || radioStatus ? 1 : 0)}
              className="empty-data-wrapper">
              <div className="no-data">
                <NodataIcon />
                <span>{t('nodata')}</span>
              </div>
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
