/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import timezone from '../../utils/api/AccessRule/timezone';

export const fetchTimeZone = createAsyncThunk(
  'timeZone/fetchTimeZone',
  async (data: any, { dispatch }) => {
    const apiResponse = await timezone
      .getListPage(data)
      .then((response: any) => {
        return {
          ...response.data,
          data: {
            list: response.data.data.data.map((val: any) => ({
              ...val,
              ...(val?.initFlag ? { delete: false } : {}),
              id: val?.id,
            })),
            size: response.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        return error;
      });
    return apiResponse;
  },
);

const initialState = {
  timeZone: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const timeZoneSlice: any = createSlice({
  name: 'timeZone',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    deleteTimezone: (state, action: any) => {
      state.timeZone = state.timeZone.filter((val: any) => val.code !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeZone.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchTimeZone.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.timeZone = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
        }
        state.status = 'success';
      })
      .addCase(fetchTimeZone.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

export const { setSearchFilter, deleteTimezone } = timeZoneSlice.actions;

export default timeZoneSlice.reducer;
