import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import { sortValuesByStatus } from '../../../utils/helpers';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
// import InputField from '../../../shared-components/input-field/input-field';

function UpgradeFirmware({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [file, setFile] = useState(null);
  const onChangeFile = (e: any): void => {
    setFile(e.target.file[0]);
  };
  const selectFile = () => {
    inputFile?.current?.click();
  };
  /**
   * @sortValuesByStatus - Helper to sort devices based on their online/offline/disabled status
   * @popupErrorMessages - Helper to assign a message based on device status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  return (
    <div className="device-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">
            {t('selectedDevice')} (<span className="text-warning">{t('onlyUpgradeSameType')}</span>)
          </h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: 'The devices which have disabled background verification',
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <div className="file-wrapper">
            <div className="file-upload">
              <span className="file-select">Select File</span>
              <div className="brows-btn" onClick={selectFile} role="presentation">
                Browse
              </div>
              <span className="not-upload">Not uploaded</span>
              <input
                type="file"
                name=""
                id="file"
                ref={inputFile}
                // accept="image/*,video/*, application/pdf"
                onChange={onChangeFile}
                style={{ display: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={t('hideInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={() => {}}
                  title={t('upgrading')}
                  className="btn-primary"
                  disabled={!file}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              <h4>Synchronize</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpgradeFirmware;
