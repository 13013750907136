import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/list`, {}, { ...data });
  },

  getPersonList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/getPersonList`, data);
  },

  enable: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/setEnable?ids=${data}`,
      {},
    );
  },

  disable: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/setDisable?ids=${data}`,
      {},
    );
  },

  delete: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/del?ids=${id}`, {});
  },

  delPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/delPerson`, {}, data);
  },

  getLinkTriggerOpt: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/getLinkTriggerOpt`,
      {},
      data,
    );
  },
  getInputInfo: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/getInputInfo?triggerNos=${data.triggerNos}`,
      {},
    );
  },

  selectDoorlist: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/getGlobalLinkageSelectDoor`,
      data,
    );
  },

  selectAuxOutList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/getSelectAuxOutList`,
      data,
    );
  },

  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/save`, data, {});
  },

  getSelectIasPartition: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/getSelectIasPartition`, data, {});
  },

  selectLineContactList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/selectLineContactList`, data, {});
  },

  edit: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalLinkage/edit?id=${id}`, {});
  },
};
