/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import door from '../../utils/api/door';
import { doorReduxInterface } from '../../interface/door.interface';

// Thunk for fetching the card list
export const fetchDoorList = createAsyncThunk(
  'door/fetchDoorList',
  async (data: any, { dispatch }) => {
    const response = await door
      .getDoors(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((val: any) => ({
              ...val,
              id: val.id,
            })),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

export const fetchVerifyMode = createAsyncThunk(
  'cards/fetchVerifyMode',
  async (data: any, { dispatch }) => {
    const response = await door
      .getVerifyMode(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.map((val: any) => ({
              label: val.text,
              id: val.value,
            })),
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: doorReduxInterface = {
  doors: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  verificationModes: [],
};

export const doorSlice = createSlice({
  name: 'doors',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchCardList actions
    builder
      .addCase(fetchDoorList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDoorList.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          // For pagination
          state.doors = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchDoorList.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchVerifyMode.pending, () => {
        // state.status = 'loading';
      })
      .addCase(fetchVerifyMode.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.verificationModes = action.payload.data.list || [];
        }
        // state.status = 'success';
      })
      .addCase(fetchVerifyMode.rejected, () => {
        // state.status = 'failed';
      });
  },
});

export const { setSearchFilter } = doorSlice.actions;

export default doorSlice.reducer;
