import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { PermissionInterface } from '../../../interface/permissions.interface';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import AddLevelModal from './add-level-modal';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import person from '../../../utils/api/person';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import accessByDepartment from '../../../utils/api/AccessRule/accessByDepartment';
import { saveLevelData, saveLevelId } from '../../../redux/slices/PersonSlices';

function CardSetting({ values, onChangeFunction }: PermissionInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { level, levelId } = useAppSelector((state) => state.persons);
  const [value, setValue] = useState<any>({
    ...values,
    general: values.general ?? false,
    levelSettings: levelId || [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('Add to Levels'),
    content: <>hi</>,
  });
  const [accLevels, setAccLevels] = useState<any>(level || []);
  // const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);

  const onChange = (name: string, changeValue: any) => {
    return setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  const handleAddLevel = (newLevels: any[]) => {
    const result: any = newLevels.map((item) => ({
      id: item.id,
      label: item.levelName,
      value: item.id,
    }));
    setAccLevels((prev: any) => [...prev, ...result]);
    const defaultLevel = result.map((item: any) => item.value);
    const combineId: any = [...defaultLevel, ...value.levelSettings];
    setValue((val: any) => ({
      ...val,
      levelSettings: combineId,
    }));
    dispatch(saveLevelData(result));
    dispatch(saveLevelId(combineId));
  };

  const checkboxTreeData = [
    { id: '1', label: t('Level 3'), value: 'my test', children: [] },
    { id: '2', label: t('My Testing'), value: '12 hours', children: [] },
    // Add other items as needed
  ];

  const getAccLevels = async () => {
    try {
      const response = await person.getAccLevelList({ personId: values?.person_id || values?.id });
      const combinedArray = [response.data.data.defaultLevel, ...response.data.data.tempList];
      const uniqueData = Array.from(new Set(combinedArray.map((item) => JSON.stringify(item)))).map(
        (item) => JSON.parse(item),
      );

      const result = uniqueData?.map((item) => ({
        label: item.name,
        id: item.id,
        value: item.id,
      }));
      setAccLevels(result);
      const defaultLevel = result.map((item) => item.value);
      setValue((val: any) => ({
        ...val,
        levelSettings: defaultLevel,
      }));
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  const getLevelListByDept = async () => {
    try {
      const response = await accessByDepartment.getLevelListByDept(values?.deptId);
      if (Object.keys(response.data.data).length > 0) {
        const result = response.data.data?.map((item: any) => ({
          label: item.name,
          id: item.id,
          value: item.id,
        }));
        setAccLevels(result);
        const defaultLevel = result.map((item: any) => item.value);
        setValue((val: any) => ({
          ...val,
          levelSettings: defaultLevel,
        }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    if (values?.id) {
      getAccLevels();
    } else {
      getLevelListByDept();
    }
  }, []);
  useEffect(() => onChangeFunction(value), [value]);
  const levelIds = accLevels.map((item: any) => item.id);
  return (
    <div className="permissions">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="permissions-form">
        <div className="row-form">
          <div className="item item1">
            <label htmlFor="level-settings" className="radio-label">
              {t('Level Settings')}
              <span> *</span>
            </label>
            <div className="level-settings-container">
              <div className="level-settings-header">
                {accLevels.length > 0 ? (
                  <CheckBoxInputField
                    name="levelSettings"
                    alignment="column"
                    position="left"
                    value={value.levelSettings}
                    data={accLevels}
                    onChangeFunction={(name: string, changeValue: any) =>
                      onChange(name, changeValue)
                    }
                  />
                ) : (
                  <span>Please add Level</span>
                )}
                <div className="add-btn-wrapper">
                  <button
                    className="add-button"
                    type="button"
                    onClick={() => {
                      setModalOpen(true);
                      setModalView({
                        title: t('Add to Levels'),
                        content: (
                          <AddLevelModal
                            onClose={() => setModalOpen(false)}
                            onAddLevel={handleAddLevel}
                            checkboxTreeData={checkboxTreeData} // Pass the data here
                            levelIds={levelIds}
                          />
                        ),
                      });
                    }}>
                    {t('Add +')}
                  </button>
                </div>
              </div>
              {/* <div className="level-settings-body">
                <CheckBoxInputField
                  label=""
                  name="levelSettings"
                  alignment="column"
                  position="left"
                  required
                  data={checkboxData}
                  value={value.levelSettings || []}
                  onChangeFunction={(name, changeValue) => {
                    onChange(name, changeValue);
                  }}
                />
              </div> */}
            </div>
          </div>
          <div className="item">
            <RadioButtonField
              label={t('superUser')}
              name="superUser"
              alignment="row"
              required
              data={[
                { label: t('yes'), id: 'yes', value: 'yes' },
                { label: t('no'), id: 'no', value: 'no' },
              ]}
              value={value.superUser}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item" />
          <div className="item" />
          <div className="item">
            <RadioButtonField
              label={t('deviceOperationRule')}
              name="deviceOperationRule"
              alignment="warp"
              required
              data={[
                {
                  label: t('ordinaryUser'),
                  id: 'ordinaryUser',
                  value: 'ordinaryUser',
                },
                {
                  label: t('enroller'),
                  id: 'enroller',
                  value: 'enroller',
                },
                {
                  label: t('administrator'),
                  id: 'administrator',
                  value: 'administrator',
                },
              ]}
              value={value.deviceOperationRule}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item item3">
            <CheckBoxInputField
              label=""
              name="type"
              alignment="column"
              position="right"
              required
              data={[
                {
                  label: t('extendPassage'),
                  id: 'extendPassage',
                  value: 'extendPassage',
                },
                {
                  label: t('accessDisabled'),
                  id: 'accessDisabled',
                  value: 'accessDisabled',
                },
                {
                  label: t('setValidTime'),
                  id: 'setValidTime',
                  value: 'setValidTime',
                },
              ]}
              value={value.type}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardSetting;
