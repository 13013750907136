import { useDroppable } from '@dnd-kit/core';

// DropContainer Component
function DropContainer({ children, imgProperties }: any) {
  const { setNodeRef } = useDroppable({
    id: 'dropzone',
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        backgroundSize: 'contain',
        width: imgProperties.mapWidth,
        height: imgProperties.mapHeight,
        backgroundImage: `url(${imgProperties.imageSrc})`,
        backgroundRepeat: 'no-repeat',
      }}>
      {children}
    </div>
  );
}

export default DropContainer;
