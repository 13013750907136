import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/alarmMonitorReport/listByPage`,
      {},
      { ...data },
    );
  },
  editAcknowledged: (data: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/alarmMonitorReport/editAcknowledged`,
      {},
      data,
    );
  },
  editStatus: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/alarmMonitorReport/editStatus`, {}, data);
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/alarmMonitorReport/export`, 'POST', data);
  },
};
