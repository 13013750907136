/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import zone from '../../utils/api/AdvancedFunctions/zone';

export const fetchZone = createAsyncThunk('zone/fetchZone', async (data: any, { dispatch }) => {
  const apiResponse = await zone
    .getListPage(data)
    .then((response) => {
      return {
        ...response.data,
        data: {
          list: response.data.data.data.map((val: any) => ({
            ...val,
            ...(val?.initFlag ? { delete: false } : {}),
          })),
          size: response.data.data.total,
          ...data,
        },
      };
    })
    .catch((error) => {
      dispatch(setNotificationError(error.response.data));
      return error;
    });
  return apiResponse;
});

const initialState = {
  zoneList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const zoneSlice: any = createSlice({
  name: 'zone',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    deleteZone: (state, action: any) => {
      state.zoneList = state.zoneList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZone.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchZone.fulfilled, (state: any, action: any) => {
        if (action.payload.success) {
          state.totalDataCount = action.payload.data.size;
          state.zoneList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchZone.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

export const { setSearchFilter, deleteZone } = zoneSlice.actions;

export default zoneSlice.reducer;
