import {
  RemoteOpeningIcon,
  RemoteClosingIcon,
  CancelAlarmIcon,
  RemoteNormallyOpenIcon,
  ExportAccessLevelIcon,
  ImportAccessLevelIcon,
  ActivatesLockdownIcon,
  DeactivatesLockdownIcon,
  EnablesIndradayIcon,
  DisablesIndradayIcon,
} from '../../../assets/svgicons/svgicon';
import { OptionType } from '../../../interface/common.interface';

const controlOptions: OptionType[] = [
  {
    value: 'remoteOpening',
    label: 'remoteOpening',
    icon: RemoteOpeningIcon,
  },
  {
    value: 'remoteClosing',
    label: 'remoteClosing',
    icon: RemoteClosingIcon,
  },
  {
    value: 'cancelAlarm',
    label: 'cancelAlarm',
    icon: CancelAlarmIcon,
  },
  {
    value: 'remoteNormallyOpen',
    label: 'remoteNormallyOpen',
    icon: RemoteNormallyOpenIcon,
  },
  {
    value: 'activateLockdown',
    label: 'activateLockdown',
    icon: ActivatesLockdownIcon,
  },
  {
    value: 'deActivateLockdown',
    label: 'deActivateLockdown',
    icon: DeactivatesLockdownIcon,
  },
  {
    value: 'enableIntradayPassageMode',
    label: 'enableIntradayPassageMode',
    icon: EnablesIndradayIcon,
  },
  {
    value: 'disableIntradayPassageMode',
    label: 'disableIntradayPassageMode',
    icon: DisablesIndradayIcon,
  },
];

const exportOptions: OptionType[] = [
  {
    value: 'exportAccessLevel',
    label: 'exportAccessLevel',
    icon: ExportAccessLevelIcon,
  },
  {
    value: 'exportDoorsOfAccessLevel',
    label: 'exportDoorsOfAccessLevel',
    icon: ExportAccessLevelIcon,
  },
];

const importOptions: OptionType[] = [
  {
    value: 'importAccessLevel',
    label: 'importAccessLevel',
    icon: ImportAccessLevelIcon,
  },
  {
    value: 'importDoorsOfAccessLevel',
    label: 'importDoorsOfAccessLevel',
    icon: ImportAccessLevelIcon,
  },
];

export { controlOptions, exportOptions, importOptions };
