import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { AddIcon } from '../../../assets/svgicons/svgicon';
import {
  fetchDeptList,
  fetchMusterPoint,
  setDeptSearchFilter,
  setSearchFilter,
  deleteDept as deleteDepartment,
} from '../../../redux/slices/MusterPointSlices';
import AddMusterPoint from './components/addMusterPoint';
import AddDepartment from './components/AddDepartment';
import musterPoint from '../../../utils/api/AdvancedFunctions/musterPoint';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';

interface LevelProps {
  pageNo: number;
  accMusterPointId?: string;
}
interface DeleteDeptProps {
  accMusterPointId: string;
  id: string;
}
function MusterPoint() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    pointList,
    status,
    totalDataCount,
    currentPageNo,
    searchFilter,
    selectId,
    departments,
    deptStatus,
    deptTotalDataCount,
    deptCurrentPageNo,
    deptSearchFilter,
  } = useAppSelector((state: any) => state.musterPoint);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  let handleEditOpen: any;

  const fetchEditData = async (ids: string) => {
    try {
      const response = await musterPoint.edit({ id: ids });
      return response.data.data;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      return null;
    }
  };

  const columnsField = [
    {
      accessorKey: 'name',
      header: t('name'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'signPoint',
      header: t('signPoint'),
      Cell: ({ row }: any) => {
        const [details, setDetails] = useState([]);

        useEffect(() => {
          const fetchData = async () => {
            const response = await fetchEditData(row.original.id);
            const result = response?.signPointList
              ?.map((item: any) => item.signPointName)
              .join(', ');
            setDetails(result);
          };

          fetchData();
        }, [row.original.id]);

        return <span>{details}</span>;
      },
    },
    {
      accessorKey: 'activated',
      header: t('activate'),
      Cell: ({ row }: any) => {
        let result;
        if (row?.original?.activated) {
          result = <span className="text-success">Activated</span>;
        } else {
          result = <span className="text-danger">Deactivated</span>;
        }
        return result;
      },
    },
  ];
  const deptColumn = [
    {
      accessorKey: 'code',
      header: t('departmentNumber'),
    },
    {
      accessorKey: 'deptName',
      header: t('departmentName'),
    },
    {
      accessorKey: 'parentDeptName',
      header: t('parentDepartment'),
    },
  ];

  handleEditOpen = (val: any): void => {
    const update = {
      ...val,
      statisticsVisitors: val?.statisticsVisitors ? ['0'] : [],
      messageNotification: val?.messageNotification ? ['0'] : [],
      sendReport: val?.sendReport ? ['0'] : [],
    };
    setModalView({
      title: t('edit'),
      content: <AddMusterPoint closeModal={() => setModalOpen(false)} update={update} />,
    });
    setModalOpen(true);
  };

  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchMusterPoint({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (deptCurrentPageNo > 0) {
      dispatch(setDeptSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.accMusterPointId) {
      dispatch(fetchDeptList({ ...params }));
    }
  };

  const fetchNextData = (type: string): any => {
    if (type === 'rule') {
      fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
    } else {
      fetchLevels({ pageNo: deptCurrentPageNo + 1, ...deptSearchFilter.filter });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ accMusterPointId: id?.[0], pageNo: 1 });
  };

  const getFilterLevels = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchLevels({ pageNo: 1, ...filter.filter });
    }
  };

  const deleteDept = ({ accMusterPointId, id }: DeleteDeptProps) => {
    const req = {
      musterPointId: accMusterPointId,
      musterPointDeptIds: id,
    };
    musterPoint
      .deleteDept(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('departmentDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteDepartment(id));
          setModalOpen(false);
          fetchData({ pageNo: 1 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const handleActivate = async (type: string) => {
    const req = {
      ids: selectedValues.toString(),
    };
    try {
      const response =
        type === 'activate' ? await musterPoint.activate(req) : await musterPoint.cancel(req);
      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        setModalOpen(false);
        fetchData({ pageNo: 1 });
      } else {
        dispatch(
          setNotificationError({
            error: response.data.message,
            status: response.data.code,
          }),
        );
        setModalOpen(false);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
      setModalOpen(false);
    }
  };

  const moreActionButton = [
    {
      title: t('activate'),
      clickFunction: (): void => {
        if (selectedValues.length > 0) {
          setModalView({
            title: t('activate'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => handleActivate('activate')}
                title={t('performTheActivateOperation')}
                buttonLabel={t('ok')}
                cancelTrue
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
    {
      title: t('cancel'),
      clickFunction: (): void => {
        if (selectedValues.length > 0) {
          setModalView({
            title: t('cancel'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => handleActivate('cancel')}
                title={t('performTheCancelOperation')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
          setModalOpen(true);
        }
      },
    },
  ];

  // const onConfirmReset = (zoneId: string) => {
  //   const req = {
  //     personLimitZoneId: zoneId,
  //   };
  //   personAvailability
  //     .resetPersonLimitZone(req)
  //     .then((response) => {
  //       if (response.data.success) {
  //         dispatch(
  //           setNotificationError({
  //             error: response.data.msg,
  //             status: 200,
  //           }),
  //         );
  //         setModalOpen(false);
  //         fetchPersonAvailability({
  //           pageNo: 1,
  //         });
  //       } else {
  //         dispatch(
  //           setNotificationError({
  //             error: response.data.message,
  //             status: response.data.code,
  //           }),
  //         );
  //         setModalOpen(false);
  //       }
  //     })
  //     .catch((error) => {
  //       dispatch(
  //         setNotificationError({
  //           error: error.message,
  //           status: error.status,
  //         }),
  //       );
  //       setModalOpen(false);
  //     });
  // };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('addDepartment'),
      function: (val: any): void => {
        console.log(val);
        setModalView({
          title: t('addDepartment'),
          content: <AddDepartment closeModal={() => setModalOpen(false)} id={val.id} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const deptActions = [
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteDept}
            />
          ),
        });
      },
    },
  ];

  useEffect(() => {
    if (pointList.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  useEffect(() => {
    if (selectId) {
      fetchLevels({ accMusterPointId: selectId, pageNo: 1 });
      setSelectedValues([selectId]);
    }
  }, [pointList]);

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('musterPoint'),
          },
        ]}
      />
      <CardBox
        header={t('musterPoint')}
        title={t('listOfPoint')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonLabel={t('new')}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddMusterPoint closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => getSelected(val)}
          checkBoxActive
          singleRowSelection
          fetchNextPage={() => fetchNextData('rule')}
          isFetching={status === 'loading'} // Placeholder status
          values={pointList} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>{t('department')}</h4>
            <span className="sub-title">{t('listOfDepartment')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={deptActions}
            columns={deptColumn}
            getFilterData={getFilterData}
            assignFilterValue={deptSearchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            checkBoxActive
            fetchNextPage={() => fetchNextData('person')}
            isFetching={deptStatus === 'loading'} // Placeholder status
            values={departments}
            fixedColumn={['id']}
            totalRecords={deptTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default MusterPoint;
