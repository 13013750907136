import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../../shared-components/swapTables/swapTable';
import Button from '../../../../shared-components/button/button';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import readerDefine from '../../../../utils/api/AdvancedFunctions/readerDefine';

interface ModalTypeProps {
  closeModal: () => void;
  getSelected: any;
  selectedReader: any;
}

function AddSelected({ closeModal, getSelected, selectedReader }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [readerList, setReaderList] = useState<any[]>([]); // Available readerList
  const [selectedReaderList, setSelectedReaderList] = useState<any[]>([]); // Selected readerList
  const tableColumns = [
    { label: t('readerName'), id: 'name' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const changeSearch = (name: string, val: any) => {
    setSearch(val);
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: any[], newSelectedData: any[]) => {
    // Update readerList to only contain available (unselected) items
    setReaderList(
      newAvailableData.filter(
        (item) => !newSelectedData.some((selected) => selected.id === item.id),
      ),
    );
    setSelectedReaderList(newSelectedData);
  };

  const fetchList = (params: any) => {
    readerDefine
      .accSelectReader(params)
      .then((res) => {
        // Set initial reader list excluding any already selected items
        setReaderList(res.data.data.data.filter((item: any) => !selectedReader.includes(item.id)));
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  const submit = () => {
    getSelected(selectedReaderList);
    closeModal();
  };

  useEffect(() => {
    fetchList({
      type: 'noSelected',
    });
  }, []);

  return (
    <div className="access-rule-add-personnel flex-row column">
      <SwapTable
        searchValue={search}
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={readerList}
        selectedTableData={selectedReaderList}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedReaderList?.length === 0}
        />
      </div>
    </div>
  );
}

export default AddSelected;
