import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared-components/button/button';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import AddSelected from './Add-Selected';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { fetchArea, fetchReaderDefineList } from '../../../../redux/slices/ReaderDefineSlices';
import readerDefine from '../../../../utils/api/AdvancedFunctions/readerDefine';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

interface MultiCombinationProps {
  closeModal: () => void;
}

interface ReaderProps {
  equals: boolean;
  doorName: string;
  doorId: string;
  id: string;
  deviceSn: string;
  name: string;
  deviceAlias: string;
}

function AddBatch({ closeModal }: MultiCombinationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { areaList } = useAppSelector((state: any) => state.readerDefine);

  const [levelDetails, setLevelDetails] = useState({
    accReaderIds: [],
    accZoneFromId: '',
    accZoneToId: '',
  });
  const [accLevels, setAccLevels] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const getSelected = (data: ReaderProps[]) => {
    const readerData = data.map((item) => ({
      label: item.name,
      id: item.id,
      value: item.id,
    }));

    setLevelDetails((prev: any) => ({
      ...prev,
      accReaderIds: readerData?.map((item) => item.id),
    }));
    setAccLevels(readerData);
  };

  const addDoor = () => {
    setModalOpen(true); // Open door selection modal
    setModalView({
      title: t('selectReader'),
      content: (
        <AddSelected
          closeModal={() => setModalOpen(false)}
          getSelected={getSelected}
          selectedReader={accLevels.map((level) => level.id)}
        />
      ),
    });
  };

  const submit = async () => {
    setDisabled(true);
    const request = {
      accReaderIds: levelDetails?.accReaderIds?.join(','),
      accZoneFromId: levelDetails?.accZoneFromId,
      accZoneToId: levelDetails?.accZoneToId,
    };
    try {
      const response1 = await readerDefine.addReader(request);
      if (response1.data.success) {
        const message = t('readerDefineAddedSuccessfully');

        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
        setDisabled(false);
        closeModal();
        dispatch(fetchReaderDefineList({ pageNo: 1, pageSize: 10 }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchArea());
  }, []);

  useEffect(() => {
    if (
      levelDetails.accReaderIds.length !== 0 &&
      levelDetails.accZoneToId &&
      levelDetails.accZoneFromId
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [levelDetails]);

  return (
    <div className="add-level-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="forms-row">
        <SelectBoxField
          id="accZoneFromId"
          name="accZoneFromId"
          label="Goes From"
          value={levelDetails.accZoneFromId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={areaList}
          required
        />
        <SelectBoxField
          id="accZoneToId"
          name="accZoneToId"
          label="Goes To"
          value={levelDetails.accZoneToId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={areaList}
          required
        />
      </div>
      <div className="add-header">
        <p>Select reader</p>
        <Button onClickFunction={addDoor} title={t('Add +')} className="add-button" />
      </div>
      <div className="level-settings-container">
        <div className="level-settings-header">
          {accLevels.length > 0 ? (
            <CheckBoxInputField
              name="accReaderIds"
              alignment="column"
              position="left"
              value={levelDetails?.accReaderIds}
              data={accLevels}
              onChangeFunction={(name: string, changeValue: any) => {
                onChangeValue(name, changeValue);
              }}
            />
          ) : (
            <span>Please add Level</span>
          )}
        </div>
      </div>

      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

export default AddBatch;
