import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../shared-components/button/button';
import { ModalTypeProps, StateProps } from '../../../../interface/occupancyControl.interface';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import occupancyControl from '../../../../utils/api/AdvancedFunctions/occupancyControl';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import readerDefine from '../../../../utils/api/AdvancedFunctions/readerDefine';
import ConformationBox from '../../../../shared-components/conformation-box/conformation-box';
import { fetchOccupancyControl } from '../../../../redux/slices/OccupancyControlSlices';

const initialValues = {
  name: '',
  zoneId: '',
  min: '',
  max: '',
};
function AddOccupancy({ closeModal, update }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.occupancyControl);

  const [values, setValues] = useState<StateProps>(update || initialValues);
  const [disable, setDisable] = useState(false);
  const [zoneList, setZoneList] = useState<any[]>([]);
  const [checkExistName, setCheckExistName] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const fetchData = () => {
    occupancyControl
      .getZoneList()
      .then((res) => {
        const initialOption = {
          label: '-------',
          id: '',
        };
        const result = [
          initialOption,
          ...(res?.data?.data?.map((item: any) => ({
            label: item.text,
            id: item.value,
          })) || []),
        ];
        setZoneList(result);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const onChangeValue = async (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'name' && value !== '') {
      const responseData = await occupancyControl.isExistName({ name: value });
      setCheckExistName(responseData?.data);
    }
  };

  const save = (type: string) => {
    setDisable(true);
    occupancyControl
      .save(values)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setDisable(false);
          dispatch(fetchOccupancyControl({ pageNo: 1, pageSize: 10, ...searchFilter.filter }));
          if (type && !update) {
            setValues(initialValues);
            setModalOpen(false);
          } else {
            closeModal();
          }
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code || 500,
            }),
          );
          setModalOpen(false);
          setDisable(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.response.data.msg,
            status: error.response.status,
          }),
        );
        setDisable(false);
      });
  };

  const submit = async (type: string = '') => {
    try {
      const req = {
        opType: 'accOccupancy',
        zoneId: values?.zoneId,
      };
      const response1 = await readerDefine.checkDevIssueVerify(req);
      /**
       * Confirmation box should be check if the device is
       * @checkDevIssueVerify is valid or not
       */
      if (response1) {
        setModalView({
          title: t('reset'),
          content: (
            <ConformationBox
              closeModal={() => save(type)}
              okFunction={() => setModalOpen(false)}
              title={t('checkDevIssueVerify')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    if (values?.name && values?.zoneId && checkExistName) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values, checkExistName]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="add-occupancy">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="form-row">
        <div className="flex-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={values.name}
            onChangeFunction={onChangeValue}
            errorStatus={values.name !== '' && !checkExistName}
            message={t('objectIsAlreadyExist')}
            required
          />
          <SelectBoxField
            id="zoneId"
            name="zoneId"
            label={t('zone')}
            value={values.zoneId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={zoneList}
            required
            disabled={update !== undefined}
          />
        </div>
        <div className="flex-row">
          <InputField
            id="min"
            name="min"
            label={t('minimumCapacity')}
            type="text"
            value={values.min}
            onChangeFunction={onChangeValue}
          />
          <InputField
            id="max"
            name="max"
            label={t('maximumCapacity')}
            type="text"
            value={values.max}
            onChangeFunction={onChangeValue}
          />
        </div>
      </div>

      <div className="form-buttons-right">
        {!update && (
          <Button
            onClickFunction={() => {
              submit('saveAndNew');
            }}
            title={t('save&new')}
            className="btn-primary"
            disabled={disable}
          />
        )}
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddOccupancy;
