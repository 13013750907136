import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared-components/button/button';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import RadioButtonField from '../../../../shared-components/radio-button-field/radio-button-field';
import alarmLog from '../../../../utils/api/AccControlReports/alarmLog';
// import { fetchAlarmLogList } from '../../../../redux/slices/AlarmLogReportSlices';

interface ModalType {
  closeModal: () => void;
  selectedId: string[];
}
function Acknowledge({ closeModal, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState({
    status: '1',
    description: '',
  });
  const [disable, setDisable] = useState(false);

  const submit = () => {
    setDisable(true);
    const request = {
      id: selectedId?.[0],
      status: value?.status,
    };
    alarmLog
      .editStatus(request)
      .then((response) => {
        if (response.data.success) {
          setDisable(false);
          closeModal();
          // dispatch(fetchAlarmLogList({ pageNo: 1 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
          setDisable(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        setDisable(false);
      });
  };

  useEffect(() => {
    const request = {
      ids: selectedId?.[0],
    };
    alarmLog
      .editAcknowledged(request)
      .then((res) => {
        console.log(res.data.data);
        setValue((prev) => ({ ...prev, description: res.data.data.description }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status || 500,
          }),
        );
      });
  }, []);

  return (
    <div className="acknowledge-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('eventDetails')}</h3>
          <div className="box">
            <span className="text-danger">{value?.description}</span>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <RadioButtonField
                label=""
                name="status"
                alignment="row"
                data={[
                  {
                    label: 'Processing',
                    id: '1',
                    value: '1',
                  },
                  {
                    label: 'Confirmed',
                    id: '2',
                    value: '2',
                  },
                ]}
                value={value.status}
                onChangeFunction={(name: string, val: any) =>
                  setValue((prev) => ({ ...prev, status: val }))
                }
              />
            </div>
          </div>
          <h3 className="title">{t('Processing Records')}</h3>
          <div className="box" />
          <div className="form-buttons-right">
            <Button
              onClickFunction={() => {
                closeModal();
              }}
              title={t('close')}
              className="btn-default"
            />
            <Button
              onClickFunction={submit}
              title={t('done')}
              className="btn-primary"
              disabled={disable}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acknowledge;
