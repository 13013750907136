import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import zone from '../../../../utils/api/AdvancedFunctions/zone';
import { fetchZone } from '../../../../redux/slices/ZoneSlices';

interface AddZoneProps {
  code: string;
  name: string;
  remark?: string;
}
interface ModalTypeProps {
  closeModal: () => void;
  update?: AddZoneProps;
}
function AddZone({ closeModal, update }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addZone, setAddZone] = useState<AddZoneProps>(
    update || {
      code: '',
      name: '',
      remark: '',
    },
  );
  const [disabled, setDisabled] = useState(false);
  const [checkExistCode, setCheckExistCode] = useState(true);
  const [checkExistName, setCheckExistName] = useState(true);
  const onChangeValue = (name: string, value: any) => {
    setAddZone((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onBlurCode = async (name: string, value: any) => {
    if (name === 'code' && value !== '') {
      const responseData = await zone.isExistCode(value);
      setCheckExistCode(responseData?.data);
    }
  };
  const onBlurName = async (name: string, value: any) => {
    if (name === 'name' && value !== '') {
      const responseData = await zone.isExistName(value);
      setCheckExistName(responseData?.data);
    }
  };
  const submit = async () => {
    setDisabled(true);
    try {
      const response1 = await zone.addZone(addZone);
      if (response1.data.success) {
        dispatch(
          setNotificationError({
            error: response1.data.msg,
            status: 200,
          }),
        );
        setDisabled(false);
        closeModal();
        dispatch(fetchZone({ pageNo: 1, pageSize: 10 }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
      setDisabled(false);
    }
  };
  useEffect(() => {
    if (addZone.name && addZone.code && checkExistCode && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [addZone, checkExistCode, checkExistName]);
  return (
    <div className="add-zone flex-row column">
      <div className="grid-row">
        <InputField
          id="code"
          name="code"
          label={t('zoneCode')}
          type="text"
          value={addZone.code}
          onChangeFunction={onChangeValue}
          onBlurFunction={onBlurCode}
          errorStatus={addZone.code !== '' && !checkExistCode}
          message={t('objectIsAlreadyExist')}
          required
        />
        <InputField
          id="name"
          name="name"
          label={t('name')}
          type="text"
          value={addZone.name}
          onChangeFunction={onChangeValue}
          onBlurFunction={onBlurName}
          errorStatus={addZone.name !== '' && !checkExistName}
          message={t('nameIsAlreadyExist')}
          required
        />
        <InputField
          id="remark"
          name="remark"
          label={t('remark')}
          type="text"
          value={addZone.remark}
          onChangeFunction={onChangeValue}
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddZone.defaultProps = {
  update: {},
  // remark: '',
};

export default AddZone;
