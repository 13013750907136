/* eslint-disable no-plusplus */
import React, { useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import Webcam from 'react-webcam';
import Button from '../../../shared-components/button/button';
import person from '../../../utils/api/person';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../redux/store';

interface CameraValue {
  personIdPhoto: string;
  personPhoto: File | null;
}

function OpenCameraModal({
  closeModal,
  submitFunction,
}: {
  closeModal: () => void;
  submitFunction: (val: any) => void;
}) {
  const width = 500;
  const height = 500;

  const { webcamRef, detected, facesDetected }: any = useFaceDetection({
    faceDetectionOptions: {
      model: 'short',
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),
    camera: ({ mediaSrc, onFrame }: CameraOptions) =>
      new Camera(mediaSrc, {
        onFrame,
        width,
        height,
      }),
  });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<CameraValue>({ personIdPhoto: '', personPhoto: null });
  const base64ToFile = (base64: string, filename: string) => {
    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const binaryFile = base64ToFile(imageSrc, 'webcam_capture.jpg');
    setValue({ personIdPhoto: imageSrc, personPhoto: binaryFile });
  };
  const submit = () => {
    const formData: any = new FormData();
    formData.append('personPhoto', value.personPhoto);
    formData.append(
      'personIdPhoto',
      value.personIdPhoto?.replace(/^data:image\/[a-z]+;base64,/, ''),
    );
    person
      .validPersonPhoto(formData)
      .then((res: any) => {
        if (res.data.success) {
          closeModal();
          submitFunction(value);
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.status || 500,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          setNotificationError({
            error: err.message,
            status: err.status,
          }),
        );
      });
  };

  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="row">
          <Webcam
            audio={false}
            forceScreenshotSourceSize
            width={300}
            height={300}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
          />
          {!detected && <div className="text-danger">{t('noFaceDetected')}</div>}
        </div>
        <div className="row">
          {value.personIdPhoto && <img src={value.personIdPhoto} alt="take_a_picture" />}
        </div>
        <div className="item row-2">
          <div className="form-buttons-right">
            <Button
              onClickFunction={closeModal}
              title={t('close')}
              className="btn-default"
              disabled={!value.personIdPhoto}
            />
            <Button
              onClickFunction={capture}
              title={t('takeAImage')}
              disabled={!detected || facesDetected > 1}
              className="btn-primary btn-bold"
            />
            <Button onClickFunction={submit} title={t('submit')} className="btn-primary btn-bold" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenCameraModal;
