import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/transactionByPersonnel/list`,
      {},
      { ...data },
    );
  },
  export: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Acc/api/transactionByPersonnel/export`,
      'POST',
      data,
    );
  },
  getDoorList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/transactionByPersonnel/doorList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
};
