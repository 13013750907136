import React from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use assets svg icons
import { CloseSmallIcon } from '../../assets/svgicons/svgicon';

function MultipleImageUpload({
  title,
  required,
  onClick,
  name,
  value,
}: {
  title: string;
  name: string;
  required: boolean;
  value: any[];
  onClick: (val: string, val1: any) => void;
}) {
  const onChangeFunction = (e: any) => {
    onClick(e.target.name, [...Object.values(e.target.files), ...value]);
  };

  const deleteImage = (index: number) => {
    value.splice(index, 1);
    onClick(name, value);
  };
  const { t } = useTranslation();

  return (
    <div className="multiple-file-upload">
      <div className="file-upload-section">
        <div className="file-label">
          {title} {required && <span>*</span>}
        </div>
        <div className="file-upload-box">
          <div className="files-list">
            {value.map((val, index) => (
              <div className="file-items">
                <span className="file-name">{val.name}</span>
                <span className="icon" onClick={() => deleteImage(index)} role="presentation">
                  <CloseSmallIcon color="#f00" />
                </span>
              </div>
            ))}
          </div>
          <label htmlFor="file" className="file-label">
            {t('chooseTheFile')}
            <input type="file" name={name} onChange={onChangeFunction} id="file" multiple />
          </label>
        </div>
      </div>
    </div>
  );
}

export default MultipleImageUpload;
