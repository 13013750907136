import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../shared-components/sidebar/sidebar';
import Header from '../../shared-components/header/header';

function Layout() {
  return (
    <div className="layout">
      <div className="admin-panel">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="right-container">
          <div className="header">
            <Header />
          </div>
          <div className="container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
