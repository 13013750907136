import axios, { defaultParams } from './axios';

export default async (url: any, body: any, params: any = {}, file = false) => {
  try {
    const header: any = { ...defaultParams() };
    if (file) {
      header.headers = { ...header.headers, 'content-type': 'multipart/form-data' };
    }

    return await axios.post(url, body, { ...header, params });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
