import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SearchIcon, { RightArrowIcon } from '../../../assets/svgicons/svgicon';
import Table from '../../../shared-components/table/table';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import {
  SelectedDevice,
  DeviceListInterface,
} from '../../../interface/accessByDepartment.interface';
import antiPassback from '../../../utils/api/AccessRule/antiPassback';

function AddAntiPassbackSelectedDevice({
  // changeSearch,
  tableColumns,
  getSelected,
  closeModal = () => {},
}: SelectedDevice) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [availableData, setAvailableData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  // State to manage selected items in both tables
  const [selectRow, setSelectRow] = useState<any>(null); // Track selected items in "available" table
  const [selectedTableRow, setSelectedTableRow] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const fetchDeviceList = (params: DeviceListInterface) => {
    antiPassback
      .selectDevicelist(params)
      .then((res) => {
        setAvailableData(res?.data?.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  // Handle moving a single item from available to selected
  const handleSelectTable = () => {
    if (selectRow) {
      const newSelected = availableData.find((item: any) => item.id === selectRow);
      const remainingData = availableData.filter((item: any) => item.id !== selectRow);

      if (newSelected) {
        setAvailableData([...remainingData, ...selectedData]);
        setSelectedData([newSelected]);
        setSelectRow(null); // Clear selection
      }
    }
  };

  // Handle moving a single item from selected back to available
  const handleSelectSelectedTable = () => {
    if (selectedTableRow) {
      const unselected = selectedData.find((item: any) => item.id === selectedTableRow);
      const remainingSelected = selectedData.filter((item: any) => item.id !== selectedTableRow);

      if (unselected) {
        setAvailableData([...availableData, unselected]);
        setSelectedData(remainingSelected);
        setSelectedTableRow(null); // Clear selection
      }
    }
  };
  const submit = () => {
    if (selectedData.length > 0) {
      getSelected?.(selectedData);
      closeModal();
    }
  };
  const changeSearch = (name: string, value: string) => {
    setSearchValue(value);
    fetchDeviceList({
      search: value,
      type: 'noSelected',
    });
  };
  useEffect(() => {
    fetchDeviceList({
      type: 'noSelected',
    });
  }, []);
  return (
    <div className="flex-row column" style={{ width: '66vw' }}>
      <div className="swap-table">
        <div className="search-input">
          <InputField
            name="name"
            label={t('search')}
            id="name"
            type="text"
            value={searchValue}
            innerIconStatus
            InnerIcon={SearchIcon}
            onChangeFunction={changeSearch}
          />
        </div>
        <div className="table-container">
          <div className="table-wrapper">
            <h4 className="title">{t('alternative')}</h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={availableData}
              radioStatus
              selectedValues={selectRow ? [selectRow] : []}
              onSelect={(selected: any[]) => setSelectRow(selected)}
            />
          </div>
          <div className="icons-wrapper">
            <div
              className={`icon ${!selectRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectTable}>
              <RightArrowIcon />
            </div>
            <div
              className={`icon backward ${!selectedTableRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectSelectedTable}>
              <RightArrowIcon />
            </div>
          </div>
          <div className="table-wrapper">
            <h4 className="title">
              {t('selected')} ({selectedData.length})
            </h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={selectedData}
              radioStatus
              selectedValues={selectedTableRow ? [selectedTableRow] : []} // Use an array with a single selected row
              onSelect={(selected) => setSelectedTableRow(selected[0])} // Update single selection
            />
          </div>
        </div>
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={selectedData.length === 0}
      />
    </div>
  );
}

export default AddAntiPassbackSelectedDevice;
