import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { CommunicationInterface } from '../../../interface/accessDevice.interface';
import { useAppDispatch } from '../../../redux/store';
import device from '../../../utils/api/device';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function ModifyCommunicationPassword({ closeModal, selectedId }: CommunicationInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errorMsg, setErrorMsg] = useState<string | number>('');
  const [validPwd, setValidPwd] = useState(true);
  const [disable, setDisable] = useState(true);

  const onChangeValue = async (name: string, value: string) => {
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'oldPassword' && value) {
      const request = {
        devId: selectedId?.[0],
        oldCommPwd: value,
      };
      const responseData = await device.checkCommOldPassword(request);
      setValidPwd(responseData?.data?.data);
    }
  };

  const submit = async () => {
    try {
      const request = {
        devId: selectedId?.[0],
        newCommPwd: passwords?.confirmPassword,
      };
      const response = await device.updateCommPassword(request);
      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        closeModal();
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.ret,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
    }
  };

  const getModifyCommInfo = () => {
    const request = {
      id: selectedId,
      type: 'updateCommPwd',
    };
    device
      .getDevCommPwdInfo(request)
      .then((res) => {
        if (res.data.success) {
          setErrorMsg(res.data.data.failedReason);
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    if (selectedId) {
      getModifyCommInfo();
    }
  }, [selectedId]);

  useEffect(() => {
    if (!errorMsg) {
      if (passwords.oldPassword && validPwd) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(false);
    }
  }, [passwords, validPwd]);

  return (
    <div className="modify-communication-modal">
      {errorMsg ? (
        <span>{errorMsg === '2' ? 'Device is disabled try again later!' : errorMsg}</span>
      ) : (
        <div className="form-row">
          <InputField
            id="oldPassword"
            name="oldPassword"
            label={t('oldPassword')}
            type="password"
            value={passwords.oldPassword}
            onChangeFunction={onChangeValue}
            // onBlurFunction={onBlur}
            errorStatus={!validPwd}
            message="Old Password is not Correct"
            required
          />
          <InputField
            id="newPassword"
            name="newPassword"
            label={t('newPassword')}
            type="password"
            value={passwords.newPassword}
            onChangeFunction={onChangeValue}
            aria-label="New Password"
          />
          <InputField
            id="confirmPassword"
            name="confirmPassword"
            label={t('confirmPassword')}
            type="password"
            value={passwords.confirmPassword}
            onChangeFunction={onChangeValue}
            errorStatus={
              passwords.confirmPassword !== '' &&
              passwords.newPassword !== passwords.confirmPassword
            }
            message="Password does not match"
            aria-label="Confirm Password"
          />
        </div>
      )}

      <div className="modal-footer">
        {!errorMsg && (
          <Button
            onClickFunction={closeModal}
            title={t('Cancel')}
            aria-label={t('Cancel')}
            className="btn-default"
          />
        )}
        <Button
          onClickFunction={() => {
            if (errorMsg) {
              closeModal();
            } else {
              submit();
            }
          }}
          title={t('ok')}
          aria-label={t('OK')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default ModifyCommunicationPassword;
