import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import TabPages from '../../shared-components/tab-pages/tab-pages';
import Button from '../../shared-components/button/button';
import TransactionsTypes from './components/TransactionsTypes';
import AutoExport from './components/AutoExport';
import RealTimeMonitoring from './components/RealTimeMonitoring';
import CommonMonitoringTab from './components/CommonMonitoringTab';
import parameters from '../../utils/api/AccessRule/parameters';
import { useAppDispatch } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

interface ParametersProps {
  down_event_mode: string;
  regular_time: string | number;
  down_event_hours: number[];
  autoExport: string;
  exportMode: string;
  exportByMonth: string;
  monthExportMode: string;
  maxHeight: string;
  rtmMail: string;
  rtmPhoneNumber: string;
  recipientsMail?: string;
  specificDate?: string;
  hour?: string;
  minute?: string;
}
function Parameters() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [parametersValue, setParameters] = useState<ParametersProps>({
    down_event_mode: 'mode2',
    regular_time: 1,
    down_event_hours: [0],
    autoExport: 'none',
    exportMode: '0',
    exportByMonth: '0',
    monthExportMode: '0',
    maxHeight: '140',
    rtmMail: '',
    rtmPhoneNumber: '',
    recipientsMail: '',
  });
  const onChangeValue = (data: any) => {
    setParameters((prev: any) => ({
      ...prev,
      ...data,
    }));
  };
  const tabs = [
    {
      key: 'typeOfGettingTransactions',
      title: t('typeOfGettingTransactions'),
      content: <TransactionsTypes value={parametersValue} onChangeValue={onChangeValue} />,
    },
    {
      key: 'transactionsAutoExport',
      title: t('transactionsAutoExport'),
      content: <AutoExport value={parametersValue} onChangeValue={onChangeValue} />,
    },
    {
      key: 'realTimeMonitoring',
      title: t('realTimeMonitoring'),
      content: <RealTimeMonitoring value={parametersValue} onChangeValue={onChangeValue} />,
    },
    {
      key: 'alarmMonitoringRecipientsMailbox',
      title: t('alarmMonitoringRecipientsMailbox'),
      content: (
        <CommonMonitoringTab value={parametersValue} onChangeValue={onChangeValue} type="email" />
      ),
    },
    {
      key: 'alarmMonitoringRecipientsMobileNumber',
      title: t('alarmMonitoringRecipientsMobileNumber'),
      content: (
        <CommonMonitoringTab
          value={parametersValue}
          onChangeValue={onChangeValue}
          type="phoneNumber"
        />
      ),
    },
  ];

  const submit = async () => {
    const {
      autoExport,
      maxHeight,
      rtmMail,
      rtmPhoneNumber,
      down_event_hours: downEvent,
      exportMode,
      monthExportMode,
      recipientsMail,
      down_event_mode: downEventMode,
      regular_time: regularTime,
      specificDate,
      hour,
      minute,
    } = parametersValue;
    const request: any = {
      down_event_mode: downEventMode,
      'acc.autoExportFrequency': autoExport,
      'acc.personPhotoMaxHeight': maxHeight,
      mail: rtmMail,
      smsReceiver: rtmPhoneNumber,
      // down_event_hours: downEvent,
      'acc.exportMode': exportMode,
      'acc.monthFrequency': monthExportMode,
      'acc.autoExportEmail': recipientsMail,
      'acc.monthFrequencyDate': specificDate,
      'acc.dayFrequencyHour': hour,
      'acc.dayFrequencyMinute': minute,
    };

    if (downEventMode === 'mode1') {
      request.regular_time = regularTime;
    } else if (downEventMode === 'mode2') {
      request.down_event_hours = downEvent.toString();
    }
    try {
      const response: any = await parameters.saveParam(request);
      if (response) {
        dispatch(
          setNotificationError({
            error: 'Operation Succeed!',
            status: 200,
          }),
        );
      } else {
        dispatch(
          setNotificationError({
            error: response.data.message,
            status: response.data.code,
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status || 500,
        }),
      );
    }
  };
  return (
    <div className="acc-rule-parameters">
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('parameters'),
          },
        ]}
      />
      <CardBox title={t('parameters')}>
        <TabPages tabs={tabs} defaultNumber={0} />
        <div className="form-buttons-right">
          <Button onClickFunction={submit} title={t('done')} className="btn-primary" />
        </div>
      </CardBox>
    </div>
  );
}

export default Parameters;
