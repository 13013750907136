import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import { PermissionInterface } from '../../../interface/permissions.interface';
import CheckBoxTreeField from '../../../shared-components/checkbox-tree-field/checkbox-tree-field';

function FaceKiosk({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();
  const selectData = [
    {
      label: t('value1'),
      id: 'value1',
    },
    {
      label: t('value2'),
      id: 'value2',
    },
  ];

  const [value, setValue] = useState(values);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <div className="permissions-form">
        <div className="row-form">
          <div className="item item1">
            <CheckBoxTreeField
              label={t('faceKioskArea')}
              name="faceKioskArea"
              alignment="column"
              position="left"
              required
              data={[
                {
                  label: t('areaName'),
                  key: 'areaName',
                  children: [
                    {
                      label: t('extendPassage'),
                      id: 'extendPassage',
                      value: 'extendPassage',
                    },
                    {
                      label: t('accessDisabled'),
                      id: 'accessDisabled',
                      value: 'accessDisabled',
                    },
                    {
                      label: t('setValidTime'),
                      id: 'setValidTime',
                      value: 'setValidTime',
                    },
                  ],
                },
                {
                  label: t('hallWayArea'),
                  key: 'hallWayArea',
                  children: [
                    {
                      label: t('extendPassage'),
                      id: 'extendPassage',
                      value: 'extendPassage',
                    },
                    {
                      label: t('accessDisabled'),
                      id: 'accessDisabled',
                      value: 'accessDisabled',
                    },
                    {
                      label: t('setValidTime'),
                      id: 'setValidTime',
                      value: 'setValidTime',
                    },
                  ],
                },
              ]}
              value={value.faceKioskArea}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="deviceOperationRole"
              label={t('deviceOperationRole')}
              id="deviceOperationRole"
              value={value.deviceOperationRole}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="personnelType"
              label={t('personnelType')}
              id="personnelType"
              value={value.personnelType}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaceKiosk;
