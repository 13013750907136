import React, { useState, useEffect } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputField from '../../../shared-components/input-field/input-field';
import { Search } from '../../../assets/svgicons/svgicon';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import RealTimeCommonPopup from './realTime-common-popup';
import ListDropdown from '../../../shared-components/list-dropdown/list-dropdown';
import { OptionType } from '../../../interface/common.interface';
import CardStatus from '../../../shared-components/card-status/cardStatus';
import LatestEventsPopup from './latest-events-popup';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';

interface DoorData {
  status: string;
  alarm: string;
  relay: string;
  doorSensor: string;
  number: string;
  serialNumber: string;
  title: string;
  id: number;
}

interface DoorStatesProps {
  devSn: string;
  no: string;
  image: string;
  relay: string;
  opDisplay: string;
  lockDisplay: string;
  areaId: string;
  devAlias: string;
  name: string;
  alarm: string;
  sensor: string;
  id: string;
  audio: string;
  connect: number;
}

function Door() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socketUrl = 'https://acs-api-dev.aictec.com/Bio/v1/Acc/websocket';
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [search, setSearch] = useState('');
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [selectedDatas, setSelectedDatas] = useState<number[]>([]);
  const [doorData, setDoorData] = useState<DoorData[]>([]);
  const allDoorIds = doorData.map((door) => door.id);
  const options: OptionType[] = [
    {
      value: 'remoteNormallyOpen',
      label: 'remoteNormallyOpen',
    },
    {
      value: 'enableIntraday',
      label: 'enableIntradayPassageModeTimeZone',
    },
    {
      value: 'disableIntraday',
      label: 'disableIntradayPassageModeTimeZone',
    },
  ];
  const changeDropdown = (value: string = '') => {
    if (value === 'remoteNormallyOpen') {
      setModalView({
        title: t('remoteNormallyOpen'),
        content: (
          <RealTimeCommonPopup
            allDoorIds={allDoorIds}
            selectedDatas={selectedDatas}
            modelName="remoteNormallyOpen"
            selected={selectedCards}
            closeModal={() => setModalOpen(false)}
            message={t('areYouWantToPerformTheRemoteNormallyOpenOperation')}
          />
        ),
      });
      setModalOpen(true);
    } else if (value === 'enableIntraday') {
      setModalView({
        title: t('enableIntradayPassageModeTimeZone'),
        content: (
          <RealTimeCommonPopup
            allDoorIds={allDoorIds}
            selectedDatas={selectedDatas}
            modelName="enableIntraday"
            selected={selectedCards}
            closeModal={() => setModalOpen(false)}
            message={t('areYouWantToPerformTheEnableIntradayPassageModeTimeZoneOpenOperation')}
          />
        ),
      });
      setModalOpen(true);
    } else if (value === 'disableIntraday') {
      setModalView({
        title: t('disableIntradayPassageModeTimeZone'),
        content: (
          <RealTimeCommonPopup
            allDoorIds={allDoorIds}
            selectedDatas={selectedDatas}
            modelName="disableIntraday"
            selected={selectedCards}
            closeModal={() => setModalOpen(false)}
            message={t('areYouWantToPerformTheDisableIntradayPassageModeTimeZoneOpenOperation')}
          />
        ),
      });
      setModalOpen(true);
    }
  };
  const changeOption = (value: any) => {
    if (selectedCards.length > 0) {
      changeDropdown(value);
    } else {
      setModalView({
        title: t('selectObject'),
        content: (
          <ConformationBox
            closeModal={() => setModalOpen(false)}
            okFunction={() => setModalOpen(false)}
            title={t('pleaseSelectTheObjectsYouWantToOperate')}
            buttonLabel={t('ok')}
          />
        ),
      });
      setModalOpen(true);
    }
  };
  const moreActionButton: any = [
    {
      title: t('remoteOpening'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('remoteOpening'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                selectedDatas={selectedDatas}
                modelName="remoteOpening"
                selected={selectedCards}
                closeModal={() => setModalOpen(false)}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('remoteClosing'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('remoteClosing'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                selectedDatas={selectedDatas}
                modelName="remoteClosing"
                selected={selectedCards}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheRemoteClosingOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('cancelAlarm'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('cancelAlarm'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                selectedDatas={selectedDatas}
                modelName="cancelAlarm"
                selected={selectedCards}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheCancelAlarmOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('activateLockDown'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('activateLockDown'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                selectedDatas={selectedDatas}
                modelName="activateLockDown"
                selected={selectedCards}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheActivateLockdownOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('deActivateLockDown'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('deActivateLockDown'),
            content: (
              <RealTimeCommonPopup
                allDoorIds={allDoorIds}
                selectedDatas={selectedDatas}
                modelName="deActivateLockDown"
                selected={selectedCards}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformTheDeActivateLockdownOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },
    {
      title: t('personnelIn/OutBoard'),
      clickFunction: (): void => {
        if (selectedCards.length > 0) {
          setModalView({
            title: t('personnelIn/OutBoard'),
            content: (
              <RealTimeCommonPopup
                modelName="personnelIn/OutBoard"
                selected={selectedCards}
                closeModal={() => setModalOpen(false)}
                message={t('areYouWantToPerformThepersonnelIn/OutBoardOperation')}
              />
            ),
          });
        } else {
          setModalView({
            title: t('selectObject'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                okFunction={() => setModalOpen(false)}
                title={t('pleaseSelectTheObjectsYouWantToOperate')}
                buttonLabel={t('ok')}
              />
            ),
          });
        }
        setModalOpen(true);
      },
    },

    {
      dropdown: (
        <ListDropdown data={options} label={t('enable&disable')} changeFunction={changeOption} />
      ),
      clickFunction: changeDropdown,
    },
  ];

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('serialNumber'),
      key: 'devSn',
    },
    {
      label: t('number'),
      key: 'no',
    },
    {
      label: t('door'),
      key: 'sensor',
    },
    {
      label: t('relay'),
      key: 'relay',
    },
    {
      label: t('alarm'),
      key: 'alarm',
    },
  ];

  const statusData = [
    {
      title: t('online'),
      count: doorData.filter((item) => item.status === 'Online')?.length || 0,
      color: 'success',
    },
    {
      title: t('offline'),
      count: doorData.filter((item) => item.status === 'Offline')?.length || 0,
      color: 'danger',
    },
    {
      title: t('disable'),
      count: doorData.filter((item) => item.status === 'Disable')?.length || 0,
      color: 'warning',
    },
    {
      title: t('unknown'),
      count: doorData.filter((item) => item.status === 'Unknown')?.length || 0,
      color: 'secondary',
    },
    {
      title: t('currentTotal'),
      count: doorData?.length || 0,
    },
  ];

  const handleCardSelect = (paramsId: any) => {
    setSelectedDatas(paramsId);
    setSelectedCards(
      (prevSelected: any) =>
        prevSelected.includes(paramsId.id)
          ? prevSelected.filter((id: any) => id !== paramsId.id) // Remove if already selected
          : [...prevSelected, paramsId.id], // Add to selected list if not selected
    );
  };
  const clickLatestEvents = (item: any) => {
    setModalView({
      title: t('queryTheLatestEventsFromTheDoor'),
      content: <LatestEventsPopup data={item.id} />,
    });
    setModalOpen(true);
  };

  const getDevState = (data: any[]) => {
    // Initialize SockJS and STOMP client
    const socket = new SockJS(socketUrl);
    const client = new Client({
      webSocketFactory: () => socket,
      onConnect: () => {
        console.log('Connected to WebSocket');
        const areaIds = [...new Set(data.map((item) => item.areaId))];
        client.publish({
          destination: '/app/accRTMonitor/getDevState',
          body: JSON.stringify({ areaIds: areaIds.join(',') }),
        });
        // Subscribe to the WebSocket topic
        client.subscribe('/topic/accRTMonitor/getDevState', (message) => {
          const response = JSON.parse(message.body);
          const mappedData = data.map((door) => {
            const matchingDevice = response.doorStates.find(
              (item: DoorStatesProps) => item.id === door.id,
            );
            let sensorData = matchingDevice?.sensor || door?.sensor;
            if (matchingDevice?.sensor === 'acc_rtMonitor_noSensor') {
              sensorData = 'No Door Sensor';
            } else if (matchingDevice?.sensor === 'acc_rtMonitor_unknown') {
              sensorData = 'Unknown';
            }
            let relayData = door?.relay;
            if (matchingDevice?.relay === 'common_close') {
              relayData = 'Close';
            } else if (matchingDevice?.relay === 'acc_rtMonitor_unknown') {
              relayData = 'Unknown';
            }
            return {
              ...door,
              sensor: sensorData,
              title: door.name,
              image: matchingDevice?.image || null,
              relay: relayData,
              opDisplay: matchingDevice?.opDisplay || null,
              alarm: matchingDevice?.alarm === 'common_none' ? 'None' : door?.alarm,
              status: matchingDevice?.connect === 1 ? 'Online' : 'Offline',
            };
          });

          // Update the state with the mapped data
          setDoorData(mappedData);
        });
      },
      onStompError: (error: any) => {
        setNotificationError({
          error: error.message,
          status: error.status || 500,
        });
      },
    });

    client.activate();

    // Cleanup on component unmount
    return () => {
      if (client) {
        client.deactivate();
      }
    };
  };

  const getDoorData = () => {
    realtimeMonitoring
      .getAllDoor()
      .then((response) => {
        getDevState(response.data.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    getDoorData();
  }, []);

  return (
    <div className="rtm-door">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="search-row">
        <div className="search-input">
          <InputField
            name="search"
            label={t('search')}
            id="name"
            type="text"
            value={search}
            innerIconStatus
            InnerIcon={Search}
            onChangeFunction={(name: string, value: any) => setSearch(value)}
          />
        </div>
        <CardRightButtonGroup
          actions={moreActionButton}
          buttonIcon={false}
          buttonLabel=""
          buttonClass=""
          buttonStatus={false}
          buttonFunction={() => {}}
        />
      </div>
      <div className="card-grid">
        {doorData.map((item, index) => (
          <CardStatus
            key={item.title}
            data={item}
            header={header}
            index={index}
            click={() => clickLatestEvents(item)}
            footerLabel={t('queryTheLatestEventsFromTheDoor')}
            onSelect={() => handleCardSelect(item)}
            isSelected={selectedCards.includes(item.id)}
          />
        ))}
      </div>
      <div className="status-container">
        {statusData.map((status: any) => (
          <div key={status.title} className="status-wrap">
            {status?.color && <div className={`circle-dot ${status?.color}`} />}
            <span style={{ color: '#696C6F' }}>
              {status?.title} : {status?.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Door;
