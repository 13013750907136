import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import Button from '../../../shared-components/button/button';
import FormRightButton from '../../../shared-components/form-right-buttons/FormRightButton';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import AddMultiSelected from './Add-MultiSelected';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Label from '../../../shared-components/label/Label';
import multiPersonOpeningDoorApi from '../../../utils/api/AccessRule/multiPersonOpeningDoor';
import { fetchMultiPersonOpeningDoor } from '../../../redux/slices/MultiPersonOpeningDoorSlices';

interface MultiCombinationProps {
  closeModal: () => void;
  data?: AddMultiPersonProps;
  searchFilter?: {
    pageIndex: 1;
    pageSize: 10;
  };
}
interface Device {
  id: string;
  serialNumber: string;
  doorName?: string;
  ownedDevice: string;
}
interface DropdownItem {
  label: string;
  id: string | number;
}
interface AddMultiPersonProps {
  id?: any;
  combinationName: string;
  deviceId: string;
  deviceName: string;
  noOfDoors: string;
}
function AddMultiCombination({ closeModal, data, searchFilter }: MultiCombinationProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [devices, setDevices] = useState<Device[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [levelDetails, setLevelDetails] = useState<AddMultiPersonProps>({
    combinationName: data?.combinationName || '',
    deviceId: data?.deviceId || '',
    deviceName: data?.deviceName || '',
    noOfDoors: data?.deviceName || '',
  });

  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Fetch dropdown data from API
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await multiPersonOpeningDoorApi.getCombOpenList();
        const formattedData: DropdownItem[] = response.data.data.map(
          (item: { text: string; value: string | number }) => ({
            label: item.text,
            id: item.value,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await multiPersonOpeningDoorApi.getCombOpenList();
        console.log('API Response:', response);
        const formattedData: DropdownItem[] = response.data.data.map(
          (item: { text: string; value: string | number }) => ({
            label: item.text,
            id: item.value,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
  }, []);

  const [groupValues, setGroupValues] = useState(
    Array(5)
      .fill(null)
      .map((_, index) => ({
        id: `group-${index + 1}`,
        firstSelect: '',
        secondSelect: '',
      })),
  );

  const optionsData = [
    { label: '1', id: '1' },
    { label: '2', id: '2' },
    { label: '3', id: '3' },
  ];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('accesspersonopening'),
    content: <>hi</>,
  });
  const changeSearch = () => {
    console.log('search');
  };
  const onTableSwap = (newAvailableData: Device[], newSelectedData: Device[]) => {
    setDevices(newAvailableData); // Update available data
    setSelectedDevices(newSelectedData); // Update selected data
  };
  const tableColumns = [
    { label: t('doorName'), id: 'doorName' },
    { label: t('ownedDevice'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const getProtocols = (id: string) => {
    multiPersonOpeningDoorApi
      .validBackgroundVerify(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('multiPersonSelected'),
              status: 200,
            }),
          );
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const getSelectedDevice = (selected: any[]) => {
    setLevelDetails((prev: any) => ({
      ...prev,
      deviceId: selected[0].id,
      deviceName: selected[0].deviceAlias,
    }));
    getProtocols(selected[0].id);
  };
  const addDoor = () => {
    setModalView({
      title: t('selectDoor'),
      content: (
        <AddMultiSelected
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          tableData={devices}
          selectedTableData={selectedDevices}
          onTableSwap={onTableSwap}
          getSelected={getSelectedDevice}
          closeModal={() => setModalOpen(false)}
          // getSelected={(selected: Device[]) => setSelectedDevices(selected)}
        />
      ),
    });
    setModalOpen(true);
  };
  const handleFirstSelectChange = (groupId: string, valueId: string) => {
    setGroupValues((prev) =>
      prev.map((group) =>
        group.id === groupId
          ? { ...group, firstSelect: valueId, secondSelect: '' } // Reset secondSelect when firstSelect changes
          : group,
      ),
    );
  };

  const handleSecondSelectChange = (groupId: string, valueId: string) => {
    setGroupValues((prev) =>
      prev.map((group) => (group.id === groupId ? { ...group, secondSelect: valueId } : group)),
    );
  };
  const submit = () => {
    const request = {
      combinationName: levelDetails.combinationName,
      deviceId: levelDetails.deviceId,
      deviceName: levelDetails.deviceName,
    };
    multiPersonOpeningDoorApi
      .save(request)
      .then((response) => {
        if (response?.data) {
          const message = data?.id
            ? t('multiPersonGroupUpdatedSuccessfully')
            : t('multiPersonGroupAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          dispatch(
            fetchMultiPersonOpeningDoor({
              pageNo: searchFilter?.pageIndex || 1,
              pageSize: searchFilter?.pageSize || 10,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  return (
    <div className="add-level-modal">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="forms-row">
        <InputField
          id="combinationName"
          name="combinationName"
          label={t('combinationName')}
          type="text"
          value={levelDetails.combinationName}
          onChangeFunction={(name, value) => onChangeValue(name, value)}
          required
        />
      </div>

      <div className="select-device-wrapper">
        {!levelDetails?.deviceId && (
          <Button onClickFunction={addDoor} title={t('selectDevice')} className="btn-default" />
        )}
        {levelDetails?.deviceId && (
          <div className="device-name" onClick={addDoor} role="presentation">
            <h4 className="label">{levelDetails?.deviceName}</h4>
          </div>
        )}
      </div>
      <div className="group-select-boxes">
        {groupValues.map((group) => (
          <div key={group.id} className="group">
            <Label title={`Group ${group.id.split('-')[1]}`} />
            <SelectBoxField
              id={`firstSelect${group.id}`}
              name={`firstSelect${group.id}`}
              label=""
              value={group.firstSelect}
              onChangeFunction={(name, value) =>
                handleFirstSelectChange(group.id, String(value.id))
              }
              data={dropdownData}
            />
            <SelectBoxField
              id={`secondSelect${group.id}`}
              name={`secondSelect${group.id}`}
              value={group.secondSelect}
              onChangeFunction={(name, value) =>
                handleSecondSelectChange(group.id, String(value.id))
              }
              data={optionsData}
              disabled={!group.firstSelect} // Enable secondSelect only if firstSelect has a value
              readOnly
            />
          </div>
        ))}
      </div>

      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={!levelDetails.combinationName}
      />
    </div>
  );
}
AddMultiCombination.defaultProps = {
  data: {
    combinationName: '',
    deviceId: '',
    deviceName: '',
    noOfDoors: '',
  },
  searchFilter: {
    pageIndex: '',
    pageSize: '',
  },
};
export default AddMultiCombination;
