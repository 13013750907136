import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

function RegistrationDevice({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const [registrationDevice, setRegistrationDevice] = useState('');
  console.log(selectedValues); // temp
  const submit = () => {
    console.log('hi');
  };
  return (
    <div className="device-setup-popup">
      <div style={{ width: '56%', padding: '20px 0 30px 0' }}>
        <SelectBoxField
          name="registrationDevice"
          label={t('Set as Registration Device')}
          id="registrationDevice"
          value={registrationDevice}
          onChangeFunction={(name: string, value: any) => setRegistrationDevice(value?.id)}
          data={[
            { label: t('yes'), id: 'yes' },
            { label: t('no'), id: 'no' },
          ]}
          className=""
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary" />
      </div>
    </div>
  );
}

export default RegistrationDevice;
