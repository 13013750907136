import React, { useState } from 'react';

// Use interface
import { InputTextAreaFieldInterFace } from '../../interface/common.interface';

function InputTextAreaField({
  name,
  label,
  id,
  rows = 5,
  value,
  className,
  disabled,
  required,
  innerIconStatus,
  InnerIcon,
  message,
  errorStatus,
  placeHolder,
  innerIconFunction,
  onChangeFunction,
}: InputTextAreaFieldInterFace) {
  const [visiblePlaceHolder, setVisiblePlaceHolder] = useState(false);
  const onKeyup = (e: any): void => {
    onChangeFunction(e.target.name, e.target.value);
  };
  return (
    <div className="form-item">
      <textarea
        id={id}
        className={`input_field ${className}`}
        value={value}
        autoComplete="off"
        required
        name={name}
        onChange={onKeyup}
        onClick={() => setVisiblePlaceHolder(true)}
        onBlur={() => setVisiblePlaceHolder(false)}
        disabled={disabled}
        rows={rows}
        cols={50}
        placeholder={visiblePlaceHolder ? placeHolder : ''}
      />
      <label className={`input-label ${className}`} htmlFor={id}>
        {label}
        {required && <span>*</span>}
      </label>
      {innerIconStatus && (
        <div className="input-right-icon" onClick={innerIconFunction} role="presentation">
          <InnerIcon />
        </div>
      )}
      {errorStatus && <small className="input-error-message">{message}</small>}
    </div>
  );
}

export default InputTextAreaField;
