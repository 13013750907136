import React from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shard components
import Button from '../button/button';

// Use interface
import { DeleteModalBoxInterFace } from '../../interface/common.interface';

function DeleteModalBox({
  closeModal,
  id,
  okFunction,
  disabled = false,
  loading,
}: DeleteModalBoxInterFace) {
  const { t } = useTranslation();
  return (
    <div className="delete-box">
      <div className="message-text">
        <p>{t('areYouSureYouWantToPerformTheDeleteOperation?')}</p>
      </div>
      <div className="confirm-buttons">
        <Button onClickFunction={closeModal} title={t('Cancel')} className="btn-default" />
        <Button
          onClickFunction={() => okFunction(id)}
          title={t('ok')}
          className={`btn-primary ${loading ? 'loading' : ''} `}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default DeleteModalBox;
