import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import { ClearDataIcon } from '../../../assets/svgicons/svgicon';
import Button from '../../../shared-components/button/button';
import auth from '../../../utils/api/auth';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../redux/store';
import device from '../../../utils/api/device';
import InputIpAddressField from '../../../shared-components/input-ip-address-field/input-ip-address-field';
import { ExtendedAddDeviceProps } from '../../../interface/device.interface';

interface ModalTypes {
  closeModal: () => void;
  data: ExtendedAddDeviceProps;
}

function SearchAddDevice({ closeModal, data }: ModalTypes) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addDevice, setAddDevice] = useState({
    ...data,
    addToLevel: '',
    area: '',
    iconType: 1,
    clearData: ['0'],
  });
  const [areaList, setAreaList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setAddDevice((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApiResponse = (response: any, successMessage: string) => {
    if (response.data.success) {
      dispatch(
        setNotificationError({
          error: t(successMessage),
          status: 200,
        }),
      );
      setIsLoading(false);
      closeModal();
    } else {
      dispatch(
        setNotificationError({
          error: response.data.msg,
          status: response.data.ret,
        }),
      );
      setIsLoading(false);
    }
  };

  const checkModifyIpAddress = () => {
    const request = {
      alias: addDevice.IP,
      webServerIP: addDevice?.serverIpAddress,
      webServerPort: addDevice?.serverPort,
      newDNS: addDevice?.DNS,
      iconType: addDevice?.iconType,
      iconType_new_value: 'false',
      authAreaId: addDevice.area,
      authAreaId_new_value: 'false',
      levelId_new_value: 'false',
      door4ToDoor2: '0',
      clearAllData: addDevice.clearData?.[0],
      sn: addDevice?.SN,
      devName: addDevice?.IP,
      mac: addDevice?.MAC,
      // if server application is working on https then only set this issupportssl or else send  supportsll as false
      // isSupportSSL: addDevice?.IsSupportSSL
      isSupportSSL: 'false',
      ipAddress: addDevice?.IP,
      type: 'webServerIP',
    };
    device
      .modifyIpAddress(request)
      .then((response) => handleApiResponse(response, 'authorizedSuccessful'))
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const checkGrantAuthority = () => {
    const request = {
      alias: addDevice.IP,
      iconType: addDevice?.iconType,
      iconType_new_value: 'false',
      authAreaId: addDevice.area,
      authAreaId_new_value: 'false',
      levelId: addDevice.addToLevel,
      levelId_new_value: 'false',
      door4ToDoor2: '0',
      clearAllData: addDevice.clearData?.[0],
      sn: addDevice?.SN,
      devName: addDevice?.IP,
    };
    device
      .grantAuthority(request)
      .then((response) => handleApiResponse(response, 'deviceAddSuccess'))
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const submit = () => {
    setIsLoading(true);
    const req = {
      machineType: addDevice.MachineType,
      sn: addDevice.SN,
    };
    device
      .validPushDevCount(req)
      .then((res) => {
        if (res.data.success) {
          if (addDevice.ModeType === '2') {
            checkGrantAuthority();
          } else if (addDevice.ModeType === '1') {
            checkModifyIpAddress();
          }
        } else {
          setIsLoading(false);
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.list.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        setAreaList(authArea);
        setAddDevice((prev: any) => ({
          ...prev,
          area: authArea?.[0]?.id,
        }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const getAccLevelList = () => {
    device
      .getLevelList({ pageNo: 1 })
      .then((res) => {
        const levels = res.data.data.list.map((lev: any) => ({
          label: lev.name,
          id: lev.id,
        }));
        setLevelList(levels);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    getAuthAreaList();
    getAccLevelList();
  }, []);

  return (
    <div className="search-add-device">
      <div className="add-device-form">
        <div className="single-row">
          <InputField
            name="IP"
            label={t('deviceName')}
            id="IP"
            type="text"
            value={addDevice.IP}
            required
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
          {addDevice?.ModeType !== '2' && (
            <>
              <InputIpAddressField
                id="serverIpAddress"
                name="serverIpAddress"
                label={t('newServerAddress')}
                type="text"
                value={addDevice?.serverIpAddress}
                onChangeFunction={onChangeValue}
                aria-label="IP Address"
              />
              <InputField
                name="serverPort"
                label={t('New server Port')}
                id="serverPort"
                type="text"
                value={addDevice.serverPort}
                required
                innerIconStatus={false}
                onChangeFunction={onChangeValue}
              />
            </>
          )}
          <SelectBoxField
            name="iconType"
            label={t('iconType')}
            id="iconType"
            value={addDevice.iconType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('door'), id: 1 },
              { label: t('parkingBarrier'), id: 2 },
              { label: t('flapBarrier'), id: 3 },
            ]}
            className=""
            required
          />
          <SelectBoxField
            name="area"
            label={t('area')}
            id="area"
            value={addDevice?.area}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={areaList}
            className=""
            required
          />
          <SelectBoxField
            name="addToLevel"
            label={t('addToLevel')}
            id="addToLevel"
            value={addDevice.addToLevel}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={levelList}
            className=""
          />
          <CheckBoxInputField
            name="clearData"
            data={[
              {
                label: t('clearDataLabel'),
                id: '1',
                value: '1',
              },
            ]}
            value={addDevice.clearData}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="error-label">
          <div>
            <ClearDataIcon />
          </div>
          <span>{t('clearDataLabelDescription')}</span>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={submit}
            title={isLoading ? t('loading') : t('Done')}
            className={`btn-primary ${isLoading ? 'loading' : ''} `}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchAddDevice;
