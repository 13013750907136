import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import SearchIcon, { RightArrowIcon } from '../../../../assets/svgicons/svgicon';
import Table from '../../../../shared-components/table/table';
import Button from '../../../../shared-components/button/button';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import personAvailability from '../../../../utils/api/AdvancedFunctions/personAvailability';

interface SelectPersonProps {
  closeModal: () => void;
  getSelected: (selected: any[]) => void;
}

function SelectPerson({ getSelected, closeModal = () => {} }: SelectPersonProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tableColumns = [
    { label: t('personnelId'), id: 'persPin' },
    { label: t('firstName'), id: 'persName' },
    { label: t('lastName'), id: 'persLastName' },
    { label: t('departmentName'), id: 'deptName' },
  ];
  const [searchValue, setSearchValue] = useState('');
  const [availableData, setAvailableData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  // State to manage selected items in both tables
  const [selectRow, setSelectRow] = useState<string | null>(null); // Track selected items in "available" table
  const [selectedTableRow, setSelectedTableRow] = useState<string | null>(null);

  const changeSearch = (value: string) => {
    setSearchValue(value);
  };

  // Handle moving a single item from available to selected
  const handleSelectTable = () => {
    if (selectRow) {
      const newSelected = availableData.find((item: any) => item.id === selectRow);
      const remainingData = availableData.filter((item: any) => item.id !== selectRow);
      if (newSelected) {
        setAvailableData([...remainingData, ...selectedData]);
        setSelectedData([newSelected]);
        setSelectRow(null); // Clear selection
      }
    }
  };

  // Handle moving a single item from selected back to available
  const handleSelectSelectedTable = () => {
    if (selectedTableRow) {
      const unselected = selectedData.find((item: any) => item.id === selectedTableRow);
      const remainingSelected = selectedData.filter((item: any) => item.id !== selectedTableRow);

      if (unselected) {
        setAvailableData([...availableData, unselected]);
        setSelectedData(remainingSelected);
        setSelectedTableRow(null);
      }
    }
  };
  const submit = () => {
    if (selectedData.length > 0) {
      const result = selectedData.find((item) => item);
      getSelected?.(result);
      closeModal();
    }
  };

  const fetchData = (params: any) => {
    personAvailability
      .selectPersonList(params)
      .then((res) => {
        setAvailableData(res?.data?.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    fetchData({
      pageNo: 1,
      pageSize: 50,
      type: 'noSelected',
    });
  }, []);

  return (
    <div className="flex-row column" style={{ width: '60vw' }}>
      <div className="swap-table">
        <div className="search-input">
          <InputField
            name="name"
            label={t('search')}
            id="name"
            type="text"
            value={searchValue}
            innerIconStatus
            InnerIcon={SearchIcon}
            onChangeFunction={(name: string, val: any) => changeSearch(val)}
          />
        </div>
        <div className="table-container">
          <div className="table-wrapper">
            <h4 className="title">{t('alternative')}</h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={availableData}
              radioStatus
              selectedValues={selectRow ? [selectRow] : []}
              onSelect={(selected: any) => setSelectRow(selected)}
            />
          </div>
          <div className="icons-wrapper">
            <div
              className={`icon ${!selectRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectTable}>
              <RightArrowIcon />
            </div>
            <div
              className={`icon backward ${!selectedTableRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectSelectedTable}>
              <RightArrowIcon />
            </div>
          </div>
          <div className="table-wrapper">
            <h4 className="title">
              {t('selected')} ({selectedData.length})
            </h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={selectedData}
              radioStatus
              selectedValues={selectedTableRow ? [selectedTableRow] : []} // Use an array with a single selected row
              onSelect={(selected: any) => setSelectedTableRow(selected)} // Update single selection
            />
          </div>
        </div>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedData?.length === 0}
        />
      </div>
    </div>
  );
}

export default SelectPerson;
