import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';

// Use Interface
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import wiegantFormat from '../../../utils/api/wiegantFormat';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { addWiegandFormat } from '../../../redux/slices/WiegantSlice';
// import { AddWiegandInterFace } from '../../../interface/wiegandFormat.interface';
type ParityErrorStates = {
  parityError: boolean;
  cardError: boolean;
};
function AddWiegandFormat({ closeModal }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [buttonDisable, setButtonDisable] = useState(false);

  const [wiegandFormatValue, setWiegandFormatValue] = useState({
    name: '',
    siteCode: '0',
    wiegandCount: '',
    autoMode: [],
    wiegandMode: 1,
    firstParityCheck: '',
    secondParityCheck: '',
    cardCheckFormat: '',
    parityCheckFormat: '',
    oddParityStartBit: '',
    oddParityMaxLength: '',
    eventParityStartBit: '',
    eventParityMaxLength: '',
    CIDStartBit: '',
    CIDMaxLength: '',
    siteCodeStartBit: '',
    siteCodeMaxLength: '',
    manufacturerCodeStartBit: '',
    manufacturerCodeMaxLength: '',
  });
  const [checkExistName, setCheckExistName] = useState(false);
  const [parityTotalBit, setParityTotalBit] = useState<string[]>([]);
  const [cardBit, setCardBit] = useState<string[]>([]);
  const [checkParityError, setCheckParityError] = useState<ParityErrorStates>({
    parityError: false,
    cardError: false,
  });
  const [isRequired, setIsRequired] = useState('');

  const onChangeTotalBit = (name: string, value: any) => {
    const arr: any = new Array(Number(value)).fill('x'); // Create an array with length equal to value, then fill it with 'x'
    setParityTotalBit(arr); // Update state with the array
    setCardBit(arr);
    setWiegandFormatValue((prev: any) => ({
      ...prev,
      wiegandCount: value,
    }));
  };

  const onChangeValue = async (name: string, value: any) => {
    // Update wiegandFormatValue
    setWiegandFormatValue((prev: any) => {
      const updatedWiegandFormat = {
        ...prev,
        [name]: value,
      };
      // Create a copy of the parityTotalBit array
      const copyParityBit = [...parityTotalBit];
      const copyCardBit = [...cardBit];
      let errorOccurred = false; // Track if an error occurs
      let cardError = false;

      // Function to safely set parity without resizing
      const setParity = (start: number, length: number, parityType: 'o' | 'e') => {
        if (start + length > copyParityBit.length) {
          errorOccurred = true; // Error: Trying to assign out of bounds
          return;
        }
        for (let i = start; i < start + length; i += 1) {
          if (parityType === 'o' && copyParityBit[i] === 'e') {
            errorOccurred = true; // Overlap with even parity
            break;
          } else if (parityType === 'e' && copyParityBit[i] === 'o') {
            errorOccurred = true; // Overlap with odd parity
            break;
          }
          copyParityBit[i] = parityType; // Set the parity ('o' for odd, 'e' for even)
        }
      };
      const setCardParity = (start: number, length: number, parityType: 's' | 'c' | 'm') => {
        if (start + length > copyCardBit.length) {
          cardError = true; // Error: Trying to assign out of bounds
          return;
        }
        for (let i = start; i < start + length; i += 1) {
          if (parityType === 's' && copyCardBit[i] === 'c' && copyCardBit[i] === 'm') {
            cardError = true; // Overlap with site parity
            break;
          } else if (parityType === 'c' && copyCardBit[i] === 's' && copyCardBit[i] === 'm') {
            cardError = true; // Overlap with cid
            break;
          } else if (parityType === 'm' && copyCardBit[i] === 'c' && copyCardBit[i] === 's') {
            cardError = true; // Overlap with manufacture code
            break;
          }
          copyCardBit[i] = parityType;
        }
      };
      // Handle oddParityStartBit and oddParityMaxLength
      if (updatedWiegandFormat.oddParityStartBit) {
        const oddStart = Number(updatedWiegandFormat.oddParityStartBit) - 1; // Adjust for 0-based index
        const oddLength = Number(updatedWiegandFormat.oddParityMaxLength || 0);
        setParity(oddStart, oddLength, 'o');
      }
      // Handle eventParityStartBit and eventParityMaxLength
      if (updatedWiegandFormat.eventParityStartBit) {
        const evenStart = Number(updatedWiegandFormat.eventParityStartBit) - 1; // Adjust for 0-based index
        const evenLength = Number(updatedWiegandFormat.eventParityMaxLength || 0);
        setParity(evenStart, evenLength, 'e');
      }
      if (updatedWiegandFormat.siteCodeStartBit) {
        const start = Number(updatedWiegandFormat.siteCodeStartBit) - 1; // Adjust for 0-based index
        const max = Number(updatedWiegandFormat.siteCodeMaxLength || 0);
        setCardParity(start, max, 's');
      }
      if (updatedWiegandFormat.CIDStartBit) {
        const start = Number(updatedWiegandFormat.CIDStartBit) - 1; // Adjust for 0-based index
        const max = Number(updatedWiegandFormat.CIDMaxLength || 0);
        setCardParity(start, max, 'c');
      }
      if (updatedWiegandFormat.manufacturerCodeStartBit) {
        const start = Number(updatedWiegandFormat.manufacturerCodeStartBit) - 1; // Adjust for 0-based index
        const max = Number(updatedWiegandFormat.manufacturerCodeMaxLength || 0);
        setCardParity(start, max, 'm');
      }

      // Update parityTotalBit and error state
      setParityTotalBit(copyParityBit);
      setCardBit(copyCardBit);
      setCheckParityError({
        parityError: errorOccurred,
        cardError,
      }); // Set error state based on the error check
      return updatedWiegandFormat;
    });
  };
  const onBlur = async (name: string, value: any) => {
    if (name === 'name') {
      const responseData = await wiegantFormat.checkNameIsExist(value);
      if (responseData?.data?.data === true) {
        setCheckExistName(true);
      } else {
        setCheckExistName(false);
      }
    }
  };

  const submit = async () => {
    const request: any = {
      name: wiegandFormatValue?.name,
      wiegandCount: wiegandFormatValue?.wiegandCount,
      siteCode: wiegandFormatValue?.siteCode,
      isDefaultFmt: wiegandFormatValue?.autoMode?.length !== 0,
    };
    if (wiegandFormatValue.wiegandMode === 0) {
      request.wiegandMode = wiegandFormatValue.wiegandMode;
      request.parityFmt = wiegandFormatValue?.parityCheckFormat;
      request.cardFmt = wiegandFormatValue?.cardCheckFormat;
    }
    if (wiegandFormatValue.wiegandMode === 1) {
      request.wiegandMode = wiegandFormatValue.wiegandMode;
      request.firstParity = wiegandFormatValue?.firstParityCheck;
      request.secondParity = wiegandFormatValue?.secondParityCheck;
      request.oStart = wiegandFormatValue?.oddParityStartBit;
      request.oCount = wiegandFormatValue?.oddParityMaxLength;
      request.sStart = wiegandFormatValue?.siteCodeStartBit;
      request.sCount = wiegandFormatValue?.siteCodeMaxLength;
      request.eStart = wiegandFormatValue?.eventParityStartBit;
      request.eCount = wiegandFormatValue?.eventParityMaxLength;
      request.mStart = wiegandFormatValue?.manufacturerCodeStartBit;
      request.mCount = wiegandFormatValue?.manufacturerCodeMaxLength;
      request.cStart = wiegandFormatValue?.CIDStartBit;
      request.cCount = wiegandFormatValue?.CIDMaxLength;
      // request.parityFmt = wiegandFormatValue?.parityCheckFormat;
      // request.cadFmt = wiegandFormatValue?.cardCheckFormat;
    }
    wiegantFormat
      .addWiegandFormat(request)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('wiegandAddSuccess'),
              status: 200, // Success status code
            }),
          );
          dispatch(addWiegandFormat(response.data.data));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    const {
      oddParityStartBit,
      oddParityMaxLength,
      eventParityStartBit,
      eventParityMaxLength,
      CIDStartBit,
      CIDMaxLength,
      parityCheckFormat,
      cardCheckFormat,
      wiegandCount,
      siteCodeStartBit,
      siteCodeMaxLength,
      manufacturerCodeStartBit,
      manufacturerCodeMaxLength,
    } = wiegandFormatValue || {};
    let errorState = '';
    if (
      (oddParityStartBit && Number(oddParityStartBit) <= 0) ||
      (!oddParityStartBit && oddParityMaxLength)
    ) {
      errorState = 'oddStart';
    }
    if (
      (oddParityMaxLength && Number(oddParityMaxLength) <= 0) ||
      (oddParityStartBit && !oddParityMaxLength)
    ) {
      errorState = 'oddMax';
    }
    if (
      (eventParityMaxLength && Number(eventParityMaxLength) <= 0) ||
      (eventParityStartBit && !eventParityMaxLength)
    ) {
      errorState = 'eventMax';
    }
    if (
      (eventParityStartBit && Number(eventParityStartBit) <= 0) ||
      (!eventParityStartBit && eventParityMaxLength)
    ) {
      errorState = 'eventStart';
    }
    if (
      (siteCodeMaxLength && Number(siteCodeMaxLength) <= 0) ||
      (siteCodeStartBit && !siteCodeMaxLength)
    ) {
      errorState = 'siteMax';
    }
    if (
      (siteCodeStartBit && Number(siteCodeStartBit) <= 0) ||
      (!siteCodeStartBit && siteCodeMaxLength)
    ) {
      errorState = 'siteStart';
    }
    if (
      (manufacturerCodeMaxLength && Number(manufacturerCodeMaxLength) <= 0) ||
      (manufacturerCodeStartBit && !manufacturerCodeMaxLength)
    ) {
      errorState = 'manufactureMax';
    }
    if (
      (manufacturerCodeStartBit && Number(manufacturerCodeStartBit) <= 0) ||
      (!manufacturerCodeStartBit && manufacturerCodeMaxLength)
    ) {
      errorState = 'manufactureStart';
    }
    if ((CIDMaxLength && Number(CIDMaxLength) <= 0) || (CIDStartBit && !CIDMaxLength)) {
      errorState = 'cidMax';
    }
    if ((CIDStartBit && Number(CIDStartBit) <= 0) || (!CIDStartBit && CIDMaxLength)) {
      errorState = 'cidStart';
    }
    if (parityCheckFormat.length > Number(wiegandCount)) {
      errorState = 'parityCheckFormat';
    }
    if (cardCheckFormat.length > Number(wiegandCount)) {
      errorState = 'cardCheckFormat';
    }
    setIsRequired(errorState);
  }, [wiegandFormatValue]);
  useEffect(() => {
    if (wiegandFormatValue.name && wiegandFormatValue.wiegandCount && !checkExistName) {
      if (
        wiegandFormatValue.wiegandMode === 0 &&
        wiegandFormatValue.cardCheckFormat &&
        wiegandFormatValue.parityCheckFormat &&
        !isRequired
      ) {
        setButtonDisable(false);
      } else if (
        wiegandFormatValue.wiegandMode === 1 &&
        wiegandFormatValue.CIDStartBit &&
        wiegandFormatValue.CIDMaxLength &&
        !checkParityError.parityError &&
        !checkParityError?.cardError &&
        !isRequired
      ) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    } else {
      setButtonDisable(true);
    }
  }, [wiegandFormatValue, checkExistName]);

  return (
    <div className="add-wiegand-format">
      <div className="add-wiegand-format-form">
        <div className="row-form">
          <div className="col">
            <InputField
              name="name"
              label={t('name')}
              id="name"
              required
              type="text"
              value={wiegandFormatValue?.name}
              onChangeFunction={onChangeValue}
              onBlurFunction={onBlur}
              errorStatus={checkExistName}
              message={t('wiegandNameIsAlreadyExistPleaseChangeTheName')}
            />
          </div>
          <div className="col">
            <InputField
              name="wiegandCount"
              label={t('totalBit')}
              id="wiegandCount"
              required
              type="number"
              value={wiegandFormatValue.wiegandCount}
              onChangeFunction={onChangeTotalBit}
            />
          </div>
          <div className="col">
            <InputField
              name="siteCode"
              label={t('sideCode')}
              id="siteCode"
              type="text"
              value={wiegandFormatValue.siteCode}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col-1">
            <CheckBoxInputField
              name="autoMode"
              data={[
                {
                  label: t('auto'),
                  id: 'yes',
                  value: 'yes',
                },
              ]}
              value={wiegandFormatValue.autoMode}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
        <div className="row-form-radio">
          <div className="col">
            <RadioButtonField
              name="wiegandMode"
              data={[
                {
                  label: t('modeOne'),
                  id: 'one',
                  value: 1,
                },
                {
                  label: t('modeTwo'),
                  id: 'two',
                  value: 0,
                },
              ]}
              value={wiegandFormatValue.wiegandMode}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
        {wiegandFormatValue.wiegandMode === 1 ? (
          <>
            <div className="row-form">
              <div className="row row-2">
                <InputField
                  name="firstParityCheck"
                  label={t('firstPriorityCheck')}
                  id="firstParityCheck"
                  type="number"
                  value={wiegandFormatValue.firstParityCheck}
                  onChangeFunction={onChangeValue}
                />
                <InputField
                  name="secondParityCheck"
                  label={t('secondPriorityCheck')}
                  id="secondParityCheck"
                  type="number"
                  value={wiegandFormatValue.secondParityCheck}
                  onChangeFunction={onChangeValue}
                />
              </div>
            </div>
            <div className="flex-warp">
              <div className="warp-item">
                <div className="title">{t('oddParityCheck')}</div>
                <div className="bit-value">
                  <div>
                    <InputField
                      name="oddParityStartBit"
                      label={t('startBit')}
                      id="oddParityStartBit"
                      type="number"
                      value={wiegandFormatValue?.oddParityStartBit}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'oddStart'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                  <div>
                    <InputField
                      name="oddParityMaxLength"
                      label={t('theMaximumLength')}
                      id="oddParityMaxLength"
                      type="number"
                      value={wiegandFormatValue?.oddParityMaxLength}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'oddMax'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                </div>
                <small className="input-error-message">
                  {checkParityError?.parityError &&
                    wiegandFormatValue?.oddParityMaxLength &&
                    'Card check format length can not be greater than the total number of!'}
                </small>
              </div>
              <div className="warp-item">
                <div className="title">{t('siteCodes')}</div>
                <div className="bit-value">
                  <div>
                    <InputField
                      name="siteCodeStartBit"
                      label={t('startBit')}
                      id="siteCodeStartBit"
                      type="number"
                      value={wiegandFormatValue?.siteCodeStartBit}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'siteStart'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                  <div>
                    <InputField
                      name="siteCodeMaxLength"
                      label={t('theMaximumLength')}
                      id="siteCodeMaxLength"
                      type="number"
                      value={wiegandFormatValue?.siteCodeMaxLength}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'siteMax'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                </div>
                <small className="input-error-message">
                  {checkParityError?.cardError &&
                    wiegandFormatValue?.siteCodeMaxLength &&
                    'Card check format length can not be greater than the total number of!'}
                </small>
              </div>
              <div className="warp-item">
                <div className="title">{t('eventParityCheck')}</div>
                <div className="bit-value">
                  <div>
                    <InputField
                      name="eventParityStartBit"
                      label={t('startBit')}
                      id="eventParityStartBit"
                      type="number"
                      value={wiegandFormatValue?.eventParityStartBit}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'eventStart'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                  <div>
                    <InputField
                      name="eventParityMaxLength"
                      label={t('theMaximumLength')}
                      id="eventParityMaxLength"
                      type="number"
                      value={wiegandFormatValue?.eventParityMaxLength}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'eventMax'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                </div>
                <small className="input-error-message">
                  {checkParityError?.parityError &&
                    wiegandFormatValue?.eventParityMaxLength &&
                    'Card check format length can not be greater than the total number of!'}
                </small>
              </div>
              <div className="warp-item">
                <div className="title">{t('manufacturerCode')}</div>
                <div className="bit-value">
                  <div>
                    <InputField
                      name="manufacturerCodeStartBit"
                      label={t('startBit')}
                      id="manufacturerCodeStartBit"
                      type="number"
                      value={wiegandFormatValue?.manufacturerCodeStartBit}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'manufactureStart'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                  <div>
                    <InputField
                      name="manufacturerCodeMaxLength"
                      label={t('theMaximumLength')}
                      id="manufacturerCodeMaxLength"
                      type="number"
                      value={wiegandFormatValue?.manufacturerCodeMaxLength}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'manufactureMax'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                </div>
                <small className="input-error-message">
                  {checkParityError?.cardError &&
                    wiegandFormatValue?.manufacturerCodeMaxLength &&
                    'Card check format length can not be greater than the total number of!'}
                </small>
              </div>
              <div className="warp-item">
                <div className="title">{t('cid')}</div>
                <div className="bit-value">
                  <div>
                    <InputField
                      name="CIDStartBit"
                      label={t('startBit')}
                      id="CIDStartBit"
                      type="number"
                      required
                      value={wiegandFormatValue?.CIDStartBit}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'cidStart'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                  <div>
                    <InputField
                      name="CIDMaxLength"
                      label={t('theMaximumLength')}
                      id="CIDMaxLength"
                      type="number"
                      required
                      value={wiegandFormatValue?.CIDMaxLength}
                      onChangeFunction={onChangeValue}
                      errorStatus={isRequired === 'cidMax'}
                      message="Mandatory field, value should be greater than 0"
                    />
                  </div>
                </div>
                <small className="input-error-message">
                  {checkParityError?.cardError &&
                    wiegandFormatValue?.CIDMaxLength &&
                    'Card check format length can not be greater than the total number of!'}
                </small>
              </div>
            </div>
          </>
        ) : (
          <div className="row-form">
            <div className="col-0">
              <InputField
                name="cardCheckFormat"
                label={t('cardCheckFormat')}
                id="cardCheckFormat"
                type="text"
                required
                value={wiegandFormatValue.cardCheckFormat}
                onChangeFunction={onChangeValue}
                errorStatus={isRequired === 'cardCheckFormat'}
                message="Check card format length must be equal to the total number of bits!"
              />
            </div>
            <div className="col-0">
              <InputField
                name="parityCheckFormat"
                label={t('parityCheckFormat')}
                id="parityCheckFormat"
                type="text"
                required
                value={wiegandFormatValue.parityCheckFormat}
                onChangeFunction={onChangeValue}
                errorStatus={isRequired === 'parityCheckFormat'}
                message="Check parity format length must be equal to the total number of bits!"
              />
            </div>
          </div>
        )}
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            disabled={buttonDisable}
            className="btn-primary btn-bold"
          />
        </div>
      </div>
    </div>
  );
}

export default AddWiegandFormat;
