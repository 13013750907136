import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';

// Use interface
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import {
  ParameterInterface,
  ParameterReduxInterface,
} from '../../../interface/parameter.interface';
import { useAppSelector } from '../../../redux/store';

function PersonnelIdSetting({ onChangeFunction }: ParameterInterface) {
  const { t } = useTranslation();
  const { parameters }: ParameterReduxInterface = useAppSelector((state: any) => state.parameters);
  const [value, setValue] = useState(parameters);

  const onChange = (name: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  useEffect(() => {
    setValue({ ...parameters });
  }, [parameters]);
  return (
    <div className="personnel-setting">
      <div className="personnel-setting-form">
        <div className="column">
          <div className="item item-1">
            <InputField
              name="pers.pinLen"
              label={t('maximumLength')}
              id="pers.pinLen"
              type="text"
              value={value['pers.pinLen']}
              required
              innerIconStatus={false}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('supportLetters')}
              name="pers.pinSupportLetter"
              alignment="row"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'true',
                  value: 'true',
                },
                {
                  label: t('no'),
                  id: 'false',
                  value: 'false',
                },
              ]}
              value={value['pers.pinSupportLetter']}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('personnelIdAutoIncrement')}
              name="pers.pinSupportIncrement"
              alignment="column"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'true',
                  value: 'true',
                },
                {
                  label: t('no'),
                  id: 'false',
                  value: 'false',
                },
              ]}
              value={value['pers.pinSupportIncrement']}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonnelIdSetting;
