import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { AttentionIcon } from '../../../../../assets/svgicons/svgicon';
import Button from '../../../../../shared-components/button/button';

// interface StateProps {
//   backupFtp: string[];
// }

interface ModalType {
  closeModal: () => void;
}

function BackupImmediate({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    backupFtp: [],
  });
  const handleChange = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="backup-Immediate flex-row column">
      <h2 className="title">{t('areYouSureYouWantToBackUpDataNow')}</h2>
      <CheckBoxInputField
        name="backupFtp"
        data={[
          {
            label: t('backUpToFTPServerAtTheSameTime'),
            id: '1',
            value: '1',
          },
        ]}
        value={values?.backupFtp}
        onChangeFunction={handleChange}
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        <span className="error-msg">{t('warningClickCheckBoxFtpServer')}</span>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={closeModal}
          title={t('done')}
          className="btn-primary"
          // disabled={disabled}
        />
      </div>
    </div>
  );
}

export default BackupImmediate;
