import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { ReplaceModal } from '../../../interface/device.interface';

function ModifyFingerThrehold({ closeModal, selectedId }: ReplaceModal) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [thresHold, setThresHold] = useState('37');
  const submit = () => {
    const req = {
      id: selectedId?.[0],
      thresHold,
    };
    device
      .updateThresHold(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  return (
    <div className="device-setup-popup">
      <div style={{ width: '56%', padding: '20px 0 30px 0' }}>
        <InputField
          name="thresHold"
          label={t('The fingerprint identification threshold')}
          id="thresHold"
          value={thresHold}
          validation={Number(thresHold) >= 35 && Number(thresHold) <= 70}
          message="(35-70)"
          onChangeFunction={(name, value) => setThresHold(value)}
          type="text"
          className=""
          required
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={!thresHold}
        />
      </div>
    </div>
  );
}

export default ModifyFingerThrehold;
