import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Sys/api/baseMediaFile/listByPage`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  saveUploadFile: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Sys/api/baseMediaFile/saveUploadFile`, data, {}),

  isExistName: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Sys/api/baseMediaFile/valid`, {}, data),

  save: (data: any) => post(`${process.env.REACT_APP_API_URL}Sys/api/baseMediaFile/save`, data, {}),

  deleteResourceFile: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Sys/api/baseMediaFile/delete?ids=${id}`, {});
  },
};
