import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use shared component
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import MultipleImageUpload from '../../../shared-components/multiple-file-upload/multiple-file-upload';

// Use apis calls
import departmentApi from '../../../utils/api/department';

// Import the notification action
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchPersonsList } from '../../../redux/slices/PersonSlices';

function DepartmentImportData({
  closeModal,
  submitFunction,
}: {
  closeModal: () => void;
  submitFunction: (val: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectData = [
    {
      label: t('excel'),
      id: 'excel',
    },
  ];
  const [value, setValue] = useState({
    fileType: '',
    fileList: [],
    updateExistingData: '',
  });
  const [disableButton, setDisableButton] = useState(true);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const handleSubmit = () => {
    const formData = new FormData();

    // Assuming fileList is an array of files
    if (value.fileList.length > 0) {
      formData.append('upload', value.fileList[0]); // Append the first file, adjust if multiple files are required
    }
    formData.append('updateExistData', value.updateExistingData === 'yes' ? '1' : '0');

    departmentApi
      .importDepartment(formData)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('departmentImportsuccessfully'),
              status: 200, // Success status code
            }),
          );
          dispatch(fetchPersonsList({ pageNo: 1 }));
          closeModal();
          setDisableButton(false);
          submitFunction(formData); // Pass the request object or another relevant value
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    // Enable the button if all fields are filled
    if (value.fileType && value.fileList.length > 0 && value.updateExistingData !== null) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="row row-1">
          <div className="col">
            <SelectBoxField
              name="fileType"
              label={t('fileType')}
              id="fileType"
              value={value.fileType}
              required
              onChangeFunction={(name, changeValue) => onChange(name, changeValue.id)}
              data={selectData}
              className=""
            />
          </div>
        </div>
        <div className="item">
          <MultipleImageUpload
            title={t('uploadFile')}
            required
            name="fileList"
            value={value.fileList}
            onClick={(name, changeValue) => {
              onChange(name, changeValue);
            }}
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={handleSubmit}
              title={t('import')}
              disabled={disableButton}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepartmentImportData;
