import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';

// Use Validation functions
import { nameValidation } from '../../../shared-components/form/validation';

// Use api calls
import libraryApis from '../../../utils/api/library';

// Use interface
import { AddLibraryInterFace } from '../../../interface/library.interface';

// Use assets json file
import LibraryTypeData from '../../../assets/json/library-type.json';

// Use redux functions
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { addLibrary } from '../../../redux/slices/LibrarySlices';

function AddLibrary({ closeModal, data }: AddLibraryInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectData = LibraryTypeData.map((val) => ({
    ...val,
    label: t(val.label),
  }));

  const [disableButton, setDisableButton] = useState(true);
  const [libraryValue, setLibraryValue] = useState(
    data || {
      name: '',
      type: '',
      description: '',
      id: '',
    },
  );
  const onChangeValue = (name: string, value: any) => {
    setLibraryValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    const request = {
      name: libraryValue.name,
      type: libraryValue.type,
      description: libraryValue.description,
      id: libraryValue.id,
    };
    libraryApis
      .addLibrary(request)
      .then((response) => {
        if (response.data.code === 0) {
          dispatch(
            setNotificationError({
              error: t('libraryAddedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(addLibrary(response.data.data));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    let i = 0;
    if (libraryValue.name === '' || libraryValue.type === '' || libraryValue.description === '') {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [libraryValue]);
  return (
    <div className="add-library">
      <div className="add-library-form">
        <div className="row row-2">
          <div className="col">
            <InputField
              name="name"
              label={t('listLibraryName')}
              id="name"
              type="text"
              value={libraryValue.name}
              required
              errorStatus={libraryValue.name !== '' && !nameValidation(1, 50, libraryValue.name)}
              message={t('listLibraryNameInvalid')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <SelectBoxField
              name="type"
              label={t('listLibraryType')}
              id="type"
              data={selectData}
              value={libraryValue.type}
              className=""
              required
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col full-size">
            <InputTextAreaField
              name="description"
              label={t('description')}
              id="description"
              value={libraryValue.description}
              required
              errorStatus={
                libraryValue.description !== '' && !nameValidation(1, 500, libraryValue.description)
              }
              message={t('listLibraryDescriptionInvalid')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
              rows={5}
            />
          </div>
        </div>
      </div>
      <div className="add-attribute-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={libraryValue.id ? t('update') : t('add')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default AddLibrary;
