import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../shared-components/button/button';
import CheckBoxInputField from '../../../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputField from '../../../../../shared-components/input-field/input-field';

interface ModalType {
  closeModal: () => void;
}

function OutgoingMailServerSettings({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    emailSendingServer: '',
    port: '25',
    senderName: '',
    emailAccount: '',
    password: '',
    ssl: false,
    tls: false,
    starttls: false,
  });
  const handleChange = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'ssl' && value) {
      setValues((prev) => ({ ...prev, port: '465' }));
    } else if (name === 'tls' && value) {
      setValues((prev) => ({ ...prev, port: '587' }));
    } else if (name === 'starttls' && value) {
      setValues((prev) => ({ ...prev, port: '587' }));
    } else if (!values.ssl && !values.tls && !values.starttls) {
      setValues((prev) => ({ ...prev, port: '25' }));
    }
  };
  return (
    <div className="outgoing-mail-server-settings">
      <h3>{t('outgoingMailServerSettings')}</h3>
      <div className="flex-row">
        <InputField
          name="emailSendingServer"
          label={t('emailSendingServer')}
          id="emailSendingServer"
          type="text"
          value={values.emailSendingServer}
          onChangeFunction={handleChange}
          required
        />

        <div className="checkbox-group">
          <CheckBoxInputField
            name="ssl"
            data={[
              {
                label: 'SSL',
                id: 'ssl',
                value: 'ssl',
              },
            ]}
            value={values.ssl ? ['ssl'] : []}
            onChangeFunction={handleChange}
          />
          <CheckBoxInputField
            name="tls"
            data={[
              {
                label: 'TLS',
                id: 'tls',
                value: 'tls',
              },
            ]}
            value={values.tls ? ['tls'] : []}
            onChangeFunction={handleChange}
          />
          <CheckBoxInputField
            name="starttls"
            data={[
              {
                label: 'STARTTLS',
                id: 'starttls',
                value: 'starttls',
              },
            ]}
            value={values.starttls ? ['starttls'] : []}
            onChangeFunction={handleChange}
          />
        </div>
      </div>
      <p className="field-helper-text">smtp.xxx.xxx</p>
      <div className="flex-input">
        <InputField
          name="emailAccount"
          label={t('emailAccount')}
          id="emailAccount"
          type="text"
          value={values.emailAccount}
          onChangeFunction={handleChange}
        />
        <InputField
          name="port"
          label={t('port')}
          id="port"
          type="text"
          value={values.port}
          onChangeFunction={() => {}}
          required
        />
      </div>
      <p className="field-helper-text">xxx@xxx.xxx</p>
      <div className="flex-input">
        <InputField
          name="password"
          label={t('password')}
          id="password"
          type="password"
          value={values.password}
          onChangeFunction={handleChange}
        />
        <InputField
          name="senderName"
          label={t('senderName')}
          id="senderName"
          type="text"
          value={values.senderName}
          onChangeFunction={handleChange}
        />
      </div>
      <div className="test-btn">
        <Button
          onClickFunction={closeModal}
          title={t('testConnection')}
          className="btn-outline-primary"
          // disabled={disabled}
        />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={closeModal} title={t('done')} className="btn-primary" />
      </div>
    </div>
  );
}

export default OutgoingMailServerSettings;
