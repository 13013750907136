/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import multiPersonGroup from '../../utils/api/AccessRule/multiPersonGroup';
import { setNotificationError } from './NotificationSlices';

interface Level {
  id: string;
}
// Define the State interface
interface State {
  multiPersonGroup: any[];
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: Record<string, any>;
  levels: Level[];
  levelStatus: string;
  levelTotalDataCount: number;
  levelCurrentPageNo: number;
  levelSearchFilter: Record<string, any>;
}

export const fetchMultiPersonGroup = createAsyncThunk(
  'multiPersonGroup/fetchMultiPersonGroup',
  async (data: any, { dispatch }) => {
    const apiResponse = await multiPersonGroup
      .listByPage(data)
      .then((response) => {
        return {
          ...response.data,
          data: {
            list: response.data.data.data.map((val: any) => ({
              ...val,
              id: val?.id,
            })),
            size: response.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return apiResponse;
  },
);

export const fetchPersonGroupList = createAsyncThunk(
  'fetchPersonGroupList',
  async (data: any, { dispatch }) => {
    try {
      const response = await multiPersonGroup.getPersonGroupList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
export const fetchPersonlist = createAsyncThunk(
  'multiPersonGroup/fetchPersonlist',
  async (data: any, { dispatch }) => {
    try {
      const response = await multiPersonGroup.getPersonlist(data);
      return {
        ...response.data,
      };
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
const initialState: State = {
  multiPersonGroup: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  levels: [],
  levelStatus: 'success',
  levelTotalDataCount: 0,
  levelCurrentPageNo: 0,
  levelSearchFilter: {},
};

export const multiPersonGroupSlice = createSlice({
  name: 'multiPersonGroup',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    setLevelSearchFilter: (state, action: any) => {
      state.levelSearchFilter = action.payload;
    },
    deleteMultiPerson: (state, action: any) => {
      state.multiPersonGroup = state.multiPersonGroup.filter(
        (val: any) => val.code !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    deleteLevels: (state, action: PayloadAction<any>) => {
      state.levels = state.levels.filter((val: any) => val.code !== action.payload);
      state.levelTotalDataCount -= 1;
    },
    resetLevels: (state) => {
      state.levels = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMultiPersonGroup.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMultiPersonGroup.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.multiPersonGroup = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
        }
        state.status = 'success';
      })
      .addCase(fetchMultiPersonGroup.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchPersonGroupList.pending, (state) => {
        state.levelStatus = 'loading';
      })
      .addCase(fetchPersonGroupList.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.levelTotalDataCount = action.payload.data.size;
          state.levelCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          if (state.levelCurrentPageNo === 1) {
            state.levels = action.payload.data.list as Level[];
          } else {
            state.levels = [...state.levels, ...(action.payload.data.list as Level[])];
          }
        }
        state.levelStatus = 'success';
      })
      .addCase(fetchPersonGroupList.rejected, (state) => {
        state.levelStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearchFilter,
  setLevelSearchFilter,
  deleteMultiPerson,
  deleteLevels,
  resetLevels,
} = multiPersonGroupSlice.actions;

export default multiPersonGroupSlice.reducer;
