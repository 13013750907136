import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

// Use interface
import { PermissionInterface } from '../../../interface/permissions.interface';
import InputField from '../../../shared-components/input-field/input-field';

function PersonnelDetails({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();

  const [value, setValue] = useState(values);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <div className="permissions-form">
        <div className="row-form">
          <div className="item">
            <InputField
              name="booking"
              label={t('booking')}
              id="booking"
              type="text"
              value={value.booking}
              required
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="civilIdNo"
              label={t('civilIdNumber')}
              id="civilIdNo"
              type="text"
              value={value.civilIdNo}
              required
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('maritalStatus')}
              name="maritalStatus"
              alignment="column"
              required
              data={[
                {
                  label: t('single'),
                  id: 'single',
                  value: 'single',
                },
                {
                  label: t('married'),
                  id: 'married',
                  value: 'married',
                },
              ]}
              value={value.maritalStatus}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('usufructo')}
              name="usufructo"
              alignment="column"
              required
              data={[
                {
                  label: '0',
                  id: '0',
                  value: '0',
                },
                {
                  label: '1',
                  id: '1',
                  value: '1',
                },
              ]}
              value={value.usufructo}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <RadioButtonField
              label={t('passage')}
              name="passage"
              alignment="column"
              required
              data={[
                {
                  label: '10',
                  id: '10',
                  value: '10',
                },
              ]}
              value={value.passage}
              onChangeFunction={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonnelDetails;
