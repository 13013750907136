import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import SelectLinkage from './SelectLinkageDevice';
import LinkageTriggerConnections from './LinkageTriggerConnections';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import TabPages from '../../../shared-components/tab-pages/tab-pages';
import OutputPoint from './OutputPoint';
import VideoLinkage from './VideoLinkage';
import EmailLinkage from './EmailLinkage';
import Intrusion from './Intrusion';
import SendSms from './SendSms';
import Label from '../../../shared-components/label/Label';
import Line from './Line';
import Whatsapp from './whatsapp';
import { DeviceProps } from '../../../interface/accessByDepartment.interface';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import linkage from '../../../utils/api/AccessRule/linkage';
import { fetchLinkage } from '../../../redux/slices/LinkageSlices';

interface LinkageProps {
  closeModal: () => void;
  update?: any;
}

interface DoorEventProps {
  id: string;
  text: string;
}
const transformData = (data: any) => {
  const { item, id, text } = data;

  // Combine child items with parent object
  return [...item, { id, text }];
};
const transformOptions = (data: any) => {
  return data.map((item: any) => ({
    id: item.id,
    label: item.text,
    value: item.id,
  }));
};
function AddLinkage({ closeModal, update }: LinkageProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(
    update || {
      name: '',
      device: '',
      trigger: [],
      inputPoint: [],
      outputAuxOutAddr: [],
      doorOutput: [],
      auxoutActionType: '',
      mailAddr: '',
      mobileNo: '',
      partitionActionType: '',
      partitionArmType: '',
      whatsappMobileNo: '',
      doorActionTime: '',
      auxoutActionTime: '',
    },
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const [disable, setDisable] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [triggerConditions, setTriggerConditions] = useState<any[]>([]);
  const [auxInputs, setAuxInputs] = useState([]);
  const [inputPointOptions, setInputPointOptions] = useState([]);
  const [doorOutputOptions, setDoorOutputOptions] = useState([]);
  const [tabs, setTabs] = useState([
    {
      key: 'outputPoint',
      title: t('outputPoint'),
      content: <OutputPoint values={value} changeValue={() => {}} options={[]} doorOutputs={[]} />,
    },
  ]);
  const [checkExistName, setCheckExistName] = useState(true);

  const onChangeValue = (name: string, data: any) => {
    console.log(data, name);
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
  };
  console.log(value);
  const tableColumns = [
    { label: t('deviceName'), id: 'deviceAlias' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];

  const changeSearch = () => {
    console.log('search');
  };

  const getSelectedDevice = (selected: DeviceProps[]) => {
    const deviceValue = selected.map((item: any) => ({
      label: item.deviceName,
      id: item.id,
      value: item.id,
    }));
    setSelectedDevices(deviceValue);
  };

  const getInOut = (params: string[]) => {
    const request = {
      devId: selectedDevices?.[0]?.id,
      triggerOpt: params.toString(),
    };
    linkage
      .getInOutInfo(request)
      .then((res) => {
        // auxiliary output data
        const transformAuxInput = transformData(res.data.data.auxOutOutputArray);
        const auxOutputs: any = transformOptions(transformAuxInput);
        setAuxInputs(auxOutputs);

        // input point data
        const transformInput: any = transformOptions(res.data.data.inputArray);
        setInputPointOptions(transformInput);

        // door data
        const transformDoors = transformData(res.data.data.doorOutputArray);
        const doorOutput: any = transformOptions(transformDoors);
        setDoorOutputOptions(doorOutput);

        setTabs([
          {
            key: 'outputPoint',
            title: t('outputPoint'),
            content: (
              <OutputPoint
                values={value}
                changeValue={onChangeValue}
                options={auxOutputs}
                doorOutputs={doorOutput}
              />
            ),
          },
        ]);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response?.data));
      });
  };
  const handleClickDevice = () => {
    setModalView({
      title: t('selectLinkageDevice'),
      content: (
        <SelectLinkage
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          getSelected={(selected: DeviceProps[]) => getSelectedDevice(selected)}
          closeModal={() => setModalOpen(false)}
        />
      ),
    });
    setModalOpen(true);
  };
  const addTriggerCondition = (val: DoorEventProps[]) => {
    console.log(val);
    const triggerValue = val.map((item) => ({
      label: item.text,
      id: item.id,
      value: item.id,
    }));
    setTriggerConditions(triggerValue);
    const getId = val.map((ids) => ids.id);
    getInOut(getId);
    setValue((prev: any) => ({
      ...prev,
      trigger: triggerValue.map((item) => item.id),
    }));
  };

  const handleLinkageTrigger = () => {
    if (selectedDevices?.length === 0) {
      dispatch(
        setNotificationError({
          error: 'Please select the device',
          status: 500,
        }),
      );
    } else {
      setModalView({
        title: t('chooseLinkageTriggerConnections'),
        content: (
          <LinkageTriggerConnections
            closeModal={() => setModalOpen(false)}
            addTriggerCondition={addTriggerCondition}
            deviceId={selectedDevices?.[0]?.id}
          />
        ),
      });
      setModalOpen(true);
    }
  };

  const onBlur = async (name: string, data: any) => {
    if (name === 'name' && data !== '') {
      const responseData = await linkage.isExistName(data);
      setCheckExistName(responseData?.data);
    }
  };

  const submit = () => {
    const {
      name,
      trigger,
      inputPoint,
      doorOutput,
      doorActionType,
      auxoutActionType,
      mailAddr,
      mobileNo,
      whatsappMobileNo,
    } = value;
    const request = {
      name,
      deviceId: selectedDevices?.[0]?.id,
      triggerCond: trigger?.toString(),
      inputAddr: inputPoint?.toString(),
      outputDoorAddr: doorOutput?.toString(),
      doorActionType,
      auxoutActionType,
      partitionActionType: '',
      partitionArmType: '',
      mailAddr,
      mobileNo,
      whatsappMobileNo,
    };

    linkage
      .save(request)
      .then((response) => {
        if (response?.data?.success) {
          const message = update ? t('linkageUpdatedSuccessfully') : t('linkageAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchLinkage({ pageNo: 1 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    setTabs([
      {
        key: 'outputPoint',
        title: t('outputPoint'),
        content: (
          <OutputPoint
            values={value}
            changeValue={onChangeValue}
            options={auxInputs}
            doorOutputs={doorOutputOptions}
          />
        ),
      },
      {
        key: 'videoLinkage',
        title: t('videoLinkage'),
        content: <VideoLinkage />,
      },
      {
        key: 'E-mail',
        title: t('E-mail'),
        content: <EmailLinkage values={value} changeValue={onChangeValue} />,
      },
      {
        key: 'intrusion',
        title: t('intrusion'),
        content: <Intrusion values={value} changeValue={onChangeValue} />,
      },
      {
        key: 'sendSMS',
        title: t('sendSMS'),
        content: <SendSms values={value} changeValue={onChangeValue} />,
      },
      {
        key: 'line',
        title: t('line'),
        content: <Line />,
      },
      {
        key: 'whatsapp',
        title: t('whatsapp'),
        content: <Whatsapp values={value} changeValue={onChangeValue} />,
      },
    ]);
  }, [auxInputs]);

  useEffect(() => {
    if (value.name && checkExistName) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value, checkExistName]);
  return (
    <div className="add-linkage flex-row column">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="flex-row" style={{ alignItems: 'center' }}>
        <div className="col">
          <InputField
            name="name"
            label={t('name')}
            id="name"
            type="text"
            value={value.name}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlur}
            errorStatus={value.name !== '' && !checkExistName}
            message={t('nameIsAlreadyExist')}
          />
        </div>
        <div className="select-device-wrapper">
          <div className="select-device">
            <Button
              onClickFunction={handleClickDevice}
              title={t('selectDevice')}
              className="btn-outline-primary"
            />
            <div className="device-name">
              <p className="label">{t('SelectedDevice')}</p>
              <h4 className="value">{selectedDevices?.[0]?.label}</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('linkageTriggerConditions')} required />
            <span className="add-link" role="presentation" onClick={handleLinkageTrigger}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="trigger"
              data={triggerConditions}
              value={value?.trigger}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('inputPoint')} required />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="inputPoint"
              data={inputPointOptions}
              value={value?.inputPoint}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
        </div>
      </div>
      <div className="linkage-types">
        <TabPages tabs={tabs} defaultNumber={0} />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => console.log('hi')}
          title={t('save&new')}
          className="btn-primary"
          disabled={disable}
        />
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddLinkage.defaultProps = {
  update: [],
};

export default AddLinkage;
