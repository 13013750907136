import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import areaHeadCount from '../../../utils/api/AdvancedFunctions/areaHeadCount';
import musterPoint from '../../../utils/api/AdvancedFunctions/musterPoint';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { DangerAnalysisIcon, SafeIcon, TotalIcon } from '../../../assets/svgicons/svgicon';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../shared-components/button/button';
import musterPointReport from '../../../utils/api/AdvancedFunctions/musterPointReport';
import {
  fetchMusterPointReport,
  setSearchFilter,
} from '../../../redux/slices/MusterPointReportSlices';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import SignInManually from './components/signInManually';
import ExportData from './components/ExportData';
// import ExportData from './components/export-data';

function MusterPointReport() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { pointList, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.musterPointReport,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [musterPoints, setMusterPoint] = useState('');
  const [musterPointList, setMusterPointList] = useState([]);
  const [musterPointReports, setMusterPointReports] = useState({
    safe: 0,
    dangerous: 0,
    total: 0,
  });

  const columnsField = [
    {
      accessorKey: 'time',
      header: t('time'),
    },
    {
      accessorKey: 'pin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'department',
      header: t('department'),
    },
    {
      accessorKey: 'mobilePhone',
      header: t('mobilePhone'),
    },
    {
      accessorKey: 'signPoint',
      header: t('signPoint'),
    },
  ];

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchMusterPointReport({ ...params }));
    }
  };

  const deleteRecord = (id: any) => {
    const request = { personPins: id };
    areaHeadCount
      .deleteAreaHead(request)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({ pageNo: 1 }); // Refresh the list after successful deletion
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const handleGenerate = async () => {
    try {
      const response = await musterPointReport.getChartData(musterPoints);
      fetchData({ pageNo: 1, musterPointId: musterPoints });
      setMusterPointReports(response.data.data);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error?.status || 500,
        }),
      );
    }
  };

  const handleClearData = () => {
    musterPointReport
      .clearData()
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          handleGenerate();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const exportData = () => {
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('signInManually'),
      clickFunction: (): void => {
        setModalView({
          title: t('signInManually'),
          content: <SignInManually closeModal={() => setModalOpen(false)} />,
        });
        setModalOpen(true);
      },
    },
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('clearAllData'),
      clickFunction: (): void => {
        setModalView({
          title: t('clearAllData'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={handleClearData}
              title={t('areYouWantToClearAllRecords')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  const fetchMusterPoint = async () => {
    try {
      const response = await musterPoint.getAllMusterPointList();
      const result = response.data.data.map((item: any) => ({
        label: item.text,
        id: item.value,
      }));
      setMusterPointList(result);
      setMusterPoint(response.data.data?.[0]?.value);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error?.status || 500,
        }),
      );
    }
  };

  useEffect(() => {
    fetchMusterPoint();
  }, []);
  return (
    <div className="department muster-point-report">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('musterPointReport'),
          },
        ]}
      />
      <div className="card-wrapper">
        <CardBox
          title={t('dataAnalysis')}
          rightSide={
            <div className="data-analysis-header">
              <SelectBoxField
                id="musterPoints"
                name="musterPoints"
                label={t('musterPoints')}
                value={musterPoints}
                onChangeFunction={(name, value: any) => setMusterPoint(value.id)}
                data={musterPointList}
              />
              <Button
                onClickFunction={handleGenerate}
                title={t('generate')}
                className="btn-outline"
                disabled={!musterPoints}
              />
            </div>
          }>
          <div className="box-wrapper">
            <div className="box box-1">
              <div className="box-body">
                <div className="icon-wrapper">
                  <SafeIcon />
                </div>
                <div className="count">
                  <h4>{musterPointReports.safe}</h4>
                  <span className="title">Safe</span>
                </div>
                <div className="progress-bar">
                  <p className="percentage">In Percentage (%)</p>
                  <progress className="safe-prog-bar" value={musterPointReports.safe} max={100} />
                  <p className="percentage">
                    {musterPointReports.safe
                      ? Math.round((musterPointReports.safe / musterPointReports.total) * 100)
                      : 0}
                    %
                  </p>
                </div>
              </div>
            </div>
            <div className="box box-2">
              <div className="box-body">
                <div className="icon-wrapper">
                  <DangerAnalysisIcon />
                </div>
                <div className="count">
                  <h4>{musterPointReports.dangerous}</h4>
                  <span className="title">Danger</span>
                </div>
                <div className="progress-bar">
                  <p className="percentage">In Percentage (%)</p>
                  <progress
                    className="danger-prog-bar"
                    value={musterPointReports.dangerous}
                    max={100}
                  />
                  <p className="percentage">
                    {musterPointReports.dangerous
                      ? Math.round((musterPointReports.dangerous / musterPointReports.total) * 100)
                      : 0}
                    %
                  </p>
                </div>
              </div>
            </div>
            <div className="box box-3">
              <div className="box-body">
                <div className="icon-wrapper">
                  <TotalIcon />
                </div>
                <div className="count">
                  <h4>{musterPointReports.total}</h4>
                  <span className="title">Total</span>
                </div>
                <div className="progress-bar">
                  <p className="percentage">In Percentage (%)</p>
                  <progress className="total-prog-bar" value={musterPointReports.total} max={100} />
                  <p className="percentage">
                    {musterPointReports.total
                      ? Math.round((musterPointReports.safe / musterPointReports.total) * 100) +
                        Math.round((musterPointReports.dangerous / musterPointReports.total) * 100)
                      : 0}
                    %
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CardBox>
        <CardBox
          title={t('report')}
          rightSide={
            <CardRightButtonGroup
              actions={moreActionButton}
              buttonIcon={false}
              buttonLabel=""
              buttonClass=""
              buttonStatus={false}
              buttonFunction={(): void => {}}
            />
          }>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={actionButtons}
            columns={columnsField}
            getFilterData={getFilterData}
            assignFilterValue={searchFilter}
            assignSelectedData={selectedValues}
            getSelectedData={(val: any): any => setSelectedValues(val)}
            checkBoxActive={false}
            fetchNextPage={fetchNextData}
            isFetching={status === 'loading'}
            values={pointList} // Use the actual data here
            fixedColumn={['time']}
            totalRecords={totalDataCount}
            height={411}
          />
        </CardBox>
      </div>
    </div>
  );
}

export default MusterPointReport;
