import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import Label from '../../../../shared-components/label/Label';
import GlobalAddContact from './globalAddContact';

function GlobalLine() {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const addPartition = () => {
    setModalView({
      title: t('addContact'),
      content: <GlobalAddContact closeModal={() => setModalOpen(false)} />,
    });
    setModalOpen(true);
  };
  return (
    <>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="intrusion">
        <div className="partition">
          <div className="header">
            <Label title={t('contacts')} />
            <span className="add-link" role="presentation" onClick={addPartition}>
              {t('add')}
            </span>
          </div>
          <div className="box" />
        </div>
      </div>
    </>
  );
}

export default GlobalLine;
