/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import occupancyControl from '../../utils/api/AdvancedFunctions/occupancyControl';
import { ReduxInterface } from '../../interface/occupancyControl.interface';

export const fetchOccupancyControl = createAsyncThunk(
  'acc/fetchOccupancyControl ',
  async (data: any, { dispatch }) => {
    const response = await occupancyControl
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: ReduxInterface = {
  occupancyControls: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const occupancyControlSlice: any = createSlice({
  name: 'occupancyControl',
  initialState,
  reducers: {
    deleteOccupancy: (state, action: any) => {
      state.occupancyControls = state.occupancyControls.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchOccupancyControl.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchOccupancyControl.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.occupancyControls = action.payload.data.list;
          state.totalDataCount = action.payload.size;
        }
        state.status = 'success';
      })
      .addCase(fetchOccupancyControl.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteOccupancy } = occupancyControlSlice.actions;

export default occupancyControlSlice.reducer;
