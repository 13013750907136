import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/firstInAndLastOut/list`, {}, { ...data });
  },
  clearData: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/firstInAndLastOut/clearData`, {});
  },
  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/firstInAndLastOut/export`, 'POST', data);
  },
};
