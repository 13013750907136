import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../shared-components/table/table';
import Button from '../../../../shared-components/button/button';
import { useAppDispatch } from '../../../../redux/store';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import zone from '../../../../utils/api/AdvancedFunctions/zone';

interface ModalType {
  closeModal: () => void;
  id: string;
}
function RulesApply({ closeModal, id }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [ruleList, setRuleList] = useState([]);
  const getRules = async (zoneId: string) => {
    try {
      const response = await zone.getRulesFromZone(zoneId);
      setRuleList(response.data.data);
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };
  useEffect(() => {
    getRules(id);
  }, []);
  return (
    <div className="rules-apply">
      <div className="table-container">
        <Table
          header={[
            { label: t('ruleType'), id: 'rule1Type' },
            { label: t('ruleName'), id: 'ruleName' },
          ]}
          hideHeader={false}
          value={ruleList}
        />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-primary" />
      </div>
    </div>
  );
}

export default RulesApply;
