import { defaultParams } from './axios';

export default (url: any, method: string = 'POST', formData?: FormData) => {
  try {
    const header: any = { ...defaultParams() };
    header.headers = {
      ...header.headers,
      Accept: 'application/octet-stream',
    };
    return fetch(url, {
      method,
      headers: header.headers,
      body: formData, // Add the formData as the body of the request
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
};
