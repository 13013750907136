/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Use apis calls
import department from '../../utils/api/department';

// Use other slices
import { setNotificationError } from './NotificationSlices';

// Use interface
import { DepartmentReduxInterface } from '../../interface/department.interface';

// Use helper functions
import { changeDateTimeFormat } from '../../utils/helpers';

// Use get table department list
export const fetchDepartmentList = createAsyncThunk(
  'department/fetchDepartmentList',
  async (data: any, { dispatch }) => {
    const response = await department
      .getDepartment(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((val: any) => ({
              ...val,
              id: val.code,
              departmentId: val.id,
              creationDate: changeDateTimeFormat(val.createTime),
            })),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

// Use get all department list
export const fetchAllDepartmentList = createAsyncThunk(
  'department/fetchAllDepartmentList',
  async (data: any, { dispatch }) => {
    const response = await department
      .getAllDepartment()
      .then((response1) => {
        return {
          ...response1.data,
          data: response1.data.data.map((val: any) => ({
            id: val.code,
            departmentId: val.id,
            label: val.name,
          })),
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: DepartmentReduxInterface = {
  departments: [],
  allDepartments: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const departmentSlice: any = createSlice({
  name: 'department',
  initialState,
  reducers: {
    addDepartment: (state, action: any) => {
      if (state.departments.find((val) => val.code === action.payload.code)) {
        state.departments = state.departments.map((val) => {
          let output = val;
          if (val.code === action.payload.code) {
            output = action.payload;
            output.id = action.payload.code;
            output.creationDate = changeDateTimeFormat(action.payload.createTime);
          }
          return output;
        });
        state.allDepartments = state.allDepartments.map((val) => {
          let output = val;
          if (val.id === action.payload.code) {
            output = {
              label: action.payload.name,
              departmentId: action.payload.id,
              id: action.payload.code,
            };
          }
          return output;
        });
      } else {
        state.departments = [
          {
            ...action.payload,
            id: action.payload.code,
            creationDate: changeDateTimeFormat(action.payload.createTime),
          },
          ...state.departments,
        ];
        state.allDepartments = [
          ...state.allDepartments,
          {
            label: action.payload.name,
            departmentId: action.payload.id,
            id: action.payload.code,
          },
        ];
        state.totalDataCount += 1;
        if (state.departments.length > 10) {
          state.departments.pop();
        }
      }
    },
    deleteDepartment: (state, action: any) => {
      state.departments = state.departments.filter((val) => val.code !== action.payload);
      state.totalDataCount -= 1;
      state.allDepartments = state.allDepartments.filter((val) => val.id !== action.payload);
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDepartmentList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchDepartmentList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo = action.payload.data.pageNo;

          state.departments = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchDepartmentList.rejected, (state: any) => {
        state.status = 'success';
      });
    builder
      .addCase(fetchAllDepartmentList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAllDepartmentList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.allDepartments = action.payload.data;
        }
        state.status = 'success';
      })
      .addCase(fetchAllDepartmentList.rejected, (state: any) => {
        state.status = 'success';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, addDepartment, deleteDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
