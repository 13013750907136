import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

// Shared components
import InputField from '../../shared-components/input-field/input-field';
import Button from '../../shared-components/button/button';

// Redux dispatch for setting error notifications
import { useAppDispatch } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

// ForgotPassword component
function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  // Validate form input
  const validateForm = (): void => {
    if (!email || !password) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  };

  // Handle input change
  const handleEmailChange = (name: string, value: any): void => {
    setEmail(value);
  };
  const handlePasswordChange = (name: string, value: any): void => {
    setPassword(value);
  };
  // Handle form submission
  const handleSubmit = () => {
    setDisableButton(true);
    if (!email.includes('@')) {
      setErrors(true);
      setErrorMessage('Invalid email address');
    } else {
      setErrors(false);
      setErrorMessage('');
      dispatch(
        setNotificationError({
          error: 'Password reset link has been sent to your email',
          status: 200,
        }),
      );
      navigate('/login');
    }
  };
  useEffect(() => {
    validateForm();
  }, [email, password]);

  return (
    <div className="forgot-password-maincontainer">
      <div className="forgot-password-container">
        <h2>{t('AICTEC SECURITY SYSTEM')}</h2>
        <p>{t('Forgot Password')}</p>
        <form>
          <InputField
            name="email"
            label={t('email')}
            id="email"
            type="email"
            value={email}
            onChangeFunction={handleEmailChange}
            className="email-input"
            required
            message={t('invalidMailID')}
            disabled={false}
          />
          <InputField
            name="password"
            label={t('Reset password')}
            id="password"
            type="password"
            value={password}
            onChangeFunction={handlePasswordChange}
            className="email-input"
            required
            message={t('invalidMailID')}
            disabled={false}
          />
          {errors && <div className="error-message">{errorMessage}</div>}
          <Button
            title={t('Send ResetLink')}
            className="btn-primary full-size"
            onClickFunction={handleSubmit}
            disabled={disableButton}
          />
        </form>
        <div className="back-to-login">
          <Link to="/login">{t('Back To Login')}</Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
