/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import { ReduxInterface } from '../../interface/musterPoint.interface';
import musterPoint from '../../utils/api/AdvancedFunctions/musterPoint';

export const fetchMusterPoint = createAsyncThunk(
  'acc/fetchMusterPoint',
  async (data: any, { dispatch }) => {
    const response = await musterPoint
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        return error;
      });
    return response;
  },
);

export const fetchDeptList = createAsyncThunk('fetchDeptList', async (data: any, { dispatch }) => {
  try {
    const response = await musterPoint.deptList(data);
    return {
      ...response.data,
      data: {
        list: response.data.data,
        size: response.data.data.length,
        // ...data,
      },
    };
  } catch (error: any) {
    dispatch(setNotificationError(error.response.data));
    throw error;
  }
});

const initialState: ReduxInterface = {
  pointList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  selectId: '',
  departments: [],
  deptStatus: 'success',
  deptTotalDataCount: 0,
  deptCurrentPageNo: 0,
  deptSearchFilter: {},
};

export const musterPointSlice: any = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    deleteAntiPassBack: (state, action: any) => {
      state.pointList = state.pointList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    deleteDept: (state, action: any) => {
      state.departments = state.departments.filter((val: any) => val.id !== action.payload);
      state.deptTotalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    setDeptSearchFilter: (state, action: any) => {
      state.deptSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchMusterPoint.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchMusterPoint.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;

          state.pointList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchMusterPoint.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDeptList.pending, (state) => {
        state.deptStatus = 'loading';
      })
      .addCase(fetchDeptList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.deptTotalDataCount = action.payload.data.size;
          state.deptCurrentPageNo = action.payload.data.pageNo;

          state.departments = action.payload.data.list;
        }
        state.deptStatus = 'success';
      })
      .addCase(fetchDeptList.rejected, (state) => {
        state.deptStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteAntiPassBack, setDeptSearchFilter, deleteDept } =
  musterPointSlice.actions;

export default musterPointSlice.reducer;
