import { post } from '../base';

export default {
  getPersonList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/getList`,
      {},
      { ...data },
    );
  },
  syncPersonLevel: (id: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/syncPersonLevel?personIds=${id}`,
      {},
    );
  },
  getPersonSelectLevel: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/getPersonSelectLevel`,
      data,
      {
        pageNo: 1,
        pageSize: 50,
      },
    );
  },
  getPersonLevel: (data: any = {}) => {
    console.log(data);
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/getPersonLevel`,
      {
        personId: data.personId,
      },
      { pageNo: 1, pageSize: 50 },
    );
  },
  addToLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/addLevel`, {}, data);
  },
  deleteLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/delLevel`, {}, data);
  },
};
