import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/alarmMonitor/historyList`,
      {},
      { ...data },
    );
  },
  export: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Acc/api/alarmMonitor/exportHistory`,
      'POST',
      data,
    );
  },
};
