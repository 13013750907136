import React, { useEffect, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

// Use interface
import { PermissionInterface, Level } from '../../../interface/permissions.interface';
import AddLevelModal from './add-level-modal';
import ModalBox from '../../../shared-components/modal-box/modal-box';

function ElevatorControl({ onChangeFunction = () => {}, values }: PermissionInterface) {
  const { t } = useTranslation();
  const selectData = [
    {
      label: t('value1'),
      id: 'value1',
    },
    {
      label: t('value2'),
      id: 'value2',
    },
  ];

  const [value, setValue] = useState({
    ...values,
    general: values.general ?? false,
    levelSettings: [],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('Add to Levels'),
    content: <>hi</>,
  });
  const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);
  const [isLevelSettingsVisible, setIsLevelSettingsVisible] = useState(false);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const handleAddLevel = (newLevels: any[]) => {
    console.log('New Levels Added:', newLevels);
    setSelectedLevels(newLevels);
    setIsLevelSettingsVisible(true);
  };

  const checkboxTreeData = [
    { id: '1', label: t('Level 3'), value: 'my test', children: [] },
    { id: '2', label: t('My Testing'), value: '12 hours', children: [] },
    // Add other items as needed
  ];

  const checkboxData = selectedLevels.map((level) => ({
    label: level.levelName,
    value: level.id,
    id: level.id,
  }));

  useEffect(() => onChangeFunction(value), [value]);

  return (
    <div className="permissions">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="permissions-form">
        <div className="row-form">
          <div className="item item1">
            <label htmlFor="level-settings" className="radio-label">
              {t('Level Settings')}
              <span>*</span>
            </label>
            <div className="level-settings-container">
              <div className={`level-settings ${isLevelSettingsVisible ? 'hide-add-button' : ''}`}>
                <button
                  className="centered-add-button"
                  type="button"
                  onClick={() => {
                    setModalOpen(true);
                    setModalView({
                      title: t('Add to Levels'),
                      content: (
                        <AddLevelModal
                          onClose={() => setModalOpen(false)}
                          onAddLevel={handleAddLevel}
                          checkboxTreeData={checkboxTreeData}
                        />
                      ),
                    });
                  }}>
                  {t('Add Levels')}
                </button>
              </div>
              {isLevelSettingsVisible && (
                <div className="level-settings-body">
                  <CheckBoxInputField
                    label=""
                    name="levelSettings"
                    alignment="column"
                    position="left"
                    required
                    data={checkboxData}
                    value={value.levelSettings || []}
                    onChangeFunction={(name, changeValue) => {
                      onChange(name, changeValue);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="item">
            <RadioButtonField
              label={t('superUser')}
              name="superUser"
              alignment="row"
              required
              data={[
                {
                  label: t('yes'),
                  id: 'yes',
                  value: 'yes',
                },
                {
                  label: t('no'),
                  id: 'no',
                  value: 'no',
                },
              ]}
              value={value.superUser}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="attendanceMode"
              label={t('attendanceMode')}
              id="attendanceMode"
              value={value.attendanceMode}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="deviceOperationRole"
              label={t('deviceOperationRole')}
              id="deviceOperationRole"
              value={value.deviceOperationRole}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
          <div className="item" />
          <div className="item">
            <CheckBoxInputField
              label=""
              name="type"
              alignment="column"
              position="right"
              required
              data={[
                {
                  label: t('extendPassage'),
                  id: 'extendPassage',
                  value: 'extendPassage',
                },
                {
                  label: t('accessDisabled'),
                  id: 'accessDisabled',
                  value: 'accessDisabled',
                },
                {
                  label: t('setValidTime'),
                  id: 'setValidTime',
                  value: 'setValidTime',
                },
              ]}
              value={value.type}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="verificationRole"
              label={t('verificationRole')}
              id="verificationRole"
              value={value.verificationRole}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
          <div className="item">
            <SelectBoxField
              name="verificationRole"
              label={t('verificationRole')}
              id="verificationRole"
              value={value.verificationRole}
              required
              onChangeFunction={(name, val) => {
                onChange(name, val.id);
              }}
              data={selectData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElevatorControl;
