import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputField from '../../../../shared-components/input-field/input-field';
import { AttentionIcon } from '../../../../assets/svgicons/svgicon';

interface InitialValueProps {
  popupVideo: any;
  video: any;
  displayTime: string;
  beforeEvent: string;
  afterEvent: string;
  capture: any;
  monitoringPage: any;
  monitorDisplayTime: string;
}
function GlobalVideoLinkage() {
  const { t } = useTranslation();
  const [value, setValue] = useState<InitialValueProps>({
    popupVideo: [],
    video: [],
    displayTime: '',
    beforeEvent: '',
    afterEvent: '',
    capture: [],
    monitoringPage: [],
    monitorDisplayTime: '',
  });
  const onChangeValue = (name: string, data: any) => {
    setValue((prev: InitialValueProps) => ({
      ...prev,
      [name]: data,
    }));
  };
  useEffect(() => {
    if (!value.capture?.[0]) {
      setValue((prev: InitialValueProps) => ({
        ...prev,
        monitoringPage: [],
        monitorDisplayTime: '',
      }));
    }
  }, [value.capture]);
  return (
    <div className="flex-row column">
      <div className="flex-row">
        <CheckBoxInputField
          name="popupVideo"
          data={[
            {
              label: t('popupVideo'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={value?.popupVideo}
          onChangeFunction={onChangeValue}
        />
        <CheckBoxInputField
          name="video"
          data={[
            {
              label: t('video'),
              id: 'video',
              value: 'video',
            },
          ]}
          value={value?.video}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="row row-3">
        <InputField
          name="displayTime"
          label={t('displayTime')}
          id="displayTime"
          type="number"
          value={value.displayTime}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(5-50)')}
          disabled={value?.popupVideo?.length === 0}
        />
        <InputField
          name="beforeEvent"
          label={t('beforeEvent')}
          id="beforeEvent"
          type="number"
          value={value.beforeEvent}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(10-80)')}
          disabled={value?.video?.length === 0}
        />
        <InputField
          name="afterEvent"
          label={t('afterEvent')}
          id="afterEvent"
          type="number"
          value={value.afterEvent}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(10-80)')}
          disabled={value?.video?.length === 0}
        />
      </div>
      <div className="flex-row">
        <CheckBoxInputField
          name="capture"
          data={[
            {
              label: t('capture'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={value?.capture}
          onChangeFunction={onChangeValue}
        />
        <CheckBoxInputField
          name="monitoringPage"
          data={[
            {
              label: t('inTheMonitoringPageImmediatelyPopUp'),
              id: 'yes',
              value: 'yes',
              disabled: value?.capture?.length === 0 ? 'disabled' : '',
            },
          ]}
          value={value?.monitoringPage}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="row row-3">
        <InputField
          name="monitorDisplayTime"
          label={t('displayTime')}
          id="monitorDisplayTime"
          type="number"
          value={value.monitorDisplayTime}
          onChangeFunction={onChangeValue}
          validation
          message={t('s(10-60)')}
          disabled={value?.monitoringPage?.length === 0}
        />
      </div>
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t('videoLinkageAttentionMessage')}
      </div>
    </div>
  );
}

export default GlobalVideoLinkage;
