import { post, fetch } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/listByPage`,
      {},
      { ...data },
    );
  },
  exportLevel: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/exportLevelPerson`,
      'POST',
      data,
    );
  },
  getListLevel: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/selectLevelPersonList`,
      data,
      {
        pageNo: 1,
        pageSize: 50,
      },
    );
  },
  addPersonToLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/addPerson`, {}, data);
  },
  getLevelPerson: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/getLevelPerson`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  deleteLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByLevel/delPerson`, {}, data);
  },
};
