import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/dayaLight/listByPage`, {}, { ...data });
  },
  timeZoneList: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/baseDictionary/selectList?key=${data}`, {}),

  edit: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/dayaLight/save`, data, {});
  },

  deleteDaylight: (id: string = '') =>
    post(`${process.env.REACT_APP_API_URL}/Acc/api/dayaLight/delete?ids=${id}`, {}),

  saveDaylight: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/dayaLight/save`, data, {});
  },
};
