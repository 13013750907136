import { get, post } from '../base';

export default {
  getIoBoard: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/ioboard/list`, {}, { ...data });
  },
  deleteIoBoard: (id: string = '') =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/ioboard/delete?ids=${id}`, {}),
  isExistAlias: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/ioboard/isExistAlias?alias=${data}`, {}),
  isExistIpAddress: (ipAddress: string, devId: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/ioboard/isExistAddress?commAddress=${ipAddress}&devId=${devId}`,
      {},
    ),
  selectDeviceList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/ioboard/selectDeviceList`,
      {},
      { ...data, pageSize: 50 },
    );
  },
  getDevProtocolTypeSelect: (data: string) =>
    get(
      `${process.env.REACT_APP_API_URL}Acc/api/ioboard/getDevProtocolTypeSelect?devId=${data}`,
      {},
    ),
  getExtBoardTypeSelects: (data: any) =>
    get(
      `${process.env.REACT_APP_API_URL}Acc/api/ioboard/getExtBoardTypeSelects?devId=${data.devId}&devProtocolType=${data.devProtocolType}`,
      {},
    ),
  saveIoBoard: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/ioboard/save`, data, {});
  },

  delete: (data: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/ioboard/delete/${data}`, {});
  },
};
