import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import { isValidMultipleEmail } from '../../../shared-components/form/validation';

type ParametersProps = {
  rtmMail: string;
  rtmPhoneNumber: string;
};

interface TabChildProps {
  value: ParametersProps;
  onChangeValue: (data: ParametersProps) => void;
  type: string;
}

function CommonMonitoringTab({ value, onChangeValue, type }: TabChildProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(value);
  const handleChange = (name: string, data: any) => {
    const updatedValues = {
      ...values,
      [name]: data,
    };
    setValues(updatedValues);
    onChangeValue(updatedValues);
  };
  return (
    <div className="parameters-inputs">
      {type === 'email' && (
        <InputTextAreaField
          id="rtmMail"
          value={values?.rtmMail}
          label={t('RecipientMailbox')}
          name="rtmMail"
          placeHolder="Example: 123@xxx.com;256@xxx.com"
          onChangeFunction={(name, data) => handleChange(name, data)}
          errorStatus={values?.rtmMail !== '' && !isValidMultipleEmail(values?.rtmMail)}
          message="Please enter a valid mail address"
        />
      )}
      {type === 'phoneNumber' && (
        <InputTextAreaField
          id="rtmPhoneNumber"
          value={values?.rtmPhoneNumber}
          label={t('recipientsPhoneNumber')}
          name="rtmPhoneNumber"
          placeHolder="Example; 8610254210;8612359874"
          onChangeFunction={(name, data) => handleChange(name, data)}
        />
      )}
      <div className="text-danger flex-row center">
        <AttentionIcon />
        <span className="error-msg">{t('multipleEmailMessage')}</span>
      </div>
    </div>
  );
}

export default CommonMonitoringTab;
