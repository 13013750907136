import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

// Use shared components
import TopHeader from '../../../../shared-components/top-header/top-header';
import CardBox from '../../../../shared-components/card/card';
import DataTable from '../../../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
// Import slice actions
import { fetchAlarmLogList, setSearchFilter } from '../../../../redux/slices/AlarmLogReportSlices';
import ExportData from './components/export-data';
import { FilterProps } from '../../../../interface/common.interface';

function OperationLog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { logList, status, totalDataCount, searchFilter, currentPageNo } = useAppSelector(
    (state: any) => state.alarmLogs,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: 'hi',
    content: <>hi</>,
  });

  const columns = [
    {
      accessorKey: 'eventTime',
      header: t('operator'),
    },
    {
      accessorKey: 'devAlias',
      header: t('time'),
    },
    {
      accessorKey: 'eventPointName',
      header: t('ipAddress'),
    },
    {
      accessorKey: 'eventName',
      header: t('module'),
    },
    {
      accessorKey: 'persPerson',
      header: t('object'),
    },
    {
      accessorKey: 'belongAreas',
      header: t('operation'),
    },
    {
      accessorKey: 'priority',
      header: t('operationDetails'),
    },
    {
      accessorKey: 'status',
      header: t('result'),
    },
    {
      accessorKey: 'timeMs',
      header: t('timeMs'),
    },
  ];

  const fetchData = async (params: FilterProps) => {
    if (status === 'success') {
      dispatch(fetchAlarmLogList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  const fetchNextData = (): any => {
    fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
  };

  const exportData = () => {
    setModalOpen(false);
  };

  const moreActionButton = [
    {
      title: t('export'),
      clickFunction: (): void => {
        setModalView({
          title: t('export'),
          content: (
            <ExportData closeModal={() => setModalOpen(false)} submitFunction={exportData} />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  useEffect(() => {
    if (logList.length === 0) {
      // fetchData({ pageNo: 1 });
    }
  }, []);

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('operationLog'),
          },
        ]}
      />
      <CardBox
        header=""
        title={t('listOfOperation')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={false}
            buttonLabel=""
            buttonClass=""
            buttonStatus={false}
          />
        }>
        <DataTable
          actionButtonStatus={false}
          columns={columns}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          // assignSelectedData={selectedValues}
          // getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive={false}
          fetchNextPage={fetchNextData}
          isFetching={status === 'loading'}
          values={[]}
          fixedColumn={['eventTime']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default OperationLog;
