import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';

function RealTimeCommonPopup({ selected, allDoorIds, closeModal, modelName, message }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [doors, setDoor] = useState({ openInterval: '5' });
  const [disable, setDisable] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const submit = () => {
    if (!modalOpen) {
      setModalOpen(true);
      setDisable(true);
      const doorIdsToSend = selected.length > 0 ? selected : allDoorIds; // Door IDs
      const idsToSend = selected.length > 0 ? selected : allDoorIds; // Auxiliary IDS
      if (modelName === 'remoteOpening') {
        realtimeMonitoring
          .openDoor(doorIdsToSend, doors?.openInterval)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'remoteClosing') {
        realtimeMonitoring
          .closeDoor(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'cancelAlarm') {
        realtimeMonitoring
          .cancelAlarm(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'activateLockDown') {
        realtimeMonitoring
          .lockDoor(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'deActivateLockDown') {
        realtimeMonitoring
          .unLockDoor(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'remoteNormallyOpen') {
        realtimeMonitoring
          .normalOpenDoor(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'enableIntraday') {
        realtimeMonitoring
          .enableNormalOpenDoor(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'disableIntraday') {
        realtimeMonitoring
          .disableNormalOpenDoor(doorIdsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'remoteOpen') {
        realtimeMonitoring
          .openAuxOut(idsToSend, doors?.openInterval)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'remoteClose') {
        realtimeMonitoring
          .closeAuxOut(idsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      } else if (modelName === 'remoteNormalOpen') {
        realtimeMonitoring
          .auxOutNormalOpen(idsToSend)
          .then((res) => {
            dispatch(
              setNotificationError({
                error: t('theOperationSucceeded'),
                status: 200,
              }),
            );
            closeModal();
            if (res.data.code === 0) {
              setTimeout(() => {
                setDisable(false);
              }, 3500);
            }
          })
          .catch((error) => {
            setDisable(false);
            dispatch(
              setNotificationError({
                error: error.message,
                status: error.status,
              }),
            );
          });
      }
    }
  };
  return (
    <div className="door-modals">
      <div className="flex-row column">
        <h4 className="label">
          {t('target')} : <span>{selected.length > 0 ? t('selected') : t('allDoors')}</span>
        </h4>
        {modelName === 'remoteOpening' || modelName === 'remoteOpen' ? (
          <div className="input-wrap">
            <InputField
              name="door"
              label={t('door')}
              id="name"
              type="text"
              value={doors.openInterval}
              innerIconStatus={false}
              onChangeFunction={(field: string, value: any) =>
                setDoor({ ...doors, openInterval: value })
              }
              validation
              message={t('second(1-254)')}
            />
          </div>
        ) : (
          <span className="message">{message}</span>
        )}
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={submit}
            title={t('ok')}
            className="btn-primary"
            disabled={disable || !doors.openInterval}
          />
        </div>
      </div>
    </div>
  );
}

export default RealTimeCommonPopup;
