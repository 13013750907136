import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAppDispatch } from '../../../../../redux/store';
import Button from '../../../../../shared-components/button/button';
import InputField from '../../../../../shared-components/input-field/input-field';

interface ModalType {
  closeModal: () => void;
}

function FTPServerSettings({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    ftpAddr: '',
    ftpPort: '',
    ftpDir: '',
    ftpUsername: '',
    ftpPwd: '',
  });
  const handleChange = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <div className="ftp-server-settings flex-row column">
      <div className="flex-row">
        <InputField
          name="ftpAddr"
          label={t('ftpServerAddress')}
          id="ftpAddr"
          type="text"
          value={values.ftpAddr}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
        <InputField
          name="ftpPort"
          label={t('port')}
          id="ftpPort"
          type="text"
          value={values.ftpPort}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
      </div>
      <div className="flex-row">
        <InputField
          name="ftpUsername"
          label={t('userName')}
          id="ftpUsername"
          type="text"
          value={values.ftpUsername}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
        <InputField
          name="ftpPwd"
          label={t('password')}
          id="ftpPwd"
          type="text"
          value={values.ftpPwd}
          innerIconStatus={false}
          onChangeFunction={handleChange}
          required
        />
      </div>
      <div className="single-row">
        <InputField
          name="ftpDir"
          label={t('folderLocation')}
          id="ftpDir"
          type="text"
          value={values.ftpDir}
          innerIconStatus={false}
          onChangeFunction={handleChange}
        />
      </div>
      <div className="test-btn">
        <Button
          onClickFunction={closeModal}
          title={t('testConnection')}
          className="btn-outline-primary"
          // disabled={disabled}
        />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={closeModal}
          title={t('done')}
          className="btn-primary"
          // disabled={disabled}
        />
      </div>
    </div>
  );
}

export default FTPServerSettings;
