import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import AddDayLight from './components/add-daylight-saving-time';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../shared-components/modal-box/modal-box';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import dayLightSavingTimeApi from '../../utils/api/Devices/daylightSavingTime';
import {
  fetchDaylightSavingTime,
  setSearchFilter,
} from '../../redux/slices/DayLightSavingTimeSlice';

import { AddIcon } from '../../assets/svgicons/svgicon';
import { AddDatainterface } from '../../interface/accessDevice.interface';

function Daylightsavingtime() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { daylightSavingTime, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.daylightSavingTime,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('daylight'),
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchDaylightSavingTime({ ...params }));
    }
  };
  const [selectedCards, setSelectedCards] = useState<string[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('dstName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'startTime',
      header: t('startTime'),
    },
    {
      accessorKey: 'endTime',
      header: t('endTime'),
    },
    {
      accessorKey: 'timeZone',
      header: t('timeZone'),
    },
  ];

  handleEditOpen = (deviceConfig: AddDatainterface) => {
    console.log(deviceConfig);
    if (!modalOpen) {
      setModalOpen(true);
      setModalView({
        title: t('edit'),
        content: (
          <AddDayLight
            initialData={{
              name: deviceConfig.name,
              startTime: deviceConfig.startTime,
              endTime: deviceConfig.endTime,
              timeZone: deviceConfig.timeZone,
              id: deviceConfig.id,
              bussinessId: deviceConfig.bussinessId,
            }}
            closeModal={() => setModalOpen(false)}
            saveChanges={async () => {
              const req = {
                name: deviceConfig.name,
                startTime: deviceConfig.startTime,
                endTime: deviceConfig.endTime,
                timeZone: deviceConfig.timeZone,
                id: deviceConfig.id,
                bussinessId: deviceConfig.bussinessId,
              };
              try {
                const response = await dayLightSavingTimeApi.edit(req);
                if (response.data.success) {
                  dispatch(
                    setNotificationError({
                      error: t('DayLight Saving Updated Successfully'),
                      status: 200,
                    }),
                  );
                  setModalOpen(false);
                } else {
                  dispatch(
                    setNotificationError({
                      error: response.data.msg,
                      status: response.data.ret,
                    }),
                  );
                }
              } catch (error) {
                dispatch(
                  setNotificationError({
                    error: t('failedToSaveChanges'),
                    status: 500,
                  }),
                );
              }
            }}
          />
        ),
      });
    }
  };
  // delete api
  const deleteRecord = (id: string) => {
    dayLightSavingTimeApi
      .deleteDaylight(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('deviceDeletedSuccessfully'),
              status: 200,
            }),
          );
          // dispatch(deleteWiegandFormat(id));
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  // Action buttons for Edit and Delete
  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('daylightsavingtime'),
          },
        ]}
      />
      <CardBox
        title={t('listOfDayLightSavingTime')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddDayLight closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen}
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedCards}
          getSelectedData={(val: any): any => setSelectedCards(val)}
          checkBoxActive
          enablePagination
          values={daylightSavingTime}
          fixedColumn={['cardNo']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Daylightsavingtime;
