import React, { useEffect, useState } from 'react';

// Use translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use redux functions
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { addCustomAttribute } from '../../../redux/slices/CustomAttributesSlices';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

// Use interface
import { AddAttributeInterFace } from '../../../interface/attribute.interface';

// Use api's calls
import customAttributeApi from '../../../utils/api/customAttribute';
import { descriptionValidation, nameValidation } from '../../../shared-components/form/validation';

import inputType from '../../../assets/json/input-type.json';

function AddAttribute({ closeModal, data }: AddAttributeInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [disableButton, setDisableButton] = useState(true);
  const [attributeValue, setAttributeValue] = useState(
    data || {
      attrName: '',
      attrValue: '',
      controlType: '',
      positionX: '',
      positionY: '',
      personType: 0,
      id: '',
    },
  );

  const onChangeValue = (name: string, value: any) => {
    setAttributeValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    const request: any = {
      attrName: attributeValue.attrName,
      attrValue: attributeValue.attrValue,
      controlType: attributeValue.controlType,
      positionX: attributeValue.positionX,
      positionY: attributeValue.positionY,
      personType: attributeValue.personType,
    };
    if (data) {
      request.id = attributeValue.id;
    }
    customAttributeApi
      .addCustomAttribute(request)
      .then((response) => {
        if (response.data.code === 0) {
          const message = data
            ? t('attributeUpdatedSuccessfully')
            : t('attributeAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          dispatch(addCustomAttribute(response.data.data));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    let i = 0;
    if (
      attributeValue.attrName === '' ||
      !nameValidation(1, 50, attributeValue.attrName) ||
      attributeValue.attrValue === '' ||
      !descriptionValidation(500, attributeValue.attrValue) ||
      attributeValue.controlType === '' ||
      attributeValue.personType === '' ||
      attributeValue.positionX === '' ||
      attributeValue.positionY === ''
    ) {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [attributeValue]);
  return (
    <div className="add-attribute">
      <div className="add-attribute-form">
        <div className="row row-3">
          <div className="col">
            <InputField
              name="attrName"
              label={t('displayName')}
              id="attrName"
              type="text"
              value={attributeValue.attrName}
              required
              errorStatus={
                attributeValue.attrName !== '' && !nameValidation(1, 50, attributeValue.attrName)
              }
              message={t('displayNameInvalid')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="positionX"
              label={t('row')}
              id="positionX"
              type="number"
              value={attributeValue.positionX}
              required
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col full-size">
            <InputTextAreaField
              name="attrValue"
              label={t('attributeValue')}
              id="attrValue"
              value={attributeValue.attrValue}
              required
              errorStatus={
                attributeValue.attrValue !== '' &&
                !descriptionValidation(500, attributeValue.attrValue)
              }
              message={t('attributeValueInvalid')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
              rows={10}
            />
          </div>
          <div className="col">
            <SelectBoxField
              name="controlType"
              label={t('inputType')}
              id="controlType"
              data={inputType}
              value={attributeValue.controlType}
              className=""
              required
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col">
            <InputField
              name="positionY"
              label={t('column')}
              id="positionY"
              type="number"
              value={attributeValue.positionY}
              required
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <RadioButtonField
              label={t('displayInPersonList')}
              name="personType"
              alignment="column"
              required
              data={[
                {
                  label: t('yes'),
                  id: '1',
                  value: 1,
                },
                {
                  label: t('no'),
                  id: '0',
                  value: 0,
                },
              ]}
              value={attributeValue.personType}
              onChangeFunction={onChangeValue}
            />
          </div>
        </div>
      </div>
      <div className="add-attribute-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={attributeValue.id ? t('update') : t('add')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default AddAttribute;
