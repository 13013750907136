import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import SearchIcon, { RightArrowIcon } from '../../../assets/svgicons/svgicon';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import { DeviceProps, SelectedDeviceProps } from '../../../interface/accessByDepartment.interface';
import { useAppDispatch } from '../../../redux/store';
import linkage from '../../../utils/api/AccessRule/linkage';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function SelectLinkage({
  searchValue = '',
  changeSearch,
  tableColumns,
  getSelected,
  closeModal = () => {},
}: SelectedDeviceProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [availableData, setAvailableData] = useState<DeviceProps[]>([]);
  const [selectedData, setSelectedData] = useState<DeviceProps[]>([]);
  // State to manage selected items in both tables
  const [selectRow, setSelectRow] = useState<any>(); // Track selected items in "available" table
  const [selectedTableRow, setSelectedTableRow] = useState<any>();

  // Handle moving a single item from available to selected
  const handleSelectTable = () => {
    if (selectRow) {
      const newSelected = availableData.find((item: any) => item.id === selectRow);
      const remainingData = availableData.filter((item: any) => item.id !== selectRow);

      if (newSelected) {
        setAvailableData([...remainingData, ...selectedData]);
        setSelectedData([newSelected]);
        setSelectRow(null); // Clear selection
      }
    }
  };

  // Handle moving a single item from selected back to available
  const handleSelectSelectedTable = () => {
    if (selectedTableRow) {
      const unselected = selectedData.find((item: any) => item.id === selectedTableRow);
      const remainingSelected = selectedData.filter((item: any) => item.id !== selectedTableRow);

      if (unselected) {
        setAvailableData([...availableData, unselected]);
        setSelectedData(remainingSelected);
        setSelectedTableRow(null);
      }
    }
  };
  const submit = () => {
    if (selectedData.length > 0) {
      getSelected?.(selectedData);
      closeModal();
    }
  };
  const fetchData = (params: any) => {
    linkage
      .selectDevicelist(params)
      .then((res) => {
        setAvailableData(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchData({
      pageNo: 1,
      type: 'noSelected',
    });
  }, []);
  return (
    <div className="flex-row column add-linkage-device">
      <div className="swap-table">
        <div className="search-input">
          <InputField
            name="name"
            label={t('search')}
            id="name"
            type="text"
            value={searchValue}
            innerIconStatus
            InnerIcon={SearchIcon}
            onChangeFunction={changeSearch}
          />
        </div>
        <div className="table-container">
          <div className="table-wrapper">
            <h4 className="title">{t('alternative')}</h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={availableData}
              radioStatus
              selectedValues={selectRow}
              onSelect={(selected) => setSelectRow(selected)}
            />
          </div>
          <div className="icons-wrapper">
            <div
              className={`icon ${!selectRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectTable}>
              <RightArrowIcon />
            </div>
            <div
              className={`icon backward ${!selectedTableRow ? 'disabled' : ''}`}
              role="none"
              onClick={handleSelectSelectedTable}>
              <RightArrowIcon />
            </div>
          </div>
          <div className="table-wrapper">
            <h4 className="title">{t('selected')}</h4>
            <Table
              classes="bordered"
              header={tableColumns}
              value={selectedData}
              radioStatus
              selectedValues={selectedTableRow} // Use an array with a single selected row
              onSelect={(selected) => setSelectedTableRow(selected)} // Update single selection
            />
          </div>
        </div>
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('done')} className="btn-primary" />
      </div>
    </div>
  );
}

export default SelectLinkage;
