import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import TabPages from '../../shared-components/tab-pages/tab-pages';
import Button from '../../shared-components/button/button';
import TransactionsTypes from './components/TransactionsTypes';
import AutoExport from './components/AutoExport';
import RealTimeMonitoring from './components/RealTimeMonitoring';
import CommonMonitoringTab from './components/CommonMonitoringTab';

interface ParametersProps {
  transactionType: string;
  interval: string;
  newTransaction: string[];
  autoExport: string;
  exportMode: string;
  exportByMonth: string;
  monthExportMode: string;
  maxHeight: string;
  rtmMail: string;
  rtmPhoneNumber: string;
}
function Parameters() {
  const { t } = useTranslation();
  const [parametersValue, setParameters] = useState<ParametersProps>({
    transactionType: '0',
    interval: '1',
    newTransaction: ['0:00'],
    autoExport: 'none',
    exportMode: '0',
    exportByMonth: '0',
    monthExportMode: '0',
    maxHeight: '140',
    rtmMail: '',
    rtmPhoneNumber: '',
  });
  const onChangeValue = (data: any) => {
    setParameters((prev: any) => ({
      ...prev,
      ...data,
    }));
  };
  const tabs = [
    {
      key: 'typeOfGettingTransactions',
      title: t('typeOfGettingTransactions'),
      content: <TransactionsTypes value={parametersValue} onChangeValue={onChangeValue} />,
    },
    {
      key: 'transactionsAutoExport',
      title: t('transactionsAutoExport'),
      content: <AutoExport value={parametersValue} onChangeValue={onChangeValue} />,
    },
    {
      key: 'realTimeMonitoring',
      title: t('realTimeMonitoring'),
      content: <RealTimeMonitoring value={parametersValue} onChangeValue={onChangeValue} />,
    },
    {
      key: 'alarmMonitoringRecipientsMailbox',
      title: t('alarmMonitoringRecipientsMailbox'),
      content: (
        <CommonMonitoringTab value={parametersValue} onChangeValue={onChangeValue} type="email" />
      ),
    },
    {
      key: 'alarmMonitoringRecipientsMobileNumber',
      title: t('alarmMonitoringRecipientsMobileNumber'),
      content: (
        <CommonMonitoringTab
          value={parametersValue}
          onChangeValue={onChangeValue}
          type="phoneNumber"
        />
      ),
    },
  ];

  const submit = () => {
    console.log(parametersValue);
  };
  return (
    <div className="acc-rule-parameters">
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessRule'),
          },
          {
            title: t('parameters'),
          },
        ]}
      />
      <CardBox title={t('parameters')}>
        <TabPages tabs={tabs} defaultNumber={0} />
        <div className="form-buttons-right">
          <Button onClickFunction={submit} title={t('done')} className="btn-primary" />
        </div>
      </CardBox>
    </div>
  );
}

export default Parameters;
