import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import accessLevel from '../../../utils/api/AccessRule/accessLevel';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchAccessLevels } from '../../../redux/slices/AccessLevelSlices';

type ModalType = {
  closeModal: () => void;
  selectedId: string[];
};
function RemoteClosingModal({ closeModal, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [levelDetails, setLevelDetails] = useState({
    userPassword: '',
  });
  const onChangeValue = (name: string, value: any) => {
    setLevelDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      const request = {
        ids: selectedId.join('&ids='),
      };
      const response = await accessLevel.closingDoor(request);
      if (response?.data?.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        dispatch(fetchAccessLevels({ pageNo: 1 }));
        closeModal();
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  return (
    <div className="remote-opening-modal">
      <div className="input-section">
        <InputField
          id="userPassword"
          name="userPassword"
          label="User Password"
          value={levelDetails.userPassword}
          onChangeFunction={onChangeValue}
          type="password"
          className=""
        />
      </div>

      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={!levelDetails.userPassword}
        />
      </div>
    </div>
  );
}

export default RemoteClosingModal;
