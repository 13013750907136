import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import Button from '../../../../shared-components/button/button';
import InputTextAreaField from '../../../../shared-components/input-text-area-field/input-text-area-field';
import Label from '../../../../shared-components/label/Label';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import globalAntiPassBack from '../../../../utils/api/AdvancedFunctions/globalAntiPassBack';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import {
  fetchGlobalAntiPassBackList,
  fetchGlobalApbZone,
} from '../../../../redux/slices/GlobalAntipassbackSlices';

interface AntiPassbackProps {
  closeModal: () => void;
  update?: any;
}
function AddAntiPassBack({ closeModal, update }: AntiPassbackProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { globalApbZone } = useAppSelector((state: any) => state.globalAntiPassBack);
  const [values, setValues] = useState(
    update || {
      name: '',
      zoneId: '',
      zoneDoors: [],
      apbType: '1',
      lockoutDuration: '',
      isResetApb: [],
      resetTime: '',
      applyTo: '0',
    },
  );
  const [disable, setDisable] = useState(false);
  const [zoneValidation, setZoneValidation] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const hours = Array.from({ length: 24 }, (_, i) => `${i}`);
  const hourOptions = hours.map((hour: string) => ({
    label: hour,
    id: hour,
    value: hour,
  }));
  const onChangeValue = async (name: string, value: any) => {
    setValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'zoneId') {
      try {
        const response1 = await globalAntiPassBack.validApb();
        const response = await globalAntiPassBack.validEntranceApb();
        const req = {
          accZoneId: value,
        };
        const judgeRes = await globalAntiPassBack.judge(req);
        if (response1.data.data && response.data.data) {
          setZoneValidation(!judgeRes.data);
        }
        const zoneRes = await globalAntiPassBack.getAccDoorByZone(req);

        if (zoneRes.data.success) {
          setValues((prev: any) => ({
            ...prev,
            zoneDoors: zoneRes.data.data,
          }));
        }
      } catch (error: any) {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      }
    }
    if (name === 'apbType') {
      if (value === '2' || value === '3') {
        setValues((prev: any) => ({
          ...prev,
          lockoutDuration: '10',
        }));
      } else {
        setValues((prev: any) => ({
          ...prev,
          lockoutDuration: '',
        }));
      }
    }
  };

  const onBlurName = async (name: string, value: any) => {
    if (name === 'name' && value !== '' && value !== update?.name) {
      const responseData = await globalAntiPassBack.isExistName({ name: value });
      setCheckExistName(responseData?.data);
    }
  };

  const submit = async () => {
    setDisable(true);
    try {
      const response1 = await globalAntiPassBack.save(values);
      if (response1.data.success) {
        dispatch(
          setNotificationError({
            error: response1.data.msg,
            status: 200,
          }),
        );
        setDisable(false);
        closeModal();
        dispatch(fetchGlobalAntiPassBackList({ pageNo: 1, pageSize: 10 }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    if (values?.name && values?.zoneId && values?.apbType && !zoneValidation) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [values]);

  console.log(update);

  useEffect(() => {
    dispatch(fetchGlobalApbZone({ zoneId: update?.id }));
  }, []);

  const zoneList =
    values?.zoneDoors?.length > 0
      ? values?.zoneDoors.map((item: any) => Object.values(item).join('\n')).join('\n')
      : 'None';

  return (
    <div className="add-global-antiPassBack">
      <div className="form-row">
        <div className="flex-row">
          <InputField
            id="name"
            name="name"
            label={t('name')}
            type="text"
            value={values.name}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlurName}
            errorStatus={values.name !== '' && !checkExistName}
            message={t('objectIsAlreadyExist')}
            required
          />
          <SelectBoxField
            id="zoneId"
            name="zoneId"
            label={t('zone')}
            value={values.zoneId}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={globalApbZone}
            required
          />
        </div>
        <div className="flex-row column controlZone">
          <Label title={t('theFollowingDoorsControlAccessInAndOutOfTheZone')} />
          <InputTextAreaField
            id="zoneDoors"
            value={zoneList}
            label=""
            name="zoneDoors"
            placeHolder=""
            rows={4}
            onChangeFunction={onChangeValue}
            errorStatus={zoneValidation}
            message="A single door cannot be used to control two relatively independent perimeter boundaries."
            disabled
          />
        </div>
        <SelectBoxField
          id="apbType"
          name="apbType"
          label={t('antiPassBackType')}
          value={values.apbType}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            {
              label: 'Logical Anti-Passback',
              id: '1',
            },
            {
              label: 'Time Anti-Passback',
              id: '2',
            },
            {
              label: 'Timed Logical Anti-Passback',
              id: '3',
            },
          ]}
          required
        />
        <InputField
          id="lockoutDuration"
          name="lockoutDuration"
          label={t('lockoutDuration')}
          type="number"
          value={values.lockoutDuration}
          onChangeFunction={onChangeValue}
          validation
          min={0}
          max={500}
          message="minute(1-500)"
          disabled={values.apbType === '1'}
        />
        <CheckBoxInputField
          name="isResetApb"
          data={[
            {
              label: 'Reset Anti-Passback Status',
              id: '0',
              value: '0',
            },
          ]}
          value={values?.isResetApb}
          onChangeFunction={onChangeValue}
          alignment="row"
        />
        <SelectBoxField
          id="resetTime"
          name="resetTime"
          label={t('antiPassbackTimeReset')}
          value={values.resetTime}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={hourOptions}
          disabled={!values?.isResetApb?.[0]}
        />
        <SelectBoxField
          id="applyTo"
          name="applyTo"
          label={t('applyTo')}
          value={values.applyTo}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={[
            {
              label: 'All Personnel',
              id: '0',
            },
            {
              label: 'Just Selected Personnel',
              id: '1',
            },
            {
              label: 'Exclude Selected Personnel',
              id: '2',
            },
          ]}
        />
      </div>

      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-outline-primary"
        />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

AddAntiPassBack.defaultProps = {
  update: {},
};

export default AddAntiPassBack;
