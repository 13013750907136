import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, CheckCircleIcon, CloseCircleIcon } from '../../../assets/svgicons/svgicon';
import AddGlobalInterlock from './components/add-global-interlock';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import AddDoors from './components/add-doors';
import glboalInterlockGroupApi from '../../../utils/api/AdvancedFunctions/globalInterlockGroup';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

import {
  InterlockLevelApiProps,
  InterlockDeleteLevelProps,
  InterlockPersonProps,
} from '../../../interface/globalInterlockGroup.interface';
import {
  fetchGlobalInterlockGroup,
  fetchInterlockDoorList,
  setSearchFilter,
  setLevelSearchFilter,
  deleteInterlockGroup,
  deleteLevels as deleteLevelList,
} from '../../../redux/slices/GlobalInterlockGroupSlices';

function GlobalInterlockGroup() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    globalInterlockList,
    groupId,
    levels,
    status,
    searchFilter,
    levelStatus,
    totalDataCount,
    levelTotalDataCount,
    personSearchFilter,
  } = useAppSelector((state: any) => state.globalInterlockGroup);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchGlobalInterlockGroup({ ...params }));
    }
  };
  const [selectedDoor, setSelectDoor] = useState<string[]>([]); // Add state to hold selected cards
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]); // Add state to hold selected cards

  const addDoors = (val: InterlockPersonProps): void => {
    setModalView({
      title: t('addDoors'),
      content: <AddDoors closeModal={() => setModalOpen(false)} groupId={val.id} />,
    });

    setModalOpen(true);
  };
  const handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      name: editData?.name,
      remarks: editData?.remark,
      isGroupInterlock: editData?.isGroupInterlock,
    };
    setModalOpen(true);
    setModalView({
      title: t('editDevice'),
      content: (
        <AddGlobalInterlock closeModal={() => setModalOpen(false)} data={{ ...updateData }} />
      ),
    });
  };
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('groupName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation();
            handleEditOpen(row.original);
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'doorCount',
      header: t('doorCount'),
      Cell: ({ row }: any) => {
        const { id } = row.original;
        const [doorCount, setDoorCount] = useState<number | undefined>(undefined);
        useEffect(() => {
          if (doorCount === undefined) {
            const fetchDoorCount = async () => {
              try {
                const response = await glboalInterlockGroupApi.convertAccGlobalInterlockCount({
                  value: id,
                });
                setDoorCount(response?.data?.data);
              } catch (error) {
                console.error('Error fetching door count:', error);
              }
            };
            fetchDoorCount();
          }
        }, [id, doorCount]);

        return doorCount || '0';
      },
    },
    {
      accessorKey: 'isGroupInterlock',
      header: t('groupInterlock'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.isGroupInterlock ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'remark',
      header: t('remark'),
    },
  ];

  const personnelColumn = [
    {
      accessorKey: 'doorName',
      header: t('doorName'),
    },
    {
      accessorKey: 'deviceName',
      header: t('ownedDevice'),
    },
  ];
  // level functions
  const fetchLevels = (params: InterlockLevelApiProps) => {
    if (params.groupId) {
      dispatch(fetchInterlockDoorList({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchLevels({
        pageNo: filter.pageIndex,
        groupId: selectedLevel?.[0],
        ...filter.filter,
      });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedLevel(id);
    fetchLevels({ groupId: id?.[0] });
  };

  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const deleteRecord = (id: any) => {
    glboalInterlockGroupApi
      .deleteGroup(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('globalInterlockGroupDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteInterlockGroup(id));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10, ...searchFilter.filter });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const deleteLevels = (params: InterlockDeleteLevelProps) => {
    const req = {
      ids: params.id,
      groupId: params.groupId,
    };
    glboalInterlockGroupApi
      .delDoors(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('doorDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deleteLevelList(params.doorId));
          setModalOpen(false);
          fetchLevels({
            pageNo: 1,
            groupId,
            doorId: params.doorId,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,

            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteGlobalInterlockGroup'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
    {
      label: t('addDoors'),
      function: (val: any) => addDoors(val),
    },
  ];

  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDoor'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('globalInterlockGroup'),
          },
        ]}
      />
      <CardBox
        header={t('globalInterlockGroup')}
        title={t('listOfGroup')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddGlobalInterlock closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedLevel} // Assign selected cards
          getSelectedData={(val: any): any => getSelected(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'}
          values={globalInterlockList} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Door</h4>
            <span className="sub-title">{t('listOfDoors')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={levelActionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={personSearchFilter}
            assignSelectedData={selectedDoor} // Assign selected cards
            getSelectedData={(val: any): any => setSelectDoor(val)} // Set selected cards
            checkBoxActive
            enablePagination
            isFetching={levelStatus === 'loading'} // Placeholder status
            values={levels}
            fixedColumn={['id']}
            totalRecords={levelTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default GlobalInterlockGroup;
