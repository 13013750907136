import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon } from '../../assets/svgicons/svgicon';
import AddHolidays from './components/add-holidays';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import holidaysApi from '../../utils/api/AccessRule/holidays';
import { setNotificationError } from '../../redux/slices/NotificationSlices';
import { fetchHolidays, setSearchFilter } from '../../redux/slices/HolidaysSlices';

function Holidays() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { holidays, status, totalDataCount, searchFilter } = useAppSelector(
    (state: any) => state.holidays,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchHolidays({ ...params }));
    }
  };
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('holidayName'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'holidayType',
      header: t('holidayType'),
    },
    {
      accessorKey: 'startDate',
      header: t('startDate'),
    },
    {
      accessorKey: 'endDate',
      header: t('endDate'),
    },
    {
      accessorKey: 'recurring',
      header: t('recurring'),
    },
    {
      accessorKey: 'remarks',
      header: t('remarks'),
    },
  ];
  handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      name: editData.name,
      holidayType: String(editData.holidayType),
      recurring: editData.recurring,
      remarks: editData.remarks,
      id: editData.id,
      startDate: editData.startDate,
      endDate: editData.endDate,
    };
    setModalOpen(true);
    setModalView({
      title: t('editHoliday'),
      content: <AddHolidays closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };

  const deleteRecord = (id: string) => {
    holidaysApi
      .deleteHolidays(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('holidayDeletedSuccessfully'),
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchData({
            pageNo: searchFilter.pageIndex,
            pageSize: searchFilter.pageSize,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteHoliday'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('holidays'),
          },
        ]}
      />
      <CardBox
        title={t('listOfHolidays')}
        rightSide={
          <CardRightButtonGroup
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddHolidays closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => setSelectedValues(val)}
          checkBoxActive
          enablePagination
          values={holidays} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
      </CardBox>
    </div>
  );
}

export default Holidays;
