import { get, post } from '../base/index';

export default {
  getAccessByDepartment: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByDepartment/listByPage`,
      {},
      { ...data },
    );
  },
  getDeptLevel: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByDepartment/getDeptLevel`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  getDeptSelectLevel: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByDepartment/getDeptSelectLevel`,
      data,
      { pageNo: 1, pageSize: 50 },
    );
  },
  addToLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByDepartment/addLevel`, {}, data);
  },
  deleteLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accLevelByDepartment/delLevel`, {}, data);
  },
  getLevelListByDept: (data: any = {}) => {
    return get(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByDepartment/getLevelListByDept/${data}`,
      {},
    );
  },
};
