/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import zone from '../../utils/api/AdvancedFunctions/zone';
import personAvailability from '../../utils/api/AdvancedFunctions/personAvailability';
import { ReduxInterface } from '../../interface/personAvailability.interface';

export const fetchPersonAvailability = createAsyncThunk(
  'acc/fetchPersonAvailability',
  async (data: any, { dispatch }) => {
    const response = await personAvailability
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchPersonList = createAsyncThunk(
  'fetchPersonList',
  async (data: any, { dispatch }) => {
    try {
      const response = await personAvailability.getPersonList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data,
          size: response.data.data.length,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
      throw error;
    }
  },
);

export const fetchGlobalApbZone = createAsyncThunk(
  'acc/fetchGlobalApbZone',
  async (data: any, { dispatch }) => {
    const response = await zone
      .getGlobalApbZoneList(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.map((val: any) => ({
              label: val.text,
              id: val.value,
            })),
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState: ReduxInterface = {
  personAvailabilities: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  globalApbZone: [],
  persons: [],
  selectId: '',
  personStatus: 'success',
  personTotalDataCount: 0,
  personCurrentPageNo: 0,
  personSearchFilter: {},
};

export const personAvailabilitySlice: any = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    editReader: (state, action: any) => {
      if (state.personAvailabilities.find((val: any) => val.id === action.payload.id)) {
        state.personAvailabilities = state.personAvailabilities.map((val: any) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
          }
          return output;
        });
      }
    },
    deleteAntiPassBack: (state, action: any) => {
      state.personAvailabilities = state.personAvailabilities.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    deletePerson: (state, action: any) => {
      state.persons = state.persons.filter((val: any) => val.id !== action.payload);
      state.personTotalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    setPersonSearchFilter: (state, action: any) => {
      state.personSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchPersonAvailability.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchPersonAvailability.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.size;

          state.personAvailabilities = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchPersonAvailability.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder.addCase(fetchGlobalApbZone.fulfilled, (state: any, action: any) => {
      // Add user to the state array
      if (action.payload.success) {
        state.globalApbZone = action.payload.data.list;
      }
    });
    builder
      .addCase(fetchPersonList.pending, (state) => {
        state.personStatus = 'loading';
      })
      .addCase(fetchPersonList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.personTotalDataCount = action.payload.data.size;
          state.personCurrentPageNo = action.payload.data.pageNo;

          state.persons = action.payload.data.list;
        }
        state.personStatus = 'success';
      })
      .addCase(fetchPersonList.rejected, (state) => {
        state.personStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearchFilter,
  editReader,
  deleteAntiPassBack,
  setPersonSearchFilter,
  deletePerson,
} = personAvailabilitySlice.actions;

export default personAvailabilitySlice.reducer;
