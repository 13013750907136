/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import alarmMonitoring from '../../utils/api/Devices/alarmMonitoring';

export const fetchMonitoringList = createAsyncThunk(
  'device/fetchMonitoringList ',
  async (_, { dispatch }) => {
    const response = await alarmMonitoring
      .getMonitoringList()
      .then((response1) => {
        const convertReadableDate = (date: number) => {
          const convertDate = new Date(date);

          // Manually formatting the date
          const year = convertDate.getFullYear();
          const month = String(convertDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
          const day = String(convertDate.getDate()).padStart(2, '0');
          const hours = String(convertDate.getHours()).padStart(2, '0');
          const minutes = String(convertDate.getMinutes()).padStart(2, '0');

          return `${year}-${month}-${day} ${hours}:${minutes}`;
        };
        return {
          ...response1.data,
          data: {
            list: response1.data.data.map((item: any) => {
              let status;
              if (item.status === 0) {
                status = 'UnConfirmed';
              } else if (item.status === 1) {
                status = 'Processing';
              } else {
                status = 'Confirmed';
              }
              let priority;
              if (item.priority === 0) {
                priority = 'Weak';
              } else if (item.priority === 1) {
                priority = 'Medium';
              } else if (item.priority === 2) {
                priority = 'Strong';
              } else if (item.priority === 3) {
                priority = 'Danger';
              }
              return {
                ...item,
                time: convertReadableDate(item.eventTime),
                device: item.devAlias,
                eventPoint: item.eventPointName,
                belongAreas: item.areaName,
                status,
                priority,
              };
            }),
            size: response1.data.data.length,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

interface ReaderReduxInterface {
  deviceMonitoringList: any; // Assuming that the 'wiegand' array contains objects of type 'Wiegantformat'
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  // totalDataCount: number;
  // currentPageNo: number;
  // searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  deviceMonitoringList: [],
  status: 'success',
  // totalDataCount: 0,
  // currentPageNo: 0,
  // searchFilter: {},
};

export const alarmMonitoringSlice: any = createSlice({
  name: 'reader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchMonitoringList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchMonitoringList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.deviceMonitoringList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchMonitoringList.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
// export const { setSearchFilter, editReader } = alarmMonitoringSlice.actions;

export default alarmMonitoringSlice.reducer;
