import React, { useEffect, useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use redux functions
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import {
  fetchAllDepartmentList,
  fetchDepartmentList,
} from '../../../redux/slices/DepartmentSlices';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use Interface
import {
  AddDepartmentInterFace,
  DepartmentReduxInterface,
} from '../../../interface/department.interface';

// Use api's calls
import departmentApi from '../../../utils/api/department';
import { nameValidation } from '../../../shared-components/form/validation';

function AddDepartment({ closeModal, data }: AddDepartmentInterFace) {
  let sortTime = true;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { allDepartments }: DepartmentReduxInterface = useAppSelector(
    (state: any) => state.departments,
  );

  const [disableButton, setDisableButton] = useState(true);
  const [departmentValue, setDepartmentValue] = useState(
    data || {
      code: '',
      name: '',
      parentCode: '',
      sortNo: '9999',
      update: false,
    },
  );

  const onChangeValue = (name: string, value: any) => {
    setDepartmentValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    const request = {
      code: departmentValue.code,
      name: departmentValue.name,
      parentCode: departmentValue.parentCode,
      sortNo: departmentValue.sortNo,
      update: departmentValue.update,
    };
    departmentApi
      .addDepartment(request)
      .then((response) => {
        if (response.data.code === 0) {
          const message = data
            ? t('departmentUpdatedSuccessfully')
            : t('departmentAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          dispatch(fetchDepartmentList({ pageNo: 1, pageSize: 10 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    let i = 0;
    if (
      departmentValue.code === '' ||
      !nameValidation(1, 50, departmentValue.code) ||
      departmentValue.name === '' ||
      !nameValidation(1, 50, departmentValue.name) ||
      departmentValue.sortNo === ''
    ) {
      i += 1;
    }
    if (i > 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [departmentValue]);

  useEffect(() => {
    if (allDepartments.length === 0 && sortTime) {
      sortTime = false;
      dispatch(fetchAllDepartmentList({}));
    }
  }, []);
  return (
    <div className="add-department">
      <div className="add-department-form">
        <div className="flex">
          <div className="col">
            <InputField
              name="code"
              label={t('departmentNumber')}
              id="code"
              type="text"
              value={departmentValue.code}
              required
              readonly={!!data}
              errorStatus={
                departmentValue.code !== '' && !nameValidation(1, 50, departmentValue.code)
              }
              message={t('departmentNumberInvalid')}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="sortNo"
              label={t('sort')}
              id="sortNo"
              type="number"
              value={departmentValue.sortNo}
              required
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <SelectBoxField
              name="parentCode"
              label={t('parentDepartment')}
              id="parentCode"
              data={allDepartments.map((val) => ({ ...val, label: val.label }))}
              value={departmentValue.parentCode}
              className=""
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
          <div className="col">
            <InputField
              name="name"
              label={t('departmentName')}
              id="name"
              type="text"
              value={departmentValue.name}
              required
              innerIconStatus={false}
              errorStatus={
                departmentValue.name !== '' && !nameValidation(1, 50, departmentValue.name)
              }
              message={t('departmentNameInvalid')}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col" />
          <div className="col" />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default AddDepartment;
