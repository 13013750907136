import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../redux/store';
import TopHeader from '../../shared-components/top-header/top-header';
import CardBox from '../../shared-components/card/card';
import DataTable from '../../shared-components/data-table/data-table';
import DeleteModalBox from '../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../shared-components/card-right-button-group/card-right-button-group';
import { ExportIcon } from '../../assets/svgicons/svgicon';
import AccessByLevelExport from './components/export-data';
import AddPersonnel from './components/add-personnel';
import accessByLevel from '../../utils/api/AccessRule/accessByLevel';
import {
  fetchAccessByLevels,
  fetchAccessDeptLevel,
  setLevelSearchFilter,
  deleteLevels as deleteLevelList,
} from '../../redux/slices/AccessByLevelSlices';
import { DepartmentParamsProps } from '../../interface/accessByDepartment.interface';
import { PersonProps } from '../../interface/accessByPerson.interface';
import { setNotificationError } from '../../redux/slices/NotificationSlices';

interface LevelProps {
  pageNo: number;
  personId: string;
  levelId?: string;
}
interface DeleteLevelProps {
  levelId: string;
  id: string;
}
function SetAccessByLevels() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    levelList,
    levelId,
    personId,
    levels,
    levelStatus: status,
    levelTotalDataCount: totalDataCount,
    levelSearchFilter: searchFilter,
  } = useAppSelector((state: any) => state.accessByLevel);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<any>([]);
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  // let handleEditOpen: any;
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('levelName'),
    },
    {
      accessorKey: 'authAreaName',
      header: t('areaName'),
    },
    {
      accessorKey: 'timeSegName',
      header: t('timeZone'),
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'personLastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];

  const handleEditOpen = (val: PersonProps): void => {
    setModalView({
      title: t('addPersonnel'),
      content: <AddPersonnel closeModal={() => setModalOpen(false)} personIds={val.id} />,
    });
    setModalOpen(true);
  };
  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchAccessByLevels({ ...params }));
    }
  };
  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchData({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.levelId) {
      dispatch(fetchAccessDeptLevel({ ...params }));
    }
  };
  // department functions
  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ levelId: id?.[0], personId: id?.[0], pageNo: 1 });
  };
  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setLevelSearchFilter(filter));
      fetchLevels({ pageNo: filter.pageIndex, pageSize: filter.pageSize, ...filter.filter });
    }
  };
  const deleteLevels = (params: DeleteLevelProps) => {
    const req = {
      levelId: params.levelId,
      personIds: params.id,
    };
    accessByLevel
      .deleteLevel(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('levelDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteLevelList(levelId));
          setModalOpen(false);
          fetchLevels({
            pageNo: 1,
            personId,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };
  const actionButtons = [
    {
      label: t('addPersonnel'),
      function: (val: any) => handleEditOpen(val),
    },
  ];
  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteLevels}
            />
          ),
        });
      },
    },
  ];
  const exportData = (data: any) => {
    console.log(data);
    setModalOpen(false);
  };
  const handleExport = () => {
    setModalOpen(true);
    setModalView({
      title: t('exportPersonnelOfAccessLevel'),
      content: (
        <AccessByLevelExport closeModal={() => setModalOpen(false)} submitFunction={exportData} />
      ),
    });
  };

  const selectedLevelName = selectedFullData.slice(selectedFullData.length - 1);

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('accessDevice'),
          },
          {
            title: t('setaccessByLevels'),
          },
        ]}
      />
      <CardBox
        header={t('accessLevels')}
        title={t('listOfAccessLevels')}
        rightSide={
          <CardRightButtonGroup
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus={false}
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <>hi</>,
              });
            }}
          />
        }>
        <div className="action-wrapper">
          <div className="action-btn" role="presentation" onClick={handleExport}>
            <ExportIcon />
            <span className="label">{t('exportPersonnelOfAccessLevel')}</span>
          </div>
        </div>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => getSelected(val)}
          getSelectedFullData={(val: any): any => setSelectedFullData(val)}
          checkBoxActive
          singleRowSelection
          enablePagination
          isFetching={status === 'loading'} // Placeholder status
          values={levelList} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Browse Personnel ({selectedLevelName?.[0]?.personPin}) From Levels</h4>
            <span className="sub-title">{t('listOfPersonnel')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={levelActionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={searchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            checkBoxActive
            isFetching={status === 'loading'} // Placeholder status
            values={levels}
            fixedColumn={['id']}
            totalRecords={0}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default SetAccessByLevels;
