import React from 'react';

type LabelProps = {
  title: string;
  required?: boolean;
};
function Label({ title, required }: LabelProps) {
  return (
    <div className="label">
      <span className="title">{title}</span>
      {required && <span className="required"> *</span>}
    </div>
  );
}

Label.defaultProps = {
  required: false,
};

export default Label;
