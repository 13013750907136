import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputField from '../../../shared-components/input-field/input-field';
import { EditEventTypeProps, DropdownItem } from '../../../interface/accessEventType.interface';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import eventTypeApi from '../../../utils/api/Devices/eventType';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

function EditEventType({ initialData, closeModal, saveChanges }: EditEventTypeProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editEventType, setEditEventType] = useState(initialData);
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [disable] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setEditEventType((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Fetch dropdown data from API
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await eventTypeApi.getMediaFileList();
        const formattedData: DropdownItem[] = response.data.item.map(
          (item: { text: string; id: string | number }) => ({
            label: item.text,
            id: item.id,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
  }, []);

  return (
    <div className="edit-eventType">
      <div className="grid-row">
        <div className="col">
          <InputField
            name="deviceName"
            label={t('deviceName')}
            id="name"
            type="text"
            value={editEventType.deviceName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
          />
        </div>
        <div className="col">
          <InputField
            name="eventName"
            label={t('eventName')}
            id="name"
            type="text"
            value={editEventType.eventName}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            disabled
          />
        </div>
        <div className="col">
          <InputField
            name="eventNumber"
            label={t('eventNumber')}
            id="name"
            type="number"
            value={editEventType.eventNumber}
            disabled
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="eventLevel"
            label={t('eventLevel')}
            id="eventLevel"
            value={editEventType.eventLevel}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('normal'), id: 'normal' },
              { label: t('exception'), id: 'exception' },
              { label: t('alarm'), id: 'alarm' },
            ]}
            className=""
            disabled
          />
        </div>
        {editEventType?.eventLevel === 'Alarm' && (
          <div className="col">
            <SelectBoxField
              name="priority"
              label={t('priority')}
              id="priority"
              value={editEventType.priority}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={[
                { label: t('weak'), id: 'weak' },
                { label: t('medium'), id: 'medium' },
                { label: t('strong'), id: 'strong' },
                { label: t('danger'), id: 'danger' },
              ]}
              className=""
              required
            />
          </div>
        )}
        <div className="col">
          <SelectBoxField
            name="eventSound"
            label={t('eventSound')}
            id="eventSound"
            value={editEventType.eventSound}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={dropdownData}
            className=""
          />
        </div>
      </div>
      <div className="switch-wrap">
        <CheckBoxInputField
          name="copyToAll"
          data={[
            {
              label: t('copyAllDevices'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={editEventType.copyToAll}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={() => {
            saveChanges(editEventType);
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default EditEventType;
