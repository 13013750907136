import React from 'react';

function SpinnerLoader({ stopLoader }: any) {
  return (
    <div className="spinner-loader">
      <div className={`spinner ${stopLoader ? 'stop-loader' : ''}`} />
    </div>
  );
}

export default SpinnerLoader;
