import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import Label from '../../../shared-components/label/Label';
import SwapTable from '../../../shared-components/swapTables/swapTable';

interface TableDataProps {
  contacts: string;
}
function Line() {
  const { t } = useTranslation();
  const tableColumns = [{ label: t('contacts'), id: 'contacts' }];
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [device, setDevice] = useState<TableDataProps[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<TableDataProps[]>([]);
  const changeSearch = () => {
    console.log('search');
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: TableDataProps[], newSelectedData: TableDataProps[]) => {
    setDevice(newAvailableData); // Update available data
    setSelectedDevice(newSelectedData); // Update selected data
  };
  const addPartition = () => {
    setModalView({
      title: t('addPartition'),
      content: (
        <div className="add-partition">
          <SwapTable
            searchValue=""
            changeSearch={changeSearch}
            tableColumns={tableColumns}
            tableData={device}
            selectedTableData={selectedDevice}
            onTableSwap={onTableSwap}
          />
        </div>
      ),
    });
    setModalOpen(true);
  };
  return (
    <>
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="intrusion">
        <div className="partition">
          <div className="header">
            <Label title={t('contacts')} />
            <span className="add-link" role="presentation" onClick={addPartition}>
              {t('add')}
            </span>
          </div>
          <div className="box" />
        </div>
      </div>
    </>
  );
}

export default Line;
