import React from 'react';

// Use interface
import { CheckBoxTreeFieldInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { CheckBoxIcon, CheckBoxActiveIcon, DownArrowIcon } from '../../assets/svgicons/svgicon';

function CheckBoxTreeField({
  name,
  data,
  value,
  label,
  alignment,
  position,
  required,
  // message,
  // errorStatus,
  onChangeFunction,
}: CheckBoxTreeFieldInterFace) {
  const onChange = (selectName: string, selectValue: string, key: string): void => {
    let changValue = {};
    if (value[key].includes(selectValue)) {
      changValue = {
        ...value,
        [key]: value[key].filter((val: any) => val !== selectValue),
      };
    } else {
      changValue = {
        ...value,
        [key]: [...value[key], selectValue],
      };
    }

    onChangeFunction(selectName, changValue);
  };
  return (
    <div className="checkbox-tree-field">
      {label && (
        <label htmlFor={label} className="checkbox-label">
          {label}
          {required && <span>*</span>}
        </label>
      )}
      <div className="checkbox-tree-field-session">
        {data.map((checkboxTree: any) => (
          <div className="tree-session">
            <div className="title">
              <div
                className="checkbox-icon"
                onClick={() => {
                  const changValue = value;
                  if (changValue[checkboxTree.key].length === checkboxTree.children.length) {
                    changValue[checkboxTree.key] = [];
                  } else {
                    changValue[checkboxTree.key] = checkboxTree.children.map(
                      (val: any) => val.value,
                    );
                  }
                  onChangeFunction(name, changValue);
                }}
                role="presentation">
                {value[checkboxTree.key].length === checkboxTree.children.length ? (
                  <CheckBoxActiveIcon />
                ) : (
                  <CheckBoxIcon />
                )}
              </div>
              <span>{checkboxTree.label}</span>
              <DownArrowIcon color="#000" />
            </div>
            <div className={`checkbox-group-list ${alignment}`}>
              {checkboxTree.children.map((checkBox: any) => (
                <div
                  className={`checkbox-field ${position} ${checkBox.disabled}`}
                  onClick={() =>
                    checkBox.disabled !== 'disabled' &&
                    onChange(name, checkBox.value, checkboxTree.key)
                  }
                  role="presentation">
                  <div className="checkbox-icon">
                    {value[checkboxTree.key].includes(checkBox.value) ? (
                      <CheckBoxActiveIcon />
                    ) : (
                      <CheckBoxIcon />
                    )}
                  </div>
                  <div className="checkbox-title">{checkBox.label}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CheckBoxTreeField;
