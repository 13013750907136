import { fetch, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/transactionByDoor/list`, {}, { ...data });
  },

  personList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/transactionByDoor/personList`,
      {},
      { ...data, pageSize: 10 },
    );
  },

  export: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/transactionByDoor/export`, 'POST', data);
  },
};
