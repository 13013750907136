import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import device from '../../../utils/api/device';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { fetchDeviceList } from '../../../redux/slices/DeviceSlice';

function RegistrationDevice({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.device);
  const [registrationDevice, setRegistrationDevice] = useState('');

  const submit = () => {
    const request = { id: selectedValues.join('&'), isRegistrationSel: registrationDevice };
    device
      .registrationDevice(request)
      .then((response) => {
        if (response.data.success) {
          closeModal();
          dispatch(fetchDeviceList({ page: 1, pageSize: 10, ...searchFilter.filter }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  return (
    <div className="device-setup-popup">
      <div style={{ width: '56%', padding: '20px 0 30px 0' }}>
        <SelectBoxField
          name="registrationDevice"
          label={t('Set as Registration Device')}
          id="registrationDevice"
          value={registrationDevice}
          onChangeFunction={(name: string, value: any) => setRegistrationDevice(value?.id)}
          data={[
            { label: t('yes'), id: 'yes' },
            { label: t('no'), id: 'no' },
          ]}
          className=""
        />
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary" />
      </div>
    </div>
  );
}

export default RegistrationDevice;
