/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import ioBoard from '../../utils/api/Devices/IoBoard';

// Thunk for fetching the IoBoard list
export const fetchIoBoardList = createAsyncThunk(
  'acc/fetchIoBoardList',
  async (data: any, { dispatch }) => {
    const response = await ioBoard
      .getIoBoard(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((val: any) => ({
              ...val,
              id: val.id,
            })),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface IoBoardReduxInterface {
  ioBoards: any[];
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: IoBoardReduxInterface = {
  ioBoards: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const IoBoardSlice = createSlice({
  name: 'doors',
  initialState,
  reducers: {
    addIoBoard: (state, action: any) => {
      if (state.ioBoards.find((val: any) => val.id === action.payload.id)) {
        state.ioBoards = state.ioBoards.map((val: any) => {
          let output = val;
          if (val.id === action.payload.id) {
            output = action.payload;
          }
          return output;
        });
      } else {
        state.ioBoards = [{ ...action.payload }, ...state.ioBoards];
        state.totalDataCount += 1;
        if (state.ioBoards.length > 10) {
          state.ioBoards.pop();
        }
      }
    },
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchCardList actions
    builder
      .addCase(fetchIoBoardList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIoBoardList.fulfilled, (state, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.ioBoards = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchIoBoardList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSearchFilter, addIoBoard } = IoBoardSlice.actions;

export default IoBoardSlice.reducer;
