import { post } from '../base/index';

export default {
  listByPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/holiday/listByPage`, {}, { ...data });
  },
  editHolidays: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/holiday/save`, data, {});
  },

  saveHolidays: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/holiday/save`, data, {});
  },

  deleteHolidays: (id: string = '') =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/holiday/delete?ids=${id}`, {}),
};
