import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import { ErrorIconTwo } from '../../../assets/svgicons/svgicon';
import { DevicePopupInterface } from '../../../interface/device.interface';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function ViewRulesOfDevices({ closeModal, selectedId }: DevicePopupInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState([]);
  const getViewRules = () => {
    device
      .getViewRuleDevices(selectedId)
      .then((res) => {
        if (res.data.success) {
          const mapData = res.data.data.ruleList.map((item: any) => ({
            ruleType: item?.[0],
            description: item?.[1],
            ruleDetails: item?.[2],
          }));
          if (mapData) {
            setTableData((prevData) =>
              JSON.stringify(prevData) !== JSON.stringify(mapData) ? mapData : prevData,
            );
          }
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    if (selectedId) {
      getViewRules();
    }
  }, [selectedId]);
  return (
    <div className="view-rulesOf-devices">
      <Table
        header={[
          { label: t('ruleType'), id: 'ruleType' },
          { label: t('description'), id: 'description' },
          { label: t('ruleDetails'), id: 'ruleDetails' },
        ]}
        value={tableData}
        hideHeader={false}
      />
      <div className="bottom">
        <div className="text-message">
          <div>
            <ErrorIconTwo />
          </div>
          <span className="text-warning">{t('linkageGlobalLinkageAntiPassbackError')}</span>
        </div>
        <Button onClickFunction={closeModal} title={t('close')} className="btn-primary" />
      </div>
    </div>
  );
}

export default ViewRulesOfDevices;
