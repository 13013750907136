const verificationModes: any = {
  common_verifyMode_entiy: 'Verification Mode',
  common_verifyMode_cardOrFpOrPwd: 'Automatic Identification',
  common_verifyMode_onlyFp: 'Only Fingerprint',
  common_verifyMode_onlyPin: 'Only Pin',
  common_verifyMode_onlyPwd: 'Only Password',
  common_verifyMode_onlyCard: 'Only Card',
  common_verifyMode_pwdOrFp: 'Fingerprint/Password',
  common_verifyMode_cardOrFp: 'Card/Fingerprint',
  common_verifyMode_cardOrPwd: 'Card/Password',
  common_verifyMode_pinAndFp: 'Pin+Fingerprint',
  common_verifyMode_fpAndPwd: 'Fingerprint+Password',
  common_verifyMode_cardAndFp: 'Card+Fingerprint',
  common_verifyMode_cardAndPwd: 'Card+Password',
  common_verifyMode_cardAndFpAndPwd: 'Card+Password+Fingerprint',
  common_verifyMode_pinAndFpAndPwd: 'Pin+Password+Fingerprint',
  common_verifyMode_pinAndFpOrCardAndFp: 'Pin+Fingerprint/Card+Fingerprint',
  common_verifyMode_adminVerify: 'The administrator authentication',
  common_verifyMode_adminOrGeneralUser: 'The administrator/user authentication',
  common_verifyMode_doubleVerify: 'The two-step verification',
  common_verifyMode_other: 'Other',
  common_verifyMode_undefined: 'Verify Mode Undefined',
  common_verifyMode_entiyReverse: 'Verification Mode Reverse Selection',
  common_newVerify_mode_face: 'Face',
  common_newVerify_mode_pv: 'Palm',
  common_newVerify_mode_fv: 'Finger Vein',
  common_newVerify_mode_fp: 'Fingerprint',
  common_newVerify_mode_voice: 'Voice Print',
  common_newVerify_mode_iris: 'Iris',
  common_newVerify_mode_retina: 'Retina',
  common_newVerify_mode_pw: 'Password',
  common_newVerify_mode_pin: 'Work ID',
  common_newVerify_mode_rf: 'Card',
  common_newVerify_mode_idcard: 'ID Card',
  common_newVerify_mode_logic: 'Logic bit',
  common_newVerify_mode_logicOr: 'Or',
  common_newVerify_mode_logicAnd: 'And',
  common_newVerify_mode_manualConfig: 'Manual Configuration',
  acc_verify_mode_onlyface: 'Face',
  acc_verify_mode_facefp: 'Face+Fingerprint',
  acc_verify_mode_facepwd: 'Face+Password',
  acc_verify_mode_facecard: 'Face+Card',
  acc_verify_mode_facefpcard: 'Face+Fingerprint+Card',
  acc_verify_mode_facefppwd: 'Face+Fingerprint+Password',
  acc_verify_mode_fv: 'Finger Vein',
  acc_verify_mode_fvpwd: 'Finger Vein+Password',
  acc_verify_mode_fvcard: 'Finger Vein+Card',
  acc_verify_mode_fvpwdcard: 'Finger Vein+Password+Card',
  acc_verify_mode_pv: 'Palm',
  acc_verify_mode_pvcard: 'Palm+Card',
  acc_verify_mode_pvface: 'Palm+Face',
  acc_verify_mode_pvfp: 'Palm+Fingerprint',
  acc_verify_mode_pvfacefp: 'Palm+Face+Fingerprint',
};

const eventCodes: any = {
  acc_newEventNo_0: 'Normal Verify Open',
  acc_newEventNo_1: 'Verify During Passage Mode Time Zone',
  acc_newEventNo_2: 'First-Personnel Open',
  acc_newEventNo_3: 'Multi-Personnel Open',
  acc_newEventNo_20: 'Operation Interval too Short',
  acc_newEventNo_21: 'Door Inactive Time Zone Verify Open',
  acc_newEventNo_26: 'Multi-Personnel Authentication Wait',
  acc_newEventNo_27: 'Unregistered Personnel',
  acc_newEventNo_29: 'Personnel Expired',
  acc_newEventNo_30: 'Password Error',
  acc_newEventNo_41: 'Verify Mode Error',
  acc_newEventNo_43: 'Staff Lock',
  acc_newEventNo_44: 'Background Verify Failed',
  acc_newEventNo_45: 'Background Verify Timed Out',
  acc_newEventNo_48: 'Multi-Personnel Verify Failed',
  acc_newEventNo_54: 'The battery voltage is too low',
  acc_newEventNo_55: 'Replace the battery immediately',
  acc_newEventNo_56: 'Illegal Operation',
  acc_newEventNo_57: 'Backup Power',
  acc_newEventNo_58: 'Normally Open Alarm',
  acc_newEventNo_59: 'Illegal Management',
  acc_newEventNo_60: 'Door Locked Inside',
  acc_newEventNo_61: 'Replicated',
  acc_newEventNo_62: 'Prohibit Users',
  acc_newEventNo_63: 'Door Locked',
  acc_newEventNo_64: 'Inactive Exit button Time Zone',
  acc_newEventNo_65: 'Inactive Auxiliary Input Time Zone',
  acc_newEventNo_66: 'Reader Upgrade Failed',
  acc_newEventNo_67: 'Remote Comparison Succeeded (Device Not Authorized)',
  acc_newEventNo_68: 'High Body Temperature - Access Denied',
  acc_newEventNo_69: 'Without Mask - Access Denied',
  acc_newEventNo_70: 'Face comparison server communication exception',
  acc_newEventNo_71: 'The face server is responding erratically',
  acc_newEventNo_73: 'Invalid QR Code',
  acc_newEventNo_74: 'QR Code Expired',
  acc_newEventNo_101: 'Duress Open Alarm',
  acc_newEventNo_104: 'Invalid Card Swipe alarm',
  acc_newEventNo_105: 'Cannot connect to server',
  acc_newEventNo_106: 'Main power down',
  acc_newEventNo_107: 'Battery power down',
  acc_newEventNo_108: 'Can not connect to the master device',
  acc_newEventNo_109: 'Reader Tamper Alarm',
  acc_newEventNo_110: 'Reader Offline',
  acc_newEventNo_159: 'The remote control to open the door',
  acc_newEventNo_214: 'Connected to the server',
  acc_newEventNo_217: 'Connected to the master device successfully',
  acc_newEventNo_218: 'ID Card Verification',
  acc_newEventNo_222: 'Background Verify Success',
  acc_newEventNo_223: 'Background Verify',
  acc_newEventNo_224: 'Ring the bell',
  acc_newEventNo_227: 'Double open the door',
  acc_newEventNo_228: 'Double close the door',
  acc_newEventNo_229: 'Auxiliary Output Timed Normally Open',
  acc_newEventNo_230: 'Auxiliary Output Timed Close',
  acc_newEventNo_232: 'Verify Success',
  acc_newEventNo_233: 'Activate Lockdown',
  acc_newEventNo_234: 'Deactivate Lockdown',
  acc_newEventNo_235: 'Reader Upgrade Success',
  acc_newEventNo_236: 'Reader Tamper Alarm Cleared',
  acc_newEventNo_237: 'Reader Online',
  acc_newEventNo_239: 'Device call',
  acc_newEventNo_240: 'Call ended',
  acc_newEventNo_4008: 'Mains recovery',
  acc_newEventNo_6005: 'Record Capacity is reaching upper limit',
  acc_newEventNo_6006: 'Line short circuit (RS485)',
  acc_newEventNo_6007: 'Short circuit in the circuit (Wiegand)',
};

export { verificationModes, eventCodes };
