import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

// Use shared component
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import Button from '../../../shared-components/button/button';
import { fetchDepartmentList } from '../../../redux/slices/DepartmentSlices';

// Import the API module
import PersonalApis from '../../../utils/api/person';
import { setNotificationError } from '../../../redux/slices/NotificationSlices'; // Import the notification action

function ChangeDepartment({
  closeModal,
  selectedPerson,
  selectedPersonFullData,
  submitFunction,
}: {
  closeModal: () => void;
  selectedPerson: any[];
  selectedPersonFullData: any[];
  submitFunction: (val: any) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const allDepartments = useAppSelector((state: any) => state.departments.departments);

  const [value, setValue] = useState({
    selectedPerson: [] as string[], // Initialize as an array of strings
    newDepartment: selectedPersonFullData[0]?.deptId || '',
    transferReason: '',
    type: 'department',
  });
  const [disableButton, setDisableButton] = useState(true);

  const departMentResult = allDepartments.map((item: any) => ({
    label: item.name,
    id: item.departmentId,
  }));

  useEffect(() => {
    if (allDepartments.length === 0) {
      dispatch(fetchDepartmentList({ pageNo: 1, pageSize: 10 }));
    }
  }, [dispatch]);

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  useEffect(() => {
    if (value.newDepartment && value.transferReason) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);

  // Add the submit function here
  const submit = () => {
    setDisableButton(true);

    // Prepare the request payload
    const request = {
      // code: value.newDepartment, // Assign the newDepartment to code
      changeReason: value.transferReason,
      deptId: value.newDepartment,
      ids: selectedPerson.join(','),
    };

    // Call the API to change the department
    PersonalApis.changeDepartment(request)
      .then((response) => {
        if (response.data.success) {
          setDisableButton(false);
          submitFunction(request); // Pass the request object or another relevant value
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        setDisableButton(false);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  useEffect(() => {
    if (value.newDepartment !== '' && value.newDepartment !== '') {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [value]);
  return (
    <div className="modal-page">
      <div className="statistics-page rows-2">
        <div className="item span-2">
          <SelectBoxField
            name="newDepartment"
            label={t('newDepartment')}
            id="newDepartment"
            value={value.newDepartment}
            required
            onChangeFunction={(name, val) => {
              onChange(name, val.id);
            }}
            data={departMentResult}
          />
        </div>
        <div className="item span-2">
          <InputTextAreaField
            name="transferReason"
            label={t('transferReason')}
            id="transferReason"
            value={value.transferReason}
            onChangeFunction={onChange}
          />
        </div>
        <div className="item span-2">
          <div className="form-buttons-right">
            <Button onClickFunction={closeModal} title={t('close')} className="btn-default" />
            <Button
              onClickFunction={submit} // Use the submit function here
              title={t('ok')}
              disabled={disableButton}
              className="btn-primary btn-bold"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeDepartment;
