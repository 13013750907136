import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDraggable } from '@dnd-kit/core';
import { DraggableImageInterface } from '../../../../interface/map.interface';
import CardStatus from '../../../../shared-components/card-status/cardStatus';

function DraggableImage({
  id,
  src,
  position,
  data,
  handleClickEvent,
  dragStart,
}: DraggableImageInterface) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id.toString(),
  });
  const { t } = useTranslation();
  const [showHide, setShowHide] = useState(false);

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
    position: 'absolute' as const,
    top: position.y,
    left: position.x,
    width: '50px',
    height: '50px',
    cursor: 'move',
  };

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('serialNumber'),
      key: 'serialNumber',
    },
    {
      label: t('number'),
      key: 'number',
    },
    {
      label: t('door'),
      key: 'doorSensor',
    },
    {
      label: t('relay'),
      key: 'relay',
    },
    {
      label: t('alarm'),
      key: 'alarm',
    },
  ];

  const handleMouseEnter = () => setShowHide(true);
  const handleMouseLeave = () => setShowHide(false);
  return (
    <div
      ref={setNodeRef}
      style={style}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="drag-image">
      <img
        src={src}
        alt="draggable"
        style={{ width: '100%', height: '100%' }}
        {...listeners}
        {...attributes}
      />
      {showHide && !dragStart && (
        <div className="card-wrapper">
          <CardStatus
            key={data.serialNumber}
            data={data}
            header={header}
            click={handleClickEvent}
            footerLabel={t('queryTheLatestEventsFromTheDoor')}
          />
        </div>
      )}
    </div>
  );
}

export default DraggableImage;
