import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import { ErrorIcon } from '../../../assets/svgicons/svgicon';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { getCurrentMilliseconds, LoadingProgressCount } from '../../../utils/helpers';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import DataTable from '../../../shared-components/data-table/data-table';
import SearchAddDevice from './search-add-device';
import { AddDeviceProps } from '../../../interface/device.interface';
import SearchModifyIpAddress from './SearchModifyIPaddress';

interface ModalTypes {
  closeModal: () => void;
}

function SearchDevice({ closeModal }: ModalTypes) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState({
    ipAddress: '',
    deviceType: '',
    serialNumber: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: t('add'),
    content: <>hi</>,
  });
  const [loading, setLoading] = useState<string>('');
  const [deviceData, setDeviceData] = useState<any>({
    data: [],
    searchCount: 0,
    addDeviceCount: 0,
  });
  const [progress, setProgress] = useState(0);
  const [searchFilter, setSearchFilter] = useState<object>({});

  const clickAddDevice = (item: AddDeviceProps) => {
    const webServerIp = item?.WebServerURL ? new URL(item?.WebServerURL).hostname : '';
    const webServerPort = item?.WebServerURL ? new URL(item?.WebServerURL).port : '';
    const deviceValue = {
      ...item,
      serverIpAddress: webServerIp,
      serverPort: webServerPort,
    };
    setModalView({
      title: t('add'),
      content: <SearchAddDevice closeModal={() => setModalOpen(false)} data={deviceValue} />,
    });
    setModalOpen(true);
  };
  const clickModifyIp = (item: AddDeviceProps) => {
    const deviceValue = {
      ipAddress: item.IP,
      subnetMask: item.NetMask,
      gatewayAddress: item.GATEIPAddress,
      MAC: item.MAC,
    };
    setModalView({
      title: t('modifyIpAddress'),
      content: <SearchModifyIpAddress closeModal={() => setModalOpen(false)} data={deviceValue} />,
    });
    setModalOpen(true);
  };
  const columnsField = [
    {
      accessorKey: 'IP',
      header: t('ipAddress'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'MAC',
      header: t('macAddress'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'NetMask',
      header: t('subnetMask'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'GATEIPAddress',
      header: t('gatewayAddress'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'SN',
      header: t('serialNumber'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'Device',
      header: t('deviceType'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'WebServerURL',
      header: t('setServer'),
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: 'operation',
      header: t('operation'),
      enableSorting: false,
      enableColumnActions: false,
      enableResizing: false,
      Cell: ({ row }: any) => {
        let result;
        if (row.original.operation) {
          result = <span>This device has been added</span>;
        } else {
          result = (
            <div className="search-add-links">
              <div
                className="list-hyperlink"
                role="presentation"
                onClick={() => clickAddDevice(row.original)}>
                {t('add')}
              </div>
              {row.original.MachineType !== '101' && (
                <div
                  className="list-hyperlink"
                  role="presentation"
                  onClick={() => clickModifyIp(row.original)}>
                  {t('modifyIPAddress')}
                </div>
              )}
            </div>
          );
        }
        return result;
      },
    },
  ];
  const onChange = (name: string, changeValue: any) =>
    setSearchValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  const getFilterData = (filter: any): any => {
    setSearchFilter(filter);
  };
  const getDeviceList = (ipSnList: any) => {
    setLoading('1');
    const stopProgress = LoadingProgressCount(
      0,
      10,
      500,
      setProgress,
      () => {}, // completion callback
    );
    const currentTime = getCurrentMilliseconds();
    device
      .searchDevice(currentTime)
      .then((response) => {
        stopProgress();
        setLoading('0');
        const finalData = response?.data?.data?.devData.map((deviceIP: any) => {
          // Find the index of matching SN and IP in the second object
          const index = ipSnList?.sn?.findIndex(
            (sn: any, i: number) => sn === deviceIP.SN && ipSnList.ipAddress[i] === deviceIP.IP,
          );
          // Update the deviceIP object with action property
          return index !== -1
            ? { ...deviceIP, operation: true }
            : { ...deviceIP, operation: false };
        });
        setDeviceData({
          ...deviceData,
          data: finalData,
          searchCount: response?.data?.data?.devCount,
          addDeviceCount: finalData.filter((count: any) => count.operation).length,
        });
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const getAllIPSn = () => {
    device
      .getAllIps()
      .then((response) => {
        getDeviceList(response?.data?.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  const searchFunction = async () => {
    await getAllIPSn();
  };

  const fetchData = (): any => {
    console.log('Fetching next page data...');
  };

  return (
    <div className="search-device">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView?.title}
        content={modalView?.content}
      />
      <div className="title">
        <span>{t('noDeviceFound?')}</span>
        <span className="download-link">{t('downloadSearch')}</span>
      </div>
      <div className="row">
        <div className="input-row">
          <InputField
            name="ipAddress"
            label={t('ipAddress')}
            id="ipAddress"
            type="text"
            value={searchValue.ipAddress}
            onChangeFunction={onChange}
            disabled={loading === '1'}
          />
          <InputField
            name="deviceType"
            label={t('deviceType')}
            id="deviceType"
            type="text"
            value={searchValue.deviceType}
            onChangeFunction={onChange}
            disabled={loading === '1'}
          />
          <InputField
            name="serialNumber"
            label={t('serialNumber')}
            id="serialNumber"
            type="text"
            value={searchValue.serialNumber}
            onChangeFunction={onChange}
            disabled={loading === '1'}
          />
        </div>
        <div className="search-wrapper">
          <Button
            onClickFunction={searchFunction}
            title={loading === '1' ? t('searching ...') : t('search')}
            className="btn-outline-primary"
            disabled={loading === '1'}
          />
          {loading && (
            <div className="search-loader">
              {loading === '1' && <span className="count">{progress}%</span>}
              {loading === '1' && <SpinnerLoader />}
            </div>
          )}
          {loading && deviceData.searchCount > 0 && (
            <div className="search-result">
              <span style={{ paddingRight: '5px' }}>
                {t('searchDevicesCount')} {deviceData?.searchCount}
              </span>
              <span>
                {t('numberOfDevicesAdded')} {deviceData?.addDeviceCount}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="device-table">
        <DataTable
          actionButtonStatus={false}
          columns={columnsField}
          getFilterData={getFilterData}
          assignFilterValue={searchFilter}
          enableColumnFilters={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableColumnPinning={false}
          enableHiding={false}
          checkBoxActive={false}
          fetchNextPage={fetchData}
          isFetching={false} // Placeholder status
          values={deviceData.data} // Use the actual data here
          totalRecords={deviceData.data.length}
          height={411}
        />
      </div>
      <div className="device-bottom">
        <span className="error-message">
          <ErrorIcon />
          {t('deviceErrorMsg')}
        </span>
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('close')} className="btn-primary" />
        </div>
      </div>
    </div>
  );
}

export default SearchDevice;
