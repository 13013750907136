import { post } from '../base';

export default {
  getVerificationModeList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/list`, {}, { ...data });
  },
  getTimeSegList: (id: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/getTimeSegListWithoutRule`,
      id,
      {},
    );
  },
  getTimeSegById: (data: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/getTimeSegById`,
      {},
      data,
    );
  },
  isExistName: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/valid`, {}, data),
  getDoorVerify: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/getVerifyMode`, {});
  },
  getDoorList: (data: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/selectDoorlist`,
      { ...data },
      {},
    );
  },
  getSelectDoorList: (data: any) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/doorList`,
      { ...data },
      {},
    );
  },
  save: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/save`, { ...data }, {});
  },
  deleteRule: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/delete`, {}, data);
  },
  addDoor: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/addDoor`, {}, data);
  },
  deleteDoor: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accVerifyModeRule/delDoor`, {}, data);
  },
};
