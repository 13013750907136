import React, { useEffect, useRef, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import * as htmlToImage from 'html-to-image';
import QRCode from 'react-qr-code';

// Use shared components
import CardBox from '../../../../shared-components/card/card';
import { useAppDispatch } from '../../../../redux/store';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import Button from '../../../../shared-components/button/button';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import InputField from '../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import TopHeader from '../../../../shared-components/top-header/top-header';
import RadioButtonField from '../../../../shared-components/radio-button-field/radio-button-field';

// Use interface
import { ParameterInterface } from '../../../../interface/parameter.interface';

function CloudSettings({ onChangeFunction, values }: ParameterInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [showYesContent, setShowYesContent] = useState(true);
  const [hasClickedNo, setHasClickedNo] = useState(false);
  const [modalView, setModalView] = useState<{
    title: string;
    content: JSX.Element | null;
  }>({
    title: '',
    content: null,
  });
  const [value, setValue] = useState({
    ...values,
    pers: { ...values.pers, selfRegistration: values.pers?.selfRegistration ?? '1' },
    qrCodeUrl: values.qrCodeUrl || 'http://127.0.0.1:6001',
  });
  const qrCodeRef = useRef<HTMLDivElement | null>(null);

  const downloadQRCode = () => {
    if (qrCodeRef.current) {
      htmlToImage
        .toPng(qrCodeRef.current as HTMLElement)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'qr-code.png';
          link.click();
        })
        .catch((error) => {
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.response?.status || 500,
            }),
          );
        });
    }
  };

  const onChange = (name: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

    if (name === 'pers.selfRegistration' && changeValue === '1') {
      if (hasClickedNo) {
        setModalView({
          title: t('Enable'),
          content: (
            <div className="enable-management">
              <div className="note">Dear user:</div>
              <p>{t('inOrderToachievereal-timedata')}</p>
              <p>{t('theDeviceDataIncludesDeviceSerial')}</p>
              <p>{t('pleaseRestAssuredThatAllDataUploaded')}</p>
              <p>{t('pleaseConfirmIfYouAgree')}</p>
              <p>{t('thankYouForChoosingZKTeco')}</p>
            </div>
          ),
        });
        setModalOpen(true);
      } else {
        setShowYesContent(true);
      }
    } else if (name === 'pers.selfRegistration' && changeValue === '0') {
      setHasClickedNo(true);
      setModalOpen(false);
      setShowYesContent(false);
    }
  };

  const handleAgree = () => {
    setModalOpen(false);
    setShowYesContent(true);
  };

  const handleDisagree = () => {
    setModalOpen(false);
    setValue((prev: any) => ({
      ...prev,
      pers: {
        ...prev.pers,
        selfRegistration: '0',
      },
    }));
    setShowYesContent(false);
  };
  useEffect(() => {
    onChangeFunction(value);
  }, [value]);

  const submit = () => {
    console.log('Form submitted with values: ', value);
  };

  const disabled = false;

  return (
    <div className="card-management">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={
          <>
            {modalView.content}
            <div className="form-buttons-right">
              <Button
                onClickFunction={handleDisagree}
                title={t('disagree')}
                className="btn-default"
              />
              <Button onClickFunction={handleAgree} title={t('agree')} className="btn-primary" />
            </div>
          </>
        }
      />
      <TopHeader
        title={t('system')}
        broadCram={[
          {
            title: t('systemManagement'),
          },
          {
            title: t('cloudSettings'),
          },
        ]}
      />
      <CardBox title={t('appCloudServiceConfiguration')}>
        <div className="cloud-setting">
          <div className="cloud-setting-form w50">
            <div className="column">
              <div className="item">
                <RadioButtonField
                  label={t('enable')}
                  name="pers.selfRegistration"
                  alignment="row"
                  data={[
                    {
                      label: t('yes'),
                      id: '1',
                      value: '1',
                    },
                    {
                      label: t('no'),
                      id: '0',
                      value: '0',
                    },
                  ]}
                  value={value['pers.selfRegistration']}
                  onChangeFunction={onChange}
                />
              </div>
              {showYesContent && (
                <>
                  <div className="item">
                    <div className="title">{t('zkBioCVConnectServerUrl')}</div>
                    <InputField
                      name="qrCodeUrl"
                      label={t('qrCodeUrl')}
                      id="qrCodeUrl"
                      type="text"
                      value={value.qrCodeUrl}
                      innerIconStatus={false}
                      onChangeFunction={onChange}
                    />
                  </div>
                  <div className="item">
                    <RadioButtonField
                      label={t('isPushingEventDataToTheCloudPlatformEnabled')}
                      name="pers.selfRegistration"
                      alignment="row"
                      data={[
                        {
                          label: t('yes'),
                          id: '1',
                          value: '1',
                        },
                        {
                          label: t('no'),
                          id: '0',
                          value: '0',
                        },
                      ]}
                      value={value['pers.selfRegistration']}
                      onChangeFunction={onChange}
                    />
                  </div>
                  <div className="item">
                    <RadioButtonField
                      label={t('isCloudSipEnabled')}
                      name="pers.selfRegistration"
                      alignment="row"
                      data={[
                        {
                          label: t('yes'),
                          id: '1',
                          value: '1',
                        },
                        {
                          label: t('no'),
                          id: '0',
                          value: '0',
                        },
                      ]}
                      value={value['pers.selfRegistration']}
                      onChangeFunction={onChange}
                    />
                  </div>
                  <div className="item text-danger">
                    <div className="note">Note:</div>
                    <div className="content">
                      The ZKBio CVConnect platform, as a sub-service of MinervalOT, mainly serves to
                      forward intranets application data to be accessed externally. If you have not
                      installed the ZKBio CVConnect client yet, please click the link below to
                      download or contact the techinical support to obtain the installer.
                    </div>
                  </div>
                  <div className="text" onClick={downloadQRCode} role="presentation">
                    {t('downloadLink')} <span className="link">{t('zkBioCVConnectClient')}</span>
                  </div>
                  <div className="item item-2">
                    <div className="title">{t('appEnterpriseQRCode')}</div>
                    {value.qrCodeUrl && (
                      <div className="qrcode" ref={qrCodeRef}>
                        <QRCode value={value.qrCodeUrl} size={100} />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <FormRightButton submitLabel={t('done')} submitFunction={submit} disabled={disabled} />
        </div>
      </CardBox>
    </div>
  );
}

export default CloudSettings;
