import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/eventType/listByPage`, {}, { ...data });
  },
  saveEvent: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/eventType/edit`, data, {});
  },
  getMediaFileList: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/baseMediaFile/getMediaFileList`, {});
  },
};
