/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import alarmProcessingHistory from '../../utils/api/AccControlReports/alarmProcessingHistory';

export const fetchAlarmMonitoryHistoryList = createAsyncThunk(
  'acc/fetchAlarmMonitoryHistoryList',
  async (data: any, { dispatch }) => {
    const response = await alarmProcessingHistory
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReduxInterface {
  historyList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  historyList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const alarmProcessingHistorySlice: any = createSlice({
  name: 'alarmProcessingHistory',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAlarmMonitoryHistoryList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAlarmMonitoryHistoryList.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.size;
          state.currentPageNo = action.payload.pageNo;

          state.historyList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchAlarmMonitoryHistoryList.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = alarmProcessingHistorySlice.actions;

export default alarmProcessingHistorySlice.reducer;
