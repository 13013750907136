import { post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/list`, {}, { ...data });
  },
  getCombOpenList: () => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/getCombOpenList`, {});
  },

  selectDoorlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/selectDoorlist`, data, {});
  },

  validBackgroundVerify: (data: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/validBackgroundVerify?doorId=${data}`,
      {},
    ),

  save: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenDoor/save`, { ...data }, {});
  },
};
