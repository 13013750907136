/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import device from '../../utils/api/device';
import { setNotificationError } from './NotificationSlices';

export const fetchDeviceList = createAsyncThunk(
  'device/fetchDeviceList ',
  async (data: any, { dispatch }) => {
    const response = await device
      .getDevices(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data.map((item: any) => {
              let result;
              if (item.connectState === '1') {
                result = 'online';
              } else if (item.connectState === '0') {
                result = 'offline';
              } else {
                result = 'disabled';
              }
              return {
                ...item,
                id: item?.id,
                status: result,
              };
            }),
            size: response1.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

export const fetchTimeZone = createAsyncThunk(
  'all/fetchTimeZone',
  async (data: any, { dispatch }) => {
    const response = await device
      .timeZoneList(data)
      .then((response1) => {
        const formattedResponse = response1.data.data.map((item: any) => ({
          label: item.text,
          id: item.value,
        }));
        return formattedResponse;
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

const initialState = {
  devices: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  timeZone: [],
  timeZoneStatus: 'success',
};

export const deviceSlice: any = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchDeviceList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.code === 0) {
          state.devices = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
          state.currentPageNo += 1;
        }
        state.status = 'success';
      })
      .addCase(fetchDeviceList.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchTimeZone.pending, (state = initialState) => {
        state.timeZoneStatus = 'loading';
      })
      .addCase(fetchTimeZone.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        state.timeZone = action.payload;
        state.timeZoneStatus = 'success';
      })
      .addCase(fetchTimeZone.rejected, (state: any) => {
        state.timeZoneStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = deviceSlice.actions;

export default deviceSlice.reducer;
