import { post } from '../base/index';

export default {
  listByPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/globalInterlock/listByPage`,
      {},
      { ...data },
    );
  },
  getPersonGroupList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/personList`, data);
  },

  getGrouplist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlockGroup/list`, data);
  },

  getDoorlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlockMid/list`, data);
  },

  addGroup: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlock/addGroup`, {}, data);
  },

  saveList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlock/save`, data, {});
  },

  deleteInterlock: (id: string = '') => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/globalInterlock/deleteByIds?ids=${id}`,
      {},
    );
  },

  delGroupInterlock: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlockMid/delete?ids=${id}`, {});
  },
};
