import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../shared-components/table/table';
import Button from '../../../shared-components/button/button';
import linkage from '../../../utils/api/AccessRule/linkage';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

interface ModalType {
  closeModal: () => void;
  addTriggerCondition: (val: any) => void;
  deviceId: string;
}
interface DoorEventProps {
  id: string;
  text: string;
}
function LinkageTriggerConnections({ closeModal, addTriggerCondition, deviceId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [allEventData, setAllEventData] = useState<any[]>([]);
  const [doorEvent, setDoorEvent] = useState<string[]>([]);
  const [inputEvent, setInputEvent] = useState<string[]>([]);
  const [selectedDeviceEvents, setSelectedDeviceEvents] = useState<string[]>([]);
  const [selectedFullData, setSelectedFullData] = useState<DoorEventProps[]>([]);
  // Handler for selecting rows
  const handleRowSelection = (selectedRows: string[], tableType: string) => {
    // Clear selections from all tables
    setDoorEvent([]);
    setInputEvent([]);
    setSelectedDeviceEvents([]);
    setSelectedFullData([]); // Clear selected full data

    // Set selected rows for the active table and update full data accordingly
    if (tableType === 'doors') {
      setDoorEvent(selectedRows);
      const fullData = allEventData?.[0]?.item?.filter((dev: DoorEventProps) =>
        selectedRows.includes(dev.id),
      );
      setSelectedFullData(fullData);
    } else if (tableType === 'inputEvent') {
      setInputEvent(selectedRows);
      const fullData = allEventData?.[1]?.item.filter((dev: DoorEventProps) =>
        selectedRows.includes(dev.id),
      );
      setSelectedFullData(fullData);
    } else if (tableType === 'selectedDeviceEvents') {
      setSelectedDeviceEvents(selectedRows);
      const fullData = allEventData?.[2]?.item.filter((dev: DoorEventProps) =>
        selectedRows.includes(dev.id),
      );
      setSelectedFullData(fullData);
    }
  };

  const submit = () => {
    if (selectedFullData?.length) {
      addTriggerCondition(selectedFullData);
      closeModal();
    }
  };
  const fetchData = (params: any) => {
    linkage
      .getLinkTriggerOpt(params)
      .then((res) => {
        setAllEventData(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchData({
      deviceId,
    });
  }, [deviceId]);

  return (
    <div className="linkage-trigger-connections">
      <div className="table-container">
        <div className="table-wrapper">
          <h4 className="table-title">{t('doorEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'text' }]}
            value={allEventData?.[0]?.item}
            checkBoxStatus
            selectedValues={doorEvent}
            onSelect={(row) => handleRowSelection(row, 'doors')}
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
          />
        </div>
        <div className="table-wrapper">
          <h4 className="table-title">{t('auxiliaryInputEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'eventName' }]}
            value={allEventData?.[1]?.item}
            checkBoxStatus
            selectedValues={inputEvent}
            onSelect={(row) => handleRowSelection(row, 'inputEvent')}
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
          />
        </div>
        <div className="table-wrapper">
          <h4 className="table-title">{t('deviceEvent')}</h4>
          <Table
            header={[{ label: t('eventName'), id: 'eventName' }]}
            value={allEventData?.[2]?.item}
            checkBoxStatus
            selectedValues={selectedDeviceEvents}
            onSelect={(row) => handleRowSelection(row, 'selectedDeviceEvents')}
            getSelectedFullData={(value: any) => setSelectedFullData(value)}
          />
        </div>
      </div>
      <div className="form-buttons-right add-margin">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedFullData.length === 0}
        />
      </div>
    </div>
  );
}

export default LinkageTriggerConnections;
