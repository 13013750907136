import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import Table from '../../../shared-components/table/table';
import { CardBatchIssueCardInterFace } from '../../../interface/card.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { Search } from '../../../assets/svgicons/svgicon';
import {
  fetchUnIssuedCards,
  fetchBatchIssueCard,
  deleteFromUnIssuedPerson,
} from '../../../redux/slices/CardSlice';
import cardApi from '../../../utils/api/card'; // Make sure this is imported

function BatchIssueCard({ closeModal }: CardBatchIssueCardInterFace) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { unIssuedCards, status } = useAppSelector((state) => state.cards);

  const [departmentValue, setDepartmentValue] = useState({
    search: '',
    inputCardNo: '',
    cardEnrollmentMethod: '',
  });

  const [selectedUnissuedCards, setSelectedUnissuedCards] = useState<any[]>([]);
  const [updatedUnIssuedCards, setUpdatedUnIssuedCards] = useState<any[]>([]);

  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchUnIssuedCards({ ...params }));
    }
  };
  const onChangeValue = (name: string, value: any) => {
    setDepartmentValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (unIssuedCards.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, [unIssuedCards]);

  const handleSelectUnissuedCard = (selectedRows: any[]) => {
    setSelectedUnissuedCards(selectedRows);
  };

  // Handle issue card logic for the "Issue Card" button
  const handleCheckCardNumber = async () => {
    try {
      const { inputCardNo } = departmentValue;
      if (!inputCardNo) {
        setNotificationError(t('Please enter a card number.'));
        return;
      }

      const response = await dispatch(
        fetchBatchIssueCard({ cardNo: departmentValue.inputCardNo }),
      ).unwrap();

      if (response.exists) {
        setNotificationError(t('The input card number already exists.'));
      } else {
        // Remove selected unissued cards from the unIssuedCards list
        const filteredUnIssuedCards = unIssuedCards.filter((card) =>
          selectedUnissuedCards.includes(card?.id),
        );
        // Dispatch the deleteFromUnIssuedPerson action for each selected card
        selectedUnissuedCards.map((cardId) => dispatch(deleteFromUnIssuedPerson(cardId)));

        // Set the updatedUnIssuedCards state and log it
        setUpdatedUnIssuedCards((prev) => [...prev, ...filteredUnIssuedCards]);

        // Clear selected unissued cards
        setSelectedUnissuedCards([]);
        setNotificationError(null);
      }
    } catch (error) {
      console.log(error);
      setNotificationError(t('An error occurred while issuing the card.'));
    }
  };

  // Handle batch card issue logic for the "Done" button
  const submit = () => {
    const { inputCardNo } = departmentValue;
    const cardPersonItems = updatedUnIssuedCards.map((card) => ({
      personPin: card.personPin, // Assuming unIssuedCards have personPin property
      cardNo: inputCardNo,
      cardType: 0, // Assuming cardType is static here, can be dynamic
    }));
    cardApi
      .batchCardIssue(cardPersonItems)
      .then((res) => {
        if (res) {
          dispatch(
            setNotificationError({
              error: t('cardNoSuccessfullyIssued'),
              status: 200, // Success status code
            }),
          );
          closeModal();
        }
      })
      .catch((error: any) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  return (
    <div className="bitch-issue-card">
      <div className="bitch-issue-card-form">
        <div className="row-form">
          <div className="col">
            <InputField
              name="search"
              label={t('search')}
              id="search"
              type="text"
              value={departmentValue.search}
              innerIconStatus
              InnerIcon={Search}
              innerIconFunction={() => {}}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col">
            <InputField
              name="inputCardNo"
              label={t('inputCardNo')}
              id="inputCardNo"
              type="number"
              value={departmentValue.inputCardNo}
              innerIconStatus={false}
              onChangeFunction={onChangeValue}
            />
          </div>
          <div className="col-text">
            <div
              className="link-tag"
              onClick={handleCheckCardNumber}
              onKeyDown={handleCheckCardNumber}
              role="button"
              tabIndex={0}>
              {t('issueCard')}
            </div>
          </div>
          <div className="col">
            <SelectBoxField
              name="cardEnrollmentMethod"
              label={t('cardEnrollmentMethod')}
              id="cardEnrollmentMethod"
              data={[
                { label: 'Value 1', id: 'value1' },
                { label: 'Value 2', id: 'value2' },
              ]}
              value={departmentValue.cardEnrollmentMethod}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            />
          </div>
        </div>
        <div className="row row-2">
          <div className="col">
            <div className="header">
              <h1>{t('unIssuedPerson')}</h1>
            </div>
            {status === 'loading' ? (
              <p>{t('loading')}</p>
            ) : (
              <Table
                header={[
                  { label: 'Personnel ID', id: 'personPin' },
                  { label: 'First Name', id: 'personName' },
                  { label: 'Last Name', id: 'personLastName' },
                  { label: 'Department', id: 'deptCode' },
                ]}
                value={unIssuedCards}
                checkBoxStatus
                selectedValues={selectedUnissuedCards} // Track selected cards
                onSelect={handleSelectUnissuedCard} // Handle selection
              />
            )}
          </div>
          <div className="dhxlayout_sep dhxlayout_sep_resize" />
          <div className="col">
            <div className="header">
              <h1>{t('currentIssuePerson')}</h1>
            </div>
            <Table
              header={[
                { label: t('personnelId'), id: 'personPin' },
                { label: t('firstName'), id: 'personName' },
                { label: t('lastName'), id: 'personLastName' },
                { label: t('departmentNumber'), id: 'deptCode' },
                { label: t('cardNumber'), id: 'cardNo' },
              ]}
              value={updatedUnIssuedCards} // Display the current issued cards
            />
          </div>
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button onClickFunction={closeModal} title={t('back')} className="btn-default btn-bold" />
          {/* Trigger the batchCardIssue API when clicking the Done button */}
          <Button onClickFunction={submit} title={t('done')} className="btn-primary btn-bold" />
        </div>
      </div>
    </div>
  );
}

export default BatchIssueCard;
