import { get, post } from './base/index';

export default {
  getWiegandFormat: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/getListByPage`, {}, { ...data });
  },
  addWiegandFormat: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/addWiegand`, data);
  },
  getWiegandParities: (id: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/getWiegandById?id=${id}`, {});
  },
  deleteWiegandFormat: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/deleteWiegand?ids=${id}`, {});
  },
  checkNameIsExist: (name: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/isNameExist?name=${name}`, {});
  },
  readerCard: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/readerCard`, {}, data);
  },
  startReader: (data: any) => {
    return get(
      `${process.env.REACT_APP_API_URL}Pers/api/wiegand/start?deviceId=${data.deviceId}`,
      {},
    );
  },
  stopReader: (data: any) => {
    return get(
      `${process.env.REACT_APP_API_URL}Pers/api/wiegand/stop?deviceId=${data.deviceId}`,
      {},
    );
  },
  recommendFmt: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/wiegand/recommendFmt`, {}, data);
  },
};
