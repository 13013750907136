import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputIpAddressField from '../../../shared-components/input-ip-address-field/input-ip-address-field';
import { DevicePopupInterface } from '../../../interface/device.interface';
import { useAppDispatch } from '../../../redux/store';
import device from '../../../utils/api/device';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function ModifyIpAddress({ closeModal, selectedValues, selectedId }: DevicePopupInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ipConfig, setIpConfig] = useState({
    ipAddress: selectedValues?.[0]?.ipAddress || '',
    subnetMask: selectedValues?.[0]?.subnetMask || '',
    gatewayAddress: selectedValues?.[0]?.gateway || '',
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onChangeValue = (name: string, value: any) => {
    setIpConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const request = {
        devId: selectedId?.[0],
        gateway: ipConfig?.gatewayAddress,
        ipAddress: ipConfig?.ipAddress,
        subnetMask: ipConfig?.subnetMask,
      };
      const response = await device.updateIpAddress(request);
      if (response.data.success) {
        dispatch(
          setNotificationError({
            error: response?.data?.msg,
            status: 200,
          }),
        );
        setIsLoading(false);
        closeModal();
      } else {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: response.data.ret,
          }),
        );
        setIsLoading(false);
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      setIsLoading(false);
    }
  };
  const getIpInfo = () => {
    const request = {
      id: selectedId,
      type: 'updateIpAddr',
    };
    device
      .getDevIPAddressInfo(request)
      .then((res) => {
        if (res.data.success) {
          setErrorMsg(res.data.data.failedReason);
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    if (selectedId) {
      getIpInfo();
    }
  }, [selectedId]);

  return (
    <div className="modify-ip-address">
      {errorMsg ? (
        <span>{errorMsg === '2' ? 'Device is disabled try again later!' : errorMsg}</span>
      ) : (
        <div className="form-row">
          <InputIpAddressField
            id="ipAddress"
            name="ipAddress"
            label={t('ipAddress')}
            type="text"
            value={ipConfig.ipAddress}
            onChangeFunction={onChangeValue}
            aria-label="IP Address"
          />
          <InputIpAddressField
            id="subnetMask"
            name="subnetMask"
            label={t('subnetMask')}
            type="text"
            value={ipConfig.subnetMask}
            onChangeFunction={onChangeValue}
            aria-label="Subnet Mask"
          />
          <InputIpAddressField
            id="gatewayAddress"
            name="gatewayAddress"
            label={t('gatewayAddress')}
            type="text"
            value={ipConfig?.gatewayAddress}
            onChangeFunction={onChangeValue}
            aria-label="Gateway Address"
          />
        </div>
      )}

      <div className="modal-footer">
        {!errorMsg && (
          <Button
            onClickFunction={closeModal}
            title={t('Cancel')}
            aria-label={t('Cancel')}
            className="btn-default"
          />
        )}
        <Button
          onClickFunction={() => {
            if (errorMsg) {
              closeModal();
            } else {
              submit();
            }
          }}
          title={isLoading ? t('loading') : t('Done')}
          className={`btn-primary ${isLoading ? 'loading' : ''} `}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

export default ModifyIpAddress;
