/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import antiPassBack from '../../utils/api/AccessRule/antiPassback';
import { setNotificationError } from './NotificationSlices';

export const fetchAntiPassBack = createAsyncThunk(
  'antiPassBack/fetchAntiPassBack',
  async (data: any, { dispatch }) => {
    const apiResponse = await antiPassBack
      .getListPage(data)
      .then((response) => {
        return {
          ...response.data,
          data: {
            list: response.data.data.data.map((val: any) => ({
              ...val,
              id: val?.id,
            })),
            size: response.data.data.total,
            ...data,
          },
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return apiResponse;
  },
);

const initialState = {
  antiPassBack: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const antiPassBackSlice: any = createSlice({
  name: 'antiPassBack',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    deleteAntiPassBack: (state, action: any) => {
      state.antiPassBack = state.antiPassBack.filter((val: any) => val.code !== action.payload);
      state.totalDataCount -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAntiPassBack.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAntiPassBack.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.antiPassBack = action.payload.data.list;
          state.totalDataCount = action.payload.data.size;
        }
        state.status = 'success';
      })
      .addCase(fetchAntiPassBack.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteAntiPassBack } = antiPassBackSlice.actions;

export default antiPassBackSlice.reducer;
