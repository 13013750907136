import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddDeviceInterface } from '../../../interface/accessDevice.interface';
import InputField from '../../../shared-components/input-field/input-field';
import { isValidIpAddress } from '../../../shared-components/form/validation';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../shared-components/button/button';
import { ClearDataIcon } from '../../../assets/svgicons/svgicon';
import GroupSwitchButton from '../../../shared-components/group-switch-button/group-switch-button';
import { getRS485Value, getSwitchStatesFromRS485 } from '../../../utils/helpers';
import InputIpAddressField from '../../../shared-components/input-ip-address-field/input-ip-address-field';
import device from '../../../utils/api/device';
import auth from '../../../utils/api/auth';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchDeviceList } from '../../../redux/slices/DeviceSlice';

function AddDevice({ closeModal, data }: AddDeviceInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addDevice, setAddDevice] = useState(
    data || {
      alias: '',
      commType: 2,
      ipAddress: '',
      ipPort: '',
      communicationPassword: '',
      iconType: '',
      controlPanelType: '',
      area: '',
      addToLevel: '',
      clearData: [],
      serialPortNumber: '',
      rs485Address: 0,
      baudRate: '',
      rs485AddressCodeFigure: {
        switch1: false,
        switch2: false,
        switch3: false,
        switch4: false,
        switch5: false,
        switch6: false,
        switch7: false,
        switch8: false,
      },
    },
  );
  const [disable, setDisable] = useState(false);
  const [checkExistName, setCheckExistName] = useState(true);
  const [checkExistIp, setCheckExistIp] = useState(true);
  const [areaList, setAreaList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const onChangeValue = (name: string, value: any) => {
    setAddDevice((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changeSwitch = (switchName: string, value: boolean) => {
    setAddDevice((prevState: any) => {
      // Update the switch state
      const updatedSwitches = {
        ...prevState.rs485AddressCodeFigure,
        [switchName]: value,
      };
      // Calculate the new RS485 address value
      const newRS485Address = getRS485Value(updatedSwitches);
      // Update the state with the new switch values and RS485 address
      return {
        ...prevState,
        rs485AddressCodeFigure: updatedSwitches,
        rs485Address: newRS485Address,
      };
    });
  };

  const handleChangeRs485 = (name: string, value: any) => {
    const newValue = parseInt(value, 10); // Convert input to number or default to 0
    if (newValue > 63) {
      setAddDevice((prevState: any) => ({
        ...prevState,
        rs485Address: newValue,
      }));
      return;
    }
    const updatedSwitches = getSwitchStatesFromRS485(newValue);
    setAddDevice((prevState: any) => ({
      ...prevState,
      rs485Address: newValue,
      rs485AddressCodeFigure: updatedSwitches, // Update switch states based on input value
    }));
  };
  const submit = async () => {
    setDisable(true);
    try {
      const response = await device.save(addDevice);

      if (response.data.success) {
        setDisable(false);
        dispatch(
          setNotificationError({
            error: data ? t('deviceUpdatedSuccessfully') : t('deviceAddedSuccessfully'),
            status: 200,
          }),
        );
        closeModal();
        dispatch(fetchDeviceList({ pageNo: 1 }));
      }
    } catch (error: any) {
      setDisable(false);
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  const onBlur = async (name: string, value: any) => {
    if (name === 'alias') {
      const responseData = await device.isExistAlias(value);
      setCheckExistName(responseData?.data?.data);
    }
  };

  const onBlurIpAddress = async () => {
    if (addDevice.ipAddress) {
      const responseData = await device.isExistIpAddress(addDevice.ipAddress);
      setCheckExistIp(responseData.data.data);
    }
  };

  const getAuthAreaList = () => {
    auth
      .getAuthArea({ pageNo: 1 })
      .then((res) => {
        const authArea = res.data.data.list.map((area: any) => ({
          label: area.name,
          id: area.id,
        }));
        setAreaList(authArea);
        // setAddDevice((prev: any)=>({
        //   ...prev,
        //   area
        // }));
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const getAccLevelList = () => {
    device
      .getLevelList({ pageNo: 1 })
      .then((res) => {
        const levels = res.data.data.list.map((lev: any) => ({
          label: lev.name,
          id: lev.id,
        }));
        setLevelList(levels);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    getAuthAreaList();
    getAccLevelList();
  }, []);

  useEffect(() => {
    // Ensure IP address is not an empty string
    // Check if the IP address is in valid format
    const isIpAddressNonEmpty = addDevice?.ipAddress?.trim() ?? '';
    if (
      addDevice?.alias &&
      addDevice?.iconType &&
      addDevice?.area &&
      checkExistName &&
      checkExistIp
    ) {
      if (
        addDevice?.commType === 1 &&
        addDevice?.ipPort &&
        isIpAddressNonEmpty &&
        isValidIpAddress(isIpAddressNonEmpty)
      ) {
        setDisable(false);
      } else if (
        addDevice?.commType === 2 &&
        addDevice?.serialPortNumber &&
        addDevice?.rs485Address &&
        addDevice?.baudRate
      ) {
        setDisable(false);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [addDevice, checkExistName, checkExistIp]);

  return (
    <div className="add-device">
      <div className="add-device-form">
        <div className="single-row">
          <InputField
            name="alias"
            label={t('name')}
            id="alias"
            type="text"
            value={addDevice.alias}
            required
            errorStatus={!checkExistName}
            message={t('nameIsAlreadyExist')}
            innerIconStatus={false}
            onChangeFunction={onChangeValue}
            onBlurFunction={onBlur}
          />
          <RadioButtonField
            label={t('communicationType')}
            name="commType"
            alignment="column"
            required
            data={
              data
                ? [
                    { label: t('tct/ip'), id: 1, value: 1, disabled: 'disabled' },
                    { label: t('rs485'), id: 2, value: 2, disabled: 'disabled' },
                    { label: t('Http'), id: 3, value: 3, disabled: 'disabled' },
                  ]
                : [
                    { label: t('tct/ip'), id: 1, value: 1 },
                    { label: t('rs485'), id: 2, value: 2 },
                  ]
            }
            value={addDevice.commType}
            onChangeFunction={onChangeValue}
          />
        </div>
        {addDevice.commType === 1 ||
          (addDevice.commType === 3 && (
            <div className="single-row-1">
              <InputIpAddressField
                name="ipAddress"
                label={t('ipAddress')}
                id="ipAddress"
                type="text"
                value={addDevice.ipAddress}
                onChangeFunction={onChangeValue}
                onBlurFunction={onBlurIpAddress}
                errorStatus={!checkExistIp}
                message={t('objectIsAlreadyExist')}
                required
                disabled={data}
              />
              <InputField
                name="ipPort"
                label={t('communicationPort')}
                id="ipPort"
                type="text"
                value={addDevice.ipPort}
                innerIconStatus={false}
                onChangeFunction={onChangeValue}
                required
                disabled={data}
              />
              {addDevice.commType === 1 && (
                <InputField
                  name="communicationPassword"
                  label={t('communicationPassword')}
                  id="communicationPassword"
                  type="text"
                  value={addDevice.communicationPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                />
              )}
              {addDevice.commType === 3 && (
                <InputField
                  name="sn"
                  label={t('serialNumber')}
                  id="sn"
                  type="text"
                  value={addDevice.sn}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                  disabled
                />
              )}
            </div>
          ))}
        {addDevice.commType === 2 && (
          <div className="grid-row">
            <div className="col-8">
              <div className="single-row">
                <SelectBoxField
                  name="serialPortNumber"
                  label={t('serialPortNumber')}
                  id="serialPortNumber"
                  value={addDevice.serialPortNumber}
                  onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                  data={[
                    { label: t('excel'), id: 'XLS' },
                    { label: t('pdf'), id: 'PDF' },
                    { label: t('csv'), id: 'CSV' },
                  ]}
                  className=""
                  required
                />
                <InputField
                  name="rs485Address"
                  label={t('rs485Address')}
                  id="rs485Address"
                  type="number"
                  value={addDevice.rs485Address}
                  innerIconStatus={false}
                  onChangeFunction={handleChangeRs485}
                  errorStatus={addDevice?.rs485Address > 63}
                  message="Please enter a value between 1 and 63."
                  required
                />
              </div>
              <div className="single-row">
                <SelectBoxField
                  name="baudRate"
                  label={t('baudRate')}
                  id="baudRate"
                  value={addDevice.baudRate}
                  onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                  data={[
                    { label: t('excel'), id: 'XLS' },
                    { label: t('pdf'), id: 'PDF' },
                    { label: t('csv'), id: 'CSV' },
                  ]}
                  className=""
                  required
                />
                <InputField
                  name="communicationPassword"
                  label={t('communicationPassword')}
                  id="communicationPassword"
                  type="text"
                  value={addDevice.communicationPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                />
              </div>
            </div>
            <div className="col-3">
              <GroupSwitchButton
                label="RS485 Address Code Figure:"
                leftText="ON"
                rightText="KE"
                name="rs485AddressCodeFigure"
                value={addDevice?.rs485AddressCodeFigure}
                changeFunction={changeSwitch}
                switchCount={8}
                disabled={6}
              />
            </div>
          </div>
        )}
        <div className="single-row-1">
          <SelectBoxField
            name="iconType"
            label={t('iconType')}
            id="iconType"
            value={addDevice.iconType}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={[
              { label: t('door'), id: 1 },
              { label: t('parkingBarrier'), id: 2 },
              { label: t('flapBarrier'), id: 3 },
            ]}
            className=""
            required
          />
          {addDevice.commType !== 3 && (
            <SelectBoxField
              name="controlPanelType"
              label={t('controlPanelType')}
              id="controlPanelType"
              value={addDevice.controlPanelType}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={[
                { label: t('oneDoorAccessControlPanel'), id: 1 },
                { label: t('twoDoorAccessControlPanel'), id: 2 },
                { label: t('fourDoorAccessControlPanel'), id: 4 },
                { label: t('standAloneDevice'), id: 5 },
              ]}
              className=""
            />
          )}
          <SelectBoxField
            name="area"
            label={t('area')}
            id="area"
            value={addDevice.area}
            onChangeFunction={(name, value) => onChangeValue(name, value.id)}
            data={areaList}
            className=""
            required
          />
        </div>
        <div className={addDevice.commType === 3 ? 'last-row' : 'single-row'}>
          {addDevice.commType !== 3 && (
            <SelectBoxField
              name="addToLevel"
              label={t('addToLevel')}
              id="addToLevel"
              value={addDevice.addToLevel}
              onChangeFunction={(name, value) => onChangeValue(name, value.id)}
              data={levelList}
              className=""
            />
          )}
          {!data && (
            <CheckBoxInputField
              name="clearData"
              data={[
                {
                  label: t('clearDataLabel'),
                  id: 'yes',
                  value: 'yes',
                },
              ]}
              value={addDevice.clearData}
              onChangeFunction={onChangeValue}
            />
          )}
        </div>
      </div>
      {!data && (
        <div className="error-label">
          <div>
            <ClearDataIcon />
          </div>
          <span>{t('clearDataLabelDescription')}</span>
        </div>
      )}
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddDevice;
