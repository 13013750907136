import React, { useState, useEffect } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';

// Use shared components
import SelectedData from '../../../shared-components/selected-data/selected-data';
import Button from '../../../shared-components/button/button';

// Use svgicon components
import SearchIcon from '../../../assets/svgicons/svgicon';

// Use interface components
import { Level, AddLevelModalProps } from '../../../interface/permissions.interface';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import person from '../../../utils/api/person';

function AddLevelModal({ onClose, onAddLevel, checkboxTreeData, levelIds }: AddLevelModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const header = [
    {
      label: 'Level Name',
      key: 'levelName',
    },
    {
      label: 'Timezone',
      key: 'timeSegName',
    },
  ];

  const [levelName, setLevelName] = useState('');
  const [disabledButton, setDisabledButton] = useState(true);
  const [levels, setLevels] = useState<any[]>([]);
  const [selectedLevels, setSelectedLevels] = useState<Level[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  // Filtered data based on search
  const filteredCheckboxTreeData = checkboxTreeData.filter((level) =>
    level.label.toLowerCase().includes(levelName.toLowerCase()),
  );

  const handleAdd = () => {
    onAddLevel(selectedLevels);
    onClose();
  };

  const fetchLevels = async (params: any) => {
    try {
      const response = await person.getPersonSelectLevel(params);
      setLevels(response.data.data);
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
    }
  };

  useEffect(() => {
    if (selectAll && selectedLevels.length !== filteredCheckboxTreeData.length) {
      setSelectAll(false);
    }

    if (selectedLevels.length > 0) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [selectedLevels, filteredCheckboxTreeData]);

  useEffect(() => {
    if (levelIds) {
      fetchLevels({
        notInId: levelIds.toString(),
        type: 'noSelected',
      });
    }
  }, [levelIds]);

  return (
    <>
      <div className="level-body">
        <div className="input-fields">
          <div className="search-container">
            <input
              type="text"
              placeholder={t('Search by level name, time zone')}
              value={levelName}
              onChange={(e) => setLevelName(e.target.value)}
              className="input-field"
              aria-label={t('Search by level name, time zone')}
            />
            <SearchIcon />
          </div>
        </div>
        <SelectedData
          selectFunction={setSelectedLevels}
          selectedValues={selectedLevels}
          values={levels}
          header={header}
          fromName={t('')}
          toName={t('')}
        />
      </div>

      <div className="modal-footer">
        <button type="button" onClick={onClose} className="cancel-button">
          {t('Cancel')}
        </button>
        <Button
          onClickFunction={handleAdd}
          title={t('Done')}
          disabled={disabledButton}
          className="btn-primary"
        />
      </div>
    </>
  );
}

export default AddLevelModal;
