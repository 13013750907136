import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/store';
import alertSound from '../../../../../assets/images/alert.wav';

// Use shared component
import InputField from '../../../../../shared-components/input-field/input-field';
import { setNotificationError } from '../../../../../redux/slices/NotificationSlices';
import Button from '../../../../../shared-components/button/button';
import FileUpload from '../../../../../shared-components/file-upload/file-upload';
import resourceFile from '../../../../../utils/api/SystemManagement/resourceFile';
import { fetchResourceFile } from '../../../../../redux/slices/system-management/ResourceFileSlices';

interface AddResources {
  fileAlias: string;
  name: string;
  size: string;
  suffix?: string;
  filePath?: string;
}

interface ModalTypeProps {
  closeModal: () => void;
  update?: AddResources;
}

function AddResource({ closeModal, update }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const [value, setValue] = useState<AddResources>(
    update || {
      fileAlias: '',
      name: '',
      size: '',
      suffix: '',
      filePath: '',
    },
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const alertAudioRef = useRef<HTMLAudioElement | null>(null);
  const [checkExistName, setCheckExistName] = useState(true);
  const handleFileUpload = async (file: File) => {
    setDisabled(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await resourceFile.saveUploadFile(formData);
      if (response.status === 200) {
        const message = t('fileUploadSuccess');
        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
      } else {
        dispatch(
          setNotificationError({
            error: response.data?.message || t('fileUploadFailed'),
            status: response.status || 500,
          }),
        );
      }
    } finally {
      setDisabled(false);
    }
  };
  const onChange = (names: string, changeValue: any) => {
    setValue((prev: any) => ({
      ...prev,
      [names]: changeValue,
    }));

    if (names === 'filePath') {
      const file = changeValue;
      if (file) {
        const sizeInMB = (file.size / 1024 / 1024).toFixed(2);
        const suffix = file.name.split('.').pop();
        const name = `${file.name} (${suffix})`;

        setValue((prev) => ({
          ...prev,
          name,
          size: `${sizeInMB} MB`,
          suffix,
        }));
        handleFileUpload(file);
      } else {
        setValue((prev) => ({
          ...prev,
          name: '',
          size: '',
          suffix: '',
        }));
      }
    }
  };

  const removeImage = () => {
    setValue((prev: any) => ({
      ...prev,
      filePath: null,
      name: '',
      size: '',
      suffix: '',
    }));
  };

  const handlePlay = () => {
    if (alertAudioRef.current) {
      alertAudioRef.current.play();
    }

    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const onBlur = async (name: string, values: any) => {
    if (name === 'name' && values.name !== '') {
      const responseData = await resourceFile.isExistName({ name: value.name });
      setCheckExistName(responseData?.data);
    }
  };
  const submit = () => {
    const request = {
      name: value.name,
      size: value.size,
      suffix: value.suffix,
      filePath: value.filePath,
    };
    resourceFile
      .save(request)
      .then((response) => {
        if (response?.data) {
          const message = update
            ? t('resourceFileUpdatedSuccessfully')
            : t('resourceFileAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(fetchResourceFile({ pageNo: 1 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (value.fileAlias && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value, checkExistName]);
  return (
    <div className="modal-page">
      <div className="statistics-page">
        <div className="rows row-1">
          <div className="grid-rows">
            <InputField
              id="name"
              name="name"
              label={t('fileAlias')}
              type="text"
              onBlurFunction={onBlur}
              errorStatus={value.name !== '' && !checkExistName}
              message={t('nameIsAlreadyExist')}
              value={value.name}
              onChangeFunction={onChange}
            />
            <InputField
              id="size"
              name="size"
              label={t('size')}
              type="text"
              value={value.size}
              disabled
            />
            <InputField
              id="suffix"
              name="suffix"
              label={t('suffix')}
              type="text"
              value={value.suffix}
              disabled
            />
          </div>
        </div>
        <FileUpload
          label={t('uploadFile')}
          name="filePath"
          value={value.filePath}
          removeFunction={removeImage}
          onChangeFunction={onChange}
          required
        />
        <div className="flex-row">
          <Button
            onClickFunction={handlePlay}
            title={isPlaying ? t('stop') : t('play')}
            className="btn-primary"
            disabled={!value.filePath}
          />
        </div>
        <audio ref={audioRef} loop>
          <source src={alertSound} type="audio/wav" />
          <track kind="captions" srcLang="en" label="English" />
          Your browser does not support the audio element.
        </audio>
        <div className="item text-danger">
          <div className="content">Please Upload a wav or MP3 File, the Size of 0 to 10MB</div>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={submit}
            title={t('save&New')}
            className="btn-primary"
            disabled={disabled}
          />
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-default"
          />
          <Button
            onClickFunction={submit}
            title={t('done')}
            className="btn-primary"
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}

AddResource.defaultProps = {
  update: {},
};

export default AddResource;
