import React from 'react';
import { useTranslation } from 'react-i18next';

function SelectedData({ fromName, toName, selectFunction, selectedValues, values, header }: any) {
  const { t } = useTranslation();

  const handleSelectAll = () => selectFunction([...values]);
  const handleDeSelectAll = () => selectFunction([]);

  const handleSelect = (level: any) => {
    if (selectedValues.find((val: any) => val.id === level.id)) {
      selectFunction([...selectedValues.filter((val: any) => val.id !== level.id)]);
    } else {
      selectFunction([...selectedValues, level]);
    }
  };

  return (
    <div className="levels-container">
      <div className="available-levels">
        <h3>{fromName}</h3>
        <table className="levels-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  id="selectAll"
                  onChange={handleSelectAll}
                  aria-labelledby="selectAllLabel"
                />
              </th>
              {header.map((val: any) => (
                <th>{val.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {values.map((level: any) => {
              const isSelected = selectedValues.some((l: any) => l.id === level.id);
              if (!isSelected) {
                return (
                  <tr key={level.levelName}>
                    <td>
                      <input
                        type="checkbox"
                        id={`checkbox-${level.levelName}`}
                        onChange={() => handleSelect(level)}
                        checked={isSelected}
                        aria-labelledby={`checkbox-${level.levelName}-label`}
                        aria-label={t(`Select ${level.levelName}`)}
                      />
                    </td>
                    {header.map((val: any) => (
                      <td>{level[val.key]}</td>
                    ))}
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
        </table>
      </div>
      <div className="selected-levels">
        <h3>{toName}</h3>
        <table className="levels-table">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  id="selectAllSelected"
                  checked={selectedValues.length > 0}
                  onChange={handleDeSelectAll}
                  aria-labelledby="selectAllLabelSelected"
                />
              </th>
              {header.map((val: any) => (
                <th>{val.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {selectedValues.map((level: any) => (
              <tr key={level.id}>
                <td>
                  <input
                    type="checkbox"
                    checked
                    onChange={() => handleSelect(level)}
                    aria-label={t('Selected Level')}
                  />
                </td>
                {header.map((val: any) => (
                  <td>{level[val.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SelectedData;
