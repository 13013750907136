import React from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';

// Use interface
import { ViewPersonnelInterFace } from '../../../interface/personnel.interface';

// Use assets images
import DemoProfile from '../../../assets/images/demo-profile.png';

function ViewPersonnel({
  closeModal,
  data = {
    pin: '',
    name: '',
    lastName: '',
    mobilePhone: '',
    cardNos: '',
    id: '',
  },
}: ViewPersonnelInterFace) {
  const { t } = useTranslation();
  return (
    <div className="view-personnel">
      <div className="view-personnel-form">
        <div className="row row-3">
          <div className="col">
            <InputField
              name="pin"
              label={t('personnelId')}
              id="pin"
              type="text"
              value={data.pin}
              readonly
            />
          </div>
          <div className="col">
            <InputField
              name="name"
              label={t('firstName')}
              id="name"
              type="text"
              value={data.name}
              readonly
            />
          </div>
          <div className="col full-size">
            <div className="image-view">
              <img src={DemoProfile} alt="profile" />
            </div>
            <div className="name">{t('photo')}</div>
          </div>
          <div className="col">
            <InputField
              name="lastName"
              label={t('lastName')}
              id="lastName"
              type="text"
              value={data.lastName}
              readonly
            />
          </div>
          <div className="col">
            <InputField
              name="mobilePhone"
              label={t('mobileNumber')}
              id="mobilePhone"
              type="text"
              value={data.mobilePhone}
              readonly
            />
          </div>
          <div className="col">
            <InputField
              name="cardNos"
              label={t('cardNumber')}
              id="cardNos"
              type="text"
              value={data.cardNos}
              readonly
            />
          </div>
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
        </div>
      </div>
    </div>
  );
}

export default ViewPersonnel;
