import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import { useAppDispatch } from '../../../redux/store';
import accessLevel from '../../../utils/api/AccessRule/accessLevel';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchAccessLevels } from '../../../redux/slices/AccessLevelSlices';

type ModalType = {
  closeModal: () => void;
  selectedId: string[];
};
function RemoteNormallyOpen({ closeModal, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const submit = async () => {
    try {
      const request = {
        ids: selectedId.join('&ids='),
      };
      const response = await accessLevel.remoteNormalOpenDoor(request);
      if (response?.data?.success) {
        dispatch(
          setNotificationError({
            error: response.data.msg,
            status: 200,
          }),
        );
        dispatch(fetchAccessLevels({ pageNo: 1 }));
        closeModal();
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  return (
    <div className="remote-opening-modal">
      <div className="target-section">
        <label htmlFor="target">{t('target')} :</label>
        <span>{t('allDoors')}</span>
      </div>
      <div className="confirmation-text">
        {t('areYouWantToPerformTheRemoteNormallyOpenOperation')}
      </div>

      <div className="form-buttons-right">
        <Button onClickFunction={() => closeModal()} title={t('cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary" />
      </div>
    </div>
  );
}

export default RemoteNormallyOpen;
