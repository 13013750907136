// import axios from 'axios';
import { post, get, fetch } from './base/index';

export default {
  getPersons: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/getPersonList`, {}, { ...data });
  },
  addPerson: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/persPerson/save`, data, {});
  },
  getStatistics: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/statistics`, data);
  },
  getCardTemplate: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/printcard/getTemplate`, {}, { ...data });
  },
  getPrintCardTemplate: (data: any) => {
    return get(`${process.env.REACT_APP_API_URL}Pers/api/printcard/getTemplates`, data);
  },
  getDismissedPersons: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/person/getDismissedList`,
      {},
      { ...data },
    );
  },
  editDismissedPersons: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/dismiss`, { ...data });
  },
  reinstatedDismissedPersons: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/reinstated`, { ...data });
  },
  changeDepartment: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/changeDepartment`, { ...data });
  },
  changePosition: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/changePosition`, { ...data });
  },
  deletePerson: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/delete/${id}`, {});
  },
  enablePersons: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/person/enabledPersCredential?ids=${data}`,
      {},
    );
  },
  disablePersons: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Pers/api/person/disableCredential?ids=${data}`,
      {},
    );
  },
  enableAppLogin: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/enabledApplogin?ids=${data}`, {});
  },
  disableAppLogin: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/disableApplogin?ids=${data}`, {});
  },
  resetSelfPwd: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/resetSelfPwd?ids=${data}`, {});
  },
  importPerson: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/importPerson`, data);
  },
  importPersBioTemplate: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/importPersBioTemplate`, data);
  },
  importLeavePerson: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/importLeavePerson`, data);
  },
  importPersPhoto: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/importPersPhoto`, data);
  },
  dlPersImprtTemplate: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Pers/api/person/dlPersImprtTemplate`,
      'POST',
      data,
    );
  },
  dlDismissionImprtTemplate: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Pers/api/person/dlDismissionImprtTemplate`,
      'POST',
      data,
    );
  },
  getPerson: (id: string) => {
    return get(`${process.env.REACT_APP_API_URL}Pers/api/person/get/${id}`, {});
  },
  exportPerson: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Pers/api/person/exportPerson`, 'POST', data);
  },
  exportPersBioTemplate: (data: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}Pers/api/person/exportPersBioTemplate`,
      'POST',
      data,
    );
  },
  exportPhoto: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/person/exportPhoto`, data);
  },
  getAccLevelList: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accPerson/edit`, {}, data);
  },
  getPersonSelectLevel: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevelByPerson/getPersonSelectLevel`,
      data,
      {
        pageNo: 1,
        pageSize: 50,
      },
    );
  },
  validPersonPhoto: (data: any) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/persPerson/validPersonPhoto`, data);
  },
};
