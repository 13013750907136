import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import Button from '../../../shared-components/button/button';
import accessByPerson from '../../../utils/api/AccessRule/accessByPerson';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import {
  AddToDefaultLevelProps,
  ApiParams,
  LevelListProps,
} from '../../../interface/accessByPerson.interface';

function AddLevels({ closeModal, personId }: AddToDefaultLevelProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [timeZone, setTimeZone] = useState<LevelListProps[]>([]); // Available timeZone
  const [selectedTimeZone, setSelectedTimeZone] = useState<LevelListProps[]>([]); // Selected timeZone
  const [search, setSearch] = useState('');
  const tableColumns = [
    { label: t('levelName'), id: 'levelName' },
    { label: t('timeZone'), id: 'timeSegName' },
  ];
  const fetchLevel = (params: ApiParams) => {
    accessByPerson
      .getPersonSelectLevel(params)
      .then((res) => {
        console.log(res.data.data);
        setTimeZone(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  // Function to handle table swapping
  const onTableSwap = (availableData: LevelListProps[], selectedData: LevelListProps[]) => {
    setTimeZone(availableData); // Update available data
    setSelectedTimeZone(selectedData); // Update selected data
  };
  const submit = () => {
    const selectedIds = selectedTimeZone.map((item: LevelListProps) => item.id).join(',');
    const req = {
      personId,
      levelIds: selectedIds,
    };
    accessByPerson
      .addToLevel(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('levelAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchLevel({
      personId,
      type: 'noSelected',
    });
  }, [personId]);
  return (
    <div className="add-level-add-personnel flex-row column">
      <SwapTable
        searchValue={search}
        changeSearch={(name: string, val: string) => setSearch(val)}
        tableColumns={tableColumns}
        tableData={timeZone}
        selectedTableData={selectedTimeZone}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('done')}
          className="btn-primary"
          disabled={selectedTimeZone.length === 0}
        />
      </div>
    </div>
  );
}

export default AddLevels;
