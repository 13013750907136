import React from 'react';

// Use interface
import { RadioButtonFieldInterFace } from '../../interface/common.interface';

// Use assets svg icons
import { RadioButtonActiveIcon, RadioButtonIcon } from '../../assets/svgicons/svgicon';

function RadioButtonField({
  name,
  data,
  value,
  label,
  alignment,
  position,
  required,
  // message,
  // errorStatus,
  onChangeFunction,
}: RadioButtonFieldInterFace) {
  const onChange = (selectName: string, selectValue: string | number): void => {
    onChangeFunction(selectName, selectValue);
  };
  return (
    <div className="radio-form-item">
      {label && (
        <label htmlFor={label} className="radio-label">
          {label}
          {required && <span> *</span>}
        </label>
      )}
      <div className={`radio-group-list ${alignment}`}>
        {data.map((radio) => (
          <div
            key={radio.id}
            className={`radio-field ${position} ${radio.disabled}`}
            onClick={() => radio.disabled !== 'disabled' && onChange(name, radio.value)}
            role="presentation">
            <div className="radio-icon">
              {value === radio.value ? <RadioButtonActiveIcon /> : <RadioButtonIcon />}
            </div>
            <div className="radio-title">{radio.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RadioButtonField;
