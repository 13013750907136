import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  fetchAccReaderList,
  fetchArea,
  fetchReaderDefineList,
} from '../../../../redux/slices/ReaderDefineSlices';
import readerDefine from '../../../../utils/api/AdvancedFunctions/readerDefine';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

interface ModalTypeProps {
  closeModal: () => void;
  update?: any;
}
interface AddReaderProps {
  readerId: string;
  accZoneToId: string;
  accZoneFromId: string;
}
function AddReader({ closeModal, update }: ModalTypeProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { accReaderList, areaList } = useAppSelector((state: any) => state.readerDefine);
  const [addReader, setAddReader] = useState<AddReaderProps>(
    update || {
      readerId: '',
      accZoneFromId: '',
      accZoneToId: '',
    },
  );
  const [disabled, setDisabled] = useState(false);
  const onChangeValue = (name: string, value: any) => {
    setAddReader((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = async () => {
    setDisabled(true);
    try {
      const response1 = await readerDefine.save(addReader);
      if (response1.data.success) {
        const message =
          Object.keys(update).length > 0
            ? t('readerDefineUpdatedSuccessfully')
            : t('readerDefineAddedSuccessfully');

        dispatch(
          setNotificationError({
            error: message,
            status: 200,
          }),
        );
        setDisabled(false);
        closeModal();
        dispatch(fetchReaderDefineList({ pageNo: 1, pageSize: 10 }));
      }
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.status,
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(fetchAccReaderList({ id: update?.readerId }));
    dispatch(fetchArea());
  }, []);
  useEffect(() => {
    if (addReader.readerId && addReader.accZoneToId && addReader.accZoneFromId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [addReader]);
  return (
    <div className="add-zone flex-row column">
      <div className="grid-row">
        <SelectBoxField
          id="readerId"
          name="readerId"
          label="Reader Name"
          value={addReader.readerId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={accReaderList}
          disabled={Object.keys(update).length !== 0}
          required
        />
        <SelectBoxField
          id="accZoneFromId"
          name="accZoneFromId"
          label="Goes From"
          value={addReader.accZoneFromId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={areaList}
          validation={
            addReader.accZoneFromId !== undefined &&
            addReader?.accZoneFromId === addReader?.accZoneToId
          }
          message="Cannot set the same zone"
          required
        />
        <SelectBoxField
          id="accZoneToId"
          name="accZoneToId"
          label="Goes To"
          value={addReader.accZoneToId}
          onChangeFunction={(name, value) => onChangeValue(name, value.id)}
          data={areaList}
          required
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddReader.defaultProps = {
  update: {},
};

export default AddReader;
