import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

interface ModalTypeProps {
  values: any;
  changeValue: (name: string, data: any) => void;
}

function SendSms({ values, changeValue }: ModalTypeProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(values);

  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
    changeValue(name, data);
  };

  return (
    <div className="intrusion">
      <div className="partition">
        <InputTextAreaField
          id="mobileNo"
          value={value.mobileNo}
          label={t('mobileNumber')}
          name="mobileNo"
          placeHolder="Ex: 8854621423 ; 9854756213"
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t('multipleNumber')}
      </div>
    </div>
  );
}

export default SendSms;
