import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import InputField from '../../../shared-components/input-field/input-field';
import { LoadingProgressCount, sortValuesByStatus } from '../../../utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import deviceApi from '../../../utils/api/device';
// import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { fetchTimeZone } from '../../../redux/slices/DeviceSlice';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';

interface ModalType {
  closeModal: () => void;
  selectedValues: any;
  selectedId: string[];
}
function DeviceTimeZone({ closeModal, selectedValues, selectedId }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { timeZone } = useAppSelector((state: any) => state.device);
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [responseMsg, setResponseMsg] = useState('');
  const [values, setValues] = useState({
    timeZone: selectedValues?.[0]?.timeZone,
    setDaylightSavingTime: t('noDSTForThisTimeZone'),
    selectedZone: '',
  });
  const [validHasDs, setValidHasDs] = useState(false);
  const [timezoneList, setTimezoneList] = useState([]);
  const hasDSTimeByTimeZones = (params: string) => {
    deviceApi
      .hasDSTimeByTimeZone(params)
      .then((res) => {
        setValidHasDs(!res.data.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  const onChangeValue = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const submit = () => {
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    deviceApi
      .synchronizeTime(selectedId.join('&'))
      .then((response) => {
        setResponseMsg(response.data.success);
        if (response.data.success) {
          setTimeout(() => {
            stopProgress();
            setLoading('0');
          }, 3500);
        } else {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        setLoading('0');
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    dispatch(fetchTimeZone('systemTimeZone'));
    hasDSTimeByTimeZones(selectedValues?.[0]?.timeZone);
    const zoneList = selectedValues.map((item: any) => ({
      label: `UTC${item.timeZone}: `,
      id: item.id,
      value: item.id,
    }));
    setTimezoneList(zoneList);
    setValues((prev) => ({
      ...prev,
      selectedZone: zoneList?.[0]?.id,
    }));
  }, [selectedValues]);
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled status
   */
  // if the inputs disabled based on the status is online or offline
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const getOnlineDevice = selectedDevices.map((data: any) => data?.status).includes('online');

  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <div className="select-device-wrapper">
                    <RadioButtonField
                      label=""
                      name="selectedZone"
                      alignment="column"
                      data={timezoneList}
                      value={values.selectedZone}
                      onChangeFunction={onChangeValue}
                    />
                    <span className="active-name">{device?.alias}</span>
                  </div>
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.alias)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disabled').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.alias)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <div className="verification-options">
            <div className="input-row">
              <SelectBoxField
                name="timeZone"
                label={t('timeZone')}
                id="timeZone"
                value={values?.timeZone}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={timeZone}
                className=""
              />
              <InputField
                name="setDaylightSavingTime"
                label={t('setDaylightSavingTime')}
                id="setDaylightSavingTime"
                value={values?.setDaylightSavingTime}
                onChangeFunction={onChangeValue}
                type="text"
                className=""
                readonly={validHasDs}
              />
            </div>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={!getOnlineDevice}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && responseMsg && (
                <div className="success-wrapper">
                  <p>Data Processing, Please Wait.....</p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Time Zone Setting......
                  </p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Time Zone Command Send Success...
                  </p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Removing Device Daylight Saving Time...
                  </p>
                  <p>
                    {selectedDevices.map((item: any) => (
                      <span>{item.alias}: </span>
                    ))}
                    Daylight Saving Time Command Send Success...
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DeviceTimeZone;
