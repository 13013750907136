import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import { BindingUnbindingCameraModalProps } from '../../../interface/accessReader.interface';

function BindingUnbindingCameraModal({ closeModal, onConfirm }: BindingUnbindingCameraModalProps) {
  const { t } = useTranslation();

  return (
    <div className="camera-modal">
      <div className="camera-p">
        <p>{t('theSystemDoesNotDetectaVideoDevice')}</p>
        <p>{t('pleaseAddaVideoDevice')}</p>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => closeModal()}
          title={t('cancel')}
          className="btn-default btn-bold"
        />
        <Button
          onClickFunction={() => {
            onConfirm();
            closeModal();
          }}
          title={t('ok')}
          className="btn-primary btn-bold"
        />
      </div>
    </div>
  );
}

export default BindingUnbindingCameraModal;
