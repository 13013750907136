import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import InputField from '../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import glboalInterlockGroupApi from '../../../../utils/api/AdvancedFunctions/globalInterlockGroup';
import { fetchGlobalInterlockGroup } from '../../../../redux/slices/GlobalInterlockGroupSlices';

import {
  AddInterlockGroupProps,
  GlobalType,
} from '../../../../interface/globalInterlockGroup.interface';

function AddGlobalInterlock({ closeModal, data }: GlobalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { searchFilter } = useAppSelector((state: any) => state.globalInterlockGroup);
  const [value, setValue] = useState<AddInterlockGroupProps>({
    name: data?.name || '',
    remarks: data?.remarks || '',
    id: data?.id || '',
    isGroupInterlock: data?.isGroupInterlock || false, // Set default to false
  });

  const [checkExistName, setCheckExistName] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const onChangeValue = (name: string, values: any) => {
    setValue((prev: AddInterlockGroupProps) => ({
      ...prev,
      [name]: values,
    }));
  };

  const onBlur = async (name: string, values: any) => {
    if (name === 'name' && values !== '' && values !== data?.name) {
      const responseData = await glboalInterlockGroupApi.isExistName(values);
      setCheckExistName(responseData?.data);
    }
  };

  const submit = () => {
    const request = {
      name: value.name,
      id: value?.id,
      remark: value.remarks,
      isGroupInterlock: value.isGroupInterlock, // Include isGroupInterlock in request
    };
    glboalInterlockGroupApi
      .saveGroup(request)
      .then((response) => {
        if (response?.data) {
          const message = data
            ? t('globalInterlockGroupUpdatedSuccessfully')
            : t('globalInterlockGroupAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(
            fetchGlobalInterlockGroup({
              ...searchFilter.filter,
              pageNo: 1,
              pageSize: 10,
            }),
          );
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  useEffect(() => {
    if (value?.name && checkExistName) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [value, checkExistName]);

  return (
    <div className="flex-row column" style={{ width: '38vw' }}>
      <div className="flex-row">
        <InputField
          id="name"
          name="name"
          label={t('groupName')}
          type="text"
          value={value?.name}
          onBlurFunction={onBlur}
          onChangeFunction={onChangeValue}
          errorStatus={value.name !== '' && !checkExistName}
          message={t('nameIsAlreadyExist')}
          required
        />
        <InputField
          id="remarks"
          name="remarks"
          label={t('remarks')}
          type="text"
          value={value?.remarks}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="isGroupInterlock"
          checked={value.isGroupInterlock}
          onChange={(event) => onChangeValue('isGroupInterlock', event.target.checked)}
        />
        <label htmlFor="isGroupInterlock">{t('groupInterlock')}</label>
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddGlobalInterlock.defaultProps = {
  data: {
    name: '',
    remarks: '',
  },
};

export default AddGlobalInterlock;
