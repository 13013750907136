import React from 'react';

interface ModalType {
  data: string;
}

function ImagePreview({ data }: ModalType) {
  return (
    <div className="photo-path-preview">
      <div className="img-wrapper">
        <img src={data} alt="img" className="preview-image" />
      </div>
    </div>
  );
}

export default ImagePreview;
