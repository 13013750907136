import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import InputDateTime from '../../../../shared-components/input-time-date/input-time-date';

type AddData = {
  startDate?: string | Dayjs | null;
  endDate?: string | Dayjs | null;
};
interface InitialValueProps {
  activeTime: any;
  activeTimeZone: any;
  startDate?: string | Dayjs | null;
  endDate?: string | Dayjs | null;
}
interface AddLinkageInterface {
  data?: AddData;
}
function GlobalActiveTime({ data }: AddLinkageInterface) {
  const { t } = useTranslation();
  const [value, setValue] = useState<InitialValueProps>({
    activeTime: [],
    activeTimeZone: '',
    startDate: data?.startDate ? dayjs(data.startDate) : dayjs(),
    endDate: data?.endDate ? dayjs(data.endDate) : dayjs(),
  });
  const onChangeValue = (name: string, values: any) => {
    setValue((prev: InitialValueProps) => ({
      ...prev,
      [name]: values,
    }));
  };
  const changeDateTime = (dateValue: any, type: string) => {
    setValue((prev: any) => ({
      ...prev,
      [type]: dayjs(dateValue),
    }));
  };

  const handleAcceptDate = (dateValue: any, type: string) => {
    setValue((prev: any) => ({
      ...prev,
      [type]: dayjs(dateValue),
    }));
  };

  return (
    <div className="flex-row column">
      <div className="col">
        <SelectBoxField
          name="activeTimeZone"
          label={t('activeTimeZone')}
          id="activeTimeZone"
          value={value?.activeTimeZone}
          onChangeFunction={(name, values) => onChangeValue(name, values.id)}
          data={[
            { label: t('24-Hour Accessible'), id: '1' },
            { label: t('2 to 10'), id: '2' },
            { label: t('Full Day Access'), id: '3' },
          ]}
          className=""
        />
      </div>
      <div className="flex-row">
        <CheckBoxInputField
          name="activeTime"
          data={[
            {
              label: t('activeTime'),
              id: 'yes',
              value: 'yes',
            },
          ]}
          value={value?.activeTime}
          onChangeFunction={onChangeValue}
        />
      </div>
      <div className="row row-3">
        <InputDateTime
          label={t('startDate')}
          value={value.startDate}
          onChangeFunction={(val) => changeDateTime(val, 'startDate')}
          okFunction={(val) => handleAcceptDate(val, 'startDate')}
          disabled={value?.activeTime?.length === 0}
        />
        <InputDateTime
          label={t('endDate')}
          value={value.endDate}
          onChangeFunction={(val) => changeDateTime(val, 'endDate')}
          okFunction={(val) => handleAcceptDate(val, 'endDate')}
          disabled={value?.activeTime?.length === 0}
        />
      </div>
    </div>
  );
}
GlobalActiveTime.defaultProps = {
  data: {
    endDate: '',
    startDate: '',
    activeTime: [],
  },
};
export default GlobalActiveTime;
