import React, { useEffect, useRef, useState } from 'react';

// Use common modules
import { useTranslation } from 'react-i18next';
import * as htmlToImage from 'html-to-image';
import QRCode from 'react-qr-code';

// Use shared components
import InputField from '../../../shared-components/input-field/input-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';

// Use interface
import { ParameterInterface } from '../../../interface/parameter.interface';
// import { QRcode } from '../../../assets/svgicons/svgicon';

function SelfServiceRegistration({ onChangeFunction, values }: ParameterInterface) {
  const { t } = useTranslation();
  const qrCodeRef: any = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(values);
  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qr-code.png';
        link.click();
      })
      .catch((error) => {
        console.error('Error generating QR code:', error);
      });
  };

  const onChange = (name: string, changeValue: string | number) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));
  };

  useEffect(() => {
    onChangeFunction(value);
  }, [value]);
  return (
    <div className="personnel-setting">
      <div className="personnel-setting-form w50">
        <div className="column">
          <div className="item">
            <RadioButtonField
              label={t('enableSelfRegistration')}
              name="pers.selfRegistration"
              alignment="row"
              required
              data={[
                {
                  label: t('yes'),
                  id: '1',
                  value: '1',
                },
                {
                  label: t('no'),
                  id: '0',
                  value: '0',
                },
              ]}
              value={value['pers.selfRegistration']}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item">
            <InputField
              name="qrCodeUrl"
              label={t('qrCodeUrl')}
              id="qrCodeUrl"
              type="text"
              value={value.qrCodeUrl}
              required
              innerIconStatus={false}
              onChangeFunction={onChange}
            />
          </div>
          <div className="item item-2">
            {/* <QRcode /> */}
            {value.qrCodeUrl && (
              <div className="qrcode" ref={qrCodeRef}>
                <QRCode value={value.qrCodeUrl} size={100} />
              </div>
            )}
            <div className="text" onClick={downloadQRCode} role="presentation">
              {t('downloadQRCode')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelfServiceRegistration;
