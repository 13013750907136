import React, { useState } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Shared components
import InputField from '../../../shared-components/input-field/input-field';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';

interface AccessControlSettingProps {
  closeModal: () => void;
  submit: () => void;
  disable: boolean;
}

function AccessControlSetting({ closeModal, submit, disable }: AccessControlSettingProps) {
  const { t } = useTranslation();
  const [isExtendPassage, setExtendPassage] = useState(false);
  const [isAccessDisabled, setAccessDisabled] = useState(false);
  const [isSetValidTime, setSetValidTime] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [superUser, setSuperUser] = useState('yes');
  const [deviceOperationRule, setDeviceOperationRule] = useState('ordinaryUser');

  return (
    <div className="access-control-setting">
      <div className="row">
        <div className="col">
          <RadioButtonField
            label={t('superUser')}
            name="superUser"
            data={[
              { label: t('yes'), value: 'yes', id: 'yes' },
              { label: t('no'), value: 'no', id: 'no' },
            ]}
            value={superUser}
            onChangeFunction={(name, value) => setSuperUser(value as string)}
          />
        </div>
        <div className="col">
          <CheckBoxInputField
            name="extendPassage"
            data={[
              {
                label: t('extendPassage'),
                id: 'extendPassage',
                value: 'extendPassage',
              },
            ]}
            value={isExtendPassage ? ['extendPassage'] : []}
            onChangeFunction={() => setExtendPassage(!isExtendPassage)}
          />
          <CheckBoxInputField
            name="accessDisabled"
            data={[
              {
                label: t('accessDisabled'),
                id: 'accessDisabled',
                value: 'accessDisabled',
              },
            ]}
            value={isAccessDisabled ? ['accessDisabled'] : []}
            onChangeFunction={() => setAccessDisabled(!isAccessDisabled)}
          />
          <CheckBoxInputField
            name="setValidTime"
            data={[
              {
                label: t('setValidTime'),
                id: 'setValidTime',
                value: 'setValidTime',
              },
            ]}
            value={isSetValidTime ? ['setValidTime'] : []}
            onChangeFunction={() => setSetValidTime(!isSetValidTime)}
          />
        </div>
        {isSetValidTime && (
          <div className="col time-fields">
            <InputField
              name="startTime"
              id="startTime"
              label={t('startTime')}
              type="text"
              value={startTime}
              onChangeFunction={(name, value) => setStartTime(value)}
            />
            <InputField
              name="endTime"
              id="endTime"
              label={t('endTime')}
              type="text"
              value={endTime}
              onChangeFunction={(name, value) => setEndTime(value)}
            />
          </div>
        )}
      </div>
      <div className="col">
        <RadioButtonField
          label={t('deviceOperationRule')}
          name="deviceOperationRule"
          data={[
            { label: t('ordinaryUser'), value: 'ordinaryUser', id: 'ordinaryUser' },
            { label: t('enroller'), value: 'enroller', id: 'enroller' },
            { label: t('administrator'), value: 'administrator', id: 'administrator' },
          ]}
          value={deviceOperationRule}
          onChangeFunction={(name, value) => setDeviceOperationRule(value as string)}
        />
      </div>
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={disable}
        />
      </div>
    </div>
  );
}

export default AccessControlSetting;
