import { post } from './base/index';

export default {
  getParameters: () => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/params/getAllParams`, {});
  },
  addParameters: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Pers/api/params/add`, { ...data });
  },
};
