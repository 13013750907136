/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: '',
  status: false,
  type: 'danger',
  code: 0,
};

export const notificationSlice: any = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationError: (state, action: any) => {
      state.status = true;
      state.message = action.payload?.error;
      state.type = action.payload?.status === 200 ? 'success' : 'danger';
      state.code = action.payload?.status;
    },
    // temp
    setNotificationSuccess: (state, action: any) => {
      state.status = true;
      state.message = action.payload.message;
      state.type = 'success';
      state.code = 200; // Default success code
    },
    closeNotification: (state) => {
      state.status = false;
      state.message = '';
      state.type = '';
      state.code = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNotificationError, closeNotification, setNotificationSuccess } =
  notificationSlice.actions;

export default notificationSlice.reducer;
