/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import allExceptionEvents from '../../utils/api/AccControlReports/allExceptionEvents';

export const fetchAlarmTransactionList = createAsyncThunk(
  'acc/fetchAlarmTransactionList',
  async (data: any, { dispatch }) => {
    const response = await allExceptionEvents
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
        return error;
      });
    return response;
  },
);

interface ReduxInterface {
  alarmTransaction: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReduxInterface = {
  alarmTransaction: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const allExceptionEventsSlice: any = createSlice({
  name: 'allExceptionEvents',
  initialState,
  reducers: {
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAlarmTransactionList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAlarmTransactionList.fulfilled, (state: any, action: any) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.size;
          state.currentPageNo = action.payload.pageNo;

          state.alarmTransaction = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchAlarmTransactionList.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter } = allExceptionEventsSlice.actions;

export default allExceptionEventsSlice.reducer;
