import React from 'react';
import Button from '../button/button';
import { FormRightButtonProps } from '../../interface/common.interface';

function FormRightButton({
  submitLabel,
  cancelFunction,
  cancelLabel,
  submitFunction,
  disabled,
}: FormRightButtonProps) {
  return (
    <div className="form-buttons-right">
      <Button onClickFunction={cancelFunction} title={cancelLabel} className="btn-default" />
      <Button
        onClickFunction={submitFunction}
        title={submitLabel}
        className="btn-primary"
        disabled={disabled}
      />
    </div>
  );
}

export default FormRightButton;
