import React, { useEffect } from 'react';

// Provides routing capabilities for the application
import { BrowserRouter } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material'; // MUI library components for theme customization
import rtlPlugin from 'stylis-plugin-rtl'; // Plugin to handle RTL (right-to-left) styles
import { CacheProvider } from '@emotion/react'; // Emotion CacheProvider to manage the style cache
import createCache from '@emotion/cache'; // Creates a cache for storing styles
import { prefixer } from 'stylis'; // Prefixer for adding vendor prefixes to CSS
import cookies from 'js-cookie'; // Library to handle cookies

import Router from './pages/router'; // Main router component for the application
import './scss/index.scss'; // Main SCSS file for styling

// Use shared components
import Notification from './shared-components/notification/notification';

function App() {
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const theme = createTheme({
    direction: currentLanguageCode === 'ar' ? 'rtl' : 'ltr',
  });

  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const cacheltr = createCache({
    key: 'muiltr',
  });

  useEffect(() => {
    document.body.dir = currentLanguageCode === 'ar' ? 'rtl' : 'ltr';
  }, [currentLanguageCode]);

  return (
    <div className="App">
      <CacheProvider value={currentLanguageCode === 'ar' ? cacheRtl : cacheltr}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Router />
            <Notification />
          </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default App;
