import { get, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/zone/listByPage`, {}, { ...data });
  },
  addZone: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/zone/save`, data, {});
  },
  isExistCode: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/zone/valid/${data}`, {}),
  isExistName: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/zone/isExistName/${data}`, {}),
  getRulesFromZone: (data: any = {}) => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/zone/getRulesFromZone/${data}`, {});
  },
  deleteZone: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/zone/delete/${data}`, {});
  },
  getGlobalApbZoneList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/zone/getGlobalApbZoneList`, {}, data);
  },
  getZoneList: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/zone/getZoneList`, {});
  },
};
