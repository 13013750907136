import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { sortValuesByStatus } from '../../../utils/helpers';

function BgVerificationOptions({ closeModal, selectedValues }: any) {
  const { t } = useTranslation();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [values, setValues] = useState({
    bgVerification: 'accessLevel',
    deviceOffline: 'disable',
    onlineStatus: 'on',
  });
  const onChangeValue = (name: string, value: any) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  /**
   * @sortValuesByStatus - Helper to sort devices based on their offline/disabled status
   */
  const selectedDevices = selectedValues.sort(sortValuesByStatus);
  const submit = () => {
    console.log('hi');
  };
  // if the inputs disabled based on the status is online or offline
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDevices.map(
              (device: any) =>
                device?.status === 'online' && (
                  <RadioButtonField
                    label=""
                    name="onlineStatus"
                    alignment="column"
                    data={[
                      {
                        label: t('The devices which have disabled background verification'),
                        id: 'on',
                        value: 'on',
                      },
                    ]}
                    value="on"
                    onChangeFunction={() => {}}
                  />
                ),
            )}
            {selectedDevices.filter((device: any) => device?.status === 'offline').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDevices.filter((device: any) => device?.status === 'disable').length > 0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDevices
                    .filter((item: any) => item?.status === 'disable')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          <div className="verification-options">
            <h4>{t('setBg-VerificationOptions')}</h4>
            <div className="input-row">
              <SelectBoxField
                name="bgVerification"
                label={t('backgroundVerification')}
                id="bgVerification"
                value={values?.bgVerification}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={[
                  { label: t('enable'), id: 'enable' },
                  { label: t('disable'), id: 'disable' },
                ]}
                className=""
                disabled
              />
              <SelectBoxField
                name="deviceOffline"
                label={t('ifTheDeiceIsOffline')}
                id="deviceOffline"
                value={values?.deviceOffline}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={[
                  { label: t('standardAccessLevel'), id: 'accessLevel' },
                  { label: t('accessDenied'), id: 'accessDenied' },
                ]}
                className=""
                disabled
              />
            </div>
          </div>
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={!selectedDevices.map((device: any) => device.status).includes('online')}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && <div className="box" />}
        </div>
      </div>
    </div>
  );
}

export default BgVerificationOptions;
