import { get, post } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/listByPage`, {}, { ...data });
  },
  selectDevicelist: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/linkage/selectDevicelist`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  isSupportLockDoor: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/isSupportLockDoor`, {}, data);
  },
  getLinkTriggerOpt: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/getLinkTriggerOpt`, {}, data);
  },
  getInOutInfo: (data: any = {}) => {
    return get(
      `${process.env.REACT_APP_API_URL}Acc/api/linkage/getInOutInfo?devId=${data.devId}&triggerOpt=${data.triggerOpt}`,
      {},
    );
  },
  getSelectIasPartition: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/getSelectIasPartition`, data, {});
  },
  save: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/save`, data, {});
  },
  isExistName: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/linkage/isNameValid/${data}`, {}),
};
