import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InputField from '../../../shared-components/input-field/input-field';
import { Search } from '../../../assets/svgicons/svgicon';
import CardStatus from '../../../shared-components/card-status/cardStatus';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import RealTimeCommonPopup from './realTime-common-popup';
import realtimeMonitoring from '../../../utils/api/Devices/realtimeMonitoring';

interface AuxiliaryData {
  status: string;
  device: string;
  number: string;
  title: string;
  id: number;
}

function AuxiliaryOutput({ data }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [selectedDatas, setSelectedDatas] = useState<number[]>([]);
  const [auxiliaryOutput, setauxiliaryOutput] = useState<AuxiliaryData[]>([]);
  const allAuxiliaryOutputIds = auxiliaryOutput.map((item) => item.id);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });

  const header = [
    {
      label: t('status'),
      key: 'status',
    },
    {
      label: t('device'),
      key: 'devAlias',
    },
    {
      label: t('number'),
      key: 'no',
    },
  ];

  const statusData = [
    {
      title: t('online'),
      count: auxiliaryOutput.filter((item) => item.status === 'Online')?.length || 0,
      color: 'success',
    },
    {
      title: t('offline'),
      count: auxiliaryOutput.filter((item) => item.status === 'Offline')?.length || 0,
      color: 'danger',
    },
    {
      title: t('disable'),
      count: auxiliaryOutput.filter((item) => item.status === 'Disable')?.length || 0,
      color: 'warning',
    },
    {
      title: t('unknown'),
      count: auxiliaryOutput.filter((item) => item.status === 'Unknown')?.length || 0,
      color: 'secondary',
    },
    {
      title: t('currentTotal'),
      count: auxiliaryOutput?.length || 0,
    },
  ];

  const moreActionButton: any = [
    {
      title: t('remoteOpening'),
      clickFunction: (): void => {
        setModalView({
          title: t('remoteOpening'),
          content: (
            <RealTimeCommonPopup
              allDoorIds={allAuxiliaryOutputIds}
              selectedDatas={selectedDatas}
              modelName="remoteOpen"
              selected={selectedCards}
              closeModal={() => setModalOpen(false)}
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('remoteClosing'),
      clickFunction: (): void => {
        setModalView({
          title: t('remoteClosing'),
          content: (
            <RealTimeCommonPopup
              allDoorIds={allAuxiliaryOutputIds}
              selectedDatas={selectedDatas}
              modelName="remoteClose"
              selected={selectedCards}
              closeModal={() => setModalOpen(false)}
              message={t('areYouWantToPerformTheRemoteClosingOperation')}
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      title: t('remoteNormallyOpen'),
      clickFunction: (): void => {
        setModalView({
          title: t('remoteNormallyOpen'),
          content: (
            <RealTimeCommonPopup
              allDoorIds={allAuxiliaryOutputIds}
              selectedDatas={selectedDatas}
              modelName="remoteNormalOpen"
              selected={selectedCards}
              closeModal={() => setModalOpen(false)}
              message={t('areYouWantToPerformTheRemoteNormallyOpenOperation')}
            />
          ),
        });
        setModalOpen(true);
      },
    },
  ];

  const handleCardSelect = (paramsId: any) => {
    setSelectedDatas(paramsId);
    setSelectedCards(
      (prevSelected: any) =>
        prevSelected.includes(paramsId.id)
          ? prevSelected.filter((id: any) => id !== paramsId.id) // Remove if already selected
          : [...prevSelected, paramsId.id], // Add to selected list if not selected
    );
  };

  useEffect(() => {
    realtimeMonitoring
      .getAllAuxOut()
      .then((response) => {
        const mappedData = response.data.data
          ? response.data.data.map((output: any) => {
              const matchingDevice = data.find((item: any) => item.id === output.id);
              return {
                ...output,
                sensor:
                  matchingDevice?.relay === 'acc_rtMonitor_unknown' ? 'Unknown' : output?.relay,
                title: output.name,
                image: matchingDevice?.image || null,
                relay:
                  matchingDevice?.relay === 'acc_rtMonitor_unknown' ? 'Unknown' : output?.relay,
                opDisplay: matchingDevice?.opDisplay || null,
                alarm: matchingDevice?.alarm === 'common_none' ? 'None' : output?.alarm,
                status: matchingDevice?.connect === 1 ? 'Online' : 'Offline',
              };
            })
          : [];
        setauxiliaryOutput(mappedData);
      })
      .catch((error) => {
        console.error('Error fetching auxiliary output data:', error);
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setauxiliaryOutput([]);
      });
  }, [dispatch]);
  return (
    <div className="rtm-door">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="search-row">
        <div className="search-input">
          <InputField
            name="search"
            label={t('search')}
            id="name"
            type="text"
            value={search}
            innerIconStatus
            InnerIcon={Search}
            onChangeFunction={(name: string, value: any) => setSearch(value)}
          />
        </div>
        <CardRightButtonGroup
          actions={moreActionButton}
          buttonIcon={false}
          buttonLabel=""
          buttonClass=""
          buttonStatus={false}
          buttonFunction={() => {}}
        />
      </div>
      <div className="card-grid">
        {auxiliaryOutput.map((item, index) => (
          <CardStatus
            key={item.title}
            data={item}
            header={header}
            index={index}
            onSelect={handleCardSelect}
            isSelected={selectedCards.includes(item.id)}
          />
        ))}
      </div>
      <div className="status-container">
        {statusData.map((status: any) => (
          <div className="status-wrap">
            {status?.color && <div className={`circle-dot ${status?.color}`} />}
            <span style={{ color: '#696C6F' }}>
              {status?.title} : {status?.count}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AuxiliaryOutput;
