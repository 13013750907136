/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import areaHeadCount from '../../utils/api/AdvancedFunctions/areaHeadCount';

export const fetchAreaHeadCountList = createAsyncThunk(
  'acc/fetchAreaHeadCountList',
  async (data: any, { dispatch }) => {
    const response = await areaHeadCount
      .getListPage(data)
      .then((response1) => {
        return {
          ...response1.data,
          data: {
            list: response1.data.data.data,
          },
          size: response1.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return response;
  },
);

interface ReaderReduxInterface {
  areaHeadCounts: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
}

const initialState: ReaderReduxInterface = {
  areaHeadCounts: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
};

export const areaHeadCountSlice: any = createSlice({
  name: 'reader',
  initialState,
  reducers: {
    deleteAreaHeadCount: (state, action: any) => {
      state.areaHeadCounts = state.areaHeadCounts.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchAreaHeadCountList.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchAreaHeadCountList.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.areaHeadCounts = action.payload.data.list;
          state.totalDataCount = action.payload.size;
        }
        state.status = 'success';
      })
      .addCase(fetchAreaHeadCountList.rejected, (state: any) => {
        state.status = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, deleteAreaHeadCount } = areaHeadCountSlice.actions;

export default areaHeadCountSlice.reducer;
