import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import { CommunicationInterface } from '../../../interface/accessDevice.interface';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';

function SwitchNetworkConnection({ closeModal, selectedId }: CommunicationInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedDevice, setSelectedDevice] = useState('Wired');
  const [errorMsg, setErrorMsg] = useState('');

  const onChangeValue = (name: string, value: string | number) => {
    setSelectedDevice(value.toString()); // Ensures value is always a string
  };

  const submit = () => {
    closeModal();
  };

  const getModifyCommInfo = () => {
    const request = {
      id: selectedId,
      type: 'updateNetConnectMode',
    };
    device
      .getDevNetConnectModeInfo(request)
      .then((res) => {
        if (res.data.success) {
          setErrorMsg(res.data.data.failedReason);
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    if (selectedId) {
      getModifyCommInfo();
    }
  }, [selectedId]);

  return (
    <div className="switch-network-modal">
      {errorMsg ? (
        <span>{errorMsg === '2' ? 'Device is disabled try again later!' : errorMsg}</span>
      ) : (
        <div className="form-row">
          <label htmlFor="networkType">{t('selectedDevice')}</label>
          <RadioButtonField
            label=""
            name="networkType"
            alignment=""
            data={[
              { label: t('wired'), id: 'wired', value: 'Wired' },
              { label: t('4G'), id: '4g', value: '4G' },
              { label: t('wIFI'), id: 'wifi', value: 'WIFI' },
            ]}
            value={selectedDevice}
            onChangeFunction={onChangeValue}
          />
        </div>
      )}

      <div className="modal-footer">
        {!errorMsg && (
          <Button
            onClickFunction={closeModal}
            title={t('Cancel')}
            aria-label={t('Cancel')}
            className="btn-default"
          />
        )}
        <Button
          onClickFunction={() => {
            if (errorMsg) {
              closeModal();
            } else {
              submit();
            }
          }}
          title={t('ok')}
          aria-label={t('OK')}
          className="btn-primary"
        />
      </div>
    </div>
  );
}

export default SwitchNetworkConnection;
