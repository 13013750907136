import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';

// Use shared components
import Button from '../../../shared-components/button/button';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';

// Use interface
import { ImportDataInterFace } from '../../../interface/common.interface';

// Import the API module
import PositionApis from '../../../utils/api/position';

// Use redux function
import { setNotificationError } from '../../../redux/slices/NotificationSlices'; // Import the notification action
import MultipleImageUpload from '../../../shared-components/multiple-file-upload/multiple-file-upload';
import { fetchPersonsList } from '../../../redux/slices/PersonSlices';

function ImportData({ closeModal }: ImportDataInterFace) {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useAppDispatch();
  const selectData = [
    {
      label: t('excel'),
      id: 'xls',
    },
  ];
  const [value, setValue] = useState({
    fileType: '',
    fileList: [],
    updateExistingData: '',
  });

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const submit = async () => {
    setDisableButton(true);
    const formData = new FormData();

    // Assuming fileList is an array of files
    if (value.fileList.length > 0) {
      formData.append('upload', value.fileList[0]); // Append the first file, adjust if multiple files are required
    }
    formData.append('updateExistData', value.updateExistingData === 'yes' ? '1' : '0');

    PositionApis.importPosition(formData)
      .then((response: any) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('positionImportSuccessfully'),
              status: 200, // Success status code
            }),
          );
          dispatch(fetchPersonsList({ pageNo: 1 }));
          closeModal();
          setDisableButton(false);
        } else {
          setDisableButton(false);
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message || 'An error occurred during file download',
            status: error.response?.status || 500, // Use error.response?.status for better error handling
          }),
        );
        setDisableButton(false); // Re-enable the button in case of an error
      });
  };

  useEffect(() => {
    // Enable the button if the 'fileType' field is filled
    const isButtonEnabled = value.fileType !== '';
    setDisableButton(!isButtonEnabled);
  }, [value.fileType]);
  return (
    <div className="import-data">
      <div className="row row-1">
        <div className="col">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={value.fileType}
            required
            onChangeFunction={(name, changeValue) => onChange(name, changeValue.id)}
            data={selectData}
            className=""
          />
        </div>
        <div className="item">
          <MultipleImageUpload
            title={t('uploadFile')}
            required
            name="fileList"
            value={value.fileList}
            onClick={(name, changeValue) => {
              onChange(name, changeValue);
            }}
          />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit} // Use the submit function here
            title={t('import')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ImportData;
