import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import alertSound from '../../../../assets/images/alert.wav';
import { DropdownItem } from '../../../../interface/accessEventType.interface';
import eventTypeApi from '../../../../utils/api/Devices/eventType';
import Button from '../../../../shared-components/button/button';

function GlobalLinkageVoicePrompts() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [eventSound, setEventSound] = useState('');
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const clickPlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await eventTypeApi.getMediaFileList();
        const formattedData: DropdownItem[] = response.data.item.map(
          (item: { text: string; id: string | number }) => ({
            label: item.text,
            id: item.id,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
  }, []);
  return (
    <div className="set-audio">
      <div className="flex-row">
        <SelectBoxField
          name="linkageVoice"
          label={t('linkageVoice')}
          id="linkageVoice"
          value={eventSound}
          onChangeFunction={(name, value: any) => setEventSound(value.id)}
          data={dropdownData}
          className=""
        />
        <Button
          onClickFunction={clickPlay}
          title={isPlaying ? t('stop') : t('play')}
          className="btn-primary"
          disabled={!eventSound}
        />
      </div>

      {/* Audio element with continuous playback */}
      <audio ref={audioRef} loop>
        <source src={alertSound} type="audio/wav" />
        <track kind="captions" srcLang="en" label="English" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default GlobalLinkageVoicePrompts;
