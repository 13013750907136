import React, { useState, useEffect } from 'react';

// Use Translation
import { useTranslation } from 'react-i18next';

// Use shared components
import Button from '../button/button';
import SelectBoxField from '../selectbox-field/selectbox-field';

// Use interface
import { ImportDataInterFace } from '../../interface/common.interface';

// / Import the helper function
import { fileDownload, getCurrentDateTimeWithTimezone } from '../../utils/helpers'; // Path based on your project structure
// Import the API module
import PersonalApis from '../../utils/api/person';
import { useAppDispatch } from '../../redux/store';
import { setNotificationError } from '../../redux/slices/NotificationSlices'; // Import the notification action

function ImportData({ closeModal, submitFunction = () => {} }: ImportDataInterFace) {
  const { t } = useTranslation();
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useAppDispatch();
  const selectData = [
    {
      label: t('excel'),
      id: 'xls',
    },
  ];
  const [value, setValue] = useState({
    fileType: '',
    fileList: [],
    updateExistingData: '',
  });

  const onChange = (name: string, changeValue: any) =>
    setValue((prev: any) => ({
      ...prev,
      [name]: changeValue,
    }));

  const submit = async () => {
    setDisableButton(true);

    PersonalApis.dlDismissionImprtTemplate({})
      .then((response: any) => response.blob())
      .then((data) => {
        const currentDateTime = getCurrentDateTimeWithTimezone(); // Use helper function
        fileDownload(data, `Dismission_import_template_${currentDateTime}.xls`);
        submitFunction(value);
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message || 'An error occurred during file download',
            status: error.response?.status || 500, // Use error.response?.status for better error handling
          }),
        );
        setDisableButton(false); // Re-enable the button in case of an error
      });
  };

  useEffect(() => {
    // Enable the button if the 'fileType' field is filled
    const isButtonEnabled = value.fileType !== '';
    setDisableButton(!isButtonEnabled);
  }, [value.fileType]);
  return (
    <div className="import-data">
      <div className="row row-1">
        <div className="col">
          <SelectBoxField
            name="fileType"
            label={t('fileType')}
            id="fileType"
            value={value.fileType}
            required
            onChangeFunction={(name, changeValue) => onChange(name, changeValue.id)}
            data={selectData}
            className=""
          />
        </div>
      </div>
      <div className="add-department-buttons">
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => {
              closeModal();
            }}
            title={t('back')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={submit} // Use the submit function here
            title={t('import')}
            className="btn-primary btn-bold"
            disabled={disableButton}
          />
        </div>
      </div>
    </div>
  );
}

export default ImportData;
