import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../redux/store';
import InputField from '../../../../shared-components/input-field/input-field';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';
import globalInterlock from '../../../../utils/api/AdvancedFunctions/globalInterlock';
import {
  AddMultiPersonProps,
  InterlockGroupType,
} from '../../../../interface/globalInterlock.interface';
import { fetchGlobalInterlock } from '../../../../redux/slices/GlobalInterlockSlices';

function AddGlobalInterlock({ closeModal, data }: InterlockGroupType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<AddMultiPersonProps>(
    data || {
      name: '',
      remark: '',
    },
  );

  const [disabled, setDisabled] = useState(false);
  const onChangeValue = (name: string, values: string | number) => {
    setValue((prev: AddMultiPersonProps) => ({
      ...prev,
      [name]: values,
    }));
  };
  const submit = () => {
    globalInterlock
      .saveList(value)
      .then((response) => {
        if (response?.data) {
          const message = data
            ? t('globalInterlockUpdatedSuccessfully')
            : t('globalInterlockAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          setDisabled(false);
          dispatch(fetchGlobalInterlock({ pageNo: 1, pageSize: 10 }));
          closeModal();
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };
  return (
    <div className="flex-row column" style={{ width: '38vw' }}>
      <div className="flex-row">
        <InputField
          id="name"
          name="name"
          label={t('groupName')}
          type="text"
          value={value?.name}
          onChangeFunction={onChangeValue}
          required
        />
        <InputField
          id="remark"
          name="remark"
          label={t('remarks')}
          type="text"
          value={value?.remark}
          onChangeFunction={onChangeValue}
        />
      </div>
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={disabled}
      />
    </div>
  );
}

AddGlobalInterlock.defaultProps = {
  data: {
    name: '',
    remarks: '',
  },
};

export default AddGlobalInterlock;
