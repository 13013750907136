import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import { DoorInterface } from '../../../interface/door.interface';
import door from '../../../utils/api/door';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { LoadingProgressCount } from '../../../utils/helpers';
import SpinnerLoader from '../../../shared-components/spinnerLoader/spinner-loader';
import auxiliaryOutApi from '../../../utils/api/Devices/auxiliaryOutput';

function CommonPopup({
  closeModal,
  selectedValues,
  selectedIds,
  inputTrue,
  modelName,
}: DoorInterface) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [showUpgrade, setShowUpgrade] = useState(true);
  const [doorValue, setDoorValue] = useState({
    openInterval: '5',
    userPassword: '',
  });
  const [disable, setDisable] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<any>({});
  const [selectedDoorList, setSelectedDoorList] = useState(selectedValues);
  const onChangeValue = (name: string, value: any) => {
    setDoorValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const getDoorStatusById = () => {
    const req = {
      ids: selectedIds?.join('&ids='),
      type: 'openAuxOut',
    };
    auxiliaryOutApi
      .getAuxOutIds(req)
      .then((response) => {
        const offlineDoors = response.data.data.offlineAuxOutsName
          ? response.data.data.offlineAuxOutsName.split('=').map((name: any) => name.trim())
          : [];
        const onlineDoors = response.data.data.retIds
          ? response.data.data.retIds.split('=').map((id: any) => id.trim())
          : [];
        const disabledDoors = response.data.data.disabledAuxOutsName
          ? response.data.data.disabledAuxOutsName.split('=').map((id: any) => id.trim())
          : [];

        setSelectedDoorList((prev: any) =>
          prev.map((item: any) => {
            let updatedStatus = item.deviceStatus;
            if (onlineDoors.includes(item.id)) {
              updatedStatus = 'online'; // Online if `item.id` is in `retIds`
            } else if (offlineDoors.includes(item.name)) {
              updatedStatus = 'offline'; // Offline if `item.name` is in `offlineDoorsName`
            } else if (disabledDoors.includes(item.name)) {
              updatedStatus = 'disabled';
            }
            return {
              ...item,
              deviceStatus: updatedStatus,
            };
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  const submit = () => {
    setDisable(true);
    setLoading('1');
    const stopProgress = LoadingProgressCount(0, 25, 100, setProgress, () => {});
    const filterEnabled = selectedDoorList.filter((val: any) => val.deviceStatus === 'online');
    const doorsId = filterEnabled.map((val: any) => val.id).join('&');
    if (modelName === 'remoteOpen') {
      door
        .remoteOpen(doorsId, doorValue?.openInterval)
        .then((res) => {
          setSuccessMsg(res?.data);
          if (res.data.code === 0) {
            setTimeout(() => {
              setDisable(false);
              stopProgress();
              setLoading('0');
            }, 3500);
          }
        })
        .catch((error) => {
          setDisable(false);
          setLoading('0');
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    } else if (modelName === 'remoteClose') {
      door
        .remoteClose(doorsId)
        .then((res) => {
          if (res.data.code === 0) {
            setSuccessMsg(res?.data);
            setTimeout(() => {
              setDisable(false);
              stopProgress();
              setLoading('0');
            }, 3500);
          }
        })
        .catch((error) => {
          setDisable(false);
          setLoading('0');
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    } else if (modelName === 'normalOpenDoor') {
      door
        .normalOpenDoor(doorsId)
        .then((res) => {
          if (res.data.code === 0) {
            setSuccessMsg(res?.data);
            setTimeout(() => {
              setDisable(false);
              stopProgress();
              setLoading('0');
            }, 3500);
          }
        })
        .catch((error) => {
          setDisable(false);
          setLoading('0');
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.status,
            }),
          );
        });
    }
  };

  useEffect(() => {
    const getOnlineDevice = selectedDoorList
      .map((device: any) => device?.deviceStatus)
      .includes('online');
    if (getOnlineDevice) {
      if (modelName === 'remoteOpen' && doorValue?.openInterval) {
        setDisable(false);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [doorValue, selectedDoorList]);

  useEffect(() => {
    getDoorStatusById();
    setSelectedDoorList(selectedValues);
  }, [selectedValues]);
  return (
    <div className="device-setup-popup">
      <div className="row">
        <div className="column-1">
          <h3 className="title">{t('selectedDevice')}</h3>
          <div className="box">
            {selectedDoorList.map(
              (item: any) =>
                item?.deviceStatus === 'online' && <span className="message">{item.name}</span>,
            )}
            {selectedDoorList.filter((item: any) => item?.deviceStatus === 'offline').length >
              0 && (
              <div>
                <h4 className="text-warning">{t('theOfflineDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDoorList
                    .filter((item: any) => item?.deviceStatus === 'offline')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
            {selectedDoorList.filter((item: any) => item?.deviceStatus === 'disabled').length >
              0 && (
              <div>
                <h4 className="text-warning">{t('theDisabledDevicesCanNotBeOperated')}</h4>
                <span className="text-warning">
                  {selectedDoorList
                    .filter((item: any) => item?.deviceStatus === 'disabled')
                    .map((item: any) => item?.name)
                    .join(', ')}
                </span>
              </div>
            )}
          </div>
          {inputTrue && (
            <div className="column-new">
              <div className="row row-3">
                <InputField
                  name="userPassword"
                  label={t('userPassword')}
                  id="userPassword"
                  type="password"
                  value={doorValue?.userPassword}
                  innerIconStatus={false}
                  onChangeFunction={onChangeValue}
                  required
                />
                {modelName === 'remoteOpen' && (
                  <div className="open-interval">
                    <InputField
                      name="openInterval"
                      label={t('openInterval')}
                      id="openInterval"
                      type="number"
                      value={doorValue?.openInterval}
                      innerIconStatus={false}
                      onChangeFunction={onChangeValue}
                      required
                    />
                    <div className="seconds">
                      Second <span style={{ color: '#696C6F' }}>(1-254)</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="column-1">
          <div className="upgrading-content">
            <div className="left">
              <Button
                onClickFunction={() => setShowUpgrade(!showUpgrade)}
                title={showUpgrade ? t('hideInfo') : t('detailedInfo')}
                className="btn-primary"
              />
            </div>
            <div className="right">
              {loading && (
                <div className="search-loader">
                  {loading === '1' && <span className="count">{progress}%</span>}
                  {loading === '1' && <SpinnerLoader />}
                </div>
              )}
              <div className="form-buttons-right">
                <Button
                  onClickFunction={submit}
                  title={t('start')}
                  className="btn-primary"
                  disabled={disable}
                />
                <Button
                  onClickFunction={() => {
                    closeModal();
                  }}
                  title={t('close')}
                  className="btn-default"
                />
              </div>
            </div>
          </div>
          {showUpgrade && (
            <div className="box">
              {loading === '0' && successMsg && (
                <div className="success-wrapper">
                  <p>The operation succeeded!</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CommonPopup;
