import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { EditAuxiliaryProps } from '../../../interface/accessAuxiliaryInput.interface';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import auxiliaryOutputApi from '../../../utils/api/Devices/auxiliaryOutput';

// Define the interface for the dropdown data
interface DropdownItem {
  label: string;
  id: string | number;
}

function EditAuxiliary({ initialData, closeModal, saveChanges }: EditAuxiliaryProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editData, setEditData] = useState(initialData);
  const [dropdownData, setDropdownData] = useState<DropdownItem[]>([]);

  // Handles changes to the form fields
  const onChangeValue = (name: string, value: string | number) => {
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch dropdown data from API
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await auxiliaryOutputApi.getTimeSegList();
        const formattedData: DropdownItem[] = response.data.data.map(
          (item: { text: string; value: string | number }) => ({
            label: item.text,
            id: item.value,
          }),
        );

        setDropdownData(formattedData);
      } catch (error: unknown) {
        if (error instanceof Error) {
          dispatch(setNotificationError(error.message || t('errorFetchingData')));
        } else {
          dispatch(setNotificationError(t('errorFetchingData')));
        }
      }
    };

    fetchDropdownData();
  }, []);

  return (
    <div className="edit-reader-modal">
      <form className="edit-reader-form">
        <div className="input-grid">
          <div className="input-form">
            <div className="input-item">
              <InputField
                id="deviceName"
                value={editData.deviceName}
                label={t('deviceName')}
                type="text"
                onChangeFunction={onChangeValue}
                name="deviceName"
                readonly
              />
            </div>
            <div className="input-item">
              <InputField
                id="number"
                value={editData.number}
                label={t('number')}
                type="number"
                onChangeFunction={onChangeValue}
                name="number"
                readonly
              />
            </div>
            <div className="input-item">
              <InputField
                id="printedName"
                value={editData.printedName}
                label={t('printedName')}
                type="text"
                name="printedName"
                onChangeFunction={onChangeValue}
                readonly
              />
            </div>
          </div>
          <div className="input-select">
            <div className="input-item">
              <SelectBoxField
                name="passageModeTimeZone"
                label={t('passageModeTimeZone')}
                id="passageModeTimeZone"
                value={editData.passageModeTimeZone}
                onChangeFunction={(name, value) => onChangeValue(name, value.id)}
                data={dropdownData}
                className=""
              />
            </div>
            <div className="input-item">
              <InputField
                id="name"
                value={editData.name}
                label={t('name')}
                type="text"
                name="name"
                onChangeFunction={(name, value) => onChangeValue(name, value)}
              />
            </div>
            <div className="input-item">
              <InputTextAreaField
                id="remarks"
                value={editData.remarks || ''} // Provide a fallback value
                label={t('remarks')}
                name="remarks"
                onChangeFunction={(name, value) => onChangeValue(name, value)}
              />
            </div>
          </div>
        </div>
        <div className="form-buttons-right">
          <Button
            onClickFunction={() => closeModal()}
            title={t('cancel')}
            className="btn-default btn-bold"
          />
          <Button
            onClickFunction={() => {
              saveChanges(editData);
              closeModal();
            }}
            title={t('ok')}
            className="btn-primary btn-bold"
          />
        </div>
      </form>
    </div>
  );
}

export default EditAuxiliary;
