import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { DepartmentParamsProps } from '../../../interface/accessByDepartment.interface';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { AddIcon } from '../../../assets/svgicons/svgicon';
import AddAvailability, { useOptions } from './components/NewAvailability';
import {
  setPersonSearchFilter,
  setSearchFilter,
  deleteAntiPassBack as deleteApb,
} from '../../../redux/slices/GlobalAntipassbackSlices';
import globalAntiPassBack from '../../../utils/api/AdvancedFunctions/globalAntiPassBack';
import {
  fetchPersonAvailability,
  deletePerson,
  fetchPersonList,
} from '../../../redux/slices/PersonAvailabilitySlices';
import personAvailability from '../../../utils/api/AdvancedFunctions/personAvailability';
import AddPersonnel from './components/AddPersonnel';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';

interface LevelProps {
  personLimitZoneId?: string;
}
interface DeleteLevelProps {
  levelId: string;
  id: string;
}
function PersonAvailability() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    personAvailabilities,
    status,
    totalDataCount,
    currentPageNo,
    searchFilter,
    selectId,
    persons,
    personStatus,
    personTotalDataCount,
    personCurrentPageNo,
    personSearchFilter,
  } = useAppSelector((state: any) => state.personAvailability);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [personnelSelect, setPersonnelSelect] = useState<string[]>([]);
  let handleEditOpen: any;
  const fetchZoneDetails = async (ids: string) => {
    try {
      const response = await personAvailability.getPersonLimitZoneDetail(ids);
      return response.data.data;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      return null;
    }
  };
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('availabilityName'),
      Cell: ({ row }: any) => (
        <div
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </div>
      ),
    },
    {
      accessorKey: 'zoneName',
      header: t('zone'),
    },
    {
      accessorKey: 'validType',
      header: t('use'),
      Cell: ({ row }: any) => {
        const result =
          useOptions.find((item) => item.id === row?.original?.validType.toString())?.label || '';
        return <div>{result}</div>;
      },
    },
    {
      accessorKey: 'detail',
      header: t('detail'),
      Cell: ({ row }: any) => {
        const [details, setDetails] = useState<string | null>(null);

        useEffect(() => {
          const fetchData = async () => {
            const data = await fetchZoneDetails(row.original.id);
            setDetails(data);
          };

          fetchData();
        }, [row.original.id]);

        return <span>{details}</span>;
      },
    },
  ];
  const personnelColumn = [
    {
      accessorKey: 'persPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'name',
      header: t('firstName'),
    },
    {
      accessorKey: 'lastName',
      header: t('lastName'),
    },
    {
      accessorKey: 'detail',
      header: t('detail'),
    },
  ];

  handleEditOpen = (val: any): void => {
    const editData = {
      ...val,
      validType: String(val.validType),
    };
    setModalView({
      title: t('edit'),
      content: <AddAvailability closeModal={() => setModalOpen(false)} update={editData} />,
    });
    setModalOpen(true);
  };

  const fetchData = (params: DepartmentParamsProps) => {
    if (status === 'success') {
      dispatch(fetchPersonAvailability({ ...params }));
    }
  };

  const getFilterData = (filter: any): any => {
    if (personCurrentPageNo > 0) {
      dispatch(setPersonSearchFilter(filter));
      fetchData({ pageNo: 1, ...filter.filter });
    }
  };

  // level functions
  const fetchLevels = (params: LevelProps) => {
    if (params.personLimitZoneId) {
      dispatch(fetchPersonList({ ...params }));
    }
  };

  const fetchNextData = (type: string): any => {
    if (type === 'rule') {
      fetchData({ pageNo: currentPageNo + 1, ...searchFilter.filter });
    } else {
      fetchLevels({ pageNo: personCurrentPageNo + 1, ...personSearchFilter.filter });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedValues(id);
    fetchLevels({ personLimitZoneId: id?.[0] });
  };

  const getFilterLevels = (filter: any): any => {
    if (currentPageNo > 0) {
      dispatch(setSearchFilter(filter));
      fetchLevels({ pageNo: 1, ...filter.filter });
    }
  };

  const deleteRecord = (params: DeleteLevelProps) => {
    const req = {
      perPersonLimitZoneIds: params.levelId,
      personLimitZoneId: params.id,
    };
    globalAntiPassBack
      .delPerson(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deletePerson(params.id));
          setModalOpen(false);
          // fetchLevels({
          //   pageNo: 1,
          //   personId,
          // });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const deleteAvailability = (ids: string) => {
    personAvailability
      .delete(ids)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          dispatch(deleteApb(ids));
          setModalOpen(false);
          fetchData({
            pageNo: 1,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const onConfirmReset = (zoneId: string) => {
    const req = {
      personLimitZoneId: zoneId,
    };
    personAvailability
      .resetPersonLimitZone(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          setModalOpen(false);
          fetchPersonAvailability({
            pageNo: 1,
          });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('reset'),
      function: (val: any) => {
        setModalView({
          title: t('reset'),
          content: (
            <ConformationBox
              closeModal={() => setModalOpen(false)}
              okFunction={() => onConfirmReset(val.id)}
              title={t('confirmResetOperation')}
              buttonLabel={t('ok')}
              cancelTrue
            />
          ),
        });
        setModalOpen(true);
      },
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteAvailability}
            />
          ),
        });
      },
    },
    {
      label: t('addPersonnel'),
      function: (val: any): void => {
        setModalView({
          title: t('addPersonnel'),
          content: <AddPersonnel closeModal={() => setModalOpen(false)} data={val} />,
        });
        setModalOpen(true);
      },
    },
  ];
  const personActions = [
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('delete'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];

  useEffect(() => {
    if (personAvailabilities.length === 0) {
      fetchData({ pageNo: 1 });
    }
  }, []);

  useEffect(() => {
    if (selectId) {
      fetchLevels({ personLimitZoneId: selectId });
      setSelectedValues([selectId]);
    }
  }, [personAvailabilities]);

  return (
    <div className="access-by-level">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('personAvailability'),
          },
        ]}
      />
      <CardBox
        header={t('personAvailability')}
        title={t('listOfAvailability')}
        rightSide={
          <CardRightButtonGroup
            buttonLabel={t('new')}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddAvailability closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedValues}
          getSelectedData={(val: any): any => getSelected(val)}
          checkBoxActive
          singleRowSelection
          fetchNextPage={() => fetchNextData('rule')}
          isFetching={status === 'loading'} // Placeholder status
          values={personAvailabilities} // Use the actual data here
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Person</h4>
            <span className="sub-title">{t('listOfPersonnel')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
            action={personActions}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={personSearchFilter}
            assignSelectedData={personnelSelect}
            getSelectedData={(val: any): any => setPersonnelSelect(val)}
            checkBoxActive
            fetchNextPage={() => fetchNextData('person')}
            isFetching={personStatus === 'loading'} // Placeholder status
            values={persons}
            fixedColumn={['id']}
            totalRecords={personTotalDataCount}
            height={411}
          />
        </div>
      </CardBox>
    </div>
  );
}

export default PersonAvailability;
