import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../shared-components/swapTables/swapTable';
import linkage from '../../../utils/api/AccessRule/linkage';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import Button from '../../../shared-components/button/button';

interface DeviceProps {
  partitionName: string;
  belongingIntrusionDevice: string;
}
interface ModalType {
  closeModal: () => void;
}
function AddPartition({ closeModal }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const tableColumns = [
    { label: t('partitionName'), id: 'partitionName' },
    { label: t('belongingIntrusionDevice'), id: 'belongingIntrusionDevice' },
  ];
  const [device, setDevice] = useState<DeviceProps[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<DeviceProps[]>([]);
  const changeSearch = () => {
    console.log('search');
  };
  // Function to handle table swapping
  const onTableSwap = (newAvailableData: DeviceProps[], newSelectedData: DeviceProps[]) => {
    setDevice(newAvailableData); // Update available data
    setSelectedDevice(newSelectedData); // Update selected data
  };
  const fetchData = (params: any) => {
    linkage
      .getSelectIasPartition(params)
      .then((res) => {
        console.log(res.data.data);
        setDevice(res.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };
  useEffect(() => {
    fetchData({
      type: 'noSelected',
      manufacturer: 'BOSCH',
    });
  }, []);
  return (
    <div className="add-partition">
      <SwapTable
        searchValue=""
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={device}
        selectedTableData={selectedDevice}
        onTableSwap={onTableSwap}
      />
      <div className="form-buttons-right">
        <Button onClickFunction={closeModal} title={t('cancel')} className="btn-default" />
        <Button
          onClickFunction={() => {}}
          title={t('done')}
          className="btn-primary"
          // disabled={disable}
        />
      </div>
    </div>
  );
}

export default AddPartition;
