import { post, fetch, get } from './base/index';

export default {
  getDevices: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/getDeviceList`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  getAllIps: () => post(`${process.env.REACT_APP_API_URL}Acc/api/device/getAllIPSn`, {}),
  // popup apis
  searchDevice: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/searchDev/${data}`, {}),
  /**
   * @param endpoint are come from the component it should be dynamic
   * @param data = id
   * @returns
   */
  deviceActionsApi: (endpoint: any = '', data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/${endpoint}`, {}, { data }),
  isExistAlias: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/isExistAlias/${data}`, {}),
  isExistIpAddress: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/isExistIpAddress/${data}`, {}),
  deleteDevice: (id: string = '') =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/delete?ids=${id}`, {}),
  modifyIpAddress: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/modifyIPAddress`, data, {}),
  validPushDevCount: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/validPushDevCount?machineType=${data?.machineType}&sn=${data?.sn}`,
      {},
    ),
  controlApis: (apiName: string, data: any) => {
    const baseUrl = process.env.REACT_APP_API_URL;
    let url = `${baseUrl}Acc/api/device/${apiName}`;
    if (apiName === 'clearCmdCache') {
      url += `?devIds=${data}`;
    } else {
      url += `?ids=${data}`;
    }
    return post(url, {});
  },
  clearAdministrator: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/clearAdministrator?devIds=${data}`, {}),
  isSupportClearAdministrator: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/isSupportClearAdministrator?devIds=${data}`,
      {},
    ),
  synchronizeTime: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/isOnline?deviceId=${data}`, {}),
  syncAllDataByDevType: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/syncAllDataByDevType`, {}, data),
  syncAllData: (data: any = {}) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/syncAllData`, {}, data),
  // levels
  getLevelList: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/accLevel/list`,
      {},
      { ...data, pageSize: 10 },
    );
  },
  timeZoneList: (data: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/baseDictionary/selectList?key=${data}`, {}),
  hasDSTimeByTimeZone: (data: string) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/accDsTime/hasDSTimeByTimeZone?timeZone=${data}`,
      {},
    ),
  exportDevice: (data: any) => {
    return fetch(`${process.env.REACT_APP_API_URL}Acc/api/device/exportDevice`, 'POST', data);
  },
  isSupportRegistration: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/getDevIdsBySetRegistrationDevice?id=${data}`,
      {},
    ),
  updateThresHold: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/updateMThreshold?devId=${data.id}&mThreshold=${data.thresHold}`,
      {},
    ),
  isSupportExtendParam: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/getDevExtendInfo?id=${data}`, {}),
  getOptFromDev: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/getOptFromDev?ids=${data}`, {}),
  getPersonInfo: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/getUploadPersonInfo?Id=${data}`, {}),
  uploadPersonInfo: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/uploadPersonInfo?dataType=${data.dataType}&devIds=${data.id}`,
      {},
    ),
  getUploadTransactionInfo: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/getUploadTransactionInfo?id=${data.id}&type=${data.type}`,
      {},
    ),
  uploadTransaction: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/uploadTransaction`, data, {}),
  getViewRuleDevices: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/queryDevRule?id=${data}`, {}),
  queryDevUsage: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/queryDevUsage?ids=${data}`, {}),
  getDevIPAddressInfo: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/getDevIPAddressInfo?id=${data.id}&type=${data.type}`,
      {},
    ),
  isSupportReplaceDevice: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}api/device/isSupportReplaceDevice`, data, {}),
  getDevCommPwdInfo: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/getDevCommPwdInfo?id=${data.id}&type=${data.type}`,
      {},
    ),
  getDevNetConnectModeInfo: (data: any) =>
    post(
      `${process.env.REACT_APP_API_URL}Acc/api/device/getDevNetConnectModeInfo?id=${data.id}&type=${data.type}`,
      {},
    ),
  updateIpAddress: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/updateIpAddr`, {}, data),
  updateCommPassword: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/updateCommPwd`, {}, data),
  checkCommOldPassword: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/checkPwd`, {}, data),
  grantAuthority: (data: any) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/device/grantAuthority`, data, {}),
  save: (data: any) => post(`${process.env.REACT_APP_API_URL}Acc/api/device/save`, data, {}),
  skip: () => get(`${process.env.REACT_APP_API_URL}Acc/api/skip/getProcess`, {}),
};
