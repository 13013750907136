import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Use Components
import InputField from '../../shared-components/input-field/input-field';
import CheckBoxInputField from '../../shared-components/checkbox-input-field/checkbox-input-field';
import SelectBoxField from '../../shared-components/selectbox-field/selectbox-field';
import RadioButtonField from '../../shared-components/radio-button-field/radio-button-field';
import Button from '../../shared-components/button/button';
import ModalBox from '../../shared-components/modal-box/modal-box';
import TabModalBox from '../../shared-components/tab-modal-box/tab-modal-box';
import CardBox from '../../shared-components/card/card';

// Use Icons
import { EyeCloseIcon, EyeOpenIcon } from '../../assets/svgicons/svgicon';

function Dashboard() {
  const [type, setType] = useState('password');
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const selectData = [
    {
      label: 'Value 1',
      id: 'value1',
    },
    {
      label: 'Value 2',
      id: 'value2',
    },
    {
      label: 'Value 3',
      id: 'value3',
    },
    {
      label: 'Value 4',
      id: 'value4',
    },
  ];
  const [input, setInput] = useState({
    username: '',
    selectUser: '',
    others: '',
    permissions: [],
  });

  useEffect(() => {
    i18n.changeLanguage('en');
  }, []);

  return (
    <div className="dashboard-wrap">
      <ModalBox
        status={open}
        closeModal={() => setOpen(false)}
        title={t('name')}
        content={<>Hi</>}
      />
      <TabModalBox
        status={open}
        closeModal={() => setOpen(false)}
        title={t('name')}
        tabs={[
          {
            title: 'Change Department',
            content: <>Change Department Page</>,
            key: 'department',
          },
          {
            title: 'Change Position',
            content: <>Change Position Page</>,
            key: 'position',
          },
          {
            title: 'Dismissal',
            content: <>Dismissal Page</>,
            key: 'dismissal',
          },
        ]}
      />
      <div className="row row-4">
        <div className="col">
          <InputField
            name="username"
            label="User Name"
            id="username"
            type={type}
            value={input.username}
            className=""
            required
            innerIconStatus
            InnerIcon={type === 'password' ? EyeCloseIcon : EyeOpenIcon}
            innerIconFunction={() => setType(type === 'password' ? 'text' : 'password')}
            onChangeFunction={(name, value) => {
              setInput((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
          />
        </div>
        <div className="col">
          <CheckBoxInputField
            label="User Name"
            name="permissions"
            alignment="column"
            required
            data={[
              {
                label: 'Value 1',
                id: 'value1',
                value: 'value1',
              },
              {
                label: 'Value 2',
                id: 'value2',
                value: 'value2',
                disabled: 'disabled',
              },
              {
                label: 'Value 3',
                id: 'value3',
                value: 'value3',
              },
              {
                label: 'Value 4',
                id: 'value4',
                value: 'value4',
              },
            ]}
            value={input.permissions}
            onChangeFunction={(name, value) => {
              setInput((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
          />
        </div>
        <div className="col">
          <RadioButtonField
            label="User Name"
            name="others"
            alignment="warp"
            required
            data={[
              {
                label: 'Value 1',
                id: 'value1',
                value: 'value1',
              },
              {
                label: 'Value 2',
                id: 'value2',
                value: 'value2',
              },
              {
                label: 'Value 3',
                id: 'value3',
                value: 'value3',
                disabled: 'disabled',
              },
              {
                label: 'Value 4',
                id: 'value4',
                value: 'value4',
              },
            ]}
            value={input.others}
            onChangeFunction={(name, value) => {
              setInput((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
          />
        </div>
        <div className="col">
          <SelectBoxField
            name="selectUser"
            label="User Name"
            id="selectUser"
            data={selectData}
            value={input.selectUser}
            className=""
            required
            onChangeFunction={(name, value) => {
              setInput((prev) => ({
                ...prev,
                [name]: value.id,
              }));
            }}
          />
        </div>
        <div className="col">
          <Button
            title="Save"
            className="btn-primary"
            onClickFunction={() => {
              setOpen(true);
            }}
            disabled={false}
          />
        </div>
        <div className="col">
          <CardBox title="Card Title">Hi</CardBox>
        </div>
      </div>
      <div className="row row-1">
        <CardBox title="Card title">gsfd</CardBox>
      </div>
    </div>
  );
}

export default Dashboard;
