import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyDataIcon } from '../../assets/svgicons/svgicon';

function EmptyContent() {
  const { t } = useTranslation();
  return (
    <div className="table-empty-content">
      <div className="content-wrapper">
        <EmptyDataIcon />
        <span className="text">{t('noData')}</span>
      </div>
    </div>
  );
}

export default EmptyContent;
