import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../shared-components/button/button';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

type ParametersProps = {
  down_event_mode: string;
  regular_time: string | number;
  down_event_hours: number[];
};

interface TabChildProps {
  value: ParametersProps;
  onChangeValue: (data: ParametersProps) => void;
}

function TransactionsTypes({ value, onChangeValue }: TabChildProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(value);
  const [btnColor, setBtnColor] = useState('');
  const options = [
    {
      label: t('Periodically'),
      id: 'mode1',
      value: 'mode1',
    },
    {
      label: t('settheTimeforObtainingNewTransactions'),
      id: 'mode2',
      value: 'mode2',
    },
  ];
  const intervalOptions = Array.from({ length: 8 }, (_, i) => ({
    label: JSON.stringify(i + 1),
    id: i + 1,
  }));
  console.log(intervalOptions);
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const checkboxData = hours.map((hour: number) => ({
    label: `${hour}: 00`,
    id: hour,
    value: hour,
  }));
  const handleChange = (name: string, data: any) => {
    const updatedValues = {
      ...values,
      [name]: data,
    };
    setValues(updatedValues);
    onChangeValue(updatedValues);
  };
  const handleClickDevice = (params: string) => {
    setBtnColor(params);
    if (params === 'unSelectAll') {
      const updatedValues = {
        ...values,
        down_event_hours: [],
      };
      setValues(updatedValues);
      onChangeValue(updatedValues);
    } else {
      const allDeviceValues = checkboxData?.map((device: any) => device.value);
      const updatedValues = {
        ...values,
        down_event_hours: allDeviceValues,
      };
      setValues(updatedValues);
      onChangeValue(updatedValues);
    }
  };
  return (
    <div className="transaction-types">
      <div className="input-row">
        <RadioButtonField
          label={t('typeOfGettingTransactions')}
          name="down_event_mode"
          alignment="row-wrap"
          data={options}
          value={values?.down_event_mode}
          onChangeFunction={(name, data) => handleChange(name, data)}
        />
        {values?.down_event_mode === 'mode1' && (
          <SelectBoxField
            id="regular_time"
            label={t('interval')}
            value={values?.regular_time}
            name="regular_time"
            data={intervalOptions}
            onChangeFunction={(name, changeValue) => handleChange(name, changeValue.id)}
            validation
            message="Hour(s)"
          />
        )}
      </div>
      {values?.down_event_mode === 'mode2' && (
        <div className="new-transaction">
          <div className="btn-wrapper">
            <Button
              onClickFunction={() => handleClickDevice('selectAll')}
              title={t('selectAll')}
              className={btnColor === 'selectAll' ? 'btn-primary' : 'btn-outline-primary'}
            />
            <Button
              onClickFunction={() => handleClickDevice('unSelectAll')}
              title={t('unselectAll')}
              className={btnColor === 'unSelectAll' ? 'btn-primary' : 'btn-outline-primary'}
            />
          </div>
          <CheckBoxInputField
            label=""
            name="down_event_hours"
            alignment="warp grid-2"
            data={checkboxData}
            value={values.down_event_hours}
            onChangeFunction={handleChange}
          />
          <div className="text-danger flex-row center">
            <AttentionIcon />
            {t('GettingtransactionsandsynchronizationtimewillbedoneatthesameTime')}
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionsTypes;
