/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setNotificationError } from './NotificationSlices';
import globalInterlock from '../../utils/api/AdvancedFunctions/globalInterlock';

export const fetchGlobalInterlock = createAsyncThunk(
  'globalInterlock/fetchGlobalInterlock',
  async (data: any, { dispatch }) => {
    const apiResponse = await globalInterlock
      .listByPage(data)
      .then((response) => {
        return {
          ...response.data,
          data: {
            list: response.data.data.data,
          },
          size: response.data.data.total,
          ...data,
        };
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
        return error;
      });
    return apiResponse;
  },
);

export const fetchDoorList = createAsyncThunk('fetchDoorList', async (data: any, { dispatch }) => {
  try {
    const response = await globalInterlock.getDoorlist(data);
    return {
      ...response.data,
      data: {
        list: response.data.data.data,
      },
      size: response.data.data.total,
      ...data,
    };
  } catch (error: any) {
    dispatch(setNotificationError(error.response.data));
    throw error;
  }
});

interface InterlockInterface {
  interlockList: any;
  status: 'loading' | 'success' | 'failed'; // Status can be one of these three strings
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: { [key: string]: any };
  interlockGroup: any;
  interlockListId: string;
  interlockGroupStatus: 'loading' | 'success' | 'failed';
  interlockGroupTotalDataCount: number;
  interlockGroupCurrentPageNo: number;
  interlockGroupSearchFilter: { [key: string]: any };
}

const initialState: InterlockInterface = {
  interlockList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  interlockGroup: [],
  interlockListId: '',
  interlockGroupStatus: 'success',
  interlockGroupTotalDataCount: 0,
  interlockGroupCurrentPageNo: 0,
  interlockGroupSearchFilter: {},
};

export const globalInterlockSlice: any = createSlice({
  name: 'globalInterlock',
  initialState,
  reducers: {
    deleteInterlock: (state, action: any) => {
      state.interlockList = state.interlockList.filter((val: any) => val.id !== action.payload);
      state.totalDataCount -= 1;
    },
    deleteInterlockGroup: (state, action: any) => {
      state.interlockGroup = state.interlockGroup.filter((val: any) => val.id !== action.payload);
      state.interlockGroupTotalDataCount -= 1;
    },
    setSearchFilter: (state, action: any) => {
      state.searchFilter = action.payload;
    },
    setInterlockGroupSearchFilter: (state, action: any) => {
      state.interlockGroupSearchFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchGlobalInterlock.pending, (state = initialState) => {
        state.status = 'loading';
      })
      .addCase(fetchGlobalInterlock.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        if (action.payload.success) {
          state.totalDataCount = action.payload.size;

          state.interlockList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchGlobalInterlock.rejected, (state: any) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDoorList.pending, (state) => {
        state.interlockGroupStatus = 'loading';
      })
      .addCase(fetchDoorList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.success) {
          state.interlockGroupTotalDataCount = action.payload.size;

          state.interlockGroup = action.payload.data.list;
        }
        state.interlockGroupStatus = 'success';
      })
      .addCase(fetchDoorList.rejected, (state) => {
        state.interlockGroupStatus = 'failed';
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setSearchFilter,
  deleteInterlock,
  setInterlockGroupSearchFilter,
  deleteInterlockGroup,
} = globalInterlockSlice.actions;

export default globalInterlockSlice.reducer;
