import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputIpAddressField from '../../../shared-components/input-ip-address-field/input-ip-address-field';
import InputField from '../../../shared-components/input-field/input-field';
import device from '../../../utils/api/device';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { useAppDispatch } from '../../../redux/store';

interface ModalTypes {
  closeModal: () => void;
  data: {
    ipAddress: string;
    subnetMask: string;
    gatewayAddress: string;
    MAC: string;
  };
}

function SearchModifyIpAddress({ closeModal, data }: ModalTypes) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [ipConfig, setIpConfig] = useState({
    ...data,
    newIPAddress: '',
    commPassword: '',
  });

  const onChangeValue = (name: string, value: any) => {
    setIpConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    const request = {
      type: '',
      newIP: ipConfig.newIPAddress,
      ipAddress: ipConfig.ipAddress,
      subnetMask: ipConfig.subnetMask,
      gateway: ipConfig.gatewayAddress,
      mac: ipConfig.MAC,
      commPwd: ipConfig.commPassword,
    };
    device
      .modifyIpAddress(request)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: response.data.msg,
              status: 200,
            }),
          );
          closeModal();
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.status,
          }),
        );
      });
  };

  return (
    <div className="modify-ip-address">
      <div className="form-row">
        <InputIpAddressField
          id="ipAddress"
          name="ipAddress"
          label={t('originalIpAddress')}
          type="text"
          value={ipConfig.ipAddress}
          onChangeFunction={onChangeValue}
          aria-label="IP Address"
          disabled
        />
        <InputIpAddressField
          id="newIPAddress"
          name="newIPAddress"
          label={t('newIpAddress')}
          type="text"
          value={ipConfig.newIPAddress}
          onChangeFunction={onChangeValue}
          aria-label="IP Address"
        />
        <InputIpAddressField
          id="subnetMask"
          name="subnetMask"
          label={t('subnetMask')}
          type="text"
          value={ipConfig.subnetMask}
          onChangeFunction={onChangeValue}
          aria-label="Subnet Mask"
        />
        <InputIpAddressField
          id="gatewayAddress"
          name="gatewayAddress"
          label={t('gatewayAddress')}
          type="text"
          value={ipConfig.gatewayAddress}
          onChangeFunction={onChangeValue}
          aria-label="Gateway Address"
        />
        <InputField
          name="commPassword"
          label={t('communicationPassword')}
          id="commPassword"
          type="password"
          value={ipConfig.commPassword}
          onChangeFunction={onChangeValue}
        />
      </div>

      <div className="modal-footer">
        <Button onClickFunction={closeModal} title={t('Cancel')} className="btn-default" />
        <Button onClickFunction={submit} title={t('ok')} className="btn-primary" />
      </div>
    </div>
  );
}

export default SearchModifyIpAddress;
