import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButtonField from '../../../shared-components/radio-button-field/radio-button-field';
import SelectBoxField from '../../../shared-components/selectbox-field/selectbox-field';
import CheckBoxInputField from '../../../shared-components/checkbox-input-field/checkbox-input-field';
import Button from '../../../shared-components/button/button';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';

type ParametersProps = {
  transactionType: string;
  interval: string;
  newTransaction: string[];
};

interface TabChildProps {
  value: ParametersProps;
  onChangeValue: (data: ParametersProps) => void;
}

function TransactionsTypes({ value, onChangeValue }: TabChildProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState(value);
  const options = [
    {
      label: t('Periodically'),
      id: '0',
      value: '0',
    },
    {
      label: t('settheTimeforObtainingNewTransactions'),
      id: '1',
      value: '1',
    },
  ];
  const intervalOptions = [
    { label: '1', id: '1' },
    { label: '2', id: '2' },
  ];
  const hours = Array.from({ length: 24 }, (_, i) => `${i}:00`);
  const checkboxData = hours.map((hour: string) => ({
    label: hour,
    id: hour,
    value: hour,
  }));
  const handleChange = (name: string, data: any) => {
    const updatedValues = {
      ...values,
      [name]: data,
    };
    setValues(updatedValues);
    onChangeValue(updatedValues);
  };
  const handleClickDevice = (params: string) => {
    if (params === 'unSelectAll') {
      const updatedValues = {
        ...values,
        newTransaction: [],
      };
      setValues(updatedValues);
      onChangeValue(updatedValues);
    } else {
      const allDeviceValues = checkboxData?.map((device: any) => device.value);
      const updatedValues = {
        ...values,
        newTransaction: allDeviceValues,
      };
      setValues(updatedValues);
      onChangeValue(updatedValues);
    }
  };
  return (
    <div className="transaction-types">
      <div className="input-row">
        <RadioButtonField
          label={t('typeOfGettingTransactions')}
          name="transactionType"
          alignment="row-wrap"
          data={options}
          value={values?.transactionType}
          onChangeFunction={(name, data) => handleChange(name, data)}
        />
        {values?.transactionType === '0' && (
          <SelectBoxField
            id="interval"
            label={t('interval')}
            value={values?.interval}
            name="interval"
            data={intervalOptions}
            onChangeFunction={(name, changeValue) => handleChange(name, changeValue.id)}
            validation
            message="Hour(s)"
          />
        )}
      </div>
      {values?.transactionType === '1' && (
        <div className="new-transaction">
          <div className="btn-wrapper">
            <Button
              onClickFunction={() => handleClickDevice('selectAll')}
              title={t('selectAll')}
              className="btn-primary"
            />
            <Button
              onClickFunction={() => handleClickDevice('unSelectAll')}
              title={t('unselectAll')}
              className="btn-outline-primary"
            />
          </div>
          <CheckBoxInputField
            label=""
            name="newTransaction"
            alignment="warp grid-2"
            data={checkboxData}
            value={values.newTransaction}
            onChangeFunction={handleChange}
          />
          <div className="text-danger flex-row center">
            <AttentionIcon />
            {t('GettingtransactionsandsynchronizationtimewillbedoneatthesameTime')}
          </div>
        </div>
      )}
    </div>
  );
}

export default TransactionsTypes;
