import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import shared components
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import TopHeader from '../../../shared-components/top-header/top-header';
import CardBox from '../../../shared-components/card/card';
import DataTable from '../../../shared-components/data-table/data-table';
import ModalBox from '../../../shared-components/modal-box/modal-box';
import CardRightButtonGroup from '../../../shared-components/card-right-button-group/card-right-button-group';
import { AddIcon, CheckCircleIcon, CloseCircleIcon } from '../../../assets/svgicons/svgicon';
import DeleteModalBox from '../../../shared-components/delete-modal-box/delete-modal-box';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import ConformationBox from '../../../shared-components/conformation-box/conformation-box';
import AddGlobalLinkage from './components/addGlobalLinkage';
// Use interface
import {
  LinkageValue,
  GlobalLevelApiProps,
  GlobalDeleteLevelProps,
} from '../../../interface/globalLinkage.interface';
import {
  deletePerson,
  fetchGlobalLinkage,
  fetchPersonList,
  setPersonSearchFilter,
  setSearchFilter,
  deleteLinkage as deleteGL,
} from '../../../redux/slices/GlobalLinkageSlices';
// Use api's calls
import globalLinkageApi from '../../../utils/api/AdvancedFunctions/globalLinkage';

function GlobalLinkageOutputPoint() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    linkageList,
    status,
    totalDataCount,
    searchFilter,
    persons,
    personStatus,
    personTotalDataCount,
    personSearchFilter,
  } = useAppSelector((state: any) => state.globalLinkage);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const fetchData = async (params: any) => {
    if (status === 'success') {
      dispatch(fetchGlobalLinkage({ ...params }));
    }
  };
  const [selectedPerson, setSelectPerson] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]); // Add state to hold selected cards

  const handleEditOpen = (editData: any) => {
    const updateData = {
      ...editData,
      id: editData?.id,
      triggerCond: editData?.triggerNos,
      triggerCondName: editData?.triggerCondName,
      deviceId: editData?.deviceId,
      inputName: editData?.label,
      inputAddr: editData?.inputAddr,
      inputType: editData?.inputType,
      isApplyToAll: editData?.isApplyToAll,
      name: editData?.name,
    };

    setModalOpen(true);
    setModalView({
      title: t('editLinkage'),
      content: <AddGlobalLinkage closeModal={() => setModalOpen(false)} data={{ ...updateData }} />,
    });
  };
  const columnsField = [
    {
      accessorKey: 'name',
      header: t('linkageName'),
      Cell: ({ row }: any) => (
        <span
          className="list-hyperlink"
          role="presentation"
          onClick={(event) => {
            event.stopPropagation(); // Prevent row selection
            handleEditOpen(row.original); // Call your edit function
          }}>
          {row?.original?.name}
        </span>
      ),
      size: 150,
    },
    {
      accessorKey: 'triggerCondName',
      header: t('linkageTriggerConditions'),
    },
    {
      accessorKey: 'enabledCredential',
      header: t('enable'),
      visibleInShowHideMenu: false,
      Cell: ({ row }: any) => {
        const value = row.original?.enabledCredential ? (
          <CheckCircleIcon color="#48d726de" />
        ) : (
          <CloseCircleIcon color="#f00" />
        );
        return value;
      },
    },
    {
      accessorKey: 'personnelRange',
      header: t('personnelRange'),
    },
  ];

  const personnelColumn = [
    {
      accessorKey: 'personPin',
      header: t('personnelId'),
    },
    {
      accessorKey: 'personName',
      header: t('firstName'),
    },
    {
      accessorKey: 'personLastName',

      header: t('lastName'),
    },
    {
      accessorKey: 'deptName',
      header: t('department'),
    },
  ];
  const enableDisableLinkage = (data: string) => {
    const linkageIds = linkageList
      .filter((val: LinkageValue) => {
        return selectedLevel.includes(val.id);
      })
      .map((val: LinkageValue) => val.id);

    if (linkageIds.length === 0) {
      dispatch(
        setNotificationError({
          error: t('pleaseSelectAtLeastOneLinkage'),
          status: 400,
        }),
      );
      return;
    }
    const request = linkageIds.join('&ids=');
    let apiCall;
    let message: string;
    if (data === 'disable') {
      apiCall = globalLinkageApi.disable(request);
      message = t('linkageDisabledSuccessfully');
    } else if (data === 'enable') {
      apiCall = globalLinkageApi.enable(request);
      message = t('linkageEnabledSuccessfully');
    }
    if (apiCall) {
      apiCall
        .then((response) => {
          if (response.data.success) {
            dispatch(
              setNotificationError({
                error: message,
                status: 200,
              }),
            );
            setModalOpen(false);
          } else {
            dispatch(
              setNotificationError({
                error: response.data.message,
                status: response.data.code, // Error status code
              }),
            );
          }
        })
        .catch((error) => {
          dispatch(
            setNotificationError({
              error: error.message,
              status: error.response?.status || 500,
            }),
          );
        });
    }
  };

  const getFilterLevels = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setSearchFilter(filter));
      fetchData({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const fetchLevels = (params: GlobalLevelApiProps) => {
    if (params.linkId) {
      dispatch(fetchPersonList({ ...params }));
    }
  };
  const getFilterData = (filter: any): any => {
    if (filter.pageIndex > 0) {
      dispatch(setPersonSearchFilter(filter));
      fetchLevels({
        pageNo: filter.pageIndex,
        pageSize: filter.pageSize,
        ...filter.filter,
      });
    }
  };

  const getSelected = (id: string[]) => {
    setSelectedLevel(id);
    fetchLevels({ linkId: id?.[0], pageNo: 1 });
  };

  const deleteRecord = (id: any) => {
    globalLinkageApi
      .delete(id)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('linkageDeletedSuccessfully'),
              status: 200,
            }),
          );
          dispatch(deleteGL(id));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };

  const deleteLinkage = (params: GlobalDeleteLevelProps) => {
    const req = {
      globalLinkageId: params.linkId,
      personIds: params.id,
    };
    globalLinkageApi
      .delPerson(req)
      .then((response) => {
        if (response.data.success) {
          dispatch(
            setNotificationError({
              error: t('personDeletedSuccessfully'),

              status: 200,
            }),
          );
          dispatch(deletePerson(params.linkId));
          setModalOpen(false);
          fetchData({ pageNo: 1, pageSize: 10 });
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
          setModalOpen(false);
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,

            status: error.status,
          }),
        );
        setModalOpen(false);
      });
  };

  const actionButtons = [
    {
      label: t('edit'),
      function: (val: any) => handleEditOpen(val),
    },
    {
      label: t('delete'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteLinkage'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val.id}
              okFunction={deleteRecord}
            />
          ),
        });
      },
    },
  ];
  const levelActionButtons = [
    {
      label: t('deleteFromDefaultLevels'),
      function: (val: any) => {
        setModalOpen(true);
        setModalView({
          title: t('deleteDepartment'),
          content: (
            <DeleteModalBox
              closeModal={() => setModalOpen(false)}
              id={val}
              okFunction={deleteLinkage}
            />
          ),
        });
      },
    },
  ];
  const moreActionButton = [
    {
      title: t('enable'),
      clickFunction: (): void => {
        if (selectedLevel.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('enable'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToEnableThisLinkage')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisableLinkage('enable')}
              />
            ),
          });
        } else {
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneLinkage'),
              status: 400,
            }),
          );
        }
      },
    },
    {
      title: t('disable'),
      clickFunction: (): void => {
        if (selectedLevel.length > 0) {
          setModalOpen(true);
          setModalView({
            title: t('disable'),
            content: (
              <ConformationBox
                closeModal={() => setModalOpen(false)}
                title={t('areYouWantToDisableThisLinkage')}
                buttonLabel={t('ok')}
                okFunction={() => enableDisableLinkage('disable')}
              />
            ),
          });
        } else {
          dispatch(
            setNotificationError({
              error: t('pleaseSelectAtLeastOneLinkage'),
              status: 400,
            }),
          );
        }
      },
    },
  ];

  return (
    <div className="department">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <TopHeader
        title={t('access')}
        broadCram={[
          {
            title: t('advancedFunctions'),
          },
          {
            title: t('globalLinkage'),
          },
        ]}
      />
      <CardBox
        header={t('globalLinkage')}
        title={t('listOfLinkage')}
        rightSide={
          <CardRightButtonGroup
            actions={moreActionButton}
            buttonIcon={<AddIcon color="#ffffff" />}
            buttonLabel={t('new')}
            buttonClass="btn-primary btn-sm"
            buttonStatus
            buttonFunction={(): void => {
              setModalOpen(true);
              setModalView({
                title: t('new'),
                content: <AddGlobalLinkage closeModal={() => setModalOpen(false)} />,
              });
            }}
          />
        }>
        <DataTable
          actionButtonStatus={!modalOpen} // Disable action buttons when modal is open
          action={actionButtons}
          columns={columnsField}
          getFilterData={getFilterLevels}
          assignFilterValue={searchFilter}
          assignSelectedData={selectedLevel}
          getSelectedData={(val: any): any => getSelected(val)}
          checkBoxActive
          isFetching={status === 'loading'}
          values={linkageList}
          fixedColumn={['name']}
          totalRecords={totalDataCount}
          height={411}
          enablePagination
        />
        <hr className="line" />
        <div className="browse-table">
          <div className="header">
            <h4>Browse Personnel</h4>
            <span className="sub-title">{t('listOfPersonnel')}</span>
          </div>
          <DataTable
            actionButtonStatus={!modalOpen}
            action={levelActionButtons}
            columns={personnelColumn}
            getFilterData={getFilterData}
            assignFilterValue={personSearchFilter}
            assignSelectedData={selectedPerson}
            getSelectedData={(val: any): any => setSelectPerson(val)}
            checkBoxActive
            isFetching={personStatus === 'loading'}
            values={persons}
            fixedColumn={['id']}
            totalRecords={personTotalDataCount}
            height={411}
            enablePagination
          />
        </div>
      </CardBox>
    </div>
  );
}

export default GlobalLinkageOutputPoint;
