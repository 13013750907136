import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputTextAreaField from '../../../shared-components/input-text-area-field/input-text-area-field';
import { AttentionIcon } from '../../../assets/svgicons/svgicon';
import { isValidMultipleEmail } from '../../../shared-components/form/validation';

interface ModalTypeProps {
  values: any;
  changeValue: (name: string, data: any) => void;
}

function EmailLinkage({ values, changeValue }: ModalTypeProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(values);

  const onChangeValue = (name: string, data: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: data,
    }));
    changeValue(name, data);
  };

  return (
    <div className="flex-row column">
      <InputTextAreaField
        id="mailAddr"
        value={value.mailAddr}
        label={t('emailAddress')}
        name="mailAddr"
        onChangeFunction={onChangeValue}
        errorStatus={value.mailAddr !== '' && !isValidMultipleEmail(value.mailAddr)}
        message="Please enter a valid mail address"
        placeHolder="Ex: 123@eee.com; 456@nnn.com"
      />
      <div className="text-danger flex-row center">
        <AttentionIcon />
        {t('multipleEmailMessage')}
      </div>
    </div>
  );
}

export default EmailLinkage;
