import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared-components/button/button';
import InputField from '../../../shared-components/input-field/input-field';
import device from '../../../utils/api/device';
import { useAppDispatch } from '../../../redux/store';
import { setNotificationError } from '../../../redux/slices/NotificationSlices';
import { ReplaceModal } from '../../../interface/device.interface';
import { ClearDataIcon } from '../../../assets/svgicons/svgicon';

function ReplaceDevice({ closeModal, selectedId }: ReplaceModal) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [sn, setSn] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const submit = () => {
    closeModal();
  };
  const isSupportDevice = () => {
    const request = {
      id: selectedId,
    };
    device
      .getDevIPAddressInfo(request)
      .then((res) => {
        if (res.data.success) {
          setErrorMsg(res?.data?.data?.failedReason);
        } else {
          dispatch(
            setNotificationError({
              error: res.data.msg,
              status: res.data.ret,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(
          setNotificationError({
            error: error.message,
            status: error.response?.status || 500,
          }),
        );
      });
  };
  useEffect(() => {
    isSupportDevice();
  }, [selectedId]);

  return (
    <div className="replace-device">
      <div className="form">
        <div className="row-input">
          <InputField
            name="sn"
            label={t('serialNumber')}
            id="sn"
            value={sn}
            onChangeFunction={(name, value) => setSn(value)}
            type="text"
            className=""
            required
          />
        </div>
        <div className="warning-message-wrap">
          {errorMsg ? (
            <div className="warning-msg">
              <ClearDataIcon />
              <span className="text-warning">{errorMsg}</span>
            </div>
          ) : (
            <>
              <div className="warning-msg">
                <ClearDataIcon />
                <span className="text-warning">{t('replaceWarningMessage1')}</span>
              </div>
              <div className="warning-msg">
                <ClearDataIcon />
                <span className="text-warning">{t('replaceWarningMessage2')}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="form-buttons-right">
        <Button
          onClickFunction={() => {
            closeModal();
          }}
          title={t('cancel')}
          className="btn-default"
        />
        <Button
          onClickFunction={submit}
          title={t('ok')}
          className="btn-primary"
          disabled={errorMsg !== ''}
        />
      </div>
    </div>
  );
}

export default ReplaceDevice;
