import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SwapTable from '../../../../shared-components/swapTables/swapTable';
import FormRightButton from '../../../../shared-components/form-right-buttons/FormRightButton';
import globalInterlock from '../../../../utils/api/AdvancedFunctions/globalInterlock';
import { setNotificationError } from '../../../../redux/slices/NotificationSlices';

import { ModalType, PersonProps } from '../../../../interface/globalInterlock.interface';
import { fetchGlobalInterlock } from '../../../../redux/slices/GlobalInterlockSlices';
import { useAppDispatch } from '../../../../redux/store';

function AddGroup({ closeModal, interlockId, groupIds }: ModalType) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [persons, setPersons] = useState<PersonProps[]>(); // Available persons
  const [selectedPerson, setSelectedPerson] = useState<PersonProps[]>([]); // Selected persons

  const tableColumns = [
    { label: t('groupName'), id: 'name' },
    { label: t('remarks'), id: 'remark' },
  ];

  const changeSearch = (event: any) => {
    setSelectedPerson(event.target.value);
  };

  const fetchLevel = () => {
    globalInterlock
      .getGrouplist({ interlockId, type: 'noSelected' })
      .then((res) => {
        setPersons(res.data.data.data);
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  const onTableSwap = (newAvailableData: PersonProps[], newSelectedData: PersonProps[]) => {
    setPersons(newAvailableData); // Update available data
    setSelectedPerson(newSelectedData); // Update selected data
  };

  const submit = () => {
    const selectedIds = selectedPerson.map((item: PersonProps) => item.id).join(',');
    const req = {
      interlockId,
      groupIds: selectedIds,
    };

    globalInterlock
      .addGroup(req)
      .then((response) => {
        if (response.data.success) {
          const message = t('groupAddedSuccessfully');
          dispatch(
            setNotificationError({
              error: message,
              status: 200,
            }),
          );
          closeModal();
          dispatch(fetchGlobalInterlock({ pageNo: 1, pageSize: 10 }));
        } else {
          dispatch(
            setNotificationError({
              error: response.data.message,
              status: response.data.code,
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(setNotificationError(error.response.data));
      });
  };

  useEffect(() => {
    fetchLevel();
  }, [groupIds]);

  return (
    <div className="access-rule-add-personnel flex-row column">
      <SwapTable
        searchValue=""
        changeSearch={changeSearch}
        tableColumns={tableColumns}
        tableData={persons}
        selectedTableData={selectedPerson}
        onTableSwap={onTableSwap}
      />
      <FormRightButton
        cancelLabel={t('cancel')}
        cancelFunction={() => closeModal()}
        submitLabel={t('done')}
        submitFunction={submit}
        disabled={selectedPerson.length === 0}
      />
    </div>
  );
}

export default AddGroup;
