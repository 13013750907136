/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Assuming you have a notification slice for errors
import { setNotificationError } from './NotificationSlices';
import verificationMode from '../../utils/api/AccessRule/verificationMode';
import { VerificationList } from '../../interface/VerificationMode.interface';

// Thunk for fetching the department list
export const fetchVerificationMode = createAsyncThunk(
  'fetchVerificationMode',
  async (data: any, { dispatch }) => {
    try {
      const response = await verificationMode.getVerificationModeList(data);
      return {
        ...response.data,
        data: {
          list: response.data.data.data,
          size: response.data.data.total,
          ...data,
        },
      };
    } catch (error: any) {
      dispatch(
        setNotificationError({
          error: error.message,
          status: error.response?.status || 500,
        }),
      );
      return error;
    }
  },
);

export const fetchTimeSegList = createAsyncThunk(
  'fetchTimeSegList',
  async (id: any, { dispatch }) => {
    try {
      const response = await verificationMode.getTimeSegList(id);
      const formattedResponse = response.data.data.map((item: any) => ({
        label: item.text,
        id: item.value,
      }));
      return formattedResponse;
    } catch (error: any) {
      dispatch(setNotificationError(error.response.data));
      throw error;
    }
  },
);
export const fetchDoorVerify = createAsyncThunk('fetchDoorVerify', async (_, { dispatch }) => {
  try {
    const response = await verificationMode.getDoorVerify();
    const formattedResponse = response.data.data.doorVerifyMode.map((item: any) => ({
      label: item.name,
      id: item.verifyNo,
    }));
    return formattedResponse;
  } catch (error: any) {
    dispatch(setNotificationError(error.response.data));
    throw error;
  }
});
export const fetchDoorList = createAsyncThunk('fetchDoorList', async (data: any, { dispatch }) => {
  try {
    const response = await verificationMode.getSelectDoorList(data);
    return {
      ...response.data,
      data: {
        list: [...new Set(response.data.data)],
        size: response.data.data.length,
        ...data,
      },
    };
  } catch (error: any) {
    dispatch(setNotificationError(error.response.data));
    throw error;
  }
});

interface LevelProps {
  equals: boolean;
  id: string;
  levelName: string;
  timeSegName: string;
}
// Initial state for the slice
interface StateProps {
  verificationModeList: VerificationList[]; // List of issued cards
  status: string;
  totalDataCount: number;
  currentPageNo: number;
  searchFilter: any;
  doors: LevelProps[];
  doorStatus: string;
  doorTotalDataCount: number;
  doorCurrentPageNo: number;
  doorSearchFilter: any;
  timeSegmentList: any[];
  doorVerifyList: any[];
}

const initialState: StateProps = {
  verificationModeList: [],
  status: 'success',
  totalDataCount: 0,
  currentPageNo: 0,
  searchFilter: {},
  doors: [],
  doorStatus: 'success',
  doorTotalDataCount: 0,
  doorCurrentPageNo: 0,
  doorSearchFilter: {},
  timeSegmentList: [],
  doorVerifyList: [],
};

// Create the slice
export const VerificationModeSlice = createSlice({
  name: 'accessByPerson',
  initialState,
  reducers: {
    // Reducer to set search filter
    setSearchFilter: (state, action: PayloadAction<any>) => {
      state.searchFilter = action.payload;
    },
    deleteVerifyRule: (state, action: PayloadAction<any>) => {
      state.verificationModeList = state.verificationModeList.filter(
        (val: any) => val.id !== action.payload,
      );
      state.totalDataCount -= 1;
    },
    deleteDoor: (state, action: PayloadAction<any>) => {
      state.doors = state.doors.filter((val: any) => val.id !== action.payload);
      state.doorTotalDataCount -= 1;
    },
    setDoorSearchFilter: (state, action: PayloadAction<any>) => {
      state.doorSearchFilter = action.payload;
    },
    resetLevels: (state) => {
      state.doors = [];
    },
  },
  extraReducers: (builder) => {
    // Handle fetchVerificationMode actions
    builder
      .addCase(fetchVerificationMode.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVerificationMode.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.totalDataCount = action.payload.data.size;
          state.verificationModeList = action.payload.data.list;
        }
        state.status = 'success';
      })
      .addCase(fetchVerificationMode.rejected, (state) => {
        state.status = 'failed';
      });
    builder
      .addCase(fetchDoorList.pending, (state) => {
        state.doorStatus = 'loading';
      })
      .addCase(fetchDoorList.fulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.code === 0) {
          state.doorTotalDataCount = action.payload.data.size;
          state.doorCurrentPageNo = action.payload.data.pageNo;

          // For pagination: append data if not the first page
          state.doors = action.payload.data.list;
        }
        state.doorStatus = 'success';
      })
      .addCase(fetchDoorList.rejected, (state) => {
        state.doorStatus = 'failed';
      });
    builder.addCase(fetchTimeSegList.fulfilled, (state: any, action: any) => {
      state.timeSegmentList = action.payload; // Only assign action.payload
    });
    builder.addCase(fetchDoorVerify.fulfilled, (state: any, action: any) => {
      state.doorVerifyList = action.payload; // Only assign action.payload
    });
  },
});

// Action creators are generated for each case reducer function
export const { setSearchFilter, setDoorSearchFilter, resetLevels, deleteVerifyRule, deleteDoor } =
  VerificationModeSlice.actions;

export default VerificationModeSlice.reducer;
