import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from '../../../../shared-components/modal-box/modal-box';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import SelectGlobalLinkage from './selectGlobalLinkage';
import SelectOutPoint from './selectOutputPoint';
import SelectBoxField from '../../../../shared-components/selectbox-field/selectbox-field';
import InputField from '../../../../shared-components/input-field/input-field';
import Label from '../../../../shared-components/label/Label';
import { DeviceProps } from '../../../../interface/accessByDepartment.interface';
import globalLinkage from '../../../../utils/api/AdvancedFunctions/globalLinkage';

interface ModalTypeProps {
  data?: any;
}
function GlobalOutputPoint({ data }: ModalTypeProps) {
  const { t } = useTranslation();
  const [value, setValue] = useState(
    data || {
      doorActionType: '',
      doorActionTime: '20',
      auxActionType: '',
      auxoutActionTime: '20',
      auxoutActionType: '',
      selectedDevice: [],
      selectedDoors: [],
      outputDoorName: [],
    },
  );
  const onChangeValue = (name: string, datas: any) => {
    setValue((prev: any) => ({
      ...prev,
      [name]: datas,
    }));
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    content: <>hi</>,
  });
  const tableColumns = [
    { label: t('deviceName'), id: 'name' },
    { label: t('ownedDevice'), id: 'deviceSn' },
    { label: t('serialNumber'), id: 'deviceSn' },
  ];
  const [search, setSearch] = useState('');

  const changeSearch = (name: string, val: any) => {
    setSearch(val);
  };

  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [selectedDoors, setSelectedDoors] = useState<any[]>([]);
  const getSelectedDevice = (selected: DeviceProps[]) => {
    const deviceValue = selected.map((item: any) => ({
      label: item.doorName,
      id: item.id,
      value: item.id,
    }));
    setSelectedDevices(deviceValue);
    setValue((prev: any) => ({
      ...prev,
      selectedDevice: deviceValue.map((item: any) => item.id),
    }));
  };
  const getSelectedDoor = (selected: DeviceProps[]) => {
    const deviceValue = selected.map((item: any) => ({
      label: item.name,
      id: item.id,
      value: item.id,
    }));
    setSelectedDoors(deviceValue);
    setValue((prev: any) => ({
      ...prev,
      selectedDoors: deviceValue.map((item: any) => item.id),
    }));
  };
  const handleClickDevice = () => {
    setModalView({
      title: t('selectDoor'),
      content: (
        <SelectGlobalLinkage
          searchValue={search}
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          getSelected={(selected: DeviceProps[]) => getSelectedDevice(selected)}
          closeModal={() => setModalOpen(false)}
          initialValues={value?.selectedDevice}
        />
      ),
    });
    setModalOpen(true);
  };
  const handleClickDevices = () => {
    setModalView({
      title: t('selectTheOutputPoint'),
      content: (
        <SelectOutPoint
          searchValue=""
          changeSearch={changeSearch}
          tableColumns={tableColumns}
          getSelected={(selected: DeviceProps[]) => getSelectedDoor(selected)}
          closeModal={() => setModalOpen(false)}
          initialValues={value?.selectedDevice}
        />
      ),
    });
    setModalOpen(true);
  };

  useEffect(() => {
    if (value.id) {
      globalLinkage.edit(value.id).then((res) => {
        setSelectedDevices([
          {
            label: res.data.data.item.outputDoorName,
            value: res.data.data.item.outputDoorAddr,
            id: res.data.data.item.outputDoorAddr,
          },
        ]);
        setValue((prev: any) => ({
          ...prev,
          selectedDevice: res.data.data.item.outputDoorAddr,
        }));
      });
    }
  }, [value?.id]);

  return (
    <div className="flex-row column output-point">
      <ModalBox
        status={modalOpen}
        closeModal={() => setModalOpen(false)}
        title={modalView.title}
        content={modalView.content}
      />
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('door')} />
            <span className="add-link" role="presentation" onClick={handleClickDevice}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="selectedDevice"
              data={selectedDevices}
              value={value?.selectedDevice}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
          <SelectBoxField
            id="doorActionType"
            label={t('actionType')}
            value={value?.doorActionType}
            name="doorActionType"
            data={[
              { label: t('close'), id: '0' },
              { label: t('open'), id: '1' },
              { label: t('normallyOpen'), id: '255' },
            ]}
            onChangeFunction={(name, val) => onChangeValue(name, val.id)}
            required
          />
          {value?.doorActionType === '1' && (
            <InputField
              name="doorActionTime"
              label={t('actionTimeDelay')}
              id="doorActionTime"
              type="text"
              value={value.doorActionTime}
              onChangeFunction={onChangeValue}
              validation
              message="s(1-254)"
              required
            />
          )}
        </div>
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('auxiliaryOutput')} />
            <span className="add-link" role="presentation" onClick={handleClickDevices}>
              {t('add')}
            </span>
          </div>
          <div className="box">
            <CheckBoxInputField
              name="selectedDevice"
              data={selectedDoors}
              value={value?.selectedDoors}
              onChangeFunction={onChangeValue}
              alignment="column"
            />
          </div>
          <SelectBoxField
            id="auxoutActionType"
            label={t('actionType')}
            value={value?.auxoutActionType}
            name="auxoutActionType"
            data={[
              { label: t('close'), id: '0' },
              { label: t('open'), id: '1' },
              { label: t('normallyOpen'), id: '255' },
            ]}
            onChangeFunction={(name, val) => onChangeValue(name, val.id)}
            required
          />
          {value?.auxoutActionType === '1' && (
            <InputField
              name="auxoutActionTime"
              label={t('actionTimeDelay')}
              id="auxoutActionTime"
              type="text"
              value={value.auxoutActionTime}
              onChangeFunction={onChangeValue}
              validation
              message="s(1-254)"
              required
            />
          )}
        </div>
      </div>
    </div>
  );
}

GlobalOutputPoint.defaultProps = {
  data: [],
};
export default GlobalOutputPoint;
