import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagePreviewIcon, RemoveIcon } from '../../../assets/svgicons/svgicon';
import { FileUploadInterface } from '../../../interface/map.interface';

function FileUpload({
  label,
  name,
  id,
  value,
  removeFunction,
  onChangeFunction,
  required = false,
}: FileUploadInterface) {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);

  const selectFile = () => {
    inputFile?.current?.click();
  };
  const onChangeFile = (e: any): void => {
    onChangeFunction(e.target.name, e.target.files[0]);
  };
  const removeImage = (): void => {
    removeFunction();
  };
  return (
    <div className="file-upload">
      <div className="label">
        {label} {required && <span>*</span>}
      </div>
      <div className={`file-input ${value?.name ? 'view' : ''}`}>
        {value?.name ? (
          <div className="badge-wrapper">
            <div className="badge">
              <ImagePreviewIcon />
              <span className="file-name" title={value?.name}>
                {value?.name}
              </span>
            </div>
            <div className="remove-icon" role="presentation" onClick={removeImage}>
              <RemoveIcon />
            </div>
          </div>
        ) : (
          <p className="file-text">
            {t('dragAndDropFileHereOr')}&nbsp;
            <span onClick={selectFile} role="presentation" className="text-secondary">
              {t('chooseFile')}
            </span>
          </p>
        )}
        <input
          type="file"
          name={name}
          id={id || name}
          ref={inputFile}
          onChange={onChangeFile}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
}

export default FileUpload;
