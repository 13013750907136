import { post } from '../base/index';

export default {
  listByPage: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/globalInterlockGroup/listByPage`,
      {},
      { ...data },
    );
  },
  getPersonDoorList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalInterlockDoor/list`, data);
  },

  getDoorlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalInterlockSelectDoor/list`, data);
  },

  addDoors: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlockGroup/addDoors`, {}, data);
  },

  isExistName: (name: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/valid?name=${name}`, {}),

  saveGroup: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/globalInterlockGroup/save`, data, {});
  },

  deleteGroup: (id: string = '') => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/globalInterlockGroup/delete?ids=${id}`,
      {},
    );
  },
  convertAccGlobalInterlockCount: (data: any = {}) => {
    return post(
      `${process.env.REACT_APP_API_URL}Acc/api/globalInterlockGroup/convertAccGlobalInterlockCount`,
      {},
      data,
    );
  },

  delDoors: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accGlobalInterlockDoor/delete`, {}, data);
  },
};
