import { post, get } from '../base/index';

export default {
  getListPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/auxIn/auxInList`, {}, { ...data });
  },
  getTimeSegList: () => {
    return get(`${process.env.REACT_APP_API_URL}Acc/api/timeSeg/getTimeSegList`, {});
  },
  saveAuxIn: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/auxIn/Edit`, data, {});
  },
};
