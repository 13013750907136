import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxInputField from '../../../../shared-components/checkbox-input-field/checkbox-input-field';
import Label from '../../../../shared-components/label/Label';

interface CheckBoxInterFace {
  id: string;
  label: string;
  value: string;
  isChecked?: boolean;
}

function GlobalMusterPoint() {
  const { t } = useTranslation();
  const [selectedDevices, setSelectedDevices] = useState<CheckBoxInterFace[]>([
    { id: 'MST', value: 'MST', label: 'MST', isChecked: true },
    { id: '123', value: '123', label: '123', isChecked: true },
  ]);

  const handleCheckboxChange = (name: string, data: string[]) => {
    setSelectedDevices(
      selectedDevices.map((device) => {
        const isSelected = data.includes(device.value);
        return { ...device, isChecked: isSelected };
      }),
    );
  };

  return (
    <div className="flex-row column output-point">
      <div className="flex-row linkage-box">
        <div className="box-wrapper">
          <div className="header">
            <Label title={t('musterpoint')} />
          </div>
          <div className="box">
            <CheckBoxInputField
              name="selectedDevice"
              data={selectedDevices}
              value={selectedDevices
                .filter((device) => device.isChecked)
                .map((device) => device.value)}
              onChangeFunction={handleCheckboxChange}
              alignment="column"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalMusterPoint;
