import { post } from '../base/index';

export default {
  listByPage: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/list`, {}, { ...data });
  },
  getPersonGroupList: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/personList`, data);
  },

  getPersonlist: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accPerson/selectPersonlist`, data);
  },

  addPersonToLevel: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/addPerson`, {}, data);
  },

  isExistName: (name: string) =>
    post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/valid?name=${name}`, {}),

  saveMultiPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/save`, data, {});
  },

  deleteMultiPerson: (id: string = '') => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/delete?ids=${id}`, {});
  },

  delPerson: (data: any = {}) => {
    return post(`${process.env.REACT_APP_API_URL}Acc/api/accCombOpenPerson/delPerson`, {}, data);
  },
};
